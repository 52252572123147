import styled from "styled-components";

const NewsButton = styled.button`
	background:#7160FF;
	color:white;
	font-size:15px;
	border-radius:29px;
	font-family: 'Montserrat', sans-serif;
	font-weight:700;
	padding:11px 40px;
	border:0px;
	margin:0px 0px 192px 0px;
`

export default NewsButton