
import React from 'react';
import ContactForm  from '../../components/ContactForm'
import {ContactFormStyles} from './Styles'

const ContactFormMobile  = (props) => {
    return (
        <ContactFormStyles>
            <ContactForm />
        </ContactFormStyles>
    )
}

export default ContactFormMobile