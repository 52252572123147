export const  data_1 = [
    ["Ø 4 mm","Ø 4.5 mm","Ø 5 mm","Ø 6 mm"],
    ["L. 7 mm","L. 7 mm","L. 7 mm","L. 7 mm"],
    ["Ø 3.3 mm","Ø 3.6 mm","Ø 4.2 mm","Ø 5.2 mm"],
    ["Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm"],
    ["ACY40070NP00I","ACY45070NP00I","ACY50070NP00I","ACY60070NP00I"]
]
export const data_2 = [
    ["Ø 3.3 mm","Ø 3.75 mm","Ø 4 mm","Ø 4.5 mm","Ø 5 mm","Ø 6 mm"],
    ["L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm-L. 17 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm-L. 17 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm-L. 17 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm-L. 17 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm-L. 17 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm"],
    ["Ø 2.5 mm","Ø 2.5 mm","Ø 2.5 mm","Ø 2.8 mm","Ø 3.2 mm","Ø 3.5 mm"],
    ["Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm"],
    ["ACY33100NP00I-ACY33115NP00I-ACY33130NP00I-ACY33150NP00I-ACY33170NP00I","ACY37080NP00I-ACY37100NP00I-ACY37115NP00I-ACY37130NP00I-ACY37150NP00I-ACY37170NP00I","ACY40080NP00I-ACY40100NP00I-ACY40115NP00I-ACY40130NP00I-ACY40150NP00I-ACY40170NP00I","ACY45080NP00I-ACY45100NP00I-ACY45115NP00I-ACY45130NP00I-ACY45150NP00I-ACY45170NP00I","ACY50080NP00I-ACY50100NP00I-ACY50115NP00I-ACY50130NP00I-ACY50150NP00I-ACY50170NP00I","ACY60080NP00I-ACY60100NP00I-ACY60115NP00I-ACY60130NP00I"]
]
export const data_3 = [
    ["Ø 3.3 mm","Ø 3.75 mm","Ø 4.2 mm","Ø 5 mm"],
    ["L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm","L. 8 mm-L. 10 mm-L. 11.5 mm-L. 13 mm-L. 15 mm"],
    ["Ø 3.2 mm","Ø 3.2 mm","Ø 3.2 mm","Ø 4.0 mm"],
    ["Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm","Ø 3.5 mm"],
    ["AIF33100N2000-AIF33115N2000-AIF33130N2000-AIF33150N2000","AIF37080N2000-AIF37100N2000-AIF37115N2000-AIF37130N2000-AIF37150N2000","AIF42080N2000-AIF42100N2000-AIF42115N2000-AIF42130N2000-AIF42150N2000","AIF50080N2000-AIF50100N2000-AIF50115N2000-AIF50130N2000-AIF50150N2000"]
]
