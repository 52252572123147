import React,{useState} from 'react'
import Plates from "../../maxillofacial/desktop/Plates"
import dataGenerator from "./data.js";
import i18n from "i18next";

const MiniplatingStandardSystemPlates = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Plates
			self={"miniplatingStandardSystem"}
			navigate={"miniplating_standard_system"}
			titles={["miniplatingStandardSystem.plates.titles.1","miniplatingStandardSystem.plates.titles.2","miniplatingStandardSystem.plates.titles.3","miniplatingStandardSystem.plates.titles.4","miniplatingStandardSystem.plates.titles.5","miniplatingStandardSystem.plates.titles.6", "miniplatingStandardSystem.plates.titles.7","miniplatingStandardSystem.plates.titles.8","miniplatingStandardSystem.plates.titles.9","miniplatingStandardSystem.plates.titles.10","miniplatingStandardSystem.plates.titles.11","miniplatingStandardSystem.plates.titles.12","miniplatingStandardSystem.plates.titles.13","miniplatingStandardSystem.plates.titles.14","miniplatingStandardSystem.plates.titles.15","miniplatingStandardSystem.plates.titles.16","miniplatingStandardSystem.plates.titles.17","miniplatingStandardSystem.plates.titles.18","miniplatingStandardSystem.plates.titles.19","miniplatingStandardSystem.plates.titles.20","miniplatingStandardSystem.plates.titles.21","miniplatingStandardSystem.plates.titles.22","miniplatingStandardSystem.plates.titles.23"]}
			data={[data.platesData_1,data.platesData_2,data.platesData_3,data.platesData_4,data.platesData_5,data.platesData_6,data.platesData_7,data.platesData_8,data.platesData_9,data.platesData_10,data.platesData_11,data.platesData_12,data.platesData_13,data.platesData_14,data.platesData_15,data.platesData_16,data.platesData_17,data.platesData_18,data.platesData_19,data.platesData_20,data.platesData_21,data.platesData_22,data.platesData_23]}
		/>
	)
}

export default (MiniplatingStandardSystemPlates)