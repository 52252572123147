/*----- Core -----*/
import React from 'react'
import { Container } from "react-bootstrap";

/*----- Commons -----*/
import "../../../commons/i18n";
import { Trans } from "react-i18next";
import { DesktopStyles } from "../Styles.js"

/*----- Private -----*/
import data from "../Data"
import Catalog from "../Catalog"

const Download = () => {

	const getItems = () => {
		return data.map( (item, idx)=>(
			<Catalog
				key = {idx}
				title = {item.title}
				image = {item.image}
				pdf = {item.pdf}
				filename = {item.filename}
				links = {item.links}
				name={item.name}
			/>
		))
	}

	return (
		<DesktopStyles>
			<div className="content-page">
				<div className="titles">
					<h1><Trans>descarga.title</Trans></h1>
				</div>
				<Container>
					<div className="row-content">
						{getItems()}
					</div>
				</Container>
			</div>
		</DesktopStyles>
	);
};

export default Download;
