import React, {useState} from 'react'
import Plates from "../../maxillofacial/desktop/Plates"
import dataGenerator from "./data.js";
import i18n from "i18next";

const MiniPlatingLowProfilePlates = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Plates
			self={"miniplatingLowProfile"}
			navigate={"miniplating_low_system"}
			titles={["miniplatingLowProfile.plates.titles.1","miniplatingLowProfile.plates.titles.2","miniplatingLowProfile.plates.titles.3","miniplatingLowProfile.plates.titles.4","miniplatingLowProfile.plates.titles.5","miniplatingLowProfile.plates.titles.6","miniplatingLowProfile.plates.titles.7","miniplatingLowProfile.plates.titles.8","miniplatingLowProfile.plates.titles.9","miniplatingLowProfile.plates.titles.10","miniplatingLowProfile.plates.titles.11","miniplatingLowProfile.plates.titles.12","miniplatingLowProfile.plates.titles.13","miniplatingLowProfile.plates.titles.14","miniplatingLowProfile.plates.titles.15","miniplatingLowProfile.plates.titles.16","miniplatingLowProfile.plates.titles.17","miniplatingLowProfile.plates.titles.18"]}
			data={[data.platesData_1,data.platesData_2,data.platesData_3,data.platesData_4,data.platesData_5,data.platesData_6,data.platesData_7,data.platesData_8,data.platesData_9,data.platesData_10,data.platesData_11,data.platesData_12,data.platesData_13,data.platesData_14,data.platesData_15,data.platesData_16,data.platesData_17,data.platesData_18]}
		/>
	)
}

export default (MiniPlatingLowProfilePlates)