/*----- Imports -----*/
import React, { useState } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import {navigate} from 'hookrouter'
/*----- Assets -----*/
import logo from "../../../../assets/images/logo.png";

/*----- Components -----*/
import NavButton from "../../../button/NavButton";
import { ModalSmall } from '../../../modals/ModalSmall';
import NavigationConfirmModal from '../../../modals/NavigationConfirmModal'

/*----- Commons -----*/
import { Trans } from "react-i18next";
import Device from "../../../../commons/Device";
import Montserrat from "../../../../commons/mixins/fonts/Montserrat";
import Color from "../../../../commons/Color";

/*----- Styles -----*/
const StyledHeader = styled.header`
	display: flex;
	z-index: 999;
	position: ${props => props.fix ? "fixed" : "relative"};
	width:100%;
	height: 60px;
	align-items: center;
	justify-content: space-between;
	padding: 0 0px 3px 0px;
	box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
	background:#1e1e1e;

	.NewsButton {
	${Montserrat(14, 400, "black")};
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: capitalize;
	height: 46px;
	padding: 0 20px;
	min-width: 100px;
	justify-content: center;
	max-width: 200px;
	border: none;
	transition: all 0.3s ease-out;
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${Color.main};
		border-bottom: 1px solid ${Color.main};
	}
	}

	.logo-container {
		@media  screen and (max-width: 1440px) {
			margin-left: 7.5%;
		}
		margin-left:140px;
		margin-bottom:5px;
		height:100%;
		z-index:999;
		cursor:pointer;
		img {
			height: inherit;
		}
	}
	.nav-button-container {
		display: flex;
		align-items: center;
		margin-right:140px;
		@media  screen and (max-width: 1440px) {
			margin-right: 7.4%;
		}
	}

	@media ${Device.lg} {
		.btn-menu {
			display: none;
		}
	}

	.nav-button-container .btn-menu {
		border: none;
		font: inherit;
		color: inherit;
		background-color: transparent;
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
	a{
		color:white;
		text-decoration:none;
		font-size:15px;
	}
	a:hover{
		text-decoration:none;
		color:#9f6ace;
		border-color:#9f6ace;
	}
	${props =>
		!props.visible &&
		css`
			@media screen and (max-width: 768px) {
				.nav-button-container a {
					display: none;
				}
				.nav-button-container button.btn-menu {
					float: right;
					display: block;
					}
				}
			}
		`}
	${props =>
		props.visible &&
		css`
			@media screen and (max-width: 768px) {
				.nav-button-container {
					position: absolute;
					z-index: 2;
					display: inline;
					background: white;
					top: 8px;
					right: 20px;
					border: 1px solid lightgray;
					padding-top: 20px;
					border-radius: 5px;
					-webkit-box-shadow: lightgray 5px 5px 5px;
					-moz-box-shadow: lightgray 5px 5px 5px;
					box-shadow: lightgray 5px 5px 5px;
				}
				.nav-button-container button.btn-menu {
					right: 0px;
					position: absolute;
					top: 0px;
					.btn-content {
						display: none;
					}
					&:after {
						content: "✕";
						font-size: 25px;
					}
				}
				.nav-button-container {
					a:hover {
						border-bottom: none;
						font-weight: 600;
						color: #5028cc;
					}
				}
				.nav-button-container a {
					float: none;
					display: block;
					text-align: left;
				}
			}
		`}
`;


/*----- Component -----*/
const Header = (props) => {
	

	const [visible, setVisible] = useState(false);

	
	const [openFaico360NavModal, setOpenFaico360NavModal] = useState(false);
	const Faico360NavModalContainer = ModalSmall(NavigationConfirmModal);

	function openFaico360NavModalCmd(){
		setOpenFaico360NavModal(true);
	}

	function closeFaico360NavModal(){
		setOpenFaico360NavModal(false);
	}


	return (
		<StyledHeader visible={visible} ref={props.header} fix={props.fix}>
			<div className="logo-container" onClick={() => navigate("/")}>
				<img src={logo} alt="logo" />
			</div>
			<div className="nav-button-container">
				<NavButton content={<Trans>home.self</Trans>} destination="/" />
				<NavButton content={<Trans>about.self</Trans>} destination="/about" />
				<NavButton content={<Trans>dental.self</Trans>} destination="/dental" />
				<NavButton content={<Trans>maxillofacial.self</Trans>} destination="/maxillofacial" />
				<NavButton content={<Trans>descarga.self</Trans>} destination="/download" />
				<NavButton content={<Trans>faico360.self</Trans>} destination="" onClick={openFaico360NavModalCmd} />
				{/* <NavButton content={<Trans>news.self</Trans>} destination="/news" /> */}
				<NavButton content={<Trans>videos.self</Trans>} destination="/videos" />
				<NavButton content={<Trans>contact.self</Trans>} destination="/contact" />
				<button className="btn-menu" onClick={() => setVisible(!visible)}>
					<div className="btn-content">&#9776;</div>
				</button>
			</div>
			<Faico360NavModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeFaico360NavModal}
					open={openFaico360NavModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="faico360"
				/>
		</StyledHeader>
	);
};

export default Header;
