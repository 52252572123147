import React,{useState} from 'react'
import Plates from "../../maxillofacial/desktop/Plates"
import {dataGenerator} from "./data.js";
import i18n from "i18next";

const MicroPlatingPlates = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Plates
			self={"microPlatingSystem"}
			navigate={"microplating_system"}
      titles={["microPlatingSystem.plates.titles.1","microPlatingSystem.plates.titles.2","microPlatingSystem.plates.titles.3","microPlatingSystem.plates.titles.4","microPlatingSystem.plates.titles.5","microPlatingSystem.plates.titles.6","microPlatingSystem.plates.titles.7","microPlatingSystem.plates.titles.8","microPlatingSystem.plates.titles.9","microPlatingSystem.plates.titles.10","microPlatingSystem.plates.titles.11","microPlatingSystem.plates.titles.12"]}
      data={[data.platesData_1,data.platesData_2,data.platesData_3,data.platesData_4,data.platesData_5,data.platesData_6,data.platesData_7,data.platesData_8,data.platesData_9,data.platesData_10,data.platesData_11,data.platesData_12]}
		/>
	)
}

export default (MicroPlatingPlates)