
import React from 'react';
import {ContactFormStyles} from "./Styles";
import ContactForm  from '../../components/ContactForm'

const ContactFormDesktop  = () => {
	return (
		<ContactFormStyles>
			<ContactForm/>
		</ContactFormStyles>
	)
}
export default ContactFormDesktop