import styled from "styled-components";

export default styled.main`
    background:black;
    position:relative;
    background:rgba(28,28,28,1);
    .black-gradient{
        background: linear-gradient(to bottom,rgba(28,28,28,0) 0%,rgba(28,28,28,1) 32%);
        position: absolute;
        height: 186%;
        z-index: 12;
        width: 170%;
        top: 0%;
    }

    

	.card-content{
		/* background: linear-gradient(to bottom left,rgba(72,71,71,0) 15%,rgba(0,0,0,1) 100%); */
        background: linear-gradient(to bottom left,rgba(64, 62, 66, 0.24) 15%,rgba(0,0,0,1) 100%);
		margin:0px !important;
		border-radius:10px !important;
        margin:auto;
		transition: transform .2s; /* Animation */
        opacity:0.9;
        z-index:50;
			@media (min-width:1500px){
				margin-top:100px !important;
				height:100%
			}
			@media (min-width:1024px) and (max-width:1500px){
				margin-top:100px !important;
				height:85%
			}
	}
	.card-content:hover{
		transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

	}

    .logo-img{
        z-index:15;
        margin-top: 30px;
        width: 13%;
		height: auto;
    }
    .content-buttons{
        display:flex;
        width:100%;
        justify-content:center;
        button{
            background: transparent !important;
			width: 186px;
			height: 41px;
			z-index:15;
			color: white;
			border: 2.5px solid white;
			font-size:12px;
			font-weight:700;
			border-radius: 25px !important;
			font-family: 'Montserrat',sans-serif;
			padding:10px;
			margin:0 10%;
        }
        button:hover {
            background:white !important;
            border:1px solid black !important;
            color:black !important;
            transform: scale(1.03);
        }
        button:active{
            border: 1px solid white;
            box-shadow:none !important;
        }
        button:focus{
            border: 1px solid white;
            box-shadow:none !important;
        }
    }
	.card-body{
		display: flex;
		flex-direction: column;
        margin-top:30%;
        margin-right:5%;
        margin-left:5%;
        z-index:50;
	}
	.card-button{
		background:transparent !important;
		width:50%;
		border:1.5px solid white;
		border-radius:25px !important;
		font-family: 'Montserrat', sans-serif;
		font-weight:600;
		padding:10px 10px;
		margin-right:auto;
		margin-left:auto;

        z-index:50;
			@media (min-width:1500px){
				font-size:16px;
				margin-bottom:15px;
                margin-top: auto;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:14px;
				margin-bottom:0px;
                margin-top: auto;
			}
	}
	.card-button:hover {
		background:white !important;
		border:1.5px solid white;
		color:black;
	}
    .card-images{
        z-index:50;
        position:relative;
        display:flex;
        justify-content:center;
        .card-image-first{
            position:absolute;
            bottom:-0;
            z-index:50;
        }
        .card-image-two{
            position:absolute;
            bottom:-10px;
            z-index:50;
        }
        .card-image-three{
            position:absolute;
            bottom:-30px;
            z-index:50;
        }
        img{
            max-width:80%;
        }
    }
	.card-text{
		color:white;
        z-index:50;
		font-family: 'Montserrat', sans-serif;
			@media (min-width:1500px){
				font-size:1vw;
				margin-top:2%;
			}
			@media (min-width:1280px) and (max-width:1500px){
				font-size:14px;
				margin-top:1%;
			}
            @media (max-width:1280px){
				font-size:13px;
				margin-top:1%;
			}
	}
	.card-title{
        z-index:50;
		margin-bottom:0px;
		color:white;
		font-weight:700;
		font-family: 'Montserrat', sans-serif;
			@media (min-width:1500px){
				font-size:32px;
				margin-top:10px;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:24px;
				margin-top:0px;
			}
	}
    .container-cards{
        justify-content:space-around;
        margin-bottom:10%;
        z-index:50;
    }
    .container-page{
        margin:auto;
    }
    .filter-blur{
		filter:blur(5px);
	}
    .first-row{
        position:relative;
        width:100%;
        height:100vh;
        .images-central{
            padding-top:70px;
            display:flex;
            justify-content:center;
            position:relative;
            width:50%;
            padding-bottom: 7%;
            .img-central{
                position:absolute;
                z-index:11;
                max-width:50%;
            }
        }
    }
    .onda-gris-reverse{
        position: absolute;
        z-index: 15;
        left: -6%;
        bottom: -16%;
        transform: scaleX(-1);
    }
    .onda-gris{
        position: absolute;
        z-index: 10;
        top: 0%;
        right: -10%;
    }
    .pop-up-style-content{
        padding:0px !important;
        width:100% !important;
        background: linear-gradient(63deg, #484848 , #2F2F2F) !important;
        opacity:100%;
        border:0px solid !important;
        position:relative;
        overflow:hidden;
        border-radius:25px;
        margin:60px 113px 60px 114px !important;
	}

    .filter-blur-footer{
        width: 100%;
        height: 5000px;
        position: relative;
        bottom: 0%;
    }
    .second-gradient{
        background: linear-gradient(to bottom,rgba(0,0,0,1) 5%,rgba(0,0,0,1) 10%,rgba(50,50,50,0.7) 25%,rgba(45,45,45,1) 80%,rgba(0,0,0,0.8) );
        width: 100%;
        height: 50%;
        z-index: 14;
        position: absolute;
        top: 50%;
    }
    .second-row{
        position:relative;
        z-index:50;
    }
    .titles{
        top:39%;
        z-index:50;
        text-align:center;
        margin-top:-5%;
        z-index:50;
        h1{
            font-family: 'Montserrat', sans-serif;
            color:#EBEBEB;
            font-weight: 700;
            display:block;
            @media (min-width:1500px) {
                padding-right:13%;
                padding-left:13%;
                font-size:45px;
            }
            @media (max-width:1500px) {
                padding-right:5%;
                padding-left:5%;
                font-size:35px;
            }
        }
        h2{
            font-family: 'Montserrat', sans-serif;
            color:#F8F8F8;
            margin-top:20px;
            display:block;
            @media (min-width:1500px) {
                font-size:26px;
                padding-right:300px;
                padding-left:300px;
            }
        }   
    }

    .section1{
        position: relative;
        //border-bottom: 0.5px solid grey;
        background: #131313 0% 0% no-repeat padding-box;
        padding-bottom: 70px;
    }

    .section1-main-content{
        padding-top: 50px;
    }

    .section1-main-img{
        background: transparent 0% 0% no-repeat padding-box;
        opacity: 1;
        width: 378x;
        height: 491px;
    }

    .black-bg-gradient{
        background: linear-gradient(to bottom,rgba(28,28,28,0) 0%,rgba(28,28,28,1) 64%);
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 12;
    }

    .section2{
        background: transparent;
        position: relative;
        //border-bottom: 0.5px solid grey;
        padding-top: 120px;
    }

    .section2-background{
        height: 54% !important;
        top: 23%;
    }

    .bg-img{
        position: absolute;
        height: 100%;
        width: 100%;
    }
 

    .title-section1{
        text-align: left;
        font: normal normal 300 40px/50px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        z-index: 15;
    }

    .bold-title-section1{
        text-align: left;
        font: normal normal bold 40px/50px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        z-index: 15;
    }

    .text1-section2{
        text-align: left;
        font: normal normal bold 22px/31px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    .title1-section2{
        text-align: left;
        font: normal normal bold 33px/26px Montserrat;
        letter-spacing: -0.33px;
        color: #FFFFFF;
    }

    .text-section2{
        text-align: left;
        font: normal normal 300 20px/26px Montserrat;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .brand-section2{
        text-align: left;
        letter-spacing: NaNpx;
        font: normal normal bold 40px/45px Montserrat;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 90%;
    }
    .brand2-section2{
        text-align: left;
        letter-spacing: NaNpx;
        font: normal normal bold 33px/45px Montserrat;
        color: #FFFFFF;
        opacity: 1;
    }
    .img-section2{
        position: absolute;
        bottom:0;
        margin-left: 23%;
    }

    .footer-section2{
        text-align: left;
        font: normal normal bold 20px/31px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }

    .section3{
        position: relative;
        border-bottom: 0.5px solid grey;
        padding-top: 60px;
        background: #131313 0% 0% no-repeat padding-box;
    }

    .title-section3{
        text-align: left;
        font: normal normal bold 33px/45px Montserrat;
        letter-spacing: 0px;
        opacity: 1;
        color: #FFFFFF;
    }

    .title-connector-section3{
        text-align: left;
        font: normal normal bold 33px/45px Montserrat;
        letter-spacing: 0px;
        color: #49D3EA;
    }

    .texts-section3{
        text-align: left;
        font: normal normal normal 20px/22px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
    }

    .section3-background{
        left: -11%;
        width: 18%;
        height: auto;
        top:0;  
    }

    .section4{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
        position: relative;
        border-bottom: 0.5px solid grey;
    }

    .title-scetion4{
        text-align: center;
        font: normal normal bold 33px/45px Montserrat;
        letter-spacing: 0px;
        color: #131313;
    }

    .title-is_four{
        text-align: left;
        font: normal normal bold 30px/45px Montserrat;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1;
        margin-top: 20%;
    }

    .title-cyroth{
        text-align: left;
        font: normal normal bold 30px/45px Montserrat;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1;
        margin-top: 20%;
    }

    .text-section4{
        text-align: left;
        font: normal normal normal 20px/22px Montserrat;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1;
        margin-top: 24px;
    }

    .plus-button-section4{
        position:absolute;
        top: 40%;
        left: 50%;
        width: 40px;
        height: 40px;
        margin-left:-20px;
        border-radius: 20px;
        text-align: center;
        vertical-align: middle;
        background: #7160FF 0% 0% no-repeat padding-box;
        opacity: 1;
        color: #FFFFFF;
        
    }

    .plus-section4{
        display:inline-block;
        width:40px;
        height:40px;
        
        background:
          linear-gradient(#fff,#fff),
          linear-gradient(#fff,#fff);
        background-position:center;
        background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (25px)*/
        background-repeat:no-repeat;  
    }

    .main-row-section4{
        margin-bottom: 100px;
        margin-top: 60px;
    }

    .blue-dot{
		color: #49D3EA;
	}

    .section5{
        position: relative;
        border-bottom: 0.5px solid grey;
        background: #000000 0% 0% no-repeat padding-box;
        opacity: 1;
    }

   

    .title-section5{
        text-align: left;
        font: normal normal bold 33px/33px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-top: 70px;
    }

    .subtitle-section5{
        text-align: left;
        font: normal normal bold 33px/33px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-bottom:30px;
    }

    .section5-background{
        left: 0%;
        width: 18%;   
    }

    .text-section5{
        text-align: left;
        font: normal normal normal 20px/22px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 22px;
    }

    .section6{
        position: relative;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }

    .subtitle-section6{
        text-align: left;
        font: normal normal bold 32px/45px Montserrat;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1
        margin-top: 60px;
    }

    .section6-subsection{
        padding-top: 8%;
    }

    .text-content-section6{
        padding: 0 !important;
    }

    .section6-b{
        background: #F2F2F2 0% 0% no-repeat padding-box; 
    }

    .section6_onetoone{
        width: 163px;
        height: 163px;
    }
    .bg-img5-section6{
        width: 20%;
        height: auto;
        padding-top: 2rem;
    }

    .img-section6-4{
        position: relative;
        margin-bottom: 0px;
        margin-top: 13%;
    }

    .paragraph-section6{
        margin-top: 40px;
        margin-bottom: 10%;
    }

    .text-section6{
        text-align: left;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1;
        font: normal normal normal 20px/22px Montserrat;
    }

    .available-section6{
        margin-bottom: 50px;
        //margin-right: 45px;

    }

    .subtitle4-section6{
        text-align: left;
        font: normal normal bold 26px/45px Montserrat;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1;
        margin-top: 40px;
    }

    .section7{
        position: relative;
        border-bottom: 0.5px solid grey;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        opacity: 1;
    }

    .section7-subsection{
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .section7-text{
        text-align: left;
        font: normal normal normal 20px/22px Montserrat;
        letter-spacing: 0px;
        color: #131313;
        opacity: 1;
        padding-top: 40px;
    }

    

    .section8{
        position: relative;
        background: transparent
    }

    .section8-bg-img{
        width: vw;
        height: auto;
    }

    .section8-content{
        position: absolute;
        bottom: 8%;
    }


    .section8-title{
        text-align: center;
        font: normal normal bold 32px/35px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 25px;
    }

    .section8-text{
        text-align: center;
        letter-spacing: 0px;
        font: normal normal normal 20px/22px Montserrat;
        line-heigth: 24px;
        color: #E4E4E4;
        opacity: 1;
        margin-bottom: 25px;
        
    }

    .section8-button{
        background: #7160FF 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        opacity: 1;
        border-radius: 60px;
        border: none;
        font: normal normal bold 13px/23px Montserrat;
        width: 300px;
        height: 43px;
    }

    .footer{
        padding: 80px 0px 80px 0px; 
    }

    .footer-button{
        border: 1px solid #FFFFFF;
        background: transparent;
        opacity: 1;
        border-radius: 60px;
        font: normal normal bold 13px/23px Montserrat;
    }

    .invisible{
        visibility: hidden;
    }
`
