/*----- Imports -----*/
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Row, Image } from 'react-bootstrap'
import { element } from 'prop-types';
import { Trans } from "react-i18next";
import { navigate } from "hookrouter";

/*----- Styles -----*/
const StyledGalleryCard = styled.main`
	.card{
		//background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;
		background: transparent;
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center;
		margin-bottom: 5%;
		border-radius:32px;
		width: 85% !important;
		margin-left: 7.5%;
		margin-right: 2.5%;
		border: none;
	}
	
	
	button{
		background-color:transparent;
		border-radius:30px;
		border: 1px solid white;
		color:white;
		width: 100%;
	}

	

	.case-button{
		position:absolute;
		bottom: 0%;
		left: 10%;
		width: 40%;
		height: 44px;
		text-align: left;
		font: normal normal bold 14px/25px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
		text-align: center;
		padding-top: 2%;
	}



	.case-button:hover{
		background: #FFFFFF 0% 0% no-repeat padding-box;
		opacity: 1;
		font: normal normal bold Montserrat;
		letter-spacing: 0px;
		color: #333D98;
		opacity: 1;
		text-align: center;
	}

	
	.carrouselImg{
		max-width: 80vw;
		max-height: 100%;
	}

	.case-title{
		text-align: left;
		font: normal normal bold 20px/28px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
	}

	.case-code{
		position: relative;
		bottom: 4%;
		text-align: right;
		font: normal normal medium 16px/38px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
	}

	.row-no-padding{
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.no-padding{
		padding: 0 !important;
	}
  `;

/*----- Component -----*/

const GalleryCardMobile = ({ code, title, index, CarrouselImg }) => {

	useEffect(() => {
	}, []);


	function setClinicalCasesAnchor(){
		localStorage.setItem("goToCards", "true");
	}

	function getButton() {
		return (
			<div className="px-5">
			<a className="">
				<button className="case-button" 
				onClick={() => {
					navigate("/faico360/" + index)
					setClinicalCasesAnchor()
				}}
				>
				<Trans>faico360.ClinicalCaseCardButton</Trans></button>
			</a>
			</div>
		);
	}



	return (
		<StyledGalleryCard>
			<Row className="card" id={"card-"+code}>
				<Col>
					<Row  id={"img-"+code}>
						<Col className="no-padding">
							<Image src={CarrouselImg} className="carrouselImg"></Image>
						</Col>
					</Row>
					<Row className="row-no-padding mt-4">
						<span className="case-title">{title}</span>
					</Row>
					<Row className="pb-4">
						<p className="case-code">#{code}</p>
					</Row>
				</Col>
			</Row>
			{getButton()}
		
		</StyledGalleryCard>
	);
};
export default GalleryCardMobile;

/*----- PropTypes -----*/
/*PersonCard.propTypes = {
	name: PropTypes.string.isRequired,
	job: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	imageURL: PropTypes.string.isRequired,
}*/
