import styled from 'styled-components'

export default styled.div `
	display: flex;
	flex-direction: column;
	padding: 20px 0;
	width: 100%;
	margin: 16px 0;
	min-width: 150px;
`
