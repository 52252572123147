import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { Image } from "react-bootstrap";
import Arrow from "../../assets/icons/icon-components/Arrow.js"
import { Trans } from "react-i18next";
import { CatalogStyles } from "./Styles.js"
import i18n from "../../commons/i18n";
import RegisterMark from "../../components/registerMark";

import { BUCKET_URL } from '../../services/BaseUrl'

const Catalog = ({title, image, pdf, filename, links, name}) => {
	const [open, setOpen] = useState(false)

	const CardHeader = ({ children, eventKey, open, setOpen }) => {

		const decoratedOnClick = useAccordionToggle(eventKey, () => setOpen(!open));

		return (
			<Card.Header>
				<div className="container-toggle">
					<div onClick={decoratedOnClick}>
						<Arrow rotateDeg={open ? "90" : "270"} color='white' width="15px" heigth="15px" strokeWidth="20px" />
					</div>
				</div>
			</Card.Header>
		)
	}

	const content = () => {
		return links.map(link => (
			<div className="links-info">
				<a download target="_blank" href={`${BUCKET_URL}${i18n.language === 'es' ? link.refSpanish : link.refEnglish}`}><RegisterMark>{link.title}</RegisterMark></a>
				<div className='border-title' />
			</div>
		))
	}

	return (
		<CatalogStyles id="Catalog" open={open}>
			<Accordion>
				<div className="element">
					<div className="content-information">
						<div className="content-image">
							{name ? 
								<Image src={image} className="imageDental" />
							:
								<Image src={image} className="image" />
							}
						</div>
						<h1><Trans>{title}</Trans></h1>
					</div>
					{links.length > 0 ?
						<Card className="card">
							<CardHeader eventKey="0" open={open} setOpen={setOpen}></CardHeader>
						</Card>
						:
						<div className="white-space"/>
					}
				</div>
				<Accordion.Collapse eventKey="0" className="accordion-collapse">
					<Card.Body>
						{content()}
					</Card.Body>
				</Accordion.Collapse>
			</Accordion>
		</CatalogStyles>
	)
}

export default Catalog
