import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_1.png"
import Image_1_2 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_2.png"
import Image_1_3 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_3.png"
import Image_1_4 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_4.png"
import Image_1_5 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_5.png"
import Image_1_6 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_6.png"
import Image_1_7 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_7.png"
import Image_1_8 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_8.png"
import Image_1_9 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_9.png"
import Image_1_10 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_10.png"
import Image_1_11 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_11.png"
import Image_1_12 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_12.png"
import Image_1_13 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_13.png"
import Image_1_14 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_14.png"
import Image_1_15 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_15.png"
import Image_1_16 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_16.png"
import Image_1_17 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_17.png"
import Image_1_18 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_18.png"
import Image_1_19 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_19.png"
import Image_1_20 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_20.png"
import Image_1_21 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_21.png"
import Image_1_22 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_22.png"
import Image_1_23 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_23.png"
import Image_1_24 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_24.png"
import Image_1_25 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_25.png"
import Image_1_26 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_26.png"
import Image_1_27 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_27.png"
import Image_1_28 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_28.png"
import Image_1_29 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_29.png"
import Image_1_30 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_30.png"
import Image_1_31 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_31.png"
import Image_1_32 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_32.png"
import Image_1_33 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_33.png"
import Image_1_34 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_34.png"
import Image_1_35 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_35.png"
import Image_1_36 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_36.png"
import Image_1_37 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_37.png"
import Image_1_38 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Plates/image_38.png"

export function dataGenerator(lng) {
  const platesData_1_description_en = ["Straight Plate. 4-holes. ", "Straight Plate. 6-holes. ", "Straight Plate. 8-holes. ", "Straight Plate. 12-holes. ", "Straight Plate. 16-holes. ", "Straight Plate. 24-holes. "]
  const platesData_1_description_es = ["Placa Recta. 4-agujeros.", "Placa Recta. 6-agujeros. ", "Placa Recta. 8-agujeros. ", "Placa Recta. 12-agujeros. ", "Placa Recta. 16-agujeros. ", "Placa Recta. 24-agujeros. "]

  const platesData_2_description_en = ["L Plate. 5-holes. Left.", "L Plate. 5-holes. Right.", "L Plate. 8-holes. Left.", "L Plate. 8-holes. Right."]
  const platesData_2_description_es = ["Placa L. 5-agujeros. Izquierda.", "Placa L. 5-agujeros. Derecha.", "Placa L. 8-agujeros. Izquierda.", "Placa L. 8-agujeros. Derecha."]

  const platesData_3_description_en = ["T Plate. 5-holes.", "T Plate. 7-holes.", "T Plate. 7-holes."]
  const platesData_3_description_es = ["Placa T. 5-agujeros.", "Placa T. 7-agujeros.", "Placa T. 7-agujeros."]

  const platesData_4_description_en = ["Double T Plate. 6-holes."]
  const platesData_4_description_es = ["Doble Placa T. 6-agujeros."]

  const platesData_5_description_en = ["Y Plate. 6-holes."]
  const platesData_5_description_es = ["Placa Y. 6-agujeros."]

  const platesData_6_description_en = ["Double Y Plate. 6-holes.", "Double Y Plate. 7-holes.", "Double Y Plate. 9-holes."]
  const platesData_6_description_es = ["Doble Placa Y. 6-agujeros.", "Doble Placa Y. 7-agujeros.", "Doble Placa Y. 9-agujeros."]

  const platesData_7_description_en = ["H Plate. 7-holes.", "H Plate. 9-holes.", "H Plate. 14-holes."]
  const platesData_7_description_es = ["Placa H. 7-agujeros.", "Placa H. 9-agujeros.", "Placa H. 14-agujeros."]

  const platesData_8_description_en = ["Orbital Plate. 4-holes.", "Orbital Plate. 8-holes."]
  const platesData_8_description_es = ["Placa Orbital. 4-agujeros.", "Placa Orbital. 8-agujeros."]

  const platesData_9_description_en = ["Burr Hole Cover.", "Burr Hole Cover.", "Burr Hole Cover."]
  const platesData_9_description_es = ["Placa de Recubrimiento de Tépano.", "Placa de Recubrimiento de Tépano.", "Placa de Recubrimiento de Tépano."]

  const platesData_9_detail_en = ["Ø 7 mm.", "Ø 10 mm.", "Ø 14 mm."]
  const platesData_9_detail_es = ["Ø 7 mm.", "Ø 10 mm.", "Ø 14 mm."]

  const platesData_10_description_en = ["Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate.", "Quadratic Plate."]
  const platesData_10_description_es = ["Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática.", "Placa Cuadrática."]

  const platesData_10_detail_en = ["2x2-holes.", "3x2-holes.", "4x2-holes.", "4x4-holes.", "6x4-holes."]
  const platesData_10_detail_es = ["2x2-agujeros.", "3x2-agujeros.","4x2-agujeros.", "4x4-agujeros.", "6x4-agujeros."]

  const platesData_11_description_en = ["Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate.", "Rectangular Plate. Zygoma."]
  const platesData_11_description_es = ["Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular.", "Placa Rectangular."]

  const platesData_11_detail_en = ["2x2-holes.", "3x2-holes.", "4x2-holes.", "4x4-holes.", "6x4-holes.", "4x2-holes."]
  const platesData_11_detail_es = ["2x2-agujeros.", "3x2-agujeros.", "4x2-agujeros.", "4x4-agujeros.", "6x4-agujeros.", "4x2-agujeros."]

  const platesData_12_description_en = ["Titan Micro Mesh. Thickness 0.1mm.", "Titan Micro Mesh. Thickness 0.1mm.", "Titan Micro Mesh. Thickness 0.1mm.", "Titan Micro Mesh. Thickness 0.2mm.", "Titan Micro Mesh. Thickness 0.2mm.", "Titan Micro Mesh. Thickness 0.2mm."]
  const platesData_12_description_es = ["Titan Micro Mesh. Espesor 0.1mm.", "Titan Micro Mesh. Espesor 0.1mm.", "Titan Micro Mesh. Espesor 0.1mm.", "Titan Micro Mesh. Espesor 0.2mm.", "Titan Micro Mesh. Espesor 0.2mm.", "Titan Micro Mesh. Espesor 0.2mm."]

  const platesData_12_detail_en = ["Measure 90x120mm.", "Measure 60x120mm.", "Measure 60x60mm.", "Measure 90x120mm.", "Measure 60x120mm.", "Measure 60x60mm."]
  const platesData_12_detail_es = ["Medida 90x120mm.", "Medida 60x120mm.", "Medida 60x60mm.", "Medida 90x120mm.", "Medida 60x120mm.", "Medida 60x60mm."]

  const platesData_1 = [
    ["MICP-0.6-4-S", "MICP-0.6-6-S", "MICP-0.6-8-S", "MICP-0.6-12-S", "MICP-0.6-16-S", "MICP-0.6-24-S"],
    lng === "en" ? platesData_1_description_en : platesData_1_description_es,
    [Image_1_1, Image_1_2, Image_1_3, Image_1_4, Image_1_5, Image_1_6]
  ]
  const platesData_2 = [
    ["MICP-0.6-5-L-L", "MICP-0.6-5-L-R", "MICP-0.6-8-L-L", "MICP-0.6-8-L-R"],
    lng === "en" ? platesData_2_description_en : platesData_2_description_es,
    [Image_1_7, Image_1_8, Image_1_9, Image_1_10]
  ]
  const platesData_3 = [
    ["MICP-0.6-5-T", "MICP-0.6-7-25-T", "MICP-0.6-7-34-T"],
    lng === "en" ? platesData_3_description_en : platesData_3_description_es,
    [Image_1_11, Image_1_12, Image_1_13]
  ]
  const platesData_4 = [
    ["MIC-0.6-6-TT"],
    lng === "en" ? platesData_4_description_en : platesData_4_description_es,
    [Image_1_14]
  ]
  const platesData_5 = [
    ["MICP-0.6-6-Y"],
    lng === "en" ? platesData_5_description_en : platesData_5_description_es,
    [Image_1_15]
  ]
  const platesData_6 = [
    ["MICP-0.6-6-YY", "MICP-0.6-7-YY", "MICP-0.6-9-YY"],
    lng === "en" ? platesData_6_description_en : platesData_6_description_es,
    [Image_1_16, Image_1_17, Image_1_18]
  ]
  const platesData_7 = [
    ["MICP-0.6-7-H", "MICP-0.6-9-H", "MICP-0.6-14-H"],
    lng === "en" ? platesData_7_description_en : platesData_7_description_es,
    [Image_1_19, Image_1_20, Image_1_21]
  ]
  const platesData_8 = [
    ["MICP-0.6-4-O", "MICP-0.6-8-O"],
    lng === "en" ? platesData_8_description_en : platesData_8_description_es,
    [Image_1_22, Image_1_23]
  ]
  const platesData_9 = [
    ["MICP-0.6-6-7-B", "MICP-0.6-6-10-B", "MICP-0.6-6-14-B"],
    lng === "en" ? platesData_9_description_en : platesData_9_description_es,
    lng === "en" ? platesData_9_detail_en : platesData_9_detail_es,
    [Image_1_24, Image_1_25, Image_1_26]
  ]
  const platesData_10 = [
    ["MICP-0.6-4-Q", "MICP-0.6-6-Q", "MICP-0.6-8-Q", "MICP-0.6-16-Q", "MICP-0.6-24-Q"],
    lng === "en" ? platesData_10_description_en : platesData_10_description_es,
    lng === "en" ? platesData_10_detail_en : platesData_10_detail_es,
    [Image_1_27, Image_1_28, Image_1_29, Image_1_30, Image_1_31]
  ]
  const platesData_11 = [
    ["MICP-0.6-4-RC", "MICP-0.6-6-RC", "MICP-0.6-8-RC", "MICP-0.6-16-RC", "MICP-0.6-24-RC", "MICP-0.6-8-ZY-RC"],
    lng === "en" ? platesData_11_description_en : platesData_11_description_es,
    lng === "en" ? platesData_11_detail_en : platesData_11_detail_es,
    [Image_1_32, Image_1_33, Image_1_34, Image_1_35, Image_1_36, Image_1_37]
  ]
  const platesData_12 = [
    ["MICMESH - 0.1 - 90x120", "MICMESH - 0.1 - 60x120", "MICMESH - 0.1 - 60x60", "MICMESH - 0.2 - 90x120", "MICMESH - 0.2 - 60x120", "MICMESH - 0.2 - 60x60"],
    lng === "en" ? platesData_12_description_en : platesData_12_description_es,
    lng === "en" ? platesData_12_detail_en : platesData_12_detail_es,
    [Image_1_38, Image_1_38, Image_1_38, Image_1_38, Image_1_38, Image_1_38]
  ]

  let data = {
    platesData_1,
    platesData_2,
    platesData_3,
    platesData_4,
    platesData_5,
    platesData_6,
    platesData_7,
    platesData_8,
    platesData_9,
    platesData_10,
    platesData_11,
    platesData_12
  }
  return data;
}