import React from 'react';
import { Image, Col, Row, Button, Table } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Styles } from "./Styles.js";
import TableStyles from '../../../../components/modals/implants/ImplantsTableStyles'

import ImageCardOne from "../../../../assets/images/CYROTH_Corte.png";
import ImageCardThree from "../../../../assets/images/Is four.png";
import RegisterMark from '../../../../components/registerMark/index.js';

import { data_1, data_2 } from "./data.js";

class SmartImplantsModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			title: <Trans>CCsmartImplants.modals.modal_{`${this.props.modal}`}.card_1.title</Trans>,
			text: <Trans>CCsmartImplants.modals.modal_{`${this.props.modal}`}.card_1.text</Trans>
		}
		this.changeText = this.changeText.bind(this)
		this.closeModal = this.closeModal.bind(this)
	}

	changeText(circle) {
		this.setState({
			title: <Trans>CCsmartImplants.modals.modal_{`${this.props.modal}`}.card_{`${circle}`}.title</Trans>,
			text: <Trans>CCsmartImplants.modals.modal_{`${this.props.modal}`}.card_{`${circle}`}.text</Trans>
		});
	}
	closeModal() {
		this.props.onButtonClose(0)
	}
	renderImage(modal) {
		switch (modal) {
			case 1:
				return (
					<>
						<Image src={ImageCardOne} className={"img-fluid"}></Image>
						<div className="circles-modal-1 modal-1-1" onMouseEnter={() => this.changeText(1)}></div>
						<div className="circles-modal-1 modal-1-2" onMouseEnter={() => this.changeText(2)}></div>
						<div className="circles-modal-1 modal-1-3" onMouseEnter={() => this.changeText(3)}></div>
						<div className="circles-modal-1 modal-1-4" onMouseEnter={() => this.changeText(4)}></div>
						<div className="circles-modal-1 modal-1-5" onMouseEnter={() => this.changeText(5)}></div>
						<div className="circles-modal-1 modal-1-6" onMouseEnter={() => this.changeText(6)}></div>
						<div className="circles-modal-1 modal-1-7" onMouseEnter={() => this.changeText(7)}></div>
					</>
				)
			case 2:
				return (
					<>
						<Image src={ImageCardThree} className={"img-fluid"}></Image>
						<div className="circles-modal-1 modal-3-1" onMouseEnter={() => this.changeText(1)}></div>
						<div className="circles-modal-1 modal-3-2" onMouseEnter={() => this.changeText(2)}></div>
						<div className="circles-modal-1 modal-3-3" onMouseEnter={() => this.changeText(3)}></div>
						<div className="circles-modal-1 modal-3-4" onMouseEnter={() => this.changeText(4)}></div>
						<div className="circles-modal-1 modal-3-5" onMouseEnter={() => this.changeText(5)}></div>
						<div className="circles-modal-1 modal-3-6" onMouseEnter={() => this.changeText(6)}></div>
						<div className="circles-modal-1 modal-3-7" onMouseEnter={() => this.changeText(7)}></div>
					</>
				)
			default: break;
		}
	}
	render() {
		return (
			<Styles>
				<div className="row-content align-items-center">
					<Button className="close-button float-right"
						onClick={this.closeModal}
					>X</Button>
					<Col className="first-col" md={2} >
						<div className="container-image">
							{this.renderImage(this.props.modal)}
						</div>
					</Col>
					<Col className="second-col" md={9}>
						<h1><RegisterMark>CCsmartImplants.modals.modal_{`${this.props.modal}`}.title</RegisterMark><span className="span-dot">.</span></h1>
						<div className="border-h1"></div>
						<Row className="row-info justify-content-between">
							<Col  md={3}>
								<div className="card-info">
									<h1>{this.state.title}</h1>
									<div className="text">
										{this.state.text}
									</div>
								</div>
							</Col>
							<Col className="card-table" md={9}>
								<TableInfo modal={this.props.modal}></TableInfo>
							</Col>
						</Row>
					</Col>
				</div>
			</Styles>
		);
	}
}

export default (SmartImplantsModal)
class TableInfo extends React.Component {
	constructor() {
		super();
		this.state = {
			data: null,
		}
	}

	componentWillMount() {
		if (this.props.modal === 2) {
			this.setState({
				data: data_1
			})
		} else {
			if (this.props.modal === 1) {
				this.setState({
					data: data_2
				})
			}
		}
	}

	infoTable() {
		const implantsDiameters = this.state.data[0];
		const realLength = this.state.data[1];
		const strawberryDiameters = this.state.data[2];
		const platformDiameters = this.state.data[3];
		const productCode = this.state.data[4];

		let table = []
		let i;
		for (i = 0; i < implantsDiameters.length; i++) {
			table.push(<tr key={i} className={i % 2 === 0 ? "tr-info-par" : "tr-info-impar"}>
				<td><ul><li>{implantsDiameters[i]}</li></ul></td>
				<td><ul className="ul-split">{this.splitData(realLength[i], "li-split-justify")}</ul></td>
				<td><ul><li>{strawberryDiameters[i]}</li></ul></td>
				<td><ul><li>{platformDiameters[i]}</li></ul></td>
				<td><ul className="ul-split">{this.splitData(productCode[i], "li-split-center")}</ul></td>
			</tr>)
		}
		return table;
	}

	splitData(array, className) {
		var arraySplit = array.split("-");
		let list = [];
		for (let j = 0; j < arraySplit.length; j++) {
			list.push(
				<li key={j} className={className}>{arraySplit[j]}</li>
			)
		}
		return list;
	}
	render() {
		return (
			<TableStyles>
				<div className="table-scroll">
					<Table striped bordered variant="dark" size="sm">
						<thead className="head">
							<tr className="tr-head">
								<th scope="col" className="first"><Trans>CCsmartImplants.table.atributes.first</Trans></th>
								<th scope="col" className="second"><Trans>CCsmartImplants.table.atributes.second</Trans></th>
								<th scope="col" className="third"><Trans>CCsmartImplants.table.atributes.third</Trans></th>
								<th scope="col" className="fourth"><Trans>CCsmartImplants.table.atributes.fourth</Trans></th>
								<th scope="col" className="fifth"><Trans>CCsmartImplants.table.atributes.fifth</Trans></th>
							</tr>
						</thead>
						<tbody>
							{this.infoTable()}
						</tbody>
					</Table>
				</div>
			</TableStyles>
		);
	}
}