import styled from 'styled-components'
import BackgroundImage from "../../../assets/images/Home/contact-background.png";

export const Styles = styled.div`
	background-image: url('${BackgroundImage}');
	background-repeat:no-repeat;
	background-size:cover;
	padding:0;
	margin:0;
	position:relative;
	min-height:95vh;
	.gradient{
		position: absolute;
		width: 100%;
		height: 100%;
		background: transparent linear-gradient(180deg, #0DB8CE00 50%, #009DE3 100%) 0% 0% no-repeat padding-box;
	}
	.col-form, .col-information {
		display:flex;
		justify-content:center;
		padding-left:45px;
	}
	.col-form{
		margin-top:70px;
	}
	.first-row, .second-row{
		padding:20px;
	}
	.first-row{
		padding-bottom:0px;
	}
	.second-row{
		padding-top:0px;
	}

	.team-information{
		margin-bottom:20px;
			h1{
				font-size:18px;
				font-weight:700;
				text-align:left;
			}
	}

	.item {
		.icon-phone {

		}
	}
`