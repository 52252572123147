import React from 'react'
import { ContactInformationStyles } from './Styles'
import ContactInformation from '../../components/ContactInformation'
import ImageFacebook from "../../../../assets/icons/facebook-white-icon.png";
import ImageTwitter from "../../../../assets/icons/twitter-white-icon.png";
import ImageInstagram from "../../../../assets/icons/instagram-icon.svg";
const ContactInformationDesktop = () => {
    return (
        <ContactInformationStyles>
            <ContactInformation socialNetworks = {[ImageFacebook, ImageInstagram, ImageTwitter]}/>
        </ContactInformationStyles>
    )
}

export default ContactInformationDesktop