import React from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import Tornillos from "../../assets/images/CranioMaxiloFacial/MandibularReconstruction/level1/mandibular-tornillos.png"
import Placas from "../../assets/images/CranioMaxiloFacial/MandibularReconstruction/level1/mandibular-placa.png"
import Instrumental from "../../assets/images/CranioMaxiloFacial/MandibularReconstruction/level1/mandibular-instrumental.png"
import Sets from "../../assets/images/CranioMaxiloFacial/MandibularReconstruction/level1/mandibular-set2dev.png"

const MandibularReconstruction = (props) => {
	return (
		<LevelOne
			title={"maxillofacial.mandibularReconstruction.self"}
			subtitle={"maxillofacial.mandibularReconstruction.subtitle"}
			productImages={[Tornillos, Placas, Instrumental, Sets]}
			productTitle={"maxillofacial.mandibularReconstruction.products"}
			titles={["MANDIBULAR RECONSTRUCTION SYSTEM SETS 2.7/2.2 mm.", "MANDIBULAR RECONSTRUCTION SYSTEM SETS 2.7/2.2 mm. (OPTIONAL ITEMS)"]}
			productDescription={"maxillofacial.mandibularReconstruction.products"}
			openPage={["/maxillofacial/mandibular_reconstruction/screws", "/maxillofacial/mandibular_reconstruction/plates", "/maxillofacial/mandibular_reconstruction/instruments", "/maxillofacial/mandibular_reconstruction/sets"]}
			currentPage={6}
			classImage={['','','','imageMandibular']}
		/>
	)
}

export default (MandibularReconstruction)