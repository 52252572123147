export default function dataGenerator(lng) {
	const cards_1 = [
		{

			products_cod: ["AVT280020000C"],
			products_type: ["h. 2 mm"],
			products_detail: [""]
		},
		{

			products_cod: ["AVT280040000C"],
			products_type: ["h. 4 mm"],
			products_detail: [""]
		},
		{
			products_cod: [
				["AVG400070000C", "AVG400150000C", "AVG400300000C", "AVG400450000C"],
				["AVG500070000C", "AVG500150000C", "AVG500300000C", "AVG500450000C"]
			],
			products_type: [
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"]
			],
			products_detail: [
				[],
				[],
			]
		},
		{

			products_cod: ["AAN000000000C"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: [
				["ATR00000IP00C"],
				["ATR00SHOIP00C"]
			],
			products_type: [
				["h. 12 mm"],
				["h. 9 mm"]
			],
			products_detail: [
				[],
				[],
			]
		},
		{
			products_cod: [
				["ATR00000IR00C"],
				["ATR00SHOIR00C"]
			],
			products_type: [
				["h. 12 mm"],
				["h. 9 mm"]
			],
			products_detail: [
				[],
				[],
			]
		},
		{
			products_cod: [
				["ATR00LNGSN00C"],
				["ATR00SHOSN00C"]
			],
			products_type: [
				lng === "es" ? ["Largo"] : ["Long"],
				lng === "es" ? ["Corto"] : ["Short"]
			],
			products_detail: [
				[],
				[],
			]
		}
	]
	const cards_2 = [
		{
			products_cod: [
				["AMO40007PT00C", "AMO40015PT00C", "AMO40030PT00C", "AMO40045PT00C"],
				["AMO50007PT00C", "AMO50015PT00C", "AMO50030PT00C", "AMO50045PT00C"]
			],
			products_type: [
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"]
			],
			products_detail: [
				[],
				[],
			]
		},
		{
			products_cod: [[ "AMO40007ZRNRC",  "AMO40015ZRNRC", "AMO40030ZRNRC"]],
			products_type: [[ "h. 0.7 mm",  "h. 1.5 mm",  "h. 3.0 mm"]],
			products_detail: lng === "es" ? [["No Rotatorio",  "No Rotatorio",  "No Rotatorio"]] : [["Not Engaging",  "Not Engaging",  "Not Engaging"]]
		},
	]
	const cards_3 = [
		{
			products_cod: [[ "ALS0000700NRC",  "ALS0001500NRC",  "ALS0003000NRC"]],
			products_type: [["h. 0.7 mm",  "h. 1.5 mm", "h. 3.0 mm"]],
			products_detail: lng === "es" ? [[ "No Rotatorio",  "No Rotatorio",  "No Rotatorio"]] : [[ "Not Engaging",  "Not Engaging",  "Not Engaging"]]
		},
		{
			products_cod: [[ "ALI0000700NRC", "ALI0001500NRC",  "ALI0003000NRC"]],
			products_type: [[ "h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm"]],
			products_detail: lng === "es" ? [["No Rotatorio", "No Rotatorio", "No Rotatorio"]] : [[ "Not Engaging",  "Not Engaging",  "Not Engaging"]]
		},
		{
			products_cod: ["ALI0000000SB0"],
			products_type: [""],
		},
		{
			products_cod: ["ALI00000TR000"],
			products_type: ["Normal"],
		},
		{
			products_cod: ["ALI00SHOTR000"],
			products_type: lng === "es" ? ["Corto"] : ["Short"],
		}
	]
	const cards_4 = [
		{
			products_cod: [
				["AMO40007ST00C", "AMO40015ST00C", "AMO40030ST00C", "AMO40045ST00C"],
				["AMO50007ST00C", "AMO50015ST00C", "AMO50030ST00C", "AMO50045ST00C"]
			],
			products_type: [
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"]
			],
			products_detail: [
				[],
				[],
			]
		},
		{
			products_cod: [
				["AMO40007IN15C", "AMO40015IN15C", "AMO40030IN15C", "AMO40045IN15C", "AMO40007IN25C", "AMO40015IN25C", "AMO40030IN25C", "AMO40045IN25C"],
				["AMO50007IN15C", "AMO50015IN15C", "AMO50030IN15C", "AMO50045IN15C", "AMO50007IN25C", "AMO50015IN25C", "AMO50030IN25C", "AMO50045IN25C"],
			],
			products_type: [
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm", "h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm", "h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
			],
			products_detail: [
				["15°", "15°", "15°", "15°", "25°", "25°", "25°", "25°"],
				["15°", "15°", "15°", "15°", "25°", "25°", "25°", "25°"],
			]
		},
	]
	const cards_5 = [
		{
			products_cod: ["AMUNB007ST00C", "AMUNB015ST00C", "AMUNB030ST00C", "AMUNB045ST00C"],
			products_type: ["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"]
		},
		{
			products_cod: [["AMUNB015IN17C", "AMUNB030IN17C", "AMUNB030IN30C", "AMUNB045IN30C"]],
			products_type: [["h. 0.7 mm", "h. 3.0 mm", "h. 3.0 mm", "h. 4.5 mm"]],
			products_detail: [["17°", "17°", "30°", "30°"]]
		},
		{
			products_cod: ["AMUNB030CG000", "AMUNB050CG000"],
			products_type: ["h. 3 mm", "h. 5 mm"]
		},
		{
			products_cod: ["AMUNB000CL000"],
			products_type: [""]
		},
		{
			products_cod: ["AMUNB000PT000"],
			products_type: [""]
		},
		{
			products_cod: ["AMUNB000AD000", ""],
			products_type: [""]
		},
		{
			products_cod: ["AMUNB000TM000"],
			products_type: [""]
		}
	]
	const cards_6 = [
		{
			products_cod: ["AMO00007TT00C", "AMO00015TT00C", "AMO00030TT00C", "AMO00045TT00C"],
			products_type: ["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"]
		},
		{
			products_cod: [
				["AMO00007TT05C", "AMO00015TT05C", "AMO00030TT05C", "AMO00045TT05C"],
				["AMO00007TT10C", "AMO00015TT10C", "AMO00030TT10C", "AMO00045TT10C"],
				["AMO00007TT15C", "AMO00015TT15C", "AMO00030TT15C", "AMO00045TT15C"]
			],
			products_type: [
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
				["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"]
			],
			products_detail: [
				["5°", "5°", "5°", "5°"],
				["10°", "10°", "10°", "10°"],
				["15°", "15°", "15°", "15°"],
			]
		},
		{
			products_cod: ["ACM00000TTPRB"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: ["ACM00000TTDEB"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: ["ACM00000TTWEB"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: ["AST00000TTPA0"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: ["AAN35000TT00B"],
			products_type: [""],
			products_detail: [""]
		},
		{
			products_cod: ["ATR35000TT00B"],
			products_type: [""],
			products_detail: [""]
		}
	]
	const cards_7 = [
		{
			products_cod: [
				["ATE340180000C", "ATE340280000C", "ATE340380000C"],
				["ATE410180000C", "ATE410280000C", "ATE410380000C"],
				["ATE500180000C", "ATE500280000C", "ATE500380000C"]
			],
			products_type: [
				["h. 1.8 mm", "h. 2.8 mm", "h. 3.8 mm"],
				["h. 1.8 mm", "h. 2.8 mm", "h. 3.8 mm"],
				["h. 1.8 mm", "h. 2.8 mm", "h. 3.8 mm"],
			],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
				["", "", "", ""],
			]
		},
		{
			products_cod: [
				["ATE34025CG00C", "ATE34035CG00C"],
				["ATE41025CG00C", "ATE41035CG00C"],
				["ATE50025CG00C", "ATE50035CG00C"]
			],
			products_type: [
				["h. 2.5 mm", " h. 3.5 mm"],
				["h. 2.5 mm", " h. 3.5 mm"],
				["h. 2.5 mm", " h. 3.5 mm"],
			],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
				["", "", "", ""],
			]
		},
		{
			products_cod: ["ATE00000VF000"],
			products_type: [""],
			products_detail: [
				["", "", "", ""],
				["", "", "", ""],
				["", "", "", ""],
			]
		},
		{
			products_cod: [
				["ALS34000TENR0", "ALS34000TERO0"],
				["ALS41000TENR0", "ALS41000TERO0"],
				["ALS50000TENR0", "ALS50000TERO0"]
			],
			products_type: lng === "es" ? [
				["No Rotatorio", "Rotatorio"],
				["No Rotatorio", "Rotatorio"],
				["No Rotatorio", "Rotatorio"],
			] : [
				["Not Engaging", "Engaging"],
				["Not Engaging", "Engaging"],
				["Not Engaging", "Engaging"]
			],
			products_detail: [
				["", ""],
				["", ""],
				["", ""],
			]
		},
		{
			products_cod: [
				["ALI34000TENR0", "ALI34000TERO0"],
				["ALI41000TENR0", "ALI41000TERO0"],
				["ALI50000TENR0", "ALI50000TERO0"]
			],
			products_type: lng === "es" ? [
				["No Rotatorio", "Rotatorio"],
				["No Rotatorio", "Rotatorio"],
				["No Rotatorio", "Rotatorio"],
			] : [
				["Not Rotating", "Rotating"],
				["Not Rotating", "Rotating"],
				["Not Rotating", "Rotating"]
			],
			products_detail: [
				["", ""],
				["", ""],
				["", ""],
			]
		}
	]
	const cards_8 = [
		{
			products_cod: ["AMO40007OVA4C", "AMO40015OVA4C", "AMO40030OVA4C", "AMO40045OVA4C"],
			products_type: ["h. 0.7 mm", "h. 1.5 mm", "h. 3.0 mm", "h. 4.5 mm"],
		},
		{
			products_cod: ["ALK000100000C", "ALK000200000C", "ALK000300000C", "ALK000400000C", "ALK000500000C", "ALK000600000C"],
			products_type: ["h. 1 mm", "h. 2 mm", "h. 3 mm", "h. 4 mm", "h. 5 mm", "h. 6 mm"],
		}
	]
	const cards_9 = [
		{
			products_cod: [
				["AMO00025CVCS0"],
				["AMO00025CVCH0"],
				["AMO00025CVCN0"]
			],
			products_type: lng === "es" ? [
				["Rosa Suave"],
				["Normal"],
				["Duro Verde"],
			] : [
				["Soft Pink"],
				["Normal"],
				["Hard Green"]
			],
			products_detail: [
				["", ""],
				["", ""],
				["", ""],
			]
		},
		{
			products_cod: ["AMO00025CVCX0"],
			products_type: ["Stainless"]
		},
		{
			products_cod: ["AMO00000CVDP0"],
			products_type: [""]
		},
		{
			products_cod: ["AVS000100000C"],
			products_type: [" h. 0.7 - 1.5"],
			products_detail: lng === "es" ? ["Rosca M 1.8"] : ["Thread M 1.8"]
		},
		{
			products_cod: ["AVS000110000C"],
			products_type: ["h. 3.0 - 4.5"],
			products_detail: lng === "es" ? ["Rosca M 1.8"] : ["Thread M 1.8"]
		},
		{
			products_cod: ["AMUNB000VMIN0"],
			products_type: [""]
		},
		{
			products_cod: ["AVR000000000C"],
			products_type: lng === "es" ? ["Largo"] : ["Long"]
		},
		{
			products_cod: ["AVR00SHO0000C"],
			products_type: lng === "es" ? ["Corto"] : ["Short"]
		},
		{
			products_cod: ["AVR00LNGSN00C"],
			products_type: lng === "es" ? ["Largo"] : ["Long"]
		},
		{
			products_cod: ["AVR00SHOSN00C"],
			products_type: lng === "es" ? ["Corto"] : ["Short"]
		},
		{
			products_cod: ["AMUNB180VR000"],
			products_type: ["h. 18 mm"]
		},
		{
			products_cod: ["AMUNB100VR000"],
			products_type: ["h. 10 mm"]
		},
	]
	const cards_10 = [
		{
			products_cod: ["ABA00000CCDO0"],
			products_type: [""]
		},
		{
			products_cod: ["ABA00000PKDO0"],
			products_type: [""]
		},
		{
			products_cod: ["ABA00000CCSI0"],
			products_type: [""]
		},
		{
			products_cod: ["ABA00000PKSI0"],
			products_type: [""]
		},
	]

	const data = {
		cards_1,
		cards_2,
		cards_3,
		cards_4,
		cards_5,
		cards_6,
		cards_7,
		cards_8,
		cards_9,
		cards_10
	}
	return data
}