import React from 'react'
import Screws from "../../maxillofacial/desktop/Screws"
import * as data from "./data.js";
import Image_1_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/01_MINS-2.0-S-14 1.svg"
import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/image_1_1.png"
import Image_2_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/02_MINS-2.0-C-14 1.svg"
import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/image_2_1.png"
import Image_3_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/03_MINS-2.0-C-14 1.svg"
import Image_3_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/03_MINS-2.0-C-14 1.png"
import Image_4_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/04_MINS-2.0-H-14 1.svg"
import Image_4_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/05_MINS-2.0-Q-14.0-TI-SC_1.png"
import Image_5_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/05_MINS-2.0-Q-14 1.svg"
import Image_5_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/05_MINS-2.0-Q-14.0-TI-SC_1.png"
import Image_6_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/06_MINS-2.3-ES-10 1.svg"
import Image_6_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/06_MINS-2.3-ES-10 2.png"
import Image_7_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/07_MINS-2.3-EC-10 1.svg"
import Image_7_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/07_MINS-2.3-EC-10 2.png"
import Image_8_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/08_MINS-2.3-EH-10 1.svg"
import Image_8_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/08_MINS-2.3-EH-10 2.png"
import Image_9_0 from  "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/09_MINS-2.3-EQ-10_1.svg"
import Image_9_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingStandardSystem/Screws/08_MINS-2.3-EH-10 2.png"
const MiniplatingStandardSystemScrews = (props) => {
	return (
		<Screws
			title={"miniplatingStandardSystem.products.1.name"}
			navigate={"miniplating_standard_system"}
			subtitle={"miniplatingStandardSystem.products.1.description"}
			sectionTitles={["miniplatingStandardSystem.screws.1", "miniplatingStandardSystem.screws.2", "miniplatingStandardSystem.screws.3", "miniplatingStandardSystem.screws.4", "miniplatingStandardSystem.screws.5", "miniplatingStandardSystem.screws.6", "miniplatingStandardSystem.screws.7", "miniplatingStandardSystem.screws.8", "miniplatingStandardSystem.screws.9"]}
			sectionImagesFirst={[Image_1_0, Image_2_0, Image_3_0, Image_4_0, Image_5_0, Image_6_0, Image_7_0, Image_8_0, Image_9_0]}
			sectionImagesSecond={[Image_1_1,Image_2_1,Image_3_1,Image_4_1,Image_5_1,Image_6_1,Image_7_1,Image_8_1,Image_9_1]}
			sectionData={[data.dataSection_1, data.dataSection_2, data.dataSection_3, data.dataSection_4, data.dataSection_5, data.dataSection_6, data.dataSection_7, data.dataSection_8, data.dataSection_9]}
		/>
	)
}

export default (MiniplatingStandardSystemScrews)