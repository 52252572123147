import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import DentalEs from "./i18n/es/dental.json";
import DescargaEs from "./i18n/es/descarga.json";
import MaxillofacialEs from "./i18n/es/maxillofacial.json";
import MaxillofacialEn from "./i18n/en/maxillofacial.json"
import faico360Es from "./i18n/es/faico360.json";
import contactEs from "./i18n/es/contact.json";
import HomeEs from "./i18n/es/home.json";
import ImplantsEs from './i18n/es/implants.json';
import ConicConnectionEs from './i18n/es/conexion_conica.json';
import NewsEs from './i18n/es/news.json';
import HexaConnectionEs from './i18n/es/conexion_hexa.json';
import FooterEs from "./i18n/es/footer.json";
import FooterEn from "./i18n/en/footer.json";
import generalEs from "./i18n/es/general.json";
import universal_expandersEs from "./i18n/es/universal_expanders.json"
import universal_expandersEn from "./i18n/en/universal_expanders.json"
import IHCsmartImplantsEs from "./i18n/es/IHCsmartImplants.json";
import IHCsmartImplantsEn from "./i18n/en/IHCsmartImplants.json";
import IHCprostheticComponentsEs from "./i18n/es/IHCprostheticComponents.json";
import IHCprostheticComponentsEn from "./i18n/en/IHCprostheticComponents.json";
import IHCsurgicalInstrumentsEs from "./i18n/es/IHCsurgicalInstruments.json";
import IHCsurgicalInstrumentsEn from "./i18n/en/IHCsurgicalInstruments.json";
import CCprostheticComponentsEs from "./i18n/es/CCprostheticComponents.json";
import CCprostheticComponentsEn from "./i18n/en/CCprostheticComponents.json";
import CCsurgicalInstrumentsEs from "./i18n/es/CCsurgicalInstruments.json";
import CCsurgicalInstrumentsEn from "./i18n/en/CCsurgicalInstruments.json";
import CCsmartImplantsEs from "./i18n/es/CCsmartImplants.json";
import CCsmartImplantsEn from "./i18n/en/CCsmartImplants.json";
import microPlatingSystemEs from "./i18n/es/microPlatingSystem.json";
import microPlatingSystemEn from "./i18n/en/microPlatingSystem.json";
import mediumplatingSystemEs from "./i18n/es/mediumplatingSystem.json";
import mediumplatingSystemEn from "./i18n/en/mediumplatingSystem.json";
import miniplatingLowProfileEs from "./i18n/es/miniplatingLowProfile.json";
import miniplatingLowProfileEn from "./i18n/en/miniplatingLowProfile.json";
import miniplatingStandardSystemEs from "./i18n/es/miniplatingStandardSystem.json";
import miniplatingStandardSystemEn from "./i18n/en/miniplatingStandardSystem.json";
import fracturePlatingSystemEs from "./i18n/es/fracturePlatingSystem.json";
import fracturePlatingSystemEn from "./i18n/en/fracturePlatingSystem.json";
import mandibularReconstructionEs from "./i18n/es/mandibularReconstruction.json";
import mandibularReconstructionEn from "./i18n/en/mandibularReconstruction.json";
import BreadCrumbEs from "./i18n/es/breadcrumb.json"
import BreadCrumbEn from "./i18n/en/breadcrumb.json"
import NewsEn from './i18n/en/news.json';
//translation in english
import DentalEn from "./i18n/en/dental.json";
import generalEn from "./i18n/en/general.json";
import HomeEn from "./i18n/en/home.json";
import AboutEn from "./i18n/en/about.json";
import AboutEs from "./i18n/es/about.json";
import smartImplantsEn from "./i18n/en/smartImplants.json";
import faico360En from "./i18n/en/faico360.json";
import DescargaEn from "./i18n/en/download.json";
import ContactEn from "./i18n/en/contact.json";
import VideosEs from "./i18n/es/videos.json";
import VideosEn from "./i18n/en/videos.json";
import LegalNoticeEs from "./i18n/es/legalNotice.json";
import LegalNoticeEn from "./i18n/en/legalNotice.json";
import NoticePrivacyEs from "./i18n/es/noticePrivacy.json";
import NoticePrivacyEn from "./i18n/en/noticePrivacy.json";
import TerminosCondicionesEs from "./i18n/es/terminosCondiciones.json";
import TerminosCondicionesEn from "./i18n/en/terminosCondiciones.json";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
	es: {
		translation: {
			home: HomeEs,
			footer: FooterEs,
			see_more: "Ver más",
			about: AboutEs,
			dental: DentalEs,
			descarga: DescargaEs,
			maxillofacial: MaxillofacialEs,
			implants: ImplantsEs,
			conic: ConicConnectionEs,
			hexa: HexaConnectionEs,
			universal: universal_expandersEs,
			faico360: faico360Es,
			contact: contactEs,
			general: generalEs,
			news: NewsEs,
			IHCsmartImplants: IHCsmartImplantsEs,
			IHCprostheticComponents: IHCprostheticComponentsEs,
			IHCsurgicalInstruments: IHCsurgicalInstrumentsEs,
			CCprostheticComponents: CCprostheticComponentsEs,
			CCsurgicalInstruments: CCsurgicalInstrumentsEs,
			CCsmartImplants: CCsmartImplantsEs,
			microPlatingSystem: microPlatingSystemEs,
			mediumplatingSystem: mediumplatingSystemEs,
			miniplatingLowProfile: miniplatingLowProfileEs,
			miniplatingStandardSystem: miniplatingStandardSystemEs,
			fracturePlatingSystem: fracturePlatingSystemEs,
			mandibularReconstruction: mandibularReconstructionEs,
			breadcrumb:BreadCrumbEs,
			videos:VideosEs,
			legalNotice:LegalNoticeEs,
			noticePrivacy:NoticePrivacyEs,
			termsConditions: TerminosCondicionesEs
		}
	},
	en: {
		translation: {
			see_more: "Learn more",
			home: HomeEn,
			about: AboutEn,
			footer: FooterEn,
			dental: DentalEn,
			news:NewsEn,
			contact: ContactEn,
			general: generalEn,
			smartImplants: smartImplantsEn,
			faico360: faico360En,
			descarga: DescargaEn,
			maxillofacial: MaxillofacialEn,
			microPlatingSystem: microPlatingSystemEn,
			mediumplatingSystem: mediumplatingSystemEn,
			miniplatingLowProfile: miniplatingLowProfileEn,
			miniplatingStandardSystem: miniplatingStandardSystemEn,
			fracturePlatingSystem: fracturePlatingSystemEn,
			mandibularReconstruction: mandibularReconstructionEn,
			IHCsmartImplants: IHCsmartImplantsEn,
			IHCprostheticComponents: IHCprostheticComponentsEn,
			IHCsurgicalInstruments: IHCsurgicalInstrumentsEn,
			universal: universal_expandersEn,
			CCsmartImplants: CCsmartImplantsEn,
			CCprostheticComponents:CCprostheticComponentsEn,
			CCsurgicalInstruments: CCsurgicalInstrumentsEn,
			breadcrumb:BreadCrumbEn,
			videos:VideosEn,
			legalNotice:LegalNoticeEn,
			noticePrivacy:NoticePrivacyEn,
			termsConditions: TerminosCondicionesEn
		}
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(LanguageDetector)
	.init({
		resources,
		fallbackLng:'en',
		keySeparator: ".",
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		whitelist: ["en", "es"],
	});

export default i18n;
