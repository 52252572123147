import React, { useState } from 'react'
import { Row, Col, Image, Button, Card, Table } from 'react-bootstrap';
import Styles from './Styles.js';
import { Trans } from 'react-i18next';
import { navigate } from 'hookrouter';

import VioletWaveImg from '../../../../assets/images/onda-violeta-3.png';

import { Modal } from '../../../../components/modals/Modal.js';
import DarkModal from '../../../../components/modals/DarkModal';

const LevelOne = (props) => {
	const [isModal, setIsModal] = useState(false);
	const [currentModal, setCurrentModal] = useState(0);
	const [modalData, setModalData] = useState();
	const [modalData_2, setModalData_2] = useState();
	const [modalHeader, setModalheader] = useState();
	const [withoutImage, setWithoutImage] = useState(false);
	const cantProducts = 4;
	const ModalContainer = Modal(DarkModal);

	function renderProducts() {
		let render = []
		for (let i = 0; i < cantProducts; i++) {
			render.push(
				<Card className="card-content">
					<Card.Body className="card-body">
						<div className="card-images">
							<Image src={`${props.productImages[i]}`} className={`image card-image-${i} img-fluid ${props.classImage ? props.classImage[i] : ''}` }></Image>
						</div>
						<Card.Title className="card-title">
							<Trans>{props.productTitle}.{`${i + 1}`}.name</Trans>
							<span className="dot"></span>
						</Card.Title>
						<Card.Text className="card-text">
							<Trans>{props.productDescription}.{`${i + 1}`}.description</Trans>
						</Card.Text>
						<div className="content-button">
							<Button
								variant="primary card-button"
								className="align-middle"
								onClick={() => {
									handlerAction(i)
								}}
							><Trans>general.ver-mas</Trans></Button>
						</div>
					</Card.Body>
				</Card>
			)
		}
		return render;
	}
	function handlerAction(i) {
		if (props.openPage[i] !== "") {
			navigate(props.openPage[i])
		} else {
			openModal(i + 1);
		}
	}
	function openModal(modal) {
		switch (modal) {
		case 2:
			setModalData(props.modalData.plates)
			setModalheader(props.modalHeaders.plates)
			setWithoutImage(false)
			setModalData_2(props.modalData.plates_2)
			break;
		case 3:
			setModalData(props.modalData.instruments)
			setModalData_2([])
			setModalheader(props.modalHeaders.instruments)
			setWithoutImage(false)
			break;
		case 4:
			setModalData(props.modalData.sets)
			setModalheader(props.modalHeaders.sets)
			setWithoutImage(true)
			setModalData_2(props.modalData.sets_2)
			break;
		default:
			break;
		}
		setCurrentModal(modal);
		isModal ? document.body.classList.remove('scrollModal') : document.body.classList.add('scrollModal')
		setIsModal(!isModal);
	}
	function renderIMF() {
		return (
			<>
				{renderScrew()}
				{renderSet()}
			</>
		)
	}
	function renderSet() {
		return (
			<div className="card-imf mt-5  mb-5 justify-content-center">
				<Col>
					<h3><Trans>Sets</Trans></h3>
					<Table className="mb-0 table" striped bordered variant="dark" size="sm">
						<thead className="head">
							<tr className="tr-head">
								<th scope="col"><Trans>maxillofacial.tableHeader.products</Trans></th>
								<th scope="col"><Trans>maxillofacial.tableHeader.description</Trans></th>
								<th scope="col"><Trans>maxillofacial.tableHeader.quantity</Trans></th>
							</tr>
						</thead>
						<tbody>
							{renderCard(props.dataSet, true)}
						</tbody>
					</Table>
				</Col>
			</div>
		)
	}
	function renderScrew() {
		return (
			<div className="card-imf mt-5">
				<Col md={1} className="content-images">
					<Image src={`${props.imageScrew[0]}`} className="img-fluid"></Image>
					<Image src={`${props.imageScrew[1]}`} className="img-fluid"></Image>
				</Col>
				<Col>
					<h3><Trans>maxillofacial.imfSystem.screws.title</Trans></h3>
					<Table className="mb-0 table" striped bordered variant="dark" size="sm">
						<thead className="head">
							<tr className="tr-head">
								<th scope="col"><Trans>maxillofacial.tableHeader.products</Trans></th>
								<th scope="col"><Trans>maxillofacial.tableHeader.description</Trans></th>
							</tr>
						</thead>
						<tbody>
							{renderCard(props.dataScrew, false)}
						</tbody>
					</Table>
				</Col>
			</div>
		)
	}
	function renderCard(data, isSet) {
		let render = [];
		for (let i = 0; i < data[0].length; i++) {
			render.push(
				<tr>
					<td>{data[0][i]}</td>
					<td>{data[1][i]}</td>
					{isSet ? <td>{data[2][i]}</td> : null}
				</tr>

			)
		}
		return render;
	}
	return (
		<Styles>
			<div className={(isModal ? "filter-blur" : "")}>
				<Image src={VioletWaveImg} className="onda-gris-reverse img-fluid"></Image>
				<div>
					{props.hasTable ?
						<Row className="first-row justify-content-center imf">
							<div className="imf_titles">
								<h1><Trans>{props.title}</Trans></h1>
								<h2><Trans>{props.subtitle}</Trans></h2>
							</div>
						</Row>
						:
						<Row className="first-row justify-content-center">
							<div className="titles">
								<h1><Trans>{props.title}</Trans></h1>
								<h2><Trans>{props.subtitle}</Trans></h2>
							</div>
						</Row>
					}

					{/* <Row className="second-row justify-content-center">
            {props.hasTable ?
              renderIMF()
              :
              <Row className="container-cards">
                {renderProducts()}
              </Row>
            }
          </Row> */}

					{props.hasTable ?
						<Row className="second-row-IMF justify-content-center">
							{renderIMF()}
						</Row>
						:
						<Row className="second-row justify-content-center">

							<Row className="container-cards">
								{renderProducts()}
							</Row>
						</Row>
					}
				</div>
			</div>
			<div className={(isModal ? "filter-blur-footer" : "")}></div>

			{/* Modal*/}
			<ModalContainer
				classNamePopUp={"pop-up-style"}
				onButtonClose={openModal}
				open={isModal}
				currentModal={currentModal}
				currentPage={props.currentPage}
				modalHeaders={modalHeader}
				modalData={modalData}
				modalData_2={modalData_2}
				titles={props.titles}
				withoutImage={withoutImage}
			/>
		</Styles>
	);
}

export default (LevelOne)