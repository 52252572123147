import Fractureplating from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/fractureplating.png'
import ImfSystem from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/imf.png'
import Mandibular from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/mandibular.png'
import Mediumplating from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/mediumplating.png'
import MiniplatingLow from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/microplating-low-profile.png'
import Microplating from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/microplating.png'
import Miniplating from '../../../assets/images/CranioMaxiloFacial/Cranios/mobile/miniplating.png'


import Maxillofacial from "../../../assets/pdf/maxilofacial-catalog.pdf"
const systems = {
  microplatingSystem: {
    title: "maxillofacial.systems.products.1.title",
    title_2: "maxillofacial.systems.products.1.title2",
    text: "maxillofacial.microPlatingSystem.subtitle",
    image: Microplating,
    catalog: Maxillofacial,
    url: '/maxillofacial/microplating_system',
    screws: {
      title: "maxillofacial.microPlatingSystem.products.1.name",
      text: "maxillofacial.microPlatingSystem.products.1.description",
      itemsCollection: [
        {item:"microPlatingSystem.screws_mobile.1",size:"microPlatingSystem.screws_mobile.1-1"},
        {item:"microPlatingSystem.screws_mobile.2",size:"microPlatingSystem.screws_mobile.2-2"},
        {item:"microPlatingSystem.screws_mobile.3",size:"microPlatingSystem.screws_mobile.3-3"},
        {item:"microPlatingSystem.screws_mobile.4",size:"microPlatingSystem.screws_mobile.4-4"},
      ]
    },
    plates: {
      title: "maxillofacial.microPlatingSystem.products.2.name",
      text: "maxillofacial.microPlatingSystem.products.2.description",
      items: ["microPlatingSystem.plates_mobile.titles.1", "microPlatingSystem.plates_mobile.titles.2", "microPlatingSystem.plates_mobile.titles.3", "microPlatingSystem.plates_mobile.titles.4", "microPlatingSystem.plates_mobile.titles.5", "microPlatingSystem.plates_mobile.titles.6", "microPlatingSystem.plates_mobile.titles.7", "microPlatingSystem.plates_mobile.titles.8", "microPlatingSystem.plates_mobile.titles.9", "microPlatingSystem.plates_mobile.titles.10","microPlatingSystem.plates_mobile.titles.11","microPlatingSystem.plates_mobile.titles.12","microPlatingSystem.plates_mobile.titles.13"]
    },
    instruments: {
      title: "maxillofacial.microPlatingSystem.products.3.name",
      text: "maxillofacial.microPlatingSystem.products.3.description",
    },
    sets: {
      title: "maxillofacial.microPlatingSystem.products.4.name",
      text: "maxillofacial.microPlatingSystem.products.4.description",
      items: ["microPlatingSystem.sets_mobile.1", "microPlatingSystem.sets_mobile.2"]
    }
  },
  mediumplatingSystem: {
    title: "maxillofacial.systems.products.2.title",
    title_2: "maxillofacial.systems.products.2.title2",
    text: "maxillofacial.mediumplatingSystem.subtitle",
    image: Mediumplating,
    catalog: Maxillofacial,
    url: '/maxillofacial/mediumplating_system',
    screws: {
      title: "maxillofacial.mediumplatingSystem.products.1.name",
      text: "maxillofacial.mediumplatingSystem.products.1.description",
      itemsCollection: [
        {item:"mediumplatingSystem.screws_mobile.1",size:"mediumplatingSystem.screws_mobile.1-1"},
        {item:"mediumplatingSystem.screws_mobile.2",size:"mediumplatingSystem.screws_mobile.2-2"},
        {item:"mediumplatingSystem.screws_mobile.3",size:"mediumplatingSystem.screws_mobile.3-3"},
        {item:"mediumplatingSystem.screws_mobile.4",size:"mediumplatingSystem.screws_mobile.4-4"},
        {item:"mediumplatingSystem.screws_mobile.5",size:"mediumplatingSystem.screws_mobile.5-5"},
        {item:"mediumplatingSystem.screws_mobile.6",size:"mediumplatingSystem.screws_mobile.6-6"},
        {item:"mediumplatingSystem.screws_mobile.7",size:"mediumplatingSystem.screws_mobile.7-7"},
        {item:"mediumplatingSystem.screws_mobile.8",size:"mediumplatingSystem.screws_mobile.8-8"},
        {item:"mediumplatingSystem.screws_mobile.9",size:"mediumplatingSystem.screws_mobile.9-9"},
        {item:"mediumplatingSystem.screws_mobile.10",size:"mediumplatingSystem.screws_mobile.10-10"},
        {item:"mediumplatingSystem.screws_mobile.11",size:"mediumplatingSystem.screws_mobile.11-11"}
      ]
    },
    plates: {
      title: "maxillofacial.mediumplatingSystem.products.2.name",
      text: "maxillofacial.mediumplatingSystem.products.2.description",
      items: ["mediumplatingSystem.plates_mobile.titles.1", "mediumplatingSystem.plates_mobile.titles.2", "mediumplatingSystem.plates_mobile.titles.3", "mediumplatingSystem.plates_mobile.titles.4", "mediumplatingSystem.plates_mobile.titles.5", "mediumplatingSystem.plates_mobile.titles.6", "mediumplatingSystem.plates_mobile.titles.7", "mediumplatingSystem.plates_mobile.titles.8", "mediumplatingSystem.plates_mobile.titles.9", "mediumplatingSystem.plates_mobile.titles.10", "mediumplatingSystem.plates_mobile.titles.11", "mediumplatingSystem.plates_mobile.titles.12", "mediumplatingSystem.plates_mobile.titles.13", "mediumplatingSystem.plates_mobile.titles.14","mediumplatingSystem.plates_mobile.titles.15","mediumplatingSystem.plates_mobile.titles.16"]
    },
    instruments: {
      title: "maxillofacial.mediumplatingSystem.products.3.name",
      text: "maxillofacial.mediumplatingSystem.products.3.description",
    },
    sets: {
      title: "maxillofacial.mediumplatingSystem.products.4.name",
      text: "maxillofacial.mediumplatingSystem.products.4.description",
      items: ["mediumplatingSystem.sets_mobile.1", "mediumplatingSystem.sets_mobile.2"]
    }
  },
  miniplatingLowProfileSystem: {
    title: "maxillofacial.systems.products.3.title",
    title_2: "maxillofacial.systems.products.3.title2",
    text: "maxillofacial.miniplatingLowProfile.subtitle",
    image: MiniplatingLow,
    catalog: Maxillofacial,
    url: '/maxillofacial/miniplating_low_system',
    screws: {
      title: "maxillofacial.miniplatingLowProfile.products.1.name",
      text: "maxillofacial.miniplatingLowProfile.products.1.description",
      itemsCollection: [
        {item:"miniplatingLowProfile.screws_mobile.1",size:"miniplatingLowProfile.screws_mobile.1-1"},
        {item:"miniplatingLowProfile.screws_mobile.2",size:"miniplatingLowProfile.screws_mobile.2-2"},
        {item:"miniplatingLowProfile.screws_mobile.3",size:"miniplatingLowProfile.screws_mobile.3-3"},
        {item:"miniplatingLowProfile.screws_mobile.4",size:"miniplatingLowProfile.screws_mobile.4-4"},
        {item:"miniplatingLowProfile.screws_mobile.5",size:"miniplatingLowProfile.screws_mobile.5-5"},
      ]
    },
    plates: {
      title: "maxillofacial.miniplatingLowProfile.products.2.name",
      text: "maxillofacial.miniplatingLowProfile.products.2.description",
      items: ["miniplatingLowProfile.plates_mobile.titles.1", "miniplatingLowProfile.plates_mobile.titles.2", "miniplatingLowProfile.plates_mobile.titles.3", "miniplatingLowProfile.plates_mobile.titles.4", "miniplatingLowProfile.plates_mobile.titles.5", "miniplatingLowProfile.plates_mobile.titles.6", "miniplatingLowProfile.plates_mobile.titles.7", "miniplatingLowProfile.plates_mobile.titles.8", "miniplatingLowProfile.plates_mobile.titles.9", "miniplatingLowProfile.plates_mobile.titles.10", "miniplatingLowProfile.plates_mobile.titles.11", "miniplatingLowProfile.plates_mobile.titles.12", "miniplatingLowProfile.plates_mobile.titles.13", "miniplatingLowProfile.plates_mobile.titles.14", "miniplatingLowProfile.plates_mobile.titles.15", "miniplatingLowProfile.plates_mobile.titles.17"]
    },
    instruments: {
      title: "maxillofacial.miniplatingLowProfile.products.3.name",
      text: "maxillofacial.miniplatingLowProfile.products.3.description",
    },
    sets: {
      title: "maxillofacial.miniplatingLowProfile.products.4.name",
      text: "maxillofacial.miniplatingLowProfile.products.4.description",
      items: ["miniplatingLowProfile.sets_mobile.1", "miniplatingLowProfile.sets_mobile.2"]
    }
  },
  miniplatingSystem: {
    title: "maxillofacial.systems.products.4.title",
    title_2: "maxillofacial.systems.products.4.title2",
    text: "maxillofacial.miniplatingStandardSystem.subtitle",
    image: Miniplating,
    catalog: Maxillofacial,
    url: '/maxillofacial/miniplating_standard_system',
    screws: {
      title: "maxillofacial.miniplatingStandardSystem.products.1.name",
      text: "maxillofacial.miniplatingStandardSystem.products.1.description",
      itemsCollection: [
        {item:"miniplatingStandardSystem.screws_mobile.1",size:"miniplatingStandardSystem.screws_mobile.1-1"},
        {item:"miniplatingStandardSystem.screws_mobile.2",size:"miniplatingStandardSystem.screws_mobile.2-2"},
        {item:"miniplatingStandardSystem.screws_mobile.3",size:"miniplatingStandardSystem.screws_mobile.3-3"},
        {item:"miniplatingStandardSystem.screws_mobile.4",size:"miniplatingStandardSystem.screws_mobile.4-4"},
        {item:"miniplatingStandardSystem.screws_mobile.5",size:"miniplatingStandardSystem.screws_mobile.5-5"},
        {item:"miniplatingStandardSystem.screws_mobile.6",size:"miniplatingStandardSystem.screws_mobile.6-6"},
        {item:"miniplatingStandardSystem.screws_mobile.7",size:"miniplatingStandardSystem.screws_mobile.7-7"},
        {item:"miniplatingStandardSystem.screws_mobile.8",size:"miniplatingStandardSystem.screws_mobile.8-8"},
        {item:"miniplatingStandardSystem.screws_mobile.9",size:"miniplatingStandardSystem.screws_mobile.9-9"}
      ]
      /* items: ["miniplatingStandardSystem.screws_mobile.1", "miniplatingStandardSystem.screws_mobile.2", "miniplatingStandardSystem.screws_mobile.3", "miniplatingStandardSystem.screws_mobile.4", "miniplatingStandardSystem.screws_mobile.5", "miniplatingStandardSystem.screws_mobile.6", "miniplatingStandardSystem.screws_mobile.7", "miniplatingStandardSystem.screws_mobile.8", "miniplatingStandardSystem.screws_mobile.9"] */
    },
    plates: {
      title: "maxillofacial.miniplatingStandardSystem.products.2.name",
      text: "maxillofacial.miniplatingStandardSystem.products.2.description_mobile",
      text_2:"maxillofacial.miniplatingStandardSystem.products.2.description_mobile_2",
      items: ["miniplatingStandardSystem.plates_mobile.titles.1", "miniplatingStandardSystem.plates_mobile.titles.2", "miniplatingStandardSystem.plates_mobile.titles.3", "miniplatingStandardSystem.plates_mobile.titles.4", "miniplatingStandardSystem.plates_mobile.titles.5", "miniplatingStandardSystem.plates_mobile.titles.6", "miniplatingStandardSystem.plates_mobile.titles.7", "miniplatingStandardSystem.plates_mobile.titles.8", "miniplatingStandardSystem.plates_mobile.titles.9", "miniplatingStandardSystem.plates_mobile.titles.10", "miniplatingStandardSystem.plates_mobile.titles.11", "miniplatingStandardSystem.plates_mobile.titles.12", "miniplatingStandardSystem.plates_mobile.titles.13", "miniplatingStandardSystem.plates_mobile.titles.14", "miniplatingStandardSystem.plates_mobile.titles.15"],
      items_2:[ "miniplatingStandardSystem.plates_mobile.titles.16","miniplatingStandardSystem.plates_mobile.titles.17", "miniplatingStandardSystem.plates_mobile.titles.18", "miniplatingStandardSystem.plates_mobile.titles.19", "miniplatingStandardSystem.plates_mobile.titles.20", "miniplatingStandardSystem.plates_mobile.titles.21", "miniplatingStandardSystem.plates_mobile.titles.22"]
    },
    instruments: {
      title: "maxillofacial.miniplatingStandardSystem.products.3.name",
      text: "maxillofacial.miniplatingStandardSystem.products.3.description",
    },
    sets: {
      title: "maxillofacial.miniplatingStandardSystem.products.4.name",
      text: "maxillofacial.miniplatingStandardSystem.products.4.description",
      items: ["miniplatingStandardSystem.sets_mobile.1", "miniplatingStandardSystem.sets_mobile.2"]
    }
  },
  fractureplatingSystem: {
    title: "maxillofacial.systems.products.5.title",
    title_2: "maxillofacial.systems.products.5.title2",
    text: "maxillofacial.fracturePlatingSystem.subtitle",
    image: Fractureplating,
    catalog: Maxillofacial,
    url: '/maxillofacial/fracture_system',
    screws: {
      title: "maxillofacial.fracturePlatingSystem.products.1.name",
      text: "maxillofacial.fracturePlatingSystem.products.1.description",
      itemsCollection: [
        {item:"fracturePlatingSystem.screws_mobile.1",size:"fracturePlatingSystem.screws_mobile.1-1"},
        {item:"fracturePlatingSystem.screws_mobile.2",size:"fracturePlatingSystem.screws_mobile.2-2"},
        {item:"fracturePlatingSystem.screws_mobile.3",size:"fracturePlatingSystem.screws_mobile.3-3"}
      ]
      /* items: ["fracturePlatingSystem.screws_mobile.1", "fracturePlatingSystem.screws_mobile.2", "fracturePlatingSystem.screws_mobile.3"] */
    },
    plates: {
      title: "maxillofacial.fracturePlatingSystem.products.2.name",
      text: "maxillofacial.fracturePlatingSystem.products.2.description",
      items: ["fracturePlatingSystem.plates_mobile.titles.1", "fracturePlatingSystem.plates_mobile.titles.2", "fracturePlatingSystem.plates_mobile.titles.3", "fracturePlatingSystem.plates_mobile.titles.4"]
    },
    instruments: {
      title: "maxillofacial.fracturePlatingSystem.products.3.name",
      text: "maxillofacial.fracturePlatingSystem.products.3.description",
    },
    sets: {
      title: "maxillofacial.fracturePlatingSystem.products.4.name",
      text: "maxillofacial.fracturePlatingSystem.products.4.description",
      items: ["fracturePlatingSystem.sets_mobile.1", "fracturePlatingSystem.sets_mobile.2"]
    }
  },
  mandibularSystem: {
    title: "maxillofacial.systems.products.6.title",
    title_2: "maxillofacial.systems.products.6.title2",
    text: "maxillofacial.mandibularReconstruction.subtitle",
    image: Mandibular,
    catalog: Maxillofacial,
    url: '/maxillofacial/mandibular_reconstruction',
    screws: {
      title: "maxillofacial.mandibularReconstruction.products.1.name",
      text: "maxillofacial.mandibularReconstruction.products.1.description",
      itemsCollection: [
        {item:"mandibularReconstruction.screws_mobile.1",size:"mandibularReconstruction.screws_mobile.1-1"},
        {item:"mandibularReconstruction.screws_mobile.2",size:"mandibularReconstruction.screws_mobile.2-2"}
      ]
      /* items: ["mandibularReconstruction.screws_mobile.1", "mandibularReconstruction.screws_mobile.2"] */
    },
    plates: {
      title: "maxillofacial.mandibularReconstruction.products.2.name",
      text: "maxillofacial.mandibularReconstruction.products.2.description",
      items: ["mandibularReconstruction.plates_mobile.titles.1", "mandibularReconstruction.plates_mobile.titles.2", "mandibularReconstruction.plates_mobile.titles.3"]
    },
    instruments: {
      title: "maxillofacial.mandibularReconstruction.products.3.name",
      text: "maxillofacial.mandibularReconstruction.products.3.description",
    },
    sets: {
      title: "maxillofacial.mandibularReconstruction.products.4.name",
      text: "maxillofacial.mandibularReconstruction.products.4.description",
      items: ["mandibularReconstruction.sets_mobile.1", "mandibularReconstruction.sets_mobile.2"]
    }
  },
  IMFSystem: {
    title: "maxillofacial.systems.products.7.title",
    title_2: "maxillofacial.systems.products.7.title2",
    text: "maxillofacial.imfSystem.subtitle",
    image: ImfSystem,
    catalog: Maxillofacial,
    url: '/maxillofacial/imf_system',
    screws:{
      title:"maxillofacial.mandibularReconstruction.products.1.name",
      items:["maxillofacial.imfSystem.screws.mobile.1","maxillofacial.imfSystem.screws.mobile.1-1"]
    },
    sets:{
      title:"maxillofacial.mandibularReconstruction.products.4.name",
      text:"maxillofacial.imfSystem.sets"
    }
  },
}

export default systems