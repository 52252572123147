import styled from "styled-components";

export const Styles = styled.div`
    position:relative;
		width:100%;
		height:100%;
		padding-top:2%;
    .row-content::-webkit-scrollbar {
      width: 0px;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .row-content::-webkit-scrollbar-thumb {
      background: #FF0000;
    }	
    .row-content{
      position:relative;
      display:flex;
      height:100%;
      overflow-y: scroll;
      .first-col{
        margin-right:2%;
        margin-left:2%;
        display: flex;
        padding:0;
        padding-top:5%;
        flex-direction: column;
        height:max-content;
        position:relative;
      }
      .second-col{
        .close-button{
          background-color:transparent !important;
          border-width:0px !important;
          font-size:24px;
              position: absolute;
              top: 0%;
              right: 0%;
        }
        h1{
          color:#EBEBEB;
          font-weight:700;
          font-family: 'Montserrat', sans-serif;
            @media (min-width:1500px){
              font-size:60px;
              margin-top:2%;
              margin-bottom: 0px;
            }
            @media (min-width:1024px) and (max-width:1500px){
              font-size:38px;
              margin-top:0px;
              margin-bottom: 0px;
            }
        }
        .border-h1{
          border-bottom: 1px solid #909090;
          width: 98%;
          position: absolute;
          right: 1.2%;
        }
        .row-info{
          margin-top:2%;
          padding-bottom:2%;
          h2{
            color:#EBEBEB;
            font-family: 'Montserrat', sans-serif;
            font-size:24px;
          }
          h3{
            color:#EBEBEB;
            font-family: 'Montserrat', sans-serif;
            font-size:18px;
            font-weight:600;
            margin:0px;
            margin-top:3%;
            padding:0px;
          }
        }
      }
    }
`