import React from 'react'

import { Trans } from "react-i18next";


import DentalProductOne from "../../../../assets/images/dental_product_two.png";
import DentalProductTwo from "../../../../assets/images/dental_product_one.png";
import DentalProductThree from "../../../../assets/images/expansores.png";
import DentalProductFour from "../../../../assets/images/dental_product_four.png";

import LevelOneCard from '../../../../components/card/levelOneCard/LevelOneCard'
import RegisterMark from '../../../../components/registerMark';
import {List} from './Styles'

const OneLevelList = () => {
	const FaicoSystemDentalImages = [
		{
			title: <RegisterMark>dental.system_faico_dental.cards.1.title</RegisterMark>,
			image:DentalProductOne,
			url: '/dental/conicalConnection'
		},
		{
			title: <Trans>dental.system_faico_dental.cards.2.title</Trans>,
			image:DentalProductTwo,
			url: '/dental/internalHexagonConnection'
		},
		{
			title: <Trans>dental.system_faico_dental.cards.3.title</Trans>,
			image:DentalProductThree,
			url: '/dental/universal_expanders'
		},
		{
			title: <Trans>dental.system_faico_dental.cards.4.title</Trans>,
			image:DentalProductFour,
			url: '/dental/guided_surgery'
		}
	];

	const getLevelOneList = ( ) => {
		return FaicoSystemDentalImages.map( card =>(
			<LevelOneCard
				title= {card.title}
				image= {card.image}
				url= {card.url}
			/>
		))
	}

	return (
		<List>
			{getLevelOneList()}
		</List>
	)
}

export default OneLevelList