import styled from 'styled-components'
import {Styles} from '../desktop/Styles'
import BackgroundImage from "../../../assets/images/Home/contact-background-mobile.png";

const StylesContact  = styled(Styles)`
    background-image: url('${BackgroundImage}');

    .col-form, .col-information {
		display:flex;
		justify-content:center;
		padding-left:10px;
        padding-right:10px
	}
	.col-form{
		margin-top:0px;
	}
    .first-row, .second-row{
        margin:0px;
		padding:2%;
    }

	.col-information{
		z-index:1;
		width: 100%;
		padding-left:20px;
        padding-right:20px
	}
    
`

export default StylesContact