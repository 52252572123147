/*----- Core -----*/
import React from 'react'

/*----- Assets -----*/
import ImageFirstRow from "../../../assets/images/GuidedSurgery/Guided Surgery_1.png";
import ImageThirdRow_1 from "../../../assets/images/GuidedSurgery/Guided Surgery_2.png";
import ImageThirdRow_2 from "../../../assets/images/GuidedSurgery/Guided Surgery_3.png";
import ConnectionImplantsSpanish from "../../../assets/images/GuidedSurgery/connection-implants-spanish.png";
import ConnectionImplantsEnglish from "../../../assets/images/GuidedSurgery/connection-implants-english.png";
import B4BlackSpanish from "../../../assets/images/GuidedSurgery/b4-black-spanish.png";
import B4BlackEnglish from "../../../assets/images/GuidedSurgery/b4-black-english.png";
import B5WhiteSpanish from "../../../assets/images/GuidedSurgery/b5-white-spanish.png";
import B5WhiteEnglish from "../../../assets/images/GuidedSurgery/b5-white-english.png";
import InstrumentsSpanish from "../../../assets/images/GuidedSurgery/instruments-spanish.png";
import InstrumentsEnglish from "../../../assets/images/GuidedSurgery/instruments-english.png";
import OptionsSpanish from "../../../assets/images/GuidedSurgery/options-spanish.png";
import OptionsEnglish from "../../../assets/images/GuidedSurgery/options-english.png";
import WaveImage from "../../../assets/images/Dental/onda-violeta.png";

/*----- Styles -----*/
import {
	Main,
	FirstRow,
	SecondRow,
	ThirdRow,
	FourthRow,
	StyledRow,
	StyledCol,
	StyledColCenter,
	StyledColFourthRow,
	StyledText,
	StyledTitleLarge,
	StyledTitleMedium,
	StyledTitleSmall,
	StyledTextItalic,
	StyledColorText,
	StyledImage,
	StyledFirstImage,
	SeparatedLine,
	Wave,
	Dot
} from './styles';

/*----- Commons -----*/
import { Trans } from "react-i18next";
import "../../../commons/i18n";
import i18n from '../../../commons/i18n';

const GuidedSurgery = () => {
	return (
		<Main>
			<FirstRow>
				<Wave src={WaveImage} />
				<StyledCol md={5}>
					<StyledTitleLarge><Trans>dental.guidedSurgery.title</Trans><Dot /></StyledTitleLarge>
					<StyledText><Trans>dental.guidedSurgery.card_1.text_1</Trans></StyledText>
					<StyledText><Trans>dental.guidedSurgery.card_1.text_2</Trans></StyledText>
				</StyledCol>
				<StyledCol md={{ span: 5, offset: 2 }}>
					<StyledFirstImage src={ImageFirstRow} />
					<StyledText><Trans>dental.guidedSurgery.card_1.text_3</Trans></StyledText>
				</StyledCol>

			</FirstRow>

			<SecondRow>
				<StyledCol md={5}>
					<StyledImage
						src={ i18n.language === 'es' ?
							ConnectionImplantsSpanish
							: ConnectionImplantsEnglish
						}
						width="900px" height="433px" />
					<StyledTextItalic>
						<div>
							<StyledColorText><Trans>dental.guidedSurgery.card_2.text_1.title</Trans></StyledColorText>
							<Trans>dental.guidedSurgery.card_2.text_1.subtitle_1</Trans>
						</div>
						<div>
							<Trans>dental.guidedSurgery.card_2.text_1.subtitle_2</Trans>
						</div>
						<div>
							<Trans>dental.guidedSurgery.card_2.text_1.subtitle_3</Trans>
						</div>
					</StyledTextItalic>
				</StyledCol>
				<StyledCol md={{ span: 5, offset: 2 }}>
					<StyledText><Trans>dental.guidedSurgery.card_2.text_2</Trans></StyledText>
					<StyledText><Trans>dental.guidedSurgery.card_2.text_3</Trans></StyledText>
				</StyledCol>
			</SecondRow>

			<ThirdRow>
				<Wave src={WaveImage} />
				<StyledCol md={5}>
					<StyledText><Trans>dental.guidedSurgery.card_3.text_1</Trans></StyledText>
					<StyledText><Trans>dental.guidedSurgery.card_3.text_2</Trans></StyledText>
					<StyledText><Trans>dental.guidedSurgery.card_3.text_3</Trans></StyledText>
				</StyledCol>
				<StyledColCenter md={{ span: 7 }}>
					<StyledImage src={ImageThirdRow_1} width="1096px" height="713px" />
					<StyledImage src={ImageThirdRow_2} width="626px" height="500px" />
				</StyledColCenter>
			</ThirdRow>

			<FourthRow>
				<StyledTitleMedium><Trans>dental.guidedSurgery.card_4.title</Trans><Dot /></StyledTitleMedium>
				<StyledRow>
					<StyledColFourthRow md={5}>
						<StyledTitleSmall><Trans>dental.guidedSurgery.card_4.subtitle_1</Trans></StyledTitleSmall>
						<SeparatedLine />
						<StyledImage
							src={ i18n.language === 'es' ?
								B4BlackSpanish
								: B4BlackEnglish
							}
							width="800" />
					</StyledColFourthRow>
					<StyledColFourthRow md={{ span: 5, offset: 2 }}>
						<StyledTitleSmall><Trans>dental.guidedSurgery.card_4.subtitle_2</Trans></StyledTitleSmall>
						<SeparatedLine />
						<StyledImage
							src={ i18n.language === 'es' ?
								B5WhiteSpanish
								: 	B5WhiteEnglish
							}
							width="800" />
					</StyledColFourthRow>
				</StyledRow>

				<StyledRow>
					<StyledColFourthRow md={5}>
						<StyledTitleSmall><Trans>dental.guidedSurgery.card_4.subtitle_3</Trans></StyledTitleSmall>
						<SeparatedLine />
						<StyledImage
							src={ i18n.language === 'es' ?
								InstrumentsSpanish
								: InstrumentsEnglish
							}
							width="800" />
					</StyledColFourthRow>
					<StyledColFourthRow md={{ span: 5, offset: 2 }}>
						<StyledTitleSmall><Trans>dental.guidedSurgery.card_4.subtitle_4</Trans></StyledTitleSmall>
						<SeparatedLine />
						<StyledImage
							src={ i18n.language === 'es' ?
								OptionsSpanish
								: OptionsEnglish
							}
							width="800" />
					</StyledColFourthRow>
				</StyledRow>
			</FourthRow>

		</Main>
	);

}

export default (GuidedSurgery)
