import React from "react";
import PropTypes from "prop-types"
import { Trans } from "react-i18next";
import { Button, Col, Image, Row } from "react-bootstrap";
import CardModal from "./components/CardModal/CardModal"
import TablePrimaryModal from "./components/Tables/TablePrimary/TablePrimary"
import TableSecondaryModal from "./components/Tables/TableSecondary/TableSecondary"
import { ModalDentalLevelOneStyles } from "./ModalDentalOneStyles";
import RegisterMark from "../registerMark";

class ModalDentalLevelOne extends React.Component {
	renderTextTitle() {
		let render = [];
		for (let i = 1; i <= this.props.textTitleCant; i++) {
			render.push(
				<p key={i}><RegisterMark>{`${this.props.textTitle}`}.{`${i}`}</RegisterMark></p>
			)
		}
		return render;
	}
	render() {
		return (
			<ModalDentalLevelOneStyles>
				<Row className="row-content">
					<Button className="close-button"
						onClick={this.props.onButtonClose}
					> X </Button>
					<Col className= {`first-col ${this.props.textTitleCant === 1 ? 'short-first-col': ''}`}
						md={this.props.imageSize ? this.props.imageSize : 1} xl={this.props.imageSize ? this.props.imageSize : 1}>
						<div className="container-image">
							<Image src={`${this.props.image}`} className={"img-fluid"}></Image>
						</div>
					</Col>
					<Col className={`second-col ${this.props.textTitleCant === 1 ? 'short-col': ''}`} md={8} mb={0}>
						<div className="header">
							<h1>
								<Trans>{this.props.title}</Trans>
								<span className="dot-span">.</span>
							</h1>
						</div>
						<div className={ this.props.textSubtitle ? "text-columns content-description" : "text-column-one content-description"}>
							<div className={`text text-one ${this.props.textTitleCant === 1 ? 'short-text': ''}`}>
								{this.renderTextTitle()}
							</div>
							<h3>
								<Trans>{this.props.subtitle}</Trans>
							</h3>
							<div className="text text-two">
								<Trans>{this.props.textSubtitle}</Trans>
							</div>
						</div>
						{this.props.isModalPrimaryTable ?
							<TablePrimaryModal
								modalHeaders={this.props.modalHeaders}
								modalDataExpand={this.props.modalDataExpand}
								modalData={this.props.modalData}
								modalColors={this.props.modalColors}
							/>
							:
							<CardModal
								card_titles={this.props.card_titles}
								card_contents={this.props.card_contents}
								card_navigates={this.props.card_navigates}
							/>}
						<br></br>
						{this.props.isModalSecondaryTable ?
							<TableSecondaryModal
								modalData={this.props.modalSecondaryData}
								modalHeaders={this.props.modalSecondaryHeaders}
							/>
							:
							null
						}
					</Col>
				</Row>
			</ModalDentalLevelOneStyles>
		);
	}
}
export default ModalDentalLevelOne;

/*----- PropTypes -----*/
ModalDentalLevelOne.propTypes = {
	open: PropTypes.bool.isRequired,
	onButtonClose: PropTypes.func.isRequired,
	title: PropTypes.string,
	textTitle: PropTypes.string,
	textTitle_2: PropTypes.string,
	subtitle: PropTypes.string,
	textSubtitle: PropTypes.string,
	card_1_title: PropTypes.string,
	card_1_content: PropTypes.string,
	card_1_navigate: PropTypes.string,
	card_2_title: PropTypes.string,
	card_2_subtitle: PropTypes.string,
	card_2_content: PropTypes.string,
	card_2_navigate: PropTypes.string,
	card_3_title: PropTypes.string,
	card_3_content: PropTypes.string,
	card_3_navigate: PropTypes.string,
	image: PropTypes.string
}

