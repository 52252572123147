import React from 'react'
import Screws from "../../maxillofacial/desktop/Screws"
import * as data from "./data.js";

/* Assets */
import Image_1_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/01_MEDS-1.5-S-12 1.svg"
import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/image_1_1.png"
import Image_2_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/02_MEDS-1.5-C-12 1.png"
import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/image_2_1.png"
import Image_2_1_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/10_MEDS-1.9-EC-14 2.png"
import Image_3_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/03_MEDS-1.5-C-13 1.png"
import Image_3_0_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/03_MEDS-1.5-C-13 2.png"
import Image_3_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/image_3_1.png"
import Image_4_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/04_MEDS-1.5-Q-12 1.png"
import Image_5_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/05_MEDS-1.7-ES-10 1.svg"
import Image_5_0_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/05_MEDS-1.7-ES-10 2.png"
import Image_6_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/06_MEDS-1.7-EC-10 1.svg"
import Image_6_0_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/06_MEDS-1.7-EC-10 2.png"
import Image_7_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/07_MEDS-1.7-C-10 1.svg"
import Image_7_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/07_MEDS-1.7-C-10.png"
import Image_8_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/08_MEDS-1.7-EQ-10 1.png"
import Image_8_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/08_MEDS-1.7-EQ-10.png"
import Image_9_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/09_MEDS-1.9-ES-14 1.svg"
import Image_9_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/09_MEDS-1.9-ES-14.0-TI_1.png"
import Image_10_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/10_MEDS-1.9-EC-14 1.svg"
import Image_11_0 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/11_MEDS-1.9-EQ-14 1.svg"
import Image_11_1 from "../../../assets/images/CranioMaxiloFacial/MediumplatingSystem/Screws/11_MEDS-1.9-EQ-14 2.png"
const MediumPlatingScrews = (props) => {
	return (
		<Screws
			self={"mediumplating_system"}
			title={"mediumplatingSystem.products.1.name"}
			navigate={"mediumplating_system"}
			subtitle={"mediumplatingSystem.products.1.description"}
			sectionTitles={["mediumplatingSystem.screws.1", "mediumplatingSystem.screws.2", "mediumplatingSystem.screws.3", "mediumplatingSystem.screws.4", "mediumplatingSystem.screws.5", "mediumplatingSystem.screws.6", "mediumplatingSystem.screws.7", "mediumplatingSystem.screws.8", "mediumplatingSystem.screws.9", "mediumplatingSystem.screws.10", "mediumplatingSystem.screws.11"]}
			sectionImagesFirst={[Image_1_0, Image_2_0, Image_3_0, Image_4_0, Image_5_0, Image_6_0, Image_7_0, Image_8_0, Image_9_0, Image_10_0, Image_11_0]}
			sectionImagesSecond={[Image_1_1, Image_2_1, Image_3_0_1, Image_3_1, Image_5_0_1, Image_6_0_1, Image_7_1, Image_8_1, Image_9_1, Image_2_1_1, Image_11_1]}
			sectionData={[data.dataSection_1, data.dataSection_2, data.dataSection_3, data.dataSection_4, data.dataSection_5, data.dataSection_6, data.dataSection_7, data.dataSection_8, data.dataSection_9, data.dataSection_10, data.dataSection_11]}
		/>
	)
}

export default (MediumPlatingScrews)