// Core
import React from 'react'
import styled from 'styled-components'
import { A } from "hookrouter"
import {Trans} from "react-i18next"
// commons
// Components
import Tag from '../../../components/Tag'
// Constants
import { ROOT_URL } from '../../../services/BaseUrl'

const StyledNovelty = styled.div`
  width:48%;
  .content-image{
    height: 416px;
    img{
      width:100%;
      height:100%;
    }
  }
  .content-information{
    .tags{
      margin:22px 0px 0px 0px;
      span{
        margin-right:5px;
      }
    }
    a{
      background:#7160FF;
			color:white;
			font-size:15px;
			border-radius:29px;
			font-family: 'Montserrat', sans-serif;
			font-weight:700;
			padding:11px 40px;
			border:0px;
			margin:0px 0px 192px 0px;
    }
  }
  .content-text{
    margin-bottom:76px;
    h1{
      width: 526px;
      color:black;
      font-family: 'Montserrat', sans-serif;
      margin-top:70px;
      font-size:28px;
      line-height:32px;
      font-weight:700;
    }
    h2{
      width: 570px;
      color:black;
      font-family: 'Montserrat', sans-serif;
      margin-top:11px;
      font-size:18px;
      line-height:20px;
      font-weight:300;
      text-align:left;
    }
    .text{
      p{

      }
    }

  }
`
const Novelty = ({ post }) => {
  let {
    title,
    subtitle,
    main_image,
    tags
  } = post.attributes
  return (
    <StyledNovelty >
      <div className="content-image"
        style={{
            backgroundImage: `url(${ROOT_URL}${main_image.url})`,
            backgroundSize:'cover'
        }}
      />

      <div className="content-information">
        <div className="tags">
          <div>{tags.map((tag, i) => <Tag key={tag} invert="true" content={tag.name} />)}</div>
        </div>
        <div className="content-text">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        <A className="zoom" href={"/news"}><Trans>news.button</Trans></A>
      </div>
    </StyledNovelty>
  )
}
export default Novelty
