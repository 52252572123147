import React from 'react'
import LevelOne from '../../dental/mobile/LevelOne';

const InternalHexagonConnection = (props) => {
	return (
		<LevelOne
			system={"internalHexagonConnection"}
		/>
	)
}

export default InternalHexagonConnection