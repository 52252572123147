import React from 'react';
import Styles from './Styles';
import { Trans } from 'react-i18next';

import systems from '../../systems';

const Plates = ({ system }) => {
  return (
    <Styles>
      <p><Trans>{systems[system].plates.text}</Trans></p>
      <div className="item-list">
        {systems[system].plates.items.map((item) => {
          return (
            <div className="item">
              <span className="dot"></span>
              <p><Trans>{item}</Trans></p>
            </div>
          )
        })}
      </div>
      {system === "miniplatingSystem" &&
        <>
          <p className="mt-2"><Trans>{systems[system].plates.text_2}</Trans></p>
          <div className="item-list">
            {systems[system].plates.items_2.map((item) => {
              return (
                <div className="item">
                  <span className="dot"></span>
                  <p><Trans>{item}</Trans></p>
                </div>
              )
            })}
          </div>
        </>
      }
    </Styles>
  )
}

export default Plates