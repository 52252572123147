// Core
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'

// Components
import NewsGrid from './NewsGrid'
import { postsSelector, loadingPostSelector, totalNewsSelector } from '../redux/NewSelectors'
import Spinner from '../../../components/Spinner';
import { Trans } from "react-i18next";
import NewsButton from '../../../components/button/NewsButton'
/*----- Actions -----*/
import { fetchNews, resetNews } from '../redux/NewsActions';
import { withTranslation } from 'react-i18next';


const ProductListContainer = props => {
	const [per_page, setPerPage] = useState(5);
	const [page, setPage] = useState(1);

	const {
		fetchNews,
		posts,
		totalNews,
		isLoading,
		resetNews
	} = props;

	useEffect(() => {
		resetNews()
		const payload = {
			"per_page": per_page,
			"page": page
		}
		fetchNews(payload);
		setPerPage(4);
		setPage(page + 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loadMoreProducts = () => {
		const payload = {
			"per_page": per_page,
			"page": page
		}
		fetchNews(payload);
		setPerPage(4);
		setPage(page + 1);
	}

	const getContent = () => {
		if( isLoading ){
			return (
				<div className="d-flex justify-content-center mb-5 pb-5">
					<Spinner />
				</div>
			)
		}
		if( totalNews > posts.length ){
			return (
				<div className="d-flex justify-content-center">
					<NewsButton
						className="zoom"
						onClick={loadMoreProducts}
					> <Trans>news.button</Trans> </NewsButton>
				</div>
			)
		}
	}

	return (
		<>
			{
				(posts.length !== 0) ? (
					<NewsGrid
						news={posts}
						match={props.match}
					/>
				) : ''
			}
			{ getContent() }
		</>
	)

}
const mapStateToProps = (state) => {
	return {
		posts: postsSelector(state),
		isLoading: loadingPostSelector(state),
		totalNews: totalNewsSelector(state)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchNews: (payload) => fetchNews(dispatch, payload),
		resetNews: (payload) => resetNews(dispatch, payload),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation() (ProductListContainer))