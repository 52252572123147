import styled from 'styled-components'

const Styles = styled.div`
  padding:${(props) => props.isMobile ? "60px 30px;" : "169px 420px 70px 420px"};
  .legal-notice{
    h1{
      font-size:25px;
      font-weight:700
    }
    p{
      font-size:15px;
      line-height:20px;
      font-weight:500;
      text-align: justify;
    }
    .nro{
      line-height:20px;
      font-size: 12px;
      font-weight:400;
      color: muted;
      letter-spacing: 1px;
      text-align: right;
    }
    .bold{
      font-weight:bold;
    }
    .text-container{
      h3{
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
  }
`
export default Styles