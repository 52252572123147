import React from 'react'
import LevelOne from '../../maxillofacial/mobile/LevelOne';

const MediumplatingSystem = () => {
	return (
		<LevelOne
			system={"mediumplatingSystem"}
		/>
	)
}

export default (MediumplatingSystem)