/*----- Core -----*/
import React, { useState } from 'react';
import { Trans } from "react-i18next";
import { navigate } from "hookrouter";
// import { Parallax } from 'react-parallax';
import { Image, Button } from "react-bootstrap";

import { HomeMobileStyles } from './Styles';

import Carousel from '../../../components/carousel';
import DefaultButton from '../../../components/button/DefaultButton';
import ArrowIcon from '../../../assets/icons/right-arrow.svg';

import FirstSlider from '../../../assets/images/Home/slide-conico-mobile.png';
import SecondSlider from '../../../assets/images/Home/slide-maxilo-mobile.png';
import SloganSlider from "../../../assets/images/sloga-slider-mobile.png";
import ImageLogoFaico from "../../../assets/images/Home/logo-Faico-lineas-blancas.png";
import ImageLogoAon from "../../../assets/images/Home/logo-AoN-blanco.png";

import DentalImage from "../../../assets/images/Home/dentalblur.png";
// import DentalImageBottom from "../../../assets/images/Home/dental-home-mobile-crop.png";
import CranioImage from "../../../assets/images/Home/cranio-home-blured.png";
import RefreshIcon from "../../../assets/images/Home/faico-360-flecha.png";
import RegisterMark from '../../../components/registerMark';

/*Modals*/
import { ModalSmall } from '../../../components/modals/ModalSmall';
import NavigationConfirmModal from '../../../components/modals/NavigationConfirmModal';
import { ModalDarkMobile } from '../../../components/modals/templates/modalDarkMobile/ModalDarkMobile';
import FVconModalMobile from '../../../components/modals/FVconModal/mobile/FVconModalMobile';



const Home = props => {
	const [openFaico360NavModal, setOpenFaico360NavModal] = useState(false);
	const Faico360NavModalContainer = ModalSmall(NavigationConfirmModal);

	function openFaico360NavModalCmd(){
		setOpenFaico360NavModal(true);
	}

	function closeFaico360NavModal(){
		setOpenFaico360NavModal(false);
	}

	const [openFVconModal, setopenFVconModal] = useState(true);
	const FVconModalContainer = ModalDarkMobile(FVconModalMobile);

	function closeFVconModal(){
		setopenFVconModal(false);
	}

	return (
		<HomeMobileStyles id='Home'>
			<Carousel className="carousel" autoPlayInterval={7000} indicator={true} switcher={false}>
				<CarouselSloganItem
					image={SloganSlider}
					text="home.slogan"
				/>
				<CarouselAonItem
					image={FirstSlider}
					text="home.carousel.1"
					onClick={() => navigate('/dental/conicalConnection')}
				/>
				<CarouselItem
					image={SecondSlider}
					text="home.carousel.2"
					onClick={() => navigate('/maxillofacial')}
				/>
			</Carousel>
			<div className="who-we-are-container-mobile">
				<div className="content-image">
					<div className="linear-effect"></div>
				</div>
				<div className="main-row">
					<div className="information-content">
						<h1><Trans>home.who_we_are</Trans></h1>
						<h2><Trans>home.who_we_are_text</Trans></h2>
						<div className="pl-0">
							<Button
								onClick={() => navigate('/about')}
								className="default-button zoomButton">
								<Trans>general.ver-mas</Trans>
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="faico-dental-cranio-content">
				<div className="card-system-content" id="card-1">
					<div className="img-content">
						<Image src={DentalImage} />
					</div>
					{/* <div className="img-content-blur">
						<Image src={DentalImageBottom} />
					</div> */}
					<div className="information-content">
						<h2><RegisterMark>home.dental_subtitle</RegisterMark></h2>
						<h1><Trans>home.dental_title</Trans></h1>
						<p><RegisterMark>home.dental_description</RegisterMark></p>
						<Button
							onClick={() => navigate('/dental')}
							className="default-button zoomButton">
							<Trans>general.ver-mas</Trans>
						</Button>
					</div>
				</div>
				<div className="card-system-content" id="card-2">
					<div className="img-content">
						<Image src={CranioImage} />
					</div>
					<div className="information-content">
						<h2><RegisterMark>home.dental_subtitle</RegisterMark></h2>
						<h1><Trans>home.cranio_title</Trans></h1>
						<p><RegisterMark>home.cranio_description</RegisterMark></p>
						<Button
							onClick={() => navigate('/maxillofacial')}
							className="default-button zoomButton">
							<Trans>general.ver-mas</Trans>
						</Button>
					</div>
				</div>
			</div>
			<div className="faico-connect-container-mobile">
				<div className="content-image">
					<div className="linear-effect"></div>
				</div>
				<div className="main-row">
					<div className="information-content">
						<h2><Trans>home.faico360_card_connect.item_3</Trans></h2>
						<h1><Trans>home.faico360_consulting_room</Trans></h1>
						<h3><Trans>home.faico360_visit</Trans></h3>
						<div className="pl-0">
							<Button
								onClick={() => navigate('/contact')}
								className="default-button zoomButton">
								<Trans>home.faico360_write_us</Trans>
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="faico360-container">
				<div className="card-faico360-connect">
					<div className="refresh-content">
						<Image src={`${RefreshIcon}`} className="refresh-image"></Image>
					</div>
					<div className="content">
						<h1>
							<Trans>home.faico360_card_connect.title_1</Trans>
							<Trans>home.faico360_card_connect.title_2</Trans>
						</h1>
						<p className="body"><Trans>home.faico360_body</Trans></p>
						<div className="arrow-list">
							<div className="arrow">
								<Image src={ArrowIcon}></Image>
								<p><Trans>home.faico360_card_connect.item_1</Trans></p>
							</div>
							<div className="arrow">
								<Image src={ArrowIcon}></Image>
								<p><Trans>home.faico360_card_connect.item_2</Trans></p>
							</div>
							<div className="arrow">
								<Image src={ArrowIcon}></Image>
								<p><Trans>home.faico360_card_connect.item_3</Trans></p>
							</div>
							<div className="arrow">
								<Image src={ArrowIcon}></Image>
								<p><Trans>home.faico360_card_connect.item_4</Trans></p>
							</div>
						</div>
						<Button className="button-connect" onClick={() => openFaico360NavModalCmd()/*navigate('/faico360')*/}>
							<Trans>home.faico360_service_details</Trans>
						</Button>
					</div>
				</div>
			</div>
			<Faico360NavModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeFaico360NavModal}
					open={openFaico360NavModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="faico360"
				/>

			<FVconModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeFVconModal}
					open={openFVconModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="dental/conicalConnection"
				/>	
		</HomeMobileStyles >
	);
};

const CarouselAonItem = ({ image,imageLogoFaico,imageLogoAon, text}) => {
	return (
		<div
			className="carousel-image"
		>
			<div className="content-image">
				<Image src={image}></Image>
			</div>
					<Image className="imageLogoFaico" src={ImageLogoFaico}></Image>
					<Image className="imageLogoAon" src={ImageLogoAon}></Image>
			<div className="carousel-text-mobile">				
				<p className="first-text"><Trans>{text}.text_1_mobile</Trans></p>
				<p className="first-text"><Trans>{text}.text_2_mobile</Trans></p>
				<p className="first-text"><Trans>{text}.text_3_mobile</Trans></p>
				<p className="first-text"><Trans>{text}.text_3a_mobile</Trans></p>
				<h1><Trans>{text}.title_desktop</Trans></h1>
				<p className="second-text"><Trans>{text}.text_4_mobile</Trans></p>
				<p className="second-text"><Trans>{text}.text_5_mobile</Trans></p>
				<a className="aon-nav-button" target="_blank" href={"https://www.aonimplants.it/"}>															
					<DefaultButton
						id="aon-nav-button"
						content={<div className="aon-nav-button"><Trans>general.goto</Trans></div>}
						colorText={"white"}
					/>
				</a>
			
			</div>
		</div>
	)
}


const CarouselItem = ({ image, text, onClick }) => {
	return (
		<div
			className="carousel-image"
		>
			<div className="content-image">
				<Image src={image}></Image>
			</div>
			<div className="carousel-text-mobile">
				<p className="first-text"><Trans>{text}.text_1</Trans></p>
				<h1><Trans>{text}.title_desktop</Trans></h1>
				<p className="second-text"><Trans>{text}.text_2_desktop</Trans></p>
				<DefaultButton
					content={<Trans>general.ver-mas</Trans>}
					onClick={onClick}
					colorText={"white"}
				/>
			</div>
		</div>
	)
}

const CarouselSloganItem = ({ image, text }) => {
	return (
		<div className="carousel-image" >
			<div className="content-image">
				<Image src={image}></Image>
			</div>
			<div className="carousel-text-mobile slogan">
				<h1><Trans>{text}.1</Trans></h1>
				<h1><Trans>{text}.2</Trans></h1>
				<h1><Trans>{text}.3</Trans></h1>
			</div>
		</div>
	)
}
export default (Home)
