/*----- Core -----*/
import React from 'react'
import styled from 'styled-components'

/*----- Components -----*/
import Carousel from 'react-multi-carousel'

/*----- utils -----*/

const Styles = styled.div`
	width:100%;
	.item{
		width: 200px;
		height:200px;
		padding: 0%;
		background-color:transparent;
		z-index:999;
	}
	

`


const PartnerCarousel = ({children}) => {
	const responsive = {
		mobile: {
			breakpoint: {
				max: 464,
				min: 0
			},
			items: 1,
			partialVisibilityGutter: 20
		},

		tablet: {
			breakpoint: {
				max: 1024,
				min: 464
			},
			items: 2,
			partialVisibilityGutter: 20
		},

		desktop: {
			breakpoint: {
				max: 3000,
				min: 1024
			},
			items: 3,
			partialVisibilityGutter: 0
		}
	};
	return (
		<Styles>
			<Carousel
				slidesToSlide={3}
				swipeable={false}
				draggable={false}
				showDots={true}
				arrows={true}
				renderButtonGroupOutside={true}
				renderDotsOutside={true}
				responsive={responsive}
				ssr={true} // means to render carousel on server-side.
				autoPlay={false}
				autoPlaySpeed={5000}
				infinite={false}
				keyBoardControl={true}
				customTransition="all .5"
				transitionDuration={500}
				containerClass="carousel-container"
				removeArrowOnDeviceType={[]}
				deviceType={"desktop"}
				dotListClass="custom-dot-list-style"
				itemClass="carousel-item-padding-40-px"
				partialVisible={true}
			>
				{children}
			</Carousel>
		</Styles>
	);
};

export default PartnerCarousel;