import React from 'react';
import { Trans } from 'react-i18next';
import { Row, Container, Col, Image } from "react-bootstrap";
import Styles from './Styles';

import AccordionCard from '../../../components/accordion/AccordionCard';
import SystemList from './components/systemList';

import BackgroundMaxilo from '../../../assets/images/CranioMaxiloFacial/maxilofacial.png';
import CARD_1 from "../../../assets/images/CranioMaxiloFacial/tornillos.svg";
import CARD_2 from "../../../assets/images/CranioMaxiloFacial/placas.svg";
import CARD_3 from "../../../assets/images/CranioMaxiloFacial/instrumental.svg";
import RegisterMark from '../../../components/registerMark';
const Maxilofacial = () => {
	return (
		<Styles>
			<div className="image-container-background">
				<Image src={BackgroundMaxilo}></Image>
			</div>
			<div className="title-container">
				<h1><Trans>maxillofacial.self</Trans><span className="blue-dot">.</span></h1>
				<p><Trans>maxillofacial.presentation_text</Trans></p>
			</div>
			<div className="cards-container">
				<AccordionCard
					image={CARD_1}
					title={<Trans>maxillofacial.cards.1.title</Trans>}
					subtitle={<Trans>maxillofacial.cards.1.subtitle_mobile</Trans>}
					inside={true}
					arrowColor={'#49D3EA'}
					content={
						<Container fluid>
							<Row>
								<Col xs={3} className="first-col" />
								<Col xs={9} className="p-0 m-0">
									<p className="card-description"><RegisterMark>maxillofacial.cards.1.content</RegisterMark></p>
									<p className="card-description"><Trans>maxillofacial.cards.1.content_extended</Trans></p>
								</Col>
							</Row>
						</Container>}
				/>
				<AccordionCard
					image={CARD_2}
					title={<Trans>maxillofacial.cards.2.title</Trans>}
					subtitle={<Trans>maxillofacial.cards.2.subtitle</Trans>}
					inside={true}
					arrowColor={'#49D3EA'}
					content={
						<Container fluid>
							<Row>
								<Col xs={3} className="first-col" />
								<Col xs={9} className="p-0 m-0">
									<p className="card-description"><RegisterMark>maxillofacial.cards.2.content</RegisterMark></p>
									<p className="card-description"><Trans>maxillofacial.cards.2.content_extended</Trans></p>
									<p className="card-description"><RegisterMark>maxillofacial.cards.2.content_extended_extra</RegisterMark></p>
								</Col>
							</Row>
						</Container>}
				/>
				<AccordionCard
					image={CARD_3}
					title={<Trans>maxillofacial.cards.3.title</Trans>}
					subtitle={<Trans>maxillofacial.cards.3.subtitle</Trans>}
					inside={true}
					arrowColor={'#49D3EA'}
					content={
						<Container fluid>
							<Row>
								<Col xs={3} className="first-col" />
								<Col xs={9} className="p-0 m-0">
									<p className="card-description"><Trans>maxillofacial.cards.3.content</Trans></p>
									<p className="card-description"><Trans>maxillofacial.cards.3.content_extended</Trans></p>
									<p className="card-description"><Trans>maxillofacial.cards.3.content_extended_extra</Trans></p>
								</Col>
							</Row>
						</Container>}
				/>
			</div>
			<div className="maxilofacial-systems">
				<div className="title-container">
					<h3><RegisterMark>maxillofacial.systems.subtitle_mobile</RegisterMark></h3>
					<h1><Trans>maxillofacial.systems.title_mobile</Trans></h1>
					<p><Trans>maxillofacial.systems.subtitle</Trans></p>
					<p><RegisterMark>maxillofacial.systems.subtitle_extended</RegisterMark></p>
				</div>
				<SystemList />
			</div>
		</Styles>
	)
}

export default Maxilofacial;