import React from 'react';
import { Trans } from 'react-i18next';
import { Image } from 'react-bootstrap';
import MultiCarousel from '../../../../../components/carousel/MuiltiCarousel/index'
import SmartImplantsStyles from './styles';
import { navigate } from 'hookrouter';
import RegisterMark from '../../../../../components/registerMark';

const SmartImplants = ({ content, open }) => {

  const renderText = (card) => {
    let render = [];
    card.texts.forEach((text) => {
      render.push(
        <p><RegisterMark>{text}</RegisterMark></p>
      )
    })
    return render;
  }
  const renderCards = () => {
    let render = []

    content.cards.forEach((card) => {
      render.push(
        <div className="smart-implant-card">
          <Image src={card.image} className={card.classImage} />
          <h1><RegisterMark>{card.title}</RegisterMark></h1>
          {renderText(card)}
          <div className="content-button">
            <button onClick={() => navigate(card.url) }><RegisterMark>general.see_details</RegisterMark></button>
          </div>
        </div>
      )
    })
    return render;
  }

  return (
    <SmartImplantsStyles id="smart-implants">
      <div className="system-text">
        <p><Trans><RegisterMark>{content.text}</RegisterMark></Trans></p>
      </div>
      {open &&
        <div>
          <MultiCarousel showDots={true}>
            {renderCards()}
          </MultiCarousel>
        </div>
      }
    </SmartImplantsStyles >
  )
}

export default SmartImplants;