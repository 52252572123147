import { GET_TEAM, GET_TEAM_SUCCESS, SHOOT_GET_POST, GET_POST_SUCCESS } from "./Constants";

export const getTeam = dispatch => {
	dispatch({ type: GET_TEAM });
}

export const getTeamSuccessActionCreator = param => ({
	type: GET_TEAM_SUCCESS,
	payload: param
})

export const getTeamSuccess = (dispatch, param) => {
	dispatch(getTeamSuccessActionCreator(param));
}

export const shootGetUser = (dispatch, userId) => {
	dispatch({ type: SHOOT_GET_POST, payload: userId });
}

export const asyncGetUser = response => ({
	type: GET_POST_SUCCESS,
	payload: response
})

export const getUser = (dispatch, userId) => {
	dispatch(asyncGetUser(userId));
}