import React,{useState} from 'react'
import Instruments from "../../maxillofacial/desktop/Instruments"
import dataGenerator from "./data.js";
import i18n from "i18next";


const MiniplatingStandardSystemInstruments = (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Instruments
			self={"miniplatingStandardSystem"}
			navigate={"miniplating_standard_system"}
			titles={["","","","","","","","",""]}
			data={[data.instrumentsData_1,data.instrumentsData_2,data.instrumentsData_3,data.instrumentsData_4,data.instrumentsData_5,data.instrumentsData_6,data.instrumentsData_7,data.instrumentsData_8,data.instrumentsData_9]}
		/>
	)
}

export default (MiniplatingStandardSystemInstruments)