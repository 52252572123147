import React, { useState } from "react";
import { slide as Menu } from 'react-burger-menu'
import { StyledSideBar } from "./Styles";
import SideBarButton from "../../../../assets/icons/sidebar-button.svg";
import SideBarCrossButton from "../../../../assets/icons/sidebar-cross-button.svg";
import { Trans } from "react-i18next";
import NavButton from "../../../button/NavButton";
import { Accordion as SelectLanguage } from "../SelectLanguage"

/*Navigation Modal*/
import { ModalSmall } from '../../../modals/ModalSmall';
import NavigationConfirmModal from '../../../modals/NavigationConfirmModal'


const SideBar = () => {
	const [open, setOpen] = useState(false);

	const [openFaico360NavModal, setOpenFaico360NavModal] = useState(false);
	const Faico360NavModalContainer = ModalSmall(NavigationConfirmModal);

	function openFaico360NavModalCmd(){
		setOpenFaico360NavModal(true);
	}

	function closeFaico360NavModal(){
		setOpenFaico360NavModal(false);
	}

	return (
		<StyledSideBar>
			<Menu right noOverlay disableAutoFocus disableFocus isOpen={open}
				onStateChange={(value) => setOpen(value.isOpen)}
				width={'60%'}
				customBurgerIcon={<img className="icon" src={open ? SideBarCrossButton : SideBarButton} alt="logo" />}
				menuClassName={"menu-content"}
			>
				<div className="button-content">
					<NavButton onClick={() => setOpen(!open)} content={<Trans>home.self</Trans>} destination="/" />
					<NavButton onClick={() => setOpen(!open)} content={<Trans>about.self</Trans>} destination="/about" />
					<NavButton onClick={() => setOpen(!open)} content={<Trans>dental.self</Trans>} destination="/dental" />
					<NavButton onClick={() => setOpen(!open)} content={<Trans>maxillofacial.self</Trans>} destination="/maxillofacial" />
					<NavButton content={<Trans>faico360.self</Trans>} destination="" onClick={() => {setOpen(false); openFaico360NavModalCmd();}} />
					{/* <NavButton onClick={() => setOpen(!open)} content={<Trans>news.self</Trans>} destination="/news" /> */}
					<NavButton onClick={() => setOpen(!open)} content={<Trans>videos.self</Trans>} destination="/videos" />
					<NavButton onClick={() => setOpen(!open)} content={<Trans>descarga.self</Trans>} destination="/download" />
					<NavButton onClick={() => setOpen(!open)} content={<Trans>contact.self</Trans>} destination="/contact" />
				</div>
				<div className="language-content">
					<SelectLanguage />
				</div>
			</Menu>
			<Faico360NavModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeFaico360NavModal}
					open={openFaico360NavModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="faico360"
				/>
		</StyledSideBar>
	);
}

export default SideBar;