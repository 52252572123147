import styled from 'styled-components'

export const TableStyles = styled.div`
	width:93%;
	div::-webkit-scrollbar {
		width: 5px;
		height:5px;
		background:#5F5F5F;
	}
	div::-webkit-scrollbar-thumb {
		background-color: #909090;
		width:99%;
	}
	.div-collapse{
		height: 160px;
		display: flex;
		justify-content: flex-end;
		border-right: 1px solid #909090 !important;
		.content-info-expand{
			width:260px;
			height:150px;
			background:#A2A2A2;
			border-radius:5px;
			margin:10px;	
			padding:10px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.content-text{
				margin-top:20px;
				font-family: 'Montserrat', sans-serif;
				p{
					display: table-row-group;
					margin:0;
					padding:0;
					text-align:left;
					font-size:15px;
				}
				.type{
					font-weight:600;
					margin-bottom:5px !important;
				} 
				.code-product{

				}
			}
		}
	}
	.line-collapse{
		border-top: 1px solid #909090 !important;
	}
	.table-scroll{
		position: relative;
		overflow: auto;
		display: block;
		height:550px !important;
		border-radius:5px;
		text-align:center;
	}
	.head{
		background:#4A4949;
	}
	.image-arrow{
		width:20px;
		height:20px;
	}
	.td-first{
		display:flex;
		justify-content: center;
		align-items: center;
	}
	.td-fourth{
		p{
			border-right:1px solid #909090 !important;
		}
	}
	.tr-head{
		font-size:16px;
		font-family: 'Montserrat', sans-serif;
		@media (max-width:1280px){
			font-size:14px;
		}
		th{
			padding: 10px;
			background:#4A4949;
			text-align: center;
			border-right:1px solid #707070;
			border-bottom:0px solid transparent;
			border-left:0px solid transparent;
			border-top:0px solid transparent;
			vertical-align: middle;
			position: sticky; top: 0;
			width:15%;
		}
		th:nth-last-child(-n+2){
			border-right: 1px solid transparent;
		}
		th:last-child{
			width:2%;
		}
	}
	.tr-info-par{
		p{
			height:45px;
			margin:0px;
			padding:0px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		td{
			padding:5px !important;
			vertical-align: middle;
			background:#797979;
			border:1px solid #797979;
		}
	}
	.tr-info-impar{
		p{
			height:45px;
			margin:0px;
			padding:0px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		td{
			padding:5px !important;
			vertical-align: middle;
			background:#545353;
			border:1px solid #545353;
		}	
	}

`
export const CircleStyles = styled.span`
	height: 25px;
	width: 25px;
	background: !important;
	border-radius: 50%;
	background:transparent;
	display: inline-block;
	margin-right:15px;
	background: ${(props) =>
		`linear-gradient(50deg, ${props.color_2}, ${props.color_1})`};
`