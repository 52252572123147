import { GET_TEAM, GET_TEAM_SUCCESS, SHOOT_GET_POST, GET_POST_SUCCESS } from "./Constants";

const initialState = {
	isLoading: false,
	teamList: [],
	postIsLoading: false,
	post: {}
}
export default function reducer(state = initialState, action) {
	switch (action.type) {
	case GET_TEAM:
		return {
			...state,
			isLoading: true
		}
	case GET_TEAM_SUCCESS:
		return {
			...state,
			isLoading: false,
			teamList: action.payload
		}
	case SHOOT_GET_POST:
		return {
			...state,
			postIsLoading: true
		}
	case GET_POST_SUCCESS:
		return {
			...state,
			post: action.payload,
			postIsLoading: false
		}
	default:
		return state;
	}
}