import React from 'react'
import Screws from "../../maxillofacial/desktop/Screws"
import * as data from "./data.js";
import Image_1_0 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Screws/01_FRMS-2.3-C-10 1.svg"
import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Screws/image_1_1.png"
import Image_2_0 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Screws/02_FRMS-2.3-H-10 1.svg"
import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Screws/image_2_1.png"
import Image_3_0 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Screws/03_FRMS-2.7-EC-10 1.svg"
import Image_3_1 from "../../../assets/images/CranioMaxiloFacial/FracturePlatingSystem/Screws/image_1_1.png"

const FracturePlatingSystemScrews = (props) => {
	return (
		<Screws
			self={"fracture_system"}
			navigate={"fracture_system"}
			title={"fracturePlatingSystem.products.1.name"}
			subtitle={"fracturePlatingSystem.products.1.description"}
			sectionTitles={["fracturePlatingSystem.screws.1","fracturePlatingSystem.screws.2","fracturePlatingSystem.screws.3"]}
			sectionImagesFirst={[Image_1_0,Image_2_0,Image_3_0]}
			sectionImagesSecond={[Image_1_1,Image_2_1,Image_3_1]}
			sectionData={[data.dataSection_1,data.dataSection_2,data.dataSection_3]}

		/>
	)
}

export default (FracturePlatingSystemScrews)