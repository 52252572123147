export function dataGenerator(lng) {

  const dataSection_1_en = [" Microplating System, Sterilization Case, Size 300x180x100 mm. (Empty) Type II", " Microplating System, Tray for Instuments. (Empty) Type II", " Microplating System, Teflon Case Only for Screws and Plates. (Empty)", " CUSHING Plate/Screw holding forcep 17.5 cm. Straight.", " ADERER Plate bending plier 12.5 cm.", " Flat plier 13.5 cm.", " Plate and Wire cutting Plier with TC 16 cm.", " Screwdriver detachable complete. Cross Head.", " Screwdriver detachable complete. Self Fitting. Cross Head."," Spiral-Drill with Sk-connection, Ø 1.0 mm. 4 mm stop. 46 mm."," Spiral-Drill with Sk-connection, Ø 1.0 mm. 8 mm stop. 50 mm.", " Straight Plate. 4-holes. ", " Straight Plate. 6-holes. ", " Straight Plate. 8-holes. ", " Straight Plate. 16-holes. ", " Straight Plate. 24-holes. ", " L Plate. 5-holes. Left.", " L Plate. 5-holes. Right.", " T Plate. 5-holes.", " Y Plate. 6-holes.", " Double Y Plate. 6-holes.", " H Plate. 7-holes."," Micro Screw. CROSS HEAD. Ø1.2 x 3mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. CROSS-HEAD. Ø1.2 x 4mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. CROSS-HEAD. Ø1.2 x 5mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. CROSS-HEAD. Ø1.2 x 6mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. CROSS-HEAD. Ø1.2 x 7mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. CROSS-HEAD. Ø1.2 x 8mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. EMERGENCY. CROSS-HEAD. Ø1.4 x 3mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. EMERGENCY. CROSS-HEAD. Ø1.4 x 5mm. (Self-Tapping). Pack of 10 Pcs."," Micro Screw. EMERGENCY. CROSS-HEAD. Ø1.4 x 7mm. (Self-Tapping). Pack of 10 Pcs."]
  const dataSection_1_es = ["Microplating System, Estuche de Esterilización, tamaño 300x180x100 mm. (Vacío) Type II", " Microplating System, Bandeja para Instrumentos. (Vacío) Type II", " Microplating System, Solo Caja de Teflón para Tornillos y Placas. (Vacío)", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Recta.", " ADERER Alicate para doblar placas 12.5 cm.", " Alicate plano 13.5 cm.", " Alicate de corte de placa y alambre con TC 16 cm.", " Destornillador desmontable completo. Cross Head.", " Destornillador desmontable completo. Autoajustable. Cross Head.", " Spiral-Drill con conexión-Sk, Ø 1.0 mm. 4 mm stop. 46 mm.", " Spiral-Drill con conexión-Sk, Ø 1.0 mm. 8 mm stop. 50 mm.", " Placa Recta. 4-agujeros. ", " Placa Recta. 6-agujeros. ", " Placa Recta. 8-agujeros. ", " Placa Recta. 16-agujeros. ", " Placa Recta. 24-agujeros. ", " Placa L. 5-agujeros. Izquierda.", " Placa L. 5-agujeros. Derecha.", " Placa T. 5-agujeros.", " Placa Y. 6-agujeros.", " Doble Placa Y. 6-agujeros.", " Placa H. 7-agujeros.", " Micro Tornillo. CROSS HEAD. Ø1.2 x 3mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. CROSS HEAD. Ø1.2 x 4mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. CROSS HEAD. Ø1.2 x 5mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. CROSS HEAD. Ø1.2 x 6mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. CROSS HEAD. Ø1.2 x 7mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. CROSS HEAD. Ø1.2 x 8mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. EMERGENCIA. CROSS-HEAD. Ø1.4 x 3mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. EMERGENCIA. CROSS-HEAD. Ø1.4 x 5mm. (Autorroscante). Pack de 10 Pcs.", " Micro Tornillo. EMERGENCIA. CROSS-HEAD. Ø1.4 x 7mm. (Autorroscante). Pack de 10 Pcs."]

  const dataSection_1 = [
    ["F1.2-998-190", "F1.2-998-200", "F1.2-998-110", "F1.2-40018", "F1.2-70112", "F1.2-70313", "F1.2-70412", "F1.2-20217", "F1.2-20217S", "F1.2-15906", "F1.2-15907", "MICP-0.6-4-S", "MICP-0.6-6-S", "MICP-0.6-8-S", "MICP-0.6-16-S", "MICP-0.6-24-S", "MICP-0.6-5-L-L", "MICP-0.6-5-L-R", "MICP-0.6-5-T", "MICP-0.6-6-Y", "MICP-0.6-6-YY", "MICP-0.6-7-H", "MICS-1.2-C-3.0-TI-SC", "MICS-1.2-C-4.0-TI-SC", "MICS-1.2-C-5.0-TI-SC", "MICS-1.2-C-6.0-TI-SC", "MICS-1.2-C-7.0-TI-SC", "MICS-1.2-C-8.0-TI-SC", "MICS-1.4-EC-3.0-TI-SC", "MICS-1.4-EC-5.0-TI-SC", "MICS-1.4-EC-7.0-TI-SC"],
    lng === "en" ? dataSection_1_en : dataSection_1_es,
    ["1", "1", "1", "1", "1", "1", "1", "1", "1", "2", "2", "5", "5", "5", "5", "2", "5", "5", "5", "3", "3", "3", "5", "5", "5", "3", "3", "2", "1", "2", "1"]
  ]

  let data = {
    dataSection_1,
  }
  return data;
}