/*----- Core -----*/
import React, { useEffect } from 'react'
import { connect } from "react-redux"

/*----- Actions -----*/
import { getTeam, shootGetUser } from './Actions';

/*----- Selectors -----*/
import { isLoadingSelector, teamListSelector, userPostSelector, postIsLoadingSelector } from './Selector'

/*----- Components -----*/
import TeamList from '../../components/list/TeamList'
import WithLoading from '../../components/commons/WithLoading'
import PostCard from '../../components/card/PostCard';
const TeamListWithLoading = WithLoading(TeamList)
const PostCardWithLoading = WithLoading(PostCard)

/*----- Component -----*/
const Team = ({ isLoading, getTeam, teamList, getUser, userPost, postIsLoading }) => {

	useEffect(() => {
		getTeam()
	}, [getTeam])

	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-6">
						<TeamListWithLoading
							team={teamList}
							isLoading={isLoading}
							onClickFunc={getUser}
						/>
					</div>
					<div className="col-6">
						<h3>Person post</h3>
						<PostCardWithLoading
							userPost={userPost}
							isLoading={postIsLoading}
						/>
					</div>
				</div>
			</div>
		</>
	)

}

function mapStateToProps(state) {
	return {
		isLoading: isLoadingSelector(state),
		teamList: teamListSelector(state),
		userPost: userPostSelector(state),
		postIsLoading: postIsLoadingSelector(state)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getTeam: () => getTeam(dispatch),
		getUser: (userId) => shootGetUser(dispatch, userId)
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Team)