/*----- Imports -----*/
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { css } from "styled-components";

/*----- Commons-----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";
import Color from "../../commons/Color";

/*----- Components -----*/
import InputContainer from "../form/InputContainer";

/*----- Styles-----*/
const StyledDefaultButton = styled.button`
	
	text-transform: capitalize;
	border-radius: 50px;
	padding: 0 20px;
	cursor: pointer;
	background: ${Color.main};
	display: flex;
	justify-content: center;
	align-items: center;

	border: none;
	transition: all 0.3s ease-out;
	@media (min-width:1500px){
	height: 36px;
	${Montserrat(14, 600, "white")};

	color: ${({ colorText }) => colorText || Color.main};};
	min-width: 115px;
	max-width: 200px;
	
	@media (max-width:1500px){
		height: 30px;
		${Montserrat(12, 600, "white")};
		min-width: 60px;
		max-width: 130px;
	}
	&:focus {
		outline: none;
	}
	&:hover {
		color: white;
		transform: scale(1.04);
		box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
	}

	${props =>
		props.invert &&
		css`
		background: white;
		border: 1px solid black;
		color: ${Color.main};
		&:hover {
		color: ${Color.main};
		}
	`}

	${props =>
		props.secondary &&
		css`
	background: ${Color.secondary};
	border: none;
	color: white;
	&:hover {
	color: "white" !important;
	}
	`}

	${props =>
		props.secondary &&
		props.invert &&
		css`
	background: none;
	border: 1px solid ${Color.secondary};
	color: ${Color.secondary} !important;
	`}

	.default-button__icon {
		width: 16px;
		margin-right: 8px;
	}
`

/*----- Component -----*/
const DefaultButton = ({ content, onClick, invert, icon, secondary ,colorText}) => {
	return (
		<InputContainer>
			<StyledDefaultButton
				className="default-button"
				invert={invert}
				onClick={onClick}
				secondary={secondary}
				colorText={colorText}
			>
				{icon ? (
					<img
						src={icon}
						className="default-button__icon"
						alt={`${content} icon`}
					/>
				) : null}
				{content}
			</StyledDefaultButton>
		</InputContainer>
	);
};
export default DefaultButton;

/*----- PropTypes -----*/
DefaultButton.propTypes = {
	content: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	invert: PropTypes.bool,
	icon: PropTypes.string
};
