/*----- Core -----*/
import React from 'react';
import { Trans } from "react-i18next";
import { MobileStyles } from "../Styles";
import { Image } from "react-bootstrap";

import WaveImage from "../../../assets/images/onda-violeta-3.png";
import { renderVideos } from '../data';

const Videos = () => (
	<MobileStyles>
		<div className="content-image">
			{
			//<Image src={WaveImage}></Image>
			}
		</div>
		<div className="content-text">
			<div className="content-title">
				<div className="title">
					<h1><Trans>videos.self</Trans></h1>
					<span className="dot"></span>
				</div>
				<p><Trans>videos.description</Trans></p>
			</div>
		</div>
		<div className="content-videos">
			{renderVideos('100%', '176px')}
		</div>
	</MobileStyles >
);


export default Videos;
