import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import AccordionStyles from './Styles'
import { Row, Container, Col, Image } from "react-bootstrap";
import { Trans } from 'react-i18next';

import Arrow from "../../assets/icons/icon-components/Arrow.js"


const AccordionCard = ({ image, title, subtitle, content, inside, classNameCard, arrowColor }) => {

	const CustomToggle = ({ children, eventKey }) => {
		const [open, setOpen] = useState(false)

		const decoratedOnClick = useAccordionToggle(eventKey, () => setOpen(!open));

		return (
			<div onClick={decoratedOnClick}>
				<Arrow rotateDeg={open ? "90" : "270"} color={arrowColor} width="15px" heigth="15px" strokeWidth="40px"  />
			</div>
		)
	}

	const renderContent = () =>
		(
			<Accordion.Collapse eventKey="0" className="accordion-collapse">
				<Card.Body>
					{content}
				</Card.Body>
			</Accordion.Collapse>
		)

	return (
		<AccordionStyles>
			<Card className={classNameCard ? "card" + classNameCard : "card"}>
				<Card.Header>
					<Container fluid>
						<Row>
							<Col xs={3} className="first-col">
								<Image alt="" className="card-image" src={`${image}`}></Image>
							</Col>
							<Col xs={9} className='p-0' >
								<div className="card-title">
									<h1><Trans>{title}</Trans></h1>
									<h3><Trans>{subtitle}</Trans></h3>
								</div>
								<div className='border-title' />
							</Col>
						</Row>
					</Container>
					{
						inside &&
						renderContent()
					}

					<div className="container-toggle">
						<CustomToggle eventKey="0"></CustomToggle>
					</div>
				</Card.Header>
			</Card>
			{
				!inside &&
				renderContent()
			}
		</AccordionStyles>
	)
}

export default AccordionCard