import styled from 'styled-components';

export const Main = styled.div`
margin-top: 40px;
margin-bottom: 70px;
`;

export const CommonText = styled.p`
  font-family: "Montserrat", sans-serif;
  color: white;
  line-height: 16px;
  font-weight: 700;
`;

export const StyledText = styled(CommonText)`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 32px;
`;

export const StyledTextList = styled(StyledText)`
  margin: 0px;
`;

export const StyledImage = styled.img`
  width: 100%;
`;

export const StyledTextItalic = styled(CommonText)`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  margin: 32px 0px;
`;

export const StyledColorText = styled.span`
  color: #00B7EA;
  font-weight: 700;
`;

export const TitleAccordion = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

`;

export const StyledTitle = styled(CommonText)`
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
`;

export const StyledSubTitle = styled(CommonText)`
  font-size: 14px;
  font-weight: 700;
  margin-top: 16px;
`;

export const Dot = styled.span`
  margin-top:5px;
  height: 6px;
  width: 6px;
  background-color:#7161FF;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ItemContainer = styled.div`
display: flex;
  justify-content: flex-start;
`;

export const Section = styled.div`
  margin: 60px 0px;
`;