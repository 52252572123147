/* eslint-disable jsx-a11y/anchor-is-valid */
/*----- Imports -----*/
import React from "react";
import styled from "styled-components";
import {Image} from "react-bootstrap";
import {Trans} from "react-i18next"
import i18n from "i18next";
import IconWorld from "../../../assets/images/worldwide.svg";

const SelectLanguageStyles = styled.header`
	display: flex;
	justify-content: flex-end;
	background:#1e1e1e;
	border: none !important;
	padding: 0px;
`;

const AccordionStyles = styled.div`
	display: flex;
	justify-content: flex-end;
	border: none !important;
  padding: 0px;
  margin-bottom: 10px;
	.content{
		border: none;
	}
	.image{
		width:20px;
		height:20px;
		margin-left:10px;
	}
	.accordion{
		position: relative;
		display: flex;
		justify-content: flex-start;
		border: none;
		margin: 20px 20px 0px 0px;
	}
	.title{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 12px;
		width: 100%;
		color:white;
		font-family: 'Montserrat', sans-serif;
		h1{
			margin: 0px;
		}
	}
	section{
		position: relative;
		width: 100%;

	}
	a{
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		color: white !important;
		margin-top: 10px;
	}

	.accordion_content{
		width: 100%;
		background: #212529;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-left: 10px;
	}

	.accordion:hover section:hover > .accordion_content,
	.accordion section.default > .accordion_content {
    height: 60px;
		position: absolute;
		z-index: 9999;
		background: #212529;
	}

	.accordion section > .accordion_content,
	.accordion:active section > .accordion_content {
		position: absolute;
		z-index: 9999;
	  height: 0;
    overflow: hidden;
    transition: height .25s;
	}
`;

export const Accordion = () => {
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		console.log('changed', lng)
	}
	return (
		<AccordionStyles className="accordion-content">
			<div className="content">
				<div className="accordion">
					<section className="section">
						<div className="title">
							<h1 className="title"><Trans>general.select_language</Trans></h1>
							<Image src={IconWorld} className="image"></Image>
						</div>
						<div className="accordion_content">
							<a onClick={() => changeLanguage('es') }> Español</a>
							<a onClick={() => changeLanguage('en') }> English </a>
						</div>
					</section>
				</div>
			</div>
		</AccordionStyles>
	);
};

/*----- Component -----*/
const SelectLanguage = () => {
	return (
		<SelectLanguageStyles >
			<Accordion/>
		</SelectLanguageStyles>
	);
};

export default SelectLanguage;
