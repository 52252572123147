import React from 'react';
import Styles from './Styles';
import { Trans } from 'react-i18next';

import systems from '../../systems';


const Instruments = ({ system }) => {
  return (
    <Styles>
      <p><Trans>{systems[system].instruments.text}</Trans></p>
    </Styles>
  )
}

export default Instruments