
import React, { useState } from 'react';
import { Form, Button, ButtonToolbar } from 'react-bootstrap';
import { Trans } from "react-i18next";
import { withTranslation } from 'react-i18next';

const ContactForm = (props) => {
	const [name, setName] = useState('')
	const [surname, setSurname] = useState('')
	const [email, setEmail] = useState('')
	const [telephone, setTelephone] = useState('')
	const [message, setMessage] = useState('')
	const [formMessage, setFormMessage] = useState('')
	const [sending, setSending] = useState(false)

	const infoEmailTemplete = `
	<table border="0" cellspacing="4" cellpadding="0" width="100%" style="width:100.0%;background:#f6f6f6">
    <tbody>
        <tr>
            <td style="padding:0cm 0cm 0cm 0cm"></td>
            <td style="border:solid #f0f0f0 1.0pt;background:white;padding:15.0pt 15.0pt 15.0pt 15.0pt;">
                    <h2><span style="font-family:&quot;Helvetica&quot;,sans-serif;color:black">Nuevo mensaje desde la web</span>
                        <span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span>
                    </h2>
                    <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif;color:black">Email de #{email}# </span><span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
                    <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif;color:black">Nombre: #{name}#</span><span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
                    <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif;color:black">Apellido: #{surname}#</span><span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
                    <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif;color:black">Telefono: #{telephone}#</span><span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
                    <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif;color:black">Mensaje: #{message}#</span><span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
            </td>
            <td style="padding:0cm 0cm 0cm 0cm"></td>
        </tr>
    </tbody>
</table>` 

	const autoreplyEmailTemplate = `
	<table border="0" cellspacing="3" cellpadding="0" width="100%" style="width:100.0%;background:#f6f6f6">
    <tbody>
        <tr>
            <td style="padding:0cm 0cm 0cm 0cm"></td>
            <td style="border:solid #f0f0f0 1.0pt;background:white;padding:15.0pt 15.0pt 15.0pt 15.0pt;max-width:600px!important">
                        <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif">
                            #{text}#<u></u><u></u></span></p>
                        <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;">
                            <span style="font-family:&quot;Helvetica&quot;,sans-serif">#{faithfully}#<u></u><u></u></span></p>
                        <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><b><span style="font-family:&quot;Helvetica&quot;,sans-serif">#{signature}#</span></b>
                            <span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
                        <p style="line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><i><span style="font-family:&quot;Helvetica&quot;,sans-serif">#{noReply}#</span></i>
                            <span style="font-family:&quot;Helvetica&quot;,sans-serif"><u></u><u></u></span></p>
                        <span class="MsoNormal" align="center" style="text-align:center;line-height:14.4pt; font-size: 12.0pt; margin: 0 !important;"><span style="font-family:&quot;Helvetica&quot;,sans-serif"><hr size="3" width="100%" align="center"></span></span>
            </td>
            <td style="padding:0cm 0cm 0cm 0cm"></td>
        </tr>
    </tbody>
</table>`

	const cleanForm = () => {
		setName('')
		setSurname('')
		setEmail('')
		setTelephone('')
		setMessage('')
	}

	const validateEmail = (email) => {
		const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ // eslint-disable-line
		return re.test(email);
	}

	const sendEmail = async (e) => {
		e.preventDefault()
		let error = ""
		if (!name.length) {
			error = t('contact.contactForm.inputName.error')
		} else if (!email.length) {
			error = t('contact.contactForm.inputEmail.error')
		} else if (!validateEmail(email)) {
			error = t('contact.contactForm.inputEmail.invalid')
		}

		if (error.length) {
			setFormMessage(error)
			return
		}
		setSending(true)

		let params = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		}

		const faicoEmail = "info@faicomedical.com";
		const webfaicoEmail = "web@faicomedical.com";
		const noReply = "no-reply@faicomedical.com";

		let emailBody = infoEmailTemplete;
		emailBody = emailBody.replace("#{email}#", email);
		emailBody = emailBody.replace("#{name}#", name);
		emailBody = emailBody.replace("#{surname}#", surname);
		emailBody = emailBody.replace("#{telephone}#", telephone);
		emailBody = emailBody.replace("#{message}#", message);

		let infoEmailParams = [
			{
				"token": "#{email}#",
				"value": email
			},
			{
				"token": "#{name}#",
				"value": name
			},
			{
				"token": "#{surname}#",
				"value": surname
			},
			{
				"token": "#{telephone}#",
				"value": telephone
			},
			{
				"token": "#{message}#",
				"value": message
			}
		]

		let firstEmail = {
			...params,
			body: JSON.stringify({
				sender: name,
				sender_email: webfaicoEmail,
				recipients: [faicoEmail],
				subject: "Consulta FAICO",
				template_name: "infoEmail",
				template_params: {
					"header": "Nuevo mensaje desde la web",
					"body": infoEmailParams
				},
				reply_to: [email]
			})
		}

		let response = await fetch('/contacto/contacto.php', firstEmail)

		if (response.ok) {

			let replyEmailBody = autoreplyEmailTemplate;
			replyEmailBody = replyEmailBody.replace("#{text}#", t('contact.messageSender.text'));
			replyEmailBody = replyEmailBody.replace("#{faithfully}#", t('contact.messageSender.faithfully'));
			replyEmailBody = replyEmailBody.replace("#{signature}#", t('contact.messageSender.signature'));
			replyEmailBody = replyEmailBody.replace("#{noReply}#", t('contact.messageSender.noReply'));

			let replyEmailParams = [
				{
					"token": "#{text}#",
					"value": t('contact.messageSender.text')
				},
				{
					"token": "#{faithfully}#",
					"value": t('contact.messageSender.faithfully')
				},
				{
					"token": "#{signature}#",
					"value": t('contact.messageSender.signature')
				},
				{
					"token": "#{noReply}#",
					"value": t('contact.messageSender.noReply')
				}
			]

			let secondEmail = {
				...params,
				body: JSON.stringify({
					sender: name,
					sender_email: noReply,
					recipients: [email],
					subject: "FAICO Medical",
					template_name: "autoReply",
					template_params: {
						"body": replyEmailParams
					},
					reply_to: [noReply]
				})
			}

			response = await fetch('/contacto/contacto.php', secondEmail)

			if (response.ok) {
				cleanForm()
				setFormMessage(t('contact.contactForm.successAction'))
				setTimeout(() => {
					setFormMessage('')
				}, 4000);
			} else {
				setFormMessage(t('contact.contactForm.errorAction'))
			}
		}
		else {
			setFormMessage(t('contact.contactForm.errorAction'))
		}
		setSending(false)
	}

	const getButtonClass = () => {
		if (!formMessage.length) {
			return "message-hidden"
		} else if (formMessage === t('contact.contactForm.successAction')) {
			return "form-success"
		}
	}


	const { t } = props;
	return (
		<>
			<h1>
				<Trans>contact.contactForm.title</Trans>
			</h1>
			<h1>
				<Trans>contact.contactForm.title2</Trans>
			</h1>
			<h1>
				<Trans>contact.contactForm.subtitle</Trans>
			</h1>
			<Form className="contact-form">
				<Form.Group>
					<Form.Control
						type="text"
						placeholder={'*' + t('contact.contactForm.inputName.placeholder')}
						onChange={(e) => { setName(e.currentTarget.value) }}
						required
						value={name}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						type="text"
						placeholder={t('contact.contactForm.inputSurname.placeholder')}
						onChange={(e) => { setSurname(e.currentTarget.value) }}
						required
						value={surname}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						type="email"
						placeholder={'*' + t('contact.contactForm.inputEmail.placeholder')}
						onChange={(e) => { setEmail(e.currentTarget.value) }}
						required
						value={email}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						type="text"
						placeholder={t('contact.contactForm.inputPhone.placeholder')}
						onChange={(e) => { setTelephone(e.currentTarget.value) }}
						required
						value={telephone}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Control
						as="textarea"
						rows="3"
						placeholder={t('contact.contactForm.inputQuery.placeholder')}
						onChange={(e) => { setMessage(e.currentTarget.value) }}
						required
						value={message}
					/>
				</Form.Group>

				<ButtonToolbar className="container-button">
					<Button
						className='contact-form-button zoomButton'
						variant="secondary"
						type="submit"
						onClick={(e) => sendEmail(e)}
						disabled={sending}
					>
						{sending ?
							<Trans>contact.contactForm.buttonProgress</Trans>
							:
							<Trans>contact.contactForm.button</Trans>
						}


					</Button>
				</ButtonToolbar>
				<div className={`form-message ${getButtonClass()}`}>
					{formMessage}
				</div>
			</Form>
		</>
	)
}

export default withTranslation(['es']['contact'])(ContactForm)
