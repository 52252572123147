/*----- Assets -----*/
import DentalImage from "../../assets/images/download-craneo-dental.png";
import CranioImage from "../../assets/images/download-craneo-maxilofacial.png";
import DocumentImage from "../../assets/images/document.svg"

const data = [
	{
		title: 'descarga.catalog_1.title',
		image: DentalImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_1.items.1',
				refSpanish: '/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO Catalogo Dental 2020-2021_(SP).pdf',
				refEnglish: '/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO Dental Catalog 2020-2021_(EN).pdf'
			},
			{
				title: 'descarga.catalog_1.items.2',
				refSpanish: '/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO FVCON® Conexión Cono Morse 2021_(SP).pdf',
				refEnglish: '/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO FVCON® Morse Cone Connection 2021_(EN).pdf'
			},
			{
				title: 'descarga.catalog_1.items.3',
				refSpanish: "/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO Conexion Hexagono Externo_(SP).pdf",
				refEnglish: "/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO External Hexagon Connection_(EN).pdf"
			},
			{
				title: 'descarga.catalog_1.items.4',
				refSpanish: "/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO Guía Quirúrgica & Manual Protésico_HI & Cónica_(SP).pdf",
				refEnglish: "/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO Surgical Guide & Prosthesis Manual_IH & Conical_(EN).pdf"
			},
			{
				title: 'descarga.catalog_1.items.5',
				refSpanish: "/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO Guía Quirúrgica & Manual Protésico_HE_(SP).pdf",
				refEnglish: "/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO Surgical Guide & Prosthesis Manual_EH_(EN).pdf"
			}
		],
		name: 'imageDental'
	},
	{
		title: 'descarga.catalog_2.title',
		image: CranioImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_2.items.1',
				refSpanish: '/pdf/Español/2_CatalogoMaxilofacialyDocumentos/FAICO Maxillofacial Catalog (EN-SP).pdf',
				refEnglish: '/pdf/Ingles/2_Maxillofacial_Catalog_and_Documents/FAICO Maxillofacial Catalog (EN-SP).pdf'
			},
		]
	},
	{
		title: 'descarga.catalog_3.title',
		image: DocumentImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_3.items.1',
				refSpanish: "/pdf/Español/3_IFU/IFU_192015 FAICO Dental Implant System_(EN-SP).pdf",
				refEnglish: "/pdf/Ingles/3_IFU/IFU_192015 FAICO Dental Implant System_(EN-SP).pdf"
			},
			{
				title: 'descarga.catalog_3.items.2',
				refSpanish: "/pdf/Español/3_IFU/IFU_191997 FAICO CMF System_(EN-SP).pdf",
				refEnglish: "/pdf/Ingles/3_IFU/IFU_191997 FAICO CMF System_(EN-SP).pdf"
			},
		]
	},
	{
		title: 'descarga.catalog_4.title',
		image: DocumentImage,
		pdf: '',
		filename: "",
		links: [
			{
				title: 'descarga.catalog_4.items.1',
				refSpanish: "/pdf/Español/4_Certificados/FDA_K192015 FAICO Dental Implant System.pdf",
				refEnglish: "/pdf/Ingles/4_Certificates/FDA_K192015 FAICO Dental Implant System.pdf"
			},
			{
				title: 'descarga.catalog_4.items.2',
				refSpanish: "/pdf/Español/4_Certificados/FDA_K191997 FAICO CMF System.pdf",
				refEnglish: "/pdf/Ingles/4_Certificates/FDA_K191997 FAICO CMF System.pdf"
			},
		]
	},
]

export default data;