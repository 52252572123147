// Core
import React from 'react'
import styled from 'styled-components'
import BackButton from '../../../components/button/BackButton'
import Tag from '../../../components/Tag'
// Constants
import { ROOT_URL } from '../../../services/BaseUrl'

const StyledNovelty = styled.div`
  width:100%;
  margin-bottom:60px;
  .content-image{
    height: 200px;
  }
  .content-information{
    margin:18px 30px 22px 30px;
    .tags{
      span{
        margin-right:5px;
      }
    }
    .content-text{
        h1{
            margin-top:40px;
            font-size:28px;
            line-height:32px;
            font-weight:700;
        }
        p{
            margin:40px 0px;
            font-size:14px;
            line-height:17px;
            font-weight:500;
        }
    }
  }

`
const Novelty = ({ post }) => {
  let {
    title,
    subtitle,
    main_image,
    tags
  } = post.attributes
  return (
    <StyledNovelty >
      <div className="content-image"
        style={{
            backgroundImage: `url(${ROOT_URL}${main_image.url})`,
            backgroundSize:'cover'
        }}
      />

      <div className="content-information">
        <div className="tags">
          <div>{tags.map((tag, i) => <Tag key={tag} invert="true" content={tag.name} />)}</div>
        </div>
        <div className="content-text">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>

        <BackButton path={'/news'} />
      </div>

    </StyledNovelty>
  )
}
export default Novelty
