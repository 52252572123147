/*----- Imports -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { A } from 'hookrouter';
/*----- Commons-----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";
import Color from "../../commons/Color";

/*----- Styles-----*/
const StyledNavButton = styled(A)`
	${Montserrat(14, 400, "black")};
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: capitalize;
	height: 46px;
	padding: 0 20px;
	min-width: 100px;
	justify-content: center;
	max-width: 200px;
	border: none;
	transition: all 0.3s ease-out;
	&:focus {
		outline: none;
	}
	&:hover {
		color: ${Color.main};
		border-bottom: 1px solid ${Color.main};
	}
`;

/*----- Component -----*/
const NavButton = ({ content, destination, onClick }) => {
	return <StyledNavButton onClick={onClick} href={destination}> {content}</StyledNavButton>
};
export default NavButton;

/*----- PropTypes -----*/
NavButton.propTypes = {
	content: PropTypes.object.isRequired,
	destination: PropTypes.string.isRequired,
	onClick: PropTypes.any
};
