import styled from 'styled-components'

export const TableSecondaryStyles = styled.div`
	width:93%;
	div::-webkit-scrollbar {
		width: 5px;
		height:5px;
		background:#5F5F5F;
	}
	div::-webkit-scrollbar-thumb {
		background-color: #909090;
		width:99%;
	}
	.div-collapse{
		height: auto;
		display: flex;
		justify-content: flex-end;
		border-right: 1px solid #909090 !important;
		.content-info-expand{
			width: 20%;
			padding: 5px;
			height: min-content;
			background:#A2A2A2;
			border-radius:5px;
			margin:10px;	
			display: flex;
			flex-direction: column;
			align-items: center;

			img{
				margin-top:5px;
			}
		}
	}
	.line-collapse{
		border-top: 1px solid #909090 !important;
	}
	.table-scroll{
		position: relative;
		overflow: auto;
		display: block;
		height: ${props => props.height ? props.height : "350px !important"};
		border-radius:5px;
		text-align:center;

		@media (min-width:1280px) and (max-width:1500px){
			height:150px !important;
		}
		@media (max-width:1280px){
			height:100px !important;
		}
	}
	.head{
		background:#4A4949;
	}
	.image-arrow{
		width:20px;
		height:20px;
	}
	.td-first{
		display:flex;
		justify-content: center;
		align-items: center;
	}
	.td-fourth{
		p{
			border-right:1px solid #909090 !important;
		}
	}
	.tr-head{
		font-size:16px;
		font-family: 'Montserrat', sans-serif;
		@media (max-width:1280px){
			font-size:14px;
		}
		th{
			padding: 10px;
			background:#4A4949;
			text-align: center;
			border-right:1px solid #707070;
			border-bottom:0px solid transparent;
			border-left:0px solid transparent;
			border-top:0px solid transparent;
			vertical-align: middle;
			z-index:900;
			position: sticky; top: 0;
		}
		.first{
			width:15%;
		}
		.second{
			width:15%
		}
		.third{
			width:2%;
			border-right: 1px solid transparent;
		}
		
	}
	.tr-info-par{
		p{
			height:45px;
			margin:0px;
			padding:0px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		td{
			padding:5px !important;
			vertical-align: middle;
			background:#797979;
			border:1px solid #797979;
		}
	}
	.tr-info-impar{
		p{
			height:45px;
			margin:0px;
			padding:0px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		td{
			padding:5px !important;
			vertical-align: middle;
			background:#545353;
			border:1px solid #545353;
		}	
	}

`