import React from 'react'
import SmartImplant from '../../../../dental/mobile/LevelTwo/SmartImplant';
import image from "../../../../../assets/images/Dental/internalHexagonConnection/smartImplants/modals/Cyroth-EI.png";

const Cyroth = (props) => {
	return (
		<SmartImplant
			image={image}
			reference={1}
			system='internalHexagonConnection'
		/>
	)
}

export default Cyroth