/*----- Imports -----*/
import React, { useState, useRef, useEffect } from "react";
import i18n from "i18next"
import Home from "../home/desktop/index";
import Team from "../team/index";
import About from "../about/desktop/index";
import Dental from "../dental/desktop/index";
import Maxillofacial from "../maxillofacial/desktop/index";
import Faico360 from "../faico360/desktop/index";
import Contact from "../contact/desktop/index";
import Download from "../download/desktop/index";
import Implants from '../implants';
import UniversalExpanders from "../../modules/universalExpanders/desktop/index";
import HexaConnections from '../hexagon';
import Header from "../../components/commons/nav/desktop/Header";
import SelectLanguage from "../../components/commons/nav/SelectLanguage"
import Footer from "../../components/commons/footer/Footer";
import MandibularReconstruction from "../mandibularReconstruction";
import MiniplatingLowProfile from "../miniplatingLowProfile";
import MediumPlatingSystem from "../mediumplatingSystem";
import IMFSystem from "../imfSystem";
import FracturePlatingSystem from "../fracturePlatingSystem";
import MiniplatingStandardSystem from "../miniplatingStandardSystem";
import GuidedSurgery from "../guidedSurgery/desktop";
import News from "../news/desktop";
import NoveltyContainer from "../news/desktop/NoveltyContainer";
import styled from "styled-components";
import { useRoutes, usePath } from 'hookrouter';
import Breadcrumb from '../../components/breadcrumb'
import MicroPlatingSystem from "../microPlatingSystem";
import IHCSmartImplants from "../internalHexagonConnection/desktop/smartImplants";
import IHCProstheticComponents from "../internalHexagonConnection/desktop/prostheticComponents";
import IHCSurgicalInstruments from "../internalHexagonConnection/desktop/surgicalInstruments";
import CCProstheticComponents from "../conicalConnection/desktop/prostheticComponents";
import CCSurgicalInstruments from "../conicalConnection/desktop/surgicalInstruments";
import CCSmartImplants from "../conicalConnection/desktop/smartImplants";
import ScrollToTop from "../../components/ScrollToTop";
import MicroPlatingScrews from "../microPlatingSystem/microPlatingScrews";
import MicroPlatingPlates from "../microPlatingSystem/microPlatingPlates";
import MicroPlatingInstruments from "../microPlatingSystem/microPlatingInstruments";
import MicroPlatingSets from "../microPlatingSystem/microPlatingSets";
import MediumPlatingScrews from "../mediumplatingSystem/mediumPlatingScrews";
import MediumPlatingSystemPlates from "../mediumplatingSystem/mediumPlatingPlates";
import MediumPlatingSystemInstruments from "../mediumplatingSystem/mediumPlatingInstruments";
import MediumPlatingSystemSets from "../mediumplatingSystem/mediumPlatingSets"
import MiniplatingLowProfileScrews from "../miniplatingLowProfile/miniplatingLowProfileScrews";
import MiniplatingLowProfilePlates from "../miniplatingLowProfile/miniplatingLowProfilePlates";
import MiniplatingLowProfileInstruments from "../miniplatingLowProfile/miniplatingLowProfileInstruments";
import MiniplatingLowProfileSets from "../miniplatingLowProfile/microplatingLowProfileSets";
import MiniplatingStandardSystemScrews from "../miniplatingStandardSystem/miniplatingStandardSystemScrews";
import MiniplatingStandardSystemPlates from "../miniplatingStandardSystem/miniplatingStandardSystemPlates";
import MiniplatingStandardSystemInstruments from "../miniplatingStandardSystem/miniplatingStandardSystemInstruments";
import MiniplatingStandardSystemSets from "../miniplatingStandardSystem/miniplatingStandardSystemSets";
import FracturePlatingSystemScrews from "../fracturePlatingSystem/fracturePlatingSystemScrews";
import FracturePlatingSystemPlates from "../fracturePlatingSystem/fracturePlatingSystemPlates";
import FracturePlatingSystemInstruments from "../fracturePlatingSystem/fracturePlatingSystemInstruments";
import FracturePlatingSystemSets from "../fracturePlatingSystem/fracturePlatingSystemSets";
import MandibularReconstructionScrews from "../mandibularReconstruction/mandibularReconstructionScrews";
import MandibularReconstructionPlates from "../mandibularReconstruction/mandibularReconstructionPlates";
import MandibularReconstructionInstruments from "../mandibularReconstruction/mandibularReconstructionInstruments";
import MandibularReconstructionSets from "../mandibularReconstruction/mandibularReconstructionSets";
import Videos from "../videos/desktop";
import LegalNotice from '../legalNotice/index';
import NoticePrivacy from '../noticePrivacy/index';
import TermsConditions from '../termsConditions/index';
import ClinicalCaseDetail from '../clinicalCaseDetails/desktop/index';
import CCNewSmartImplantsDesktop from '../conicalConnection/desktop/smartImplants/new/index'

const StyledBody = styled.div`
	overflow: hidden;
`;

function generateRoutes() {
	const routes = {
		'/': { breadCrumbText:  i18n.t('breadcrumb./') , component: () => <Home /> },
		'/team': { breadCrumbText:  i18n.t('breadcrumb./team'), component: () => <Team /> },
		'/products': { breadCrumbText:  i18n.t('breadcrumb./products'), component: () => <Team /> },
		'/about': { breadCrumbText:  i18n.t('breadcrumb./about'), component: () => <About /> },
		'/dental': { breadCrumbText:  i18n.t('breadcrumb./dental'), component: () => <Dental /> },
		'/dental/implants': { breadCrumbText:  i18n.t('breadcrumb./dental/implants') , component: () => <Implants /> },
		'/dental/conical_connections': { breadCrumbText:  i18n.t('breadcrumb./dental/conical_connections'), component: () => <Dental conicModalState={true} /> },
		'/dental/conical_connections/cyroth': { breadCrumbText:  i18n.t('breadcrumb./dental/conical_connections/Cyroth™'), component: () => <Dental /> },
		'/dental/conical_connections/is_four': { breadCrumbText:  i18n.t('breadcrumb./dental/conical_connections/is_four'), component: () => <Dental /> },
		'/dental/hexagonal_connections': { breadCrumbText:  i18n.t('breadcrumb./dental/hexagonal_connections'), component: () => <HexaConnections /> },
		'/dental/hexagonal_connections/cyroth_short': { breadCrumbText:  i18n.t('breadcrumb./dental/hexagonal_connections/Cyroth™_short'), component: () => <Dental /> },
		'/dental/hexagonal_connections/cyroth': { breadCrumbText:  i18n.t('breadcrumb./dental/hexagonal_connections/Cyroth™'), component: () => <Dental /> },
		'/dental/hexagonal_connections/is_four': { breadCrumbText:  i18n.t('breadcrumb./dental/hexagonal_connections/is_four'), component: () => <Dental /> },
		'/dental/universal_expanders': { breadCrumbText:  i18n.t('breadcrumb./dental/universal_expanders'), component: () => <UniversalExpanders /> },
		'/dental/guided_surgery': { breadCrumbText:  i18n.t('breadcrumb./dental/guided_surgery'), component: () => <GuidedSurgery /> },
		'/dental/internalHexagonConnection': { breadCrumbText:  i18n.t('breadcrumb./dental/internalHexagonConnection'), component: () => <Dental hexagonModalState={true} /> },
		'/dental/internalHexagonConnection/smartImplants': { breadCrumbText:  i18n.t('breadcrumb./dental/internalHexagonConnection/smartImplants'), component: () => <IHCSmartImplants /> },
		'/dental/internalHexagonConnection/prostheticComponents': { breadCrumbText:  i18n.t('breadcrumb./dental/internalHexagonConnection/prostheticComponents'), component: () => <IHCProstheticComponents /> },
		'/dental/internalHexagonConnection/surgicalInstruments': { breadCrumbText:  i18n.t('breadcrumb./dental/internalHexagonConnection/surgicalInstruments'), component: () => <IHCSurgicalInstruments /> },
		'/dental/conicalConnection': { breadCrumbText:  i18n.t('breadcrumb./dental/conicalConnection'), component: () => <Dental conicalModalState={true} /> },
		'/dental/conicalConnection/prostheticComponents': { breadCrumbText:  i18n.t('breadcrumb./dental/conicalConnection/prostheticComponents'), component: () => <CCProstheticComponents /> },
		'/dental/conicalConnection/surgicalInstruments': { breadCrumbText:  i18n.t('breadcrumb./dental/conicalConnection/surgicalInstruments'), component: () => <CCSurgicalInstruments /> },
		'/dental/conicalConnection/smartImplants': { breadCrumbText:  i18n.t('breadcrumb./dental/conicalConnection/smartImplants'), component: () => <CCSmartImplants /> },
		'/maxillofacial': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial'), component: () => <Maxillofacial /> },
		'/maxillofacial/microplating_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/microplating_system'), component: () => <MicroPlatingSystem /> },
		'/maxillofacial/microplating_system/screws': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/microplating_system/screws'), component: () => <MicroPlatingScrews /> },
		'/maxillofacial/microplating_system/plates': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/microplating_system/plates'), component: () => <MicroPlatingPlates /> },
		'/maxillofacial/microplating_system/instruments': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/microplating_system/instruments'), component: () => <MicroPlatingInstruments /> },
		'/maxillofacial/microplating_system/sets': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/microplating_system/sets'), component: () => <MicroPlatingSets /> },
		'/maxillofacial/miniplating_low_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_low_system'), component: () => <MiniplatingLowProfile /> },
		'/maxillofacial/miniplating_low_system/screws': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_low_system/screws'),component: () => <MiniplatingLowProfileScrews /> },
		'/maxillofacial/miniplating_low_system/plates': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_low_system/plates'), component: () => <MiniplatingLowProfilePlates /> },
		'/maxillofacial/miniplating_low_system/instruments': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_low_system/instruments'), component: () => <MiniplatingLowProfileInstruments /> },
		'/maxillofacial/miniplating_low_system/sets': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_low_system/sets'), component: () => <MiniplatingLowProfileSets /> },
		'/maxillofacial/mediumplating_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mediumplating_system'), component: () => <MediumPlatingSystem /> },
		'/maxillofacial/mediumplating_system/screws': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mediumplating_system/screws'), component: () => <MediumPlatingScrews /> },
		'/maxillofacial/mediumplating_system/plates': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mediumplating_system/plates'), component: () => <MediumPlatingSystemPlates /> },
		'/maxillofacial/mediumplating_system/instruments': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mediumplating_system/instruments'), component: () => <MediumPlatingSystemInstruments /> },
		'/maxillofacial/mediumplating_system/sets': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mediumplating_system/sets'), component: () => <MediumPlatingSystemSets /> },
		'/maxillofacial/imf_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/imf_system'), component: () => <IMFSystem /> },
		'/maxillofacial/miniplating_standard_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_standard_system'), component: () => <MiniplatingStandardSystem /> },
		'/maxillofacial/miniplating_standard_system/screws': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_standard_system/screws'), component: () => <MiniplatingStandardSystemScrews /> },
		'/maxillofacial/miniplating_standard_system/plates': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_standard_system/plates'), component: () => <MiniplatingStandardSystemPlates /> },
		'/maxillofacial/miniplating_standard_system/instruments': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_standard_system/instruments'), component: () => <MiniplatingStandardSystemInstruments /> },
		'/maxillofacial/miniplating_standard_system/sets': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_standard_system/sets'), component: () => <MiniplatingStandardSystemSets /> },
		'/maxillofacial/fracture_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/fracture_system'), component: () => <FracturePlatingSystem /> },
		'/maxillofacial/fracture_system/screws': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/fracture_system/screws'), component: () => <FracturePlatingSystemScrews /> },
		'/maxillofacial/fracture_system/plates': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/fracture_system/plates'), component: () => <FracturePlatingSystemPlates /> },
		'/maxillofacial/fracture_system/instruments': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/fracture_system/instruments'), component: () => <FracturePlatingSystemInstruments /> },
		'/maxillofacial/fracture_system/sets': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/fracture_system/sets'), component: () => <FracturePlatingSystemSets /> },
		'/maxillofacial/mandibular_reconstruction': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mandibular_reconstruction'), component: () => <MandibularReconstruction /> },
		'/maxillofacial/mandibular_reconstruction/screws': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mandibular_reconstruction/screws'), component: () => <MandibularReconstructionScrews /> },
		'/maxillofacial/mandibular_reconstruction/plates': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mandibular_reconstruction/plates'), component: () => <MandibularReconstructionPlates /> },
		'/maxillofacial/mandibular_reconstruction/instruments': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mandibular_reconstruction/instruments'), component: () => <MandibularReconstructionInstruments /> },
		'/maxillofacial/mandibular_reconstruction/sets': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mandibular_reconstruction/sets'), component: () => <MandibularReconstructionSets /> },
		'/faico360': { breadCrumbText:  i18n.t('breadcrumb./faico360'), component: () => <Faico360 /> },
		'/faico360/workshops': { breadCrumbText:  i18n.t('breadcrumb./faico360/workshops'), component: () => <Faico360 /> },
		'/faico360/clinical_cases': { breadCrumbText:  i18n.t('breadcrumb./faico360/clinical_cases'), component: () => <Faico360 /> },
		'/faico360/faico_connect': { breadCrumbText:  i18n.t('breadcrumb./faico360/faico_connect'), component: () => <Faico360 /> },
		'/contact': { breadCrumbText:  i18n.t('breadcrumb./contact'), component: () => <Contact /> },
		'/download': { breadCrumbText:  i18n.t('breadcrumb./download'), component: () => <Download /> },
		'/news': { breadCrumbText:  i18n.t('breadcrumb./news'), component: () => <News /> },
		'/news/:postId': { breadCrumbText:  i18n.t('breadcrumb./news/:postId'), component: ({ postId }) => <NoveltyContainer id={postId} /> },
		'/videos': {breadCrumbText: i18n.t('breadcrumb./videos') , component: () => <Videos /> },
		'/legalNotice': { breadCrumbText: i18n.t('breadcrumb./legalNotice'), component: () => <LegalNotice /> },
		'/noticePrivacy': { breadCrumbText: i18n.t('breadcrumb./noticePrivacy'), component: () => <NoticePrivacy /> },
		'/termsConditions': { breadCrumbText: i18n.t('breadcrumb./termsConditions'), component: () => <TermsConditions /> },
		'/faico360/:index': { breadCrumbText: i18n.t('breadcrumb./faico360/:index'), component: ({index}) => <ClinicalCaseDetail index={index}/> },
		'/dental/conicalConnection/newSmartImplants': { breadCrumbText:  i18n.t('breadcrumb./dental/conicalConnection/smartImplants'), component: () => <CCNewSmartImplantsDesktop /> }
		
	}
	return routes
}

const ViewsContainer = () => {
	const [fixedHeader, setFixedHeader] = useState(false)
	const [dictToBread, setDictToBread] = useState({})
	const header = useRef();

	useEffect(() => {
		generateBreadCrumb();
		function handleScroll() {
			if ((window.pageYOffset > header.current.offsetTop)) {
				setFixedHeader(true)
			} else {
				setFixedHeader(false)
			}
		}
		window.addEventListener('scroll', handleScroll);
	}, []);
	i18n.on('languageChanged', function(lng) {
		generateBreadCrumb();
	})
	function generateBreadCrumb() {
		let dictToBreadRoutes = {};
		let routes = generateRoutes();
		Object.keys(routes).map((k) => { return (dictToBreadRoutes[k] = routes[k].breadCrumbText) });
		setDictToBread(dictToBreadRoutes);
	}
	//assemble object for hookrouter
	const routerForRouter = {};
	let routes = generateRoutes();
	Object.keys(routes).map((k) => { return (routerForRouter[k] = routes[k].component) });
	const routeResult = useRoutes(routerForRouter);
	//assemble object for breadcrummb
	document.body.classList.remove('scrollModal')
	const path = usePath();

	return (
		<StyledBody>
			<React.Fragment>
				{!fixedHeader ? <SelectLanguage /> : null}
				<Header
					header={header}
					fix={fixedHeader}
				/>
				{!fixedHeader ? <Breadcrumb dictionary={dictToBread} /> : null}
				<ScrollToTop path={path}>
					{routeResult}
				</ScrollToTop>
				<Footer />
			</React.Fragment>
		</StyledBody>

	);
};

export default ViewsContainer;