import styled from 'styled-components'
import Montserrat from "../../commons/mixins/fonts/Montserrat";
import Device from "../../commons/Device";

export default styled.main`
	ul{
		height:100%;
		width:100%;
	}
	ul li{
		height:100%;
		width:100%;
	}
	.content-image{
		height:100%;
		width:100%;
		position:absolute;
		img{
			position:relative;
			width:100%;
			height:100%;
		}
	}
}
ol {
	position: absolute;
	bottom: 55px;
	display: flex;
	color: white;
	justify-items: center;
	list-style-type: none;
	li {
		display: inline-block;
		min-width: 22px;
		min-height: 20px;
		background-color: #636262;
		margin: 0 0.5em;
		border-radius: 50%;
		overflow: hidden;
		cursor: pointer;
		text-indent: 100%;
	}
	@media ${Device.xs} {
		bottom: 205px;
		left: -25px;
	}
}

.li-active {
	pointer-events: none;
	cursor: auto;
	background-color: white;
}

.carousel-image {
	position: relative;
	height: 600px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	justify-content: center;
	margin-bottom:80px;
	.carousel-text {
		display: flex;
		justify-content: center;
		background: transparent;
		/* height: 35%; */
		/* background-color: #7160ff; */
		position: absolute;
		text-align: right;

		right:10px;
		bottom:-10%;
		width:100%;

		.row{
			width:100%;
		}

		.button {
			@media ${Device.xs} {
				right: 20px !important;
			}
			@media (max-width: 321px) {
				right: 25px !important;
			}
		}
		.default-button {
			border: 1px solid #7160ff;
			color: white;
			background-color: #7160ff;
		}
		/* .header-title {
			display: flex;
			${Montserrat(16, 500, "#7160ff")};
			@media ${Device.xs} {
				margin-left: 20px !important;
				font-size: 13px;
			}
			@media ${Device.md} {
				font-size: 13px;
			}
			@media ${Device.lg} {
				font-size: 16px;
			}
		} */
		.main-title {
			width:100%;
			${Montserrat(22, 600, "white")};

			@media (max-width: 321px) {
				font-size: 16px !important;
			}
			@media ${Device.xs} {
				margin-left: 20px !important;
				font-size: 19px;
			}
			@media ${Device.md} {
				font-size: 20px;
			}
			@media ${Device.lg} {
				font-size: 35px;
			}
		}

	}

	.content-main-title:hover{
		cursor: pointer;
	}
	/* .carousel-text-1{
		left:20px;
	} */
	.carousel-text-2, .carousel-text-3{
		text-align:right;
	}
}

/* .arrow-container{
	position:absolute;
	width:100%;
	height:100%;
	display:flex;
	align-items:center;
	justify-content:space-between;
	top:0;
	padding: 0 130px; */
	.img-container{
		position:absolute;
		img{
			width:40px;
			height:40px;
		}
	}
	.img-container:active {
		transform: translateY(4px);
	}
	.arrow-left {
		top:43%;
		left: 3.5%;
	}

	.arrow-right{
		top:43%;
		right: 3.5%;
	}
/* } */

`