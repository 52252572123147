import styled, { css } from 'styled-components'
import BackgroundImage from "../../assets/images/Home/background-download.png";

export const DesktopStyles = styled.div`
	position:relative;
	display:flex;
	background-image: url('${BackgroundImage}');
	background-position-x: center;
	background-repeat: no-repeat;
	background-size:cover;
	width:100vw;
	min-height:67vh;
	justify-content:center;
	.content-button{
		display: none;
		/* justify-content: flex-end; */
		.button{
			display: flex;
			justify-content: center;
			align-items: center;
			padding:5px;
			border-width: 2px;
			border-style: solid;
			border-color: rgb(255, 255, 255);
			font-family: Montserrat, sans-serif;
			border-image: initial;
			border-radius: 5px;
			background: transparent;
			font-weight: 700;
			margin: 0px;
			text-decoration:none;
			font-size:14px;
			color: #FFFFFF;
			opacity: 1;
			div{
				margin:4.5px 12px;
			}
			.icon{
				width:25px;
				height:25px;
				margin-right:5px;
			}
		}
	}
	.content-page{
		z-index:20;
		width:100%;
		height:100%;
		padding:5% 3%;
		display:flex;
		align-content:center;
		flex-direction:column;
		justify-content: center;
		.titles{
			text-align:center;
			width:100%;
			height:min-content;
			h1{
				font-family: 'Montserrat', sans-serif;
				font-size:38px;
				font-weight:800;
				color:#102C65;
				background:transparent;
			}
			p{
				font-family: 'Montserrat', sans-serif;
				font-size:24px;
				z-index:20;
				color:white;
			}
		}
		.row-content{
			padding: 0px 100px;
			margin-top: 100px;
			justify-content:space-between;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
`;

export const MobileStyles = styled.div`
	background-image: url('${BackgroundImage}');
	background-repeat: no-repeat;
	background-size:cover;
	background-position: center;
	.container{
		margin: 40px 0px;
		padding: 0px;
	}
	.row-content{
			width:100%;
			justify-content:center;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
    .catalog:last-child{
      .accordion{
        .element{
          .content-information{
            .content-image{
              .image{
                padding: 25px;
              }
            }
          }
        }
      }
    }
		.catalog{
			.accordion{
				width: 300px;
				margin: 0px;
        .element{
          .content-information{
            align-items: center;
            flex-direction:row;
            justify-content: flex-start;
            .content-image{
              width: 90px;
              min-height: unset;
              .image{
                width: inherit;
              }
            }
            h1{
              font-size: 14px;
              margin: 0px;
              margin-left: 49px;

            }
          }
          .white-space{
		      	height: 0px;
		      }
        }
				.card{
					.card-header{
						width: 300px;
					}
				}
			}
      .accordion-collapse {
    		.card-body{
					.links-info{
						a{
							font-size:13px;
						}
					}
				}
			}
		}
	}
	.content-information{
			height: 100%;
    	width: 100%;
			min-height:auto !important;
	}
`;

export const CatalogStyles = styled.div.attrs({
	className: "catalog"
})`
	height: 100%;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:flex-end;
	flex-wrap: wrap;
	margin-bottom: 20px;
	.accordion{
		display: flex;
		width: 350px;
		flex-direction: column;
		justify-content: flex-end;
		border: none;
		margin: 0px 35px;
		background: rgba(0,0,0,0.80) 0% 0% no-repeat padding-box;
		border-radius: 20px;
		.card{
			background: transparent;
			border: none;
			width: 100%;
			.container-toggle{
				display: flex;
				justify-content: flex-end;
				margin-bottom: 0px !important;
			}
			.card-header{
				padding: 0px 20px 20px 0px;
				border-radius: 20px;
				width: 330px;
				background: transparent;
			}
		}
		.accordion-collapse {
    	.card-body{
				.links-info{
					margin-bottom: 10px;
					a{
						font-family: 'Montserrat', sans-serif;
						font-size:14px;
						font-weight:500;
						color:white;
            text-transform: capitalize;
					}
					.border-title{
						border:0.5px solid white;
						margin: 10px 0px;
						width:94%;
					}
				}
				.links-info:last-child{
					.border-title{
						border:none;
					}
				}
			}
		}
	}
	.element{
		widows: 100%;
		border-radius:20px;
		height: 100%;
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:space-around;
		background: rgba(0,0,0,0.30) 0% 0% no-repeat padding-box;
		.content-information{
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:space-around;
			height: 100%;
			padding: 20px;
			min-height:350px;
			.content-image{
				display:flex;
				align-items:center;
				justify-content: center;
				min-height: 180px;
				img{
					padding:0px !important;
					width:100%;
				}
				.imageDental{
				width: 48%;
			  	}
			}
			h1{
				font-family: 'Montserrat', sans-serif;
				font-size:18px;
				font-weight:600;
				color:white;
				padding:10px;
				margin: 35px 0px 10px 0px;
			}
			p{
				font-family: 'Montserrat', sans-serif;
				font-size:14px;
				z-index:20;
				color:white;
				text-align:center;
			}
		}
		.white-space{
			height: 35px;
		}
	}
	${props =>
		props.open && css`
			.accordion{
				border-bottom-left-radius:  0px;
				border-bottom-right-radius: 0px;
			}
	`}
`;