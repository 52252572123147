import React from "react";
import Popup from "reactjs-popup";

export function Modal(WrappedComponent) {
  return class extends React.Component {
    
    render() {
      return (
        <Popup
          open={this.props.open}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          className={this.props.classNamePopUp}
          lockScroll={true}
        >
          <WrappedComponent
            {...this.props}
          ></WrappedComponent>
        </Popup>
      )
    }
  }
}