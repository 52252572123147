import React from 'react'
import { Row, Col, Image, Button, Card } from "react-bootstrap";
import { navigate } from 'hookrouter';
import Styles from "./Styles.js";
import { Trans } from "react-i18next";
import i18n from '../../../../../commons/i18n'
import "../../../../../commons/i18n";
//images
import Imagen1 from '../../../../../assets/images/Implants/Imagen-1.png';
import FVconLogo from '../../../../../assets/images/Implants/logo-fvcon.png';
import section2Image from '../../../../../assets/images/Implants/03_FVCON The_Connection.png';
import section2Background from '../../../../../assets/images/Implants/onda-violeta.png';

//section3
import section3_img1 from '../../../../../assets/images/Implants/04_FVCON_Conical.png';
import section3_img2 from '../../../../../assets/images/Implants/05_FVCON__Platform_Switching.png';
import section3_img3 from '../../../../../assets/images/Implants/06_FVCON_Unique_Platform.png';
import section3_img4 from '../../../../../assets/images/Implants/07_FVCON_1i1a.png';
import section3_img5 from '../../../../../assets/images/Implants/08_FVCON_Carga_Inmed.png';
import section3_img6 from '../../../../../assets/images/Implants/09_FVCON_Protocols.png';

//section4
import section4_isFour from '../../../../../assets/images/Implants/10_FVCON_Is-Four.png';
import section4_cyroth from '../../../../../assets/images/Implants/11_FVCON_Cyroth.png';

import SmartImplantsModal from "../../smartImplantsModal/index";
import { Modal } from "../../../../../components/modals/Modal.js";

//section5
import section5_leftImg from '../../../../../assets/images/Implants/Imagen_2.png';
import section5_rightImage from '../../../../../assets/images/Implants/Imagen_3.png';

//section6
import section6_Img1 from '../../../../../assets/images/Implants/15_FVCON_Prostetic.png';
import section6_Img2 from '../../../../../assets/images/Implants/19_FVCON_(opcional).png';
import section6_Img3 from '../../../../../assets/images/Implants/21_FVCON_KiSS.png';
import section6_Img4 from '../../../../../assets/images/Implants/23_FVCON_Transepithelial.png';
import section6_usa_kiss from '../../../../../assets/images/Implants/kiss-available-usa.png';
import section6_eeuu_kiss from '../../../../../assets/images/Implants/kiss-disponible-estados-unidos.png';
import section6_usa_one from '../../../../../assets/images/Implants/1to1-available-usa.png';
import section6_eeuu_one from '../../../../../assets/images/Implants/1to1-disponible-estados-unidos.png';
import section6_onetoone from '../../../../../assets/images/Implants/Logo_One-to-One.png';
import section6_kiss from '../../../../../assets/images/Implants/Logo_KiSS.png';

//section 7
import section7_unique from '../../../../../assets/images/Implants/unique.png';
import section7_performing from '../../../../../assets/images/Implants/performing.png';
import section7_safe from '../../../../../assets/images/Implants/safe.png';
import section7_unico from '../../../../../assets/images/Implants/unico.png';
import section7_performante from '../../../../../assets/images/Implants/performante.png';
import section7_seguro from '../../../../../assets/images/Implants/seguro.png';


//section 8
import section8_cover from '../../../../../assets/images/Implants/tapa-doble-pag-cuadrada-fvcon.png';


import VioletWaveImg from "../../../../../assets/images/Implants/onda-violeta-2.png";


import RegisterMark from '../../../../../components/registerMark/index.js';
import RegisterMarkBold from '../../../../../components/registerMarkBold/index.js';
import { BUCKET_URL } from '../../../../../services/BaseUrl'

const ModalContainer = Modal(SmartImplantsModal)

const englishCatalog = '/pdf/Ingles/1_Dental_Catalog_And_Documents/FAICO FVCON® Morse Cone Connection 2021_(EN).pdf';
const spanishCatalog = '/pdf/Español/1_CatalogosDentalesyDocumentos/FAICO FVCON® Conexión Cono Morse 2021_(SP).pdf';
class CCNewSmartImplantsDesktop extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isModal: false,
			actualModal: 0
		};
		this.openModal = this.openModal.bind(this);
	}

	openModal(modal) {
		this.setState({
			isModal: !this.state.isModal,
			actualModal: modal
		});
	}

	boldUpText(text){
		console.log(text);
		let chunks = text.split('<b>');
		return (chunks.map((el, index) => {
			if(index == 0){
				return el
			}else{
				let parts = el.split['</b>'];
				return <span><b>{parts[0]}</b>{parts[1]}</span>
			}
		}))
	}

	getImage1Section7(){
		console.log("idioma: " + i18n.language);
		if(i18n.language == 'en'){
			return (<Image src={section7_unique} style={{'width': '100%', 'height':'auto'}}></Image>)
		}else{
			return (<Image src={section7_unico} style={{'width': '100%', 'height':'auto'}}></Image>)
		}
	}

	getImage2Section7(){
		if(i18n.language == 'en'){
			return (<Image src={section7_performing} style={{'width': '100%', 'height':'auto'}}></Image>)
		}else{
			return (<Image src={section7_performante} style={{'width': '100%', 'height':'auto'}}></Image>)
		}
	}

	getImage3Section7(){
		if(i18n.language == 'en'){
			return (<Image src={section7_safe} style={{'width': '100%', 'height':'auto'}}></Image>)
		}else{
			return (<Image src={section7_seguro} style={{'width': '100%', 'height':'auto'}}></Image>)
		}
	}

	getImageAvaibleKiss(){
		
		if(i18n.language == 'en'){
			return (<Image src={section6_usa_kiss}></Image>)
		}

		return (<Image src={section6_eeuu_kiss} style={{'position':'absoluto', 'left':'0%'}}></Image>)
	}
	getImageAvaibleOne(){
		
		if(i18n.language == 'en'){
			return (<Image src={section6_usa_one}></Image>)
		}

		return (<Image src={section6_eeuu_one} style={{'position':'absoluto', 'left':'0%'}}></Image>)
	}

	render() {
		return (
			<Styles>
				<div className="section1">
					<div className="black-bg-gradient section3-background"></div>
					<Row className="d-flex justify-content-center align-content-center section1-main-content">
						<Col lg={1}></Col>
						<Col lg={10}>
							<Row className="d-flex justify-content-center align-content-center">
								<Col lg={4}></Col>
								<Col lg={3}>
									<Row className="d-flex justify-content-center align-content-center">
										<Image src={Imagen1} className="img-fluid section1-main-img"></Image>
									</Row>
								</Col>
								<Col lg={4}></Col>
								<Image src={FVconLogo} className="img-fluid logo-img"></Image>
							</Row>
							<br></br>
						</Col>
						<Col lg={1}></Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center">
						<h3 className="title-section1"><Trans>CCsmartImplants.FVconPage.section1.title</Trans></h3><br></br>
					</Row>
					<Row className="d-flex justify-content-center align-content-center">
						<h3 className="bold-title-section1"><Trans>CCsmartImplants.FVconPage.section1.subtitle</Trans></h3>
					</Row>
				</div>
				<div className="section2">
					<Image src={section2Background} className="bg-img section2-background"></Image>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5}>
							<p className="text1-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.text1</RegisterMark></p>
						</Col>
					</Row>
					<br></br>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5}>
							<p className="title1-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.subTitle1</RegisterMark></p>
						</Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5}>
							<p className="text-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.text2</RegisterMark></p>
						</Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5}>
							<p className="text-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.text3</RegisterMark></p>
						</Col>
					</Row>
					<br></br>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={5}>
							<Row className="d-flex justify-content-center align-content-center">
								<Col lg={6}>
									<p className="brand-section2"><RegisterMark>CCsmartImplants.FVconPage.section2.subTitle2</RegisterMark></p>
									<p className="brand2-section2"><Trans>CCsmartImplants.FVconPage.section2.subTitle3</Trans></p>
									<p className="footer-section2 mb-5 pb-5"><Trans>CCsmartImplants.FVconPage.section2.text4</Trans></p>
								</Col>
								<Col lg={6}>
									<Image src={section2Image} className="img-section2"></Image>
								</Col>
							</Row>

						</Col>
					</Row>

				</div>
				<div className="section3">
					<Image src={VioletWaveImg} className="bg-img section3-background"></Image>
					<Row className="d-flex justify-content-center align-content-center mb-5">
						<Col lg={6}>
							<p>
								<span className="title-section3"><RegisterMark>CCsmartImplants.FVconPage.section3.title1</RegisterMark></span>
								<span className="title-connector-section3"><Trans>CCsmartImplants.FVconPage.section3.title11</Trans></span>
								<span className="title-section3"><Trans>CCsmartImplants.FVconPage.section3.title12</Trans></span>
							</p>
						</Col>
					</Row>
				
					<Row className="d-flex justify-content-center align-content-center mb-5">
						<Col lg={3}>
							<Image src={section3_img1}></Image>
							
							<p className="texts-section3 mt-4"><RegisterMark>CCsmartImplants.FVconPage.section3.text1</RegisterMark></p>
						</Col>
						<Col lg={3}>
							<Image src={section3_img2}></Image>
						
							<p className="texts-section3 mt-4"><RegisterMark>CCsmartImplants.FVconPage.section3.text2</RegisterMark></p>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center mb-5">
						<Col lg={3}>
							<Image src={section3_img3}></Image>
							
							<p className="texts-section3 mt-4"><RegisterMark>CCsmartImplants.FVconPage.section3.text3</RegisterMark></p>
						</Col>
						<Col lg={3}>
							<Image src={section3_img4}></Image>
							
							<p className="texts-section3 mt-4"><RegisterMark>CCsmartImplants.FVconPage.section3.text4</RegisterMark></p>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center mb-5">
						<Col lg={3}>
							<Image src={section3_img5}></Image>
						
							<p className="texts-section3 mt-4"><RegisterMark>CCsmartImplants.FVconPage.section3.text5</RegisterMark></p>
						</Col>
						<Col lg={3}>
							<Image src={section3_img6}></Image>
							
							<p className="texts-section3 mt-4"><RegisterMark>CCsmartImplants.FVconPage.section3.text6</RegisterMark></p>
						</Col>
					</Row>
			
				</div>
				<div className="section4">

					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={3}>
							<h2 className="title-scetion4  mt-5 pt-5"><RegisterMark>CCsmartImplants.FVconPage.section4.title</RegisterMark></h2>
						</Col>
					</Row>

					<Row className="d-flex justify-content-center align-content-center main-row-section4">
						<Col lg={3}>

							<h2 className="title-is_four"><RegisterMark>CCsmartImplants.FVconPage.section4.title_isFour</RegisterMark></h2>

							<p className="text-section4"><Trans>CCsmartImplants.FVconPage.section4.text_isFour</Trans></p>
						</Col>
						<Col lg={2}>
							<Row className="d-flex justify-content-center align-content-center">
								<Image src={section4_isFour}>

								</Image>
								<div className="plus-button-section4" onClick={() => { this.openModal(2) }}><div className="plus-section4"></div></div>
							</Row>
						</Col>
						<Col lg={2}>
							<Row className="d-flex justify-content-center align-content-center">
								<Image src={section4_cyroth}>

								</Image>
								<div className="plus-button-section4" onClick={() => { this.openModal(1) }}><div className="plus-section4"></div></div>
							</Row>
						</Col>
						<Col lg={3}>

							<h2 className="title-cyroth"><RegisterMark>CCsmartImplants.FVconPage.section4.title_cyroth</RegisterMark></h2>

							<p className="text-section4"><Trans>CCsmartImplants.FVconPage.section4.text_cyroth</Trans></p>
						</Col>
					</Row>
					<ModalContainer
						classNamePopUp={"pop-up-style"}
						onButtonClose={this.openModal}
						modal={this.state.actualModal}
						open={this.state.isModal}
					/>
				</div>
				<div className="section5">
					<Image src={VioletWaveImg} className="bg-img section5-background"></Image>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={4}>

						</Col>
						<Col lg={8}>
							<h2 className="title-section5"><Trans>CCsmartImplants.FVconPage.section5.title1</Trans><span className="blue-dot">.</span></h2>
							<h2 className="subtitle-section5"><Trans>CCsmartImplants.FVconPage.section5.subTitle1</Trans><span className="blue-dot">.</span></h2>
						</Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center">
						<Col lg={4}>
							<Image src={section5_leftImg}></Image>
						</Col>
						<Col lg={4}>
							<Row className="justify-content-center align-content-center">
								<Col lg={12}>
									<p className="text-section5"><RegisterMark>CCsmartImplants.FVconPage.section5.text1</RegisterMark></p>
									<h2 className="title-section5"><Trans>CCsmartImplants.FVconPage.section5.title2</Trans></h2>
									<p className="text-section5"><Trans>CCsmartImplants.FVconPage.section5.text2</Trans></p>
									<p className="text-section5"><Trans>CCsmartImplants.FVconPage.section5.text3</Trans></p>
									<p className="text-section5"><Trans>CCsmartImplants.FVconPage.section5.text4</Trans></p>
								</Col>
							</Row>
						</Col>
						<Col lg={4}>
							<Image src={section5_rightImage}></Image>
						</Col>
					</Row>
				</div>
				<div className="section6">
					<Row className="d-flex justify-content-center align-content-center section6-subsection">
						<Col lg={1}></Col>
						<Col lg={4}>
							<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title1</Trans></h2>
							<div className="paragraph-section6">
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text1</RegisterMark></div>
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text2</RegisterMark></div>
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text3</RegisterMark></div>
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text4</RegisterMark></div>
							</div>

						</Col>
						<Col lg={1}></Col>
						<Col lg={4}>
							<Row className="justify-content-end align-content-end">
								<Image src={section6_Img1} className="img-section6-4"></Image>
							</Row>
						</Col>
						<Col lg={2}></Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center section6-subsection section6-b">
						<Col lg={1}></Col>
						<Col lg={4}>
							<Row className="justify-content-center align-content-start">
								<Image src={section6_Img2}></Image>
							</Row>
						</Col>

						<Col lg={1}></Col>
						<Col lg={4} className="mb-5">
							<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title2</Trans></h2>
							<div className="paragraph-section6">
								<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text5</RegisterMark></div>
								<div className="text-section6"><Trans>CCsmartImplants.FVconPage.section6.text6</Trans></div>
							</div>
						</Col>
						<Col lg={2}></Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center section6-subsection">
						<Col lg={1}></Col>
						<Col lg={4}>
							<Row>
								<Image src={section6_kiss}></Image>
							</Row>
							<Row>
								<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title3</Trans></h2>
								<div className="paragraph-section6">
									<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text7</RegisterMark></div>
									<div className="text-section6"><RegisterMark>CCsmartImplants.FVconPage.section6.text8</RegisterMark></div>
									
								</div>
							</Row>
						</Col>
						<Col lg={1}></Col>
						<Col lg={5}>
							<Row className="d-flex justify-content-end align-content-center">								
								{
									this.getImageAvaibleKiss()
									//<Image src={section6_Img3} ></Image>
								}																
							</Row>
						</Col>
						<Col lg={1}></Col>
					</Row>
					<Row className="d-flex justify-content-center align-content-center section6-subsection section6-b">
						<Col lg={1}></Col>
						<Col lg={4} >
							<Row>
								<Image src={section6_onetoone} className="bg-img5-section6"></Image>
							</Row>
							<Row>
								<Col lg={12} className="text-content-section6">
									<h2 className="subtitle-section6"><Trans>CCsmartImplants.FVconPage.section6.title4</Trans></h2>
									<h3 className="subtitle4-section6"><Trans>CCsmartImplants.FVconPage.section6.subTitle4</Trans></h3>
									<div className="paragraph-section6">
										<div className="text-section6">
											<Trans>CCsmartImplants.FVconPage.section6.text9</Trans>
											<RegisterMarkBold>CCsmartImplants.FVconPage.section6.text91</RegisterMarkBold>
											<Trans>CCsmartImplants.FVconPage.section6.text92</Trans>
										</div>
										<div className="text-section6">
											<Trans>CCsmartImplants.FVconPage.section6.text10</Trans>
										</div>
										<div className="text-section6">
											<RegisterMark>CCsmartImplants.FVconPage.section6.text11</RegisterMark>
										</div>
									</div>
								</Col>
							</Row>
						</Col>
						<Col lg={1}></Col>
						<Col lg={5}>
							<Row className="d-flex justify-content-end align-content-end img-section6-4">								
								{
									this.getImageAvaibleOne()
									//<Image src={section6_Img4} className="img-section6-4"></Image>
								}								
							</Row>
						</Col>
						<Col lg={1}></Col>
					</Row>
				</div>
				<div className="section7">
					<Row className="d-flex justify-content-center align-content-center section7-subsection">

						<Col lg={12}>
							<Row className="justify-content-center align-content-center mt-5 py-5">								
								<Col lg={3}>									
									{this.getImage1Section7()}
									<p className="section7-text"><RegisterMark>CCsmartImplants.FVconPage.section7.text1</RegisterMark></p>
								</Col>
								<Col lg={3} className="mx-5">
									{this.getImage2Section7()}
									<p className="section7-text"><RegisterMark>CCsmartImplants.FVconPage.section7.text2</RegisterMark></p>
								</Col>
								<Col lg={3}>
									{this.getImage3Section7()}
									<p className="section7-text"><RegisterMark>CCsmartImplants.FVconPage.section7.text3</RegisterMark></p>
								</Col>
							</Row>
						</Col>
	
					</Row>

				</div>
				<div className="section8">
					<Image src={section8_cover} className="bg-img section8-bg-img"></Image>
					<Image src={section8_cover} className="invisible"></Image>
					<Row className="d-flex justify-content-center align-content-center section8-content">
						<Col lg={4}>
							
						</Col>
						<Col lg={4}>
							<Row className="d-flex justify-content-center align-content-center">

								<h2 className="section8-title"><RegisterMark>CCsmartImplants.FVconPage.section8.title</RegisterMark></h2>
								<p className="section8-text"><Trans>CCsmartImplants.FVconPage.section8.text</Trans></p>
								<a download target="_blank" href={`${BUCKET_URL}${i18n.language === 'es' ? spanishCatalog : englishCatalog}`}>
									<Button className="section8-button">
										<RegisterMark>CCsmartImplants.FVconPage.section8.button</RegisterMark>
									</Button>
								</a>

							</Row>
						</Col>
						<Col lg={4}>
							
						</Col>
					</Row>
				</div>
				<div className="footer">
						<Row className="d-flex justify-content-center align-content-center">
						<Col lg={3}>
							
						</Col>
						<Col lg={3}>
							<Row className="d-flex justify-content-center align-content-center">
								<Button className="footer-button"
								onClick={() => navigate("/dental/conicalConnection/prostheticComponents")}
								><Trans>CCsmartImplants.FVconPage.footer.leftButton</Trans></Button>
							</Row>
						</Col>
						<Col lg={3}>
							<Row className="d-flex justify-content-center align-content-center">
								
									<Button 
										className="footer-button"
										onClick={() => navigate("/dental/conicalConnection/surgicalInstruments")}
									>
									<Trans>CCsmartImplants.FVconPage.footer.rightButton</Trans>
									</Button>
								
							</Row>
						</Col>
						<Col lg={3}>
							
						</Col>
					</Row>
				</div>
			</Styles>
		);
	}
}

export default (CCNewSmartImplantsDesktop)