import styled from "styled-components";
import Device from "../../../commons/Device";

export const Styles = styled.main`
  overflow: hidden;
  background-color: #0F0F0F;
  .carousel{
    #my-carousel{
      margin:0;
    }
    .carousel-text{
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      bottom: 0%;
      right: 0;
      top: 10%;
      left: 0;
      .content-text{
        width: max-content;
        height: max-content;
        margin-left:10%;
      }
      p{
        font-weight:300;
        margin:0;
        padding:0;
        color:white;
        font-family: "Montserrat", sans-serif;
        width:max-content;
      }
      .first-text{
        margin:0px;
        padding:0;
        font-size:20px;
      }
      h1{
        font-weight:700;
        color:white;
        font-size:62px;
        line-height:62px;
        margin:15px 0px;
        width:max-content;
        font-family: "Montserrat", sans-serif;
        text-align: left;
      }
      .second-text{
        font-size:15px;
      }
      .default-button{
        background-color:#7160FF;
        font-family: "Montserrat", sans-serif;
        padding:8px;
        width:40%;
        height:100%;
        font-weight:700;
        font-size:15px;
        margin-top:10px;
      }
    }
  }
  .form-box {
    margin-top: -50px;
  }
  .form-box > .form-box__data-container {
    .input-container {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    .form-child > div {
      margin-bottom: 0 !important;
    }
  }
  .cyroth-container {
    .cyroth-description {
      h1,
      h2 {
        margin: 0px;
        margin-left: 11px;
      }
      max-width: 400px;
      .default-button {
        background: #221e1e;
      }
    }
    .cyroth-title {
      h1 {
        margin-bottom: 0px;
        &:last-child {
          position: absolute;
          bottom: -10px;
          right: 0px;
        }
      }
    }
    width: 100%;
    position: relative;
    .cyroth-title,
    .cyroth-description {
      position: absolute;
      z-index: 3;
    }
    @media ${Device.xs} {
      height: 600px;
      .cyroth-title {
        top: 0px;
        h1:first-child {
          font-size: 75px !important;
        }
        h1:last-child {
          font-size: 25px;
        }
      }
      .cyroth-description {
        top: 23% !important;
        max-width: 400px !important;
        left: 10% !important;
        h1:first-child {
          font-size: 20px !important;
        }
        h1:last-child {
          font-size: 10px !important;
        }
      }
      .default-button {
        position: absolute;
        right: 30px;
        bottom: -10px;
      }
    }
    @media (max-width: 1024px) {
      height: 600px;
      .cyroth-title {
        top: 10px;
        left: 10%;
        h1:first-child {
          font-size: 120px;
        }
        h1:last-child {
          font-size: 25px;
        }
      }
      .cyroth-description {
        max-width: 300px;
        left: 10%;
        top: 34%;
        h1:first-child {
          font-size: 35px;
        }
        h2 {
          font-size: 18px;
        }
      }
    }
    @media ${Device.lg} {
      height: 650px;
      .cyroth-description {
        h1,
        h2 {
          margin: 0px;
          margin-left: 11px;
          font-size: 23px !important;
        }
        h1 {
          font-size: 60px !important;
        }
        top: 61%;
        max-width: 400px;
        left: 23%;
        .default-button {
          background: #221e1e;
        }
      }
      .cyroth-title {
        left: 10%;
        top: 10%;
        h1 {
          margin-bottom: 0px;
          &:last-child {
            position: absolute;
            bottom: -10px;
            right: 0px;
          }
        }
      }
    }
    @media ${Device.xl} {
      height: 900px;
    }
  }
  .cyroth-container {
    .cyroth-curve {
      position: absolute;
      z-index: 2;
      bottom: 0px;
      left: 0px;
      width: 100%;
    }
    .cyroth-img {
      position: absolute;
      z-index: 2;
      bottom: 0px;
      right: 0px;
      width: 40%;
      @media ${Device.xs} {
        left: 0px;
        width: 90%;
      }
      @media ${Device.md} {
        width: 50%;
      }
      @media ${Device.lg} {
        width: 45%;
      }
    }
  }

  .karoth-container {
    height: 600px;
    position: relative;
    .main-row {
      margin: 0px !important;
      .title-row {
        margin: 0px;
        h1:first-child {
          @media ${Device.xs} {
            font-size: 75px;
          }
          @media ${Device.md} {
            font-size: 125px;
          }
        }
        h1:last-child {
          @media ${Device.xs} {
            position: absolute;
            font-size: 25px;
            left: 18px;
            top: 85px;
          }
          @media ${Device.md} {
            position: absolute;
            left: 23px;
            top: 145px;
          }
          @media ${Device.xl} {
            position: initial;
            margin-bottom: auto;
            margin-top: auto;
            margin-left: 35px;
          }
        }
      }
    }
    .button-row {
      @media ${Device.xs} {
        h2 {
          font-size: 16px;
        }
        h3 {
          font-size: 13px;
        }
      }
      @media ${Device.md} {
        .second-col {
          left: 25px;
        }
        h2 {
          font-size: 29px;
        }
      }
      @media ${Device.lg} {
        position: absolute;
        top: 245px;
        h2 {
          font-size: 35px;
        }
      }
      @media ${Device.xl} {
        .second-col {
          left: 45px;
        }
        position: initial;
      }
      .first-col {
        border-right: white 1px solid;
      }
      .default-button {
        position: absolute;
        bottom: 20px;
        left: 10px;
        background: #7160ff;
        &:first-child {
          bottom: 18px;
        }
      }
    }
  }
  .karoth-footer {
    margin: 0px;
    background: #7160ff;
    display: flex;
    justify-content: center;
    .trademark-mobile {
      display: none;
    }
    .trademark h3 {
      font-weight: 700;
    }
    .trademark-description {
      margin-top: 2px;
    }
    @media ${Device.xs} {
      .trademark {
        margin-top: 2px;
        margin-right: 5px !important;
      }
      .trademark h3 {
        font-size: 15px;
      }
      .trademark-mobile {
        margin-top: 3px;
        display: block;
        h4 {
          font-size: 13px;
        }
      }
      .trademark-description {
        display: none;
      }
    }
  }
  .col-img {
    height: 245px;
  }
  .karoth-img {
    position: absolute;
    width: 30%;
    top: 65px;
    @media ${Device.xs} {
      width: 50%;
      top: 59%;
    }
    @media ${Device.md} {
      top: 130px;
      width: 32%;
    }
    @media (min-width: 1023px) {
      top: 75px;
      width: 38%;
    }
    @media (min-width: 1025px) {
      top: 30px;
      width: 35%;
    }
    @media (min-width: 1900px) {
      top: 20px;
      width: 30%;
    }
  }

  /*.linear-effect{
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(135deg, black, transparent 10px);
    position: absolute;
    opacity: 0.26;
  }*/
  .who-we-are-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    height: 630px;
    background: #0F0F0F;
    position: relative;
    overflow: hidden;
    .react-parallax-bgimage{
      top: -25%;
    }
    .content-image{
      position: absolute;
      display: flex;
      width: 100%;
      height: 100%;
      img{
        width: 100%;
      }
    }
    .invisible-row {
      height: 100px;
      @media ${Device.xs} {
        height: 80px;
      }
    }
    .main-row {
      margin: 0px !important;
      .column-information{
        display: flex;
          margin-bottom: 65px;
          margin-left: 1037px;
          margin-top: 214px;
          padding-right: 195px;
        .content-information{
          display: flex;
          flex-flow: column;
          width: 679px;
          height: 351px;
          background: #1e1e1e;
          border-radius: 30px;
          opacity:0.95;
          align-items: flex-start;
          justify-content: flex-start;
          padding-left: 57px;
          padding-top: 39.8px;
          padding-bottom: 44px;
          h1{
            font-weight: 600;
            color: white;
            font-family: "Montserrat", sans-serif;
            font-size: 62px;
          }
          h2{
            color: white;
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            text-align:left;
            padding-right: 65.63px;
            margin-top: 17px;
          }
          .default-button{
            font-family: 'Montserrat',sans-serif;
            font-size: 15px;
            font-weight: 600;
            display: flex;
            text-align: center;
            border-radius: 29px;
            align-items: center;
            justify-content: center;
            border: none;
            background: #7160ff;
            width:144px;
            height:43px;
            margin-top:29px;
          }
          .zoomButton {
            transition: transform .2s; /* Animation */
          }
          .zoomButton:hover {
            transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
          }
        }
      }
    }
    height: 600px;
    position: relative;
    .who-we-are-img {
      width: 100%;
      height: 100%;
    }
    .input-container{
      padding:0 !important;
    }
    .default-button {
      min-width: 100%;
      margin-left: auto;
      margin-right: auto;
      display:flex;
      background: #7160ff;
      @media ${Device.xs} {
        min-width: 100% !important;
        margin-top: -5px;
      }
    }
  }

  .dental-container {
    background-color: #171717;
    height: 100%;
    width: 100%;
    padding-top: 123px;
    h3 {
      margin-top:21px;
      margin-bottom:27px;
    }
    li {
      vertical-align: top;
    }
    .content-information{
      margin-right: 289px;
      width: 100%;
    }
    .content-image{
      display:flex;
      margin-right: 293px;
      margin-left: 268px;
      margin-bottom: 91px;
      padding: 0px;
      width: 100%;
      height: 100%;
      .img{
      }
    }
    .default-button {
      font-family: 'Montserrat',sans-serif;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      text-align: center;
      border-radius: 29px;
      align-items: center;
      justify-content: center;
      border: none;
      background: #7160ff;
      width:144px;
      height:43px;
      margin-top:29px;
    }
  }

  .content-list{
    width:30vw;
    margin-top:15px;
  }

  .cranio-container {
    background-color: #1E1E1E;
    display: flex;
    align-items: center;
    width: 100%;
    height: 829px;
    h3 {
      margin-top:21px;
      margin-bottom:27px;
      padding-right: 5px;
    }
    h1{
      white-space: nowrap;
    }
    .content-image {
      margin-left: 166px;
      .cranio-img {
        width: 576px;
        height: 507px;
      }
    }
    .main-row {
      display: flex;
      align-items: center;
      padding-left:284px;
      width: 100%;
      .cranioCont{
        padding-left: 0px !important;
      }
      @media  screen and (max-width: 1440px) {
        padding-left:120px;
      }
    }
    .default-button {
      font-family: 'Montserrat',sans-serif;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      text-align: center;
      border-radius: 29px;
      align-items: center;
      justify-content: center;
      border: none;
      background: #7160ff;
      width:144px;
      height:43px;
      margin-top:29px;
    }
    @media ${Device.xs} {
      ul {
        font-size: 17px;
        margin-left: -160px;
      }
    }
  }
  .faicoConnect-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 451px;
    background-color: #0F0F0F;
    overflow: hidden;
    position: relative;
    .react-parallax-bgimage{
      top: 10%;
    }
    .content-image{
      display: flex;
      width: 100%;
      height: 100%;
      .faicoConnect-img {
        width: 100%;
        height: 145%;
      }
    }
    .faicoConnect-content{
      opacity: 0.93;
      .column-information{
        display: flex;
        margin-bottom: 47px;
        margin-right: 248px;
        .content-information{
          display: flex;
          flex-flow: column;
          width: 614px;
          height: 344px;
          background: #1e1e1e;
          border-radius: 30px;
          opacity:0.93;
          align-items: flex-start;
          justify-content: flex-start;
          padding-top: 34px;
          padding-left:50px;
          h1{
            font-weight: 700;
            color: white;
            font-family: "Montserrat", sans-serif;
            font-size: 40px;
            margin-top: 18px;
          }
          h2 {
            font-size: 20px;
            margin-bottom: 0px;
          }
          h3{
            color: white;
            font-family: "Montserrat", sans-serif;
            font-size: 26px;
            font-weight: 700;
            text-align:left;
            margin-top: 10px;
          }
        }
      }
      .default-button {
        font-family: 'Montserrat',sans-serif;
        font-size: 17px;
        font-weight: 700;
        color: white;
        display: flex;
        text-align: center;
        border-radius: 50px;
        border: 3px solid #FFFFFF;
        align-items: center;
        justify-content: center;
        background: transparent;
        width: 251px !important;
        height: 53px !important;
        margin-bottom: 37px;
        margin-top: 17px;
      }
    }
  }

  .faico360-container {
    height: 900px;
    position: relative;
    background: #282828;
    display: flex;
    height: 494px;
    width: 100%;
    background: #171717;
    .card-faico360-connect{
      display: flex;
      flex-flow: row;
      grid-gap: 0px;
      .refresh-content{
        display: flex;
        align-items: center;
        position: relative;
        h1{
          position: absolute;
          font-family: 'Montserrat',sans-serif;
          font-size: 54px;
          font-weight: 700;
          white-space: nowrap;
          color: white;
          letter-spacing: 0px;
          z-index: 2;
          margin-left: 47%;
          @media  screen and (max-width: 1440px) {
            margin-left: 47%;
          }
        }
        .refresh-image{
          margin-left: 26%;
          z-index: 2;
          position: absolute;
          @media  screen and (max-width: 1440px) {
            margin-left: 50px;
          }
        }
      }
     .content{
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-left: 100px;
        h3{
          margin-left: 39px;
          white-space: pre-line;
          width: 40%;
          font-weight: 400;
        }
        .arrow-list{
          li{
            white-space: nowrap;
          }
        }
        .button-connect {
          font-family: 'Montserrat',sans-serif;
          font-size: 15px;
          font-weight: 600;
          display: flex;
          text-align: center;
          border-radius: 29px;
          align-items: center;
          justify-content: center;
          border: none;
          background: #7160ff;
          width:144px;
          height:43px;
          margin-left: 95px;
        }
      }
    }
  }

  .row-news{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #1E1E1E;
    height: 864px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    h1{
      font-family: "Montserrat", sans-serif;
      font-size:42px;
      color:white;
      font-weight:700;
      text-transform: uppercase;
      text-align:center;
      letter-spacing: 0px;
      margin-bottom:83px;
      margin-top: 107px;
    }
    .content-cards{
      @media  screen and (max-width: 1440px) {
        width:100%;
        margin-left:15%;
        margin-right: 15%;
      }
      display:flex;
      flex-direction:row;
      margin: 0px 140px;
      .card-info{
        width: 520px;
        .col-news{
          padding: 0px !important;
        }
        .novelty-data{
          height: 383px;
          border-radius: 24px;
          padding: 0px !important;
        }
        .cover{
          border-radius: 24px;
        }
        .image{
          border-radius: 24px;
        }
        .card-text{
          margin-left: 43px;
        }
        .title{
          font-family: "Montserrat", sans-serif;
          font-size: 23px;
          color: white;
          font-weight: 700;
          letter-spacing: 0px;
          line-height: 27px;
          margin-bottom: 12px;
          min-height: 62px;
        }
        .subtitle{
          font-family: "Montserrat", sans-serif;
          font-size: 18px;
          color: white;
          font-weight: 400;
          min-height: 94px;
          line-height: 20px;
        }
        .tags{
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          color: white;
          font-weight: 700;
          letter-spacing: 0px;
        }
        span{
          padding: 6px 22px 10px 22px;
          margin-right: 13px !important;
          margin-bottom: 23px !important;
          margin-left: 0px !important;
        }
      }
      .col-news:first-child{
        .title {
          margin-right: 183px;
        }
        .subtitle {
          margin-right: 75px;
        }
      }
      .col-news:nth-child(2n){
        .subtitle {
          margin-right: 83px;
        }
      }
      .col-news:last-child{
        .title {
          margin-right: 135px;
        }
        .subtitle {
          margin-right: 42px;
        }
      }
    }

    .default-button {
      font-family: 'Montserrat',sans-serif;
      font-size: 15px;
      font-weight: 600;
      display: flex;
      text-align: center;
      border-radius: 29px;
      align-items: center;
      justify-content: center;
      align-content: center;
      border: none;
      background: #7160ff;
      width:144px;
      height:43px;
      margin-top:100px;
    }
  }
  .react-parallax{
    height: 100%;
    width: 100%;
    position: absolute !important;
  }
  .parallax{
    height: 100%;
    width: 100%;
  }

  A{
    color: inherit;
    text-decoration: none;
  }

  A:hover{
    text-decoration: none;
  }
`;
