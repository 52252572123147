import React from "react";
import { Button, Image } from 'react-bootstrap';
import { Trans } from "react-i18next";

import { ImplantologiaModalStyles } from "./ModalStyles";

import ImageBackground from "../../../assets/images/Dental/modalBackground.png";
import Component_1 from "../../../assets/images/Dental/Component_1.png";
import Component_2 from "../../../assets/images/Dental/Component_2.png";
import RegisterMark from "../../../components/registerMark";

class ImplantologiaModal extends React.Component {
  render() {
    return (
      <ImplantologiaModalStyles>
        <div className="content-image">
          <Image src={ImageBackground}></Image>
        </div>
        <div className="main-content">
          <div className="container-button">
            <Button
              onClick={this.props.onButtonClose}
            >
              x
						</Button>
          </div>
          <div className="title">
            <h1><Trans>dental.implantologia_popup.title_1</Trans></h1>
          </div>
          <div className="information-content osteo">
            <div className="image-content">
              <Image src={Component_1}></Image>
            </div>
            <div className="information">
              <h1><Trans>dental.implantologia_popup.card_1.title</Trans></h1>
              <p><Trans>dental.implantologia_popup.card_1.text</Trans></p>
            </div>
          </div>
          <div className="information-content nano mt-5">
            <div className="image-content">
              <Image src={Component_2}></Image>
            </div>
            <div className="information">
              <h1><Trans>dental.implantologia_popup.card_2.title</Trans></h1>
              <p><Trans>dental.implantologia_popup.card_2.text</Trans></p>
            </div>
          </div>
        </div>
      </ImplantologiaModalStyles>
    );
  }
}
export default (ImplantologiaModal);

