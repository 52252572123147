import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ children, path }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [path])
    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop)