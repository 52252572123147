import React, { useState } from 'react'
import { Row, Image, Col, Container, Table, Button } from "react-bootstrap"
import { navigate } from 'hookrouter'
import { Trans } from "react-i18next"
import i18n from "i18next"

import { Styles, ProductCardStyles, SurgicalKitStyles } from "./Styles";

import dataGenerator from "./data.js";

/* Assets */
/* FRESAS-MUCOTOMOS-STOP-PREPARADORES DE HOMBRO */
import ImageCards1_1 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-1.png";
import ImageCards1_2 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-2.png";
import ImageCards1_3 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-3.png";
import ImageCards1_4 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-4.png";
import ImageCards1_5 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-5.png";
import ImageCards1_6 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-6.png";
import ImageCards1_7 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_1/image-card-7.png";

import ImageCards4_1 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_4/image-card-1.png";

import ImageCards2_1 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-1.png";
import ImageCards2_2 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-2.png";
import ImageCards2_3 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-3.png";
import ImageCards2_4 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-4.png";
import ImageCards2_5 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-5.png";
import ImageCards2_6 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-6.png";
import ImageCards2_7 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-7.png";
import ImageCards2_8 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-8.png";
import ImageCards2_9 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-9.png";
import ImageCards2_10 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-10.png";
import ImageCards2_11 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_2/image-card-11.png";

import ImageCards3_1 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_3/image-card-1.png";
import ImageCards3_2 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_3/image-card-2.png";
import ImageCards3_3 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_3/image-card-3.png";
import ImageCards3_4 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_3/image-card-4.png";
import ImageCards3_5 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_3/image-card-5.png";
import ImageCards3_6 from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_3/image-card-6.png";

import VioletWaveImg from "../../../../assets/images/onda-violeta-2.png";
import SurgicalKitImage from "../../../../assets/images/Dental/internalHexagonConnection/surgicalInstruments/cards_5/surgicalKit.png";
import RegisterMark from '../../../../components/registerMark'

const cards_1_images = [ImageCards1_1, ImageCards1_2, ImageCards1_3, ImageCards1_4, ImageCards1_5, ImageCards1_6, ImageCards1_7]
const cards_2_images = [ImageCards2_1, ImageCards2_2, ImageCards2_3, ImageCards2_4, ImageCards2_5, ImageCards2_6, ImageCards2_7, ImageCards2_8, ImageCards2_9, ImageCards2_10, ImageCards2_11]
const cards_3_images = [ImageCards3_1, ImageCards3_2, ImageCards3_3, ImageCards3_4, ImageCards3_5, ImageCards3_6]
const widthImages_1 = ["106%", "82%", "103%", "49%", "16%", "13%", "94%"]
const widthImages_2 = ["100%", "90%", "58%", "100%", "68%", "95%", "92%", "90%", "66%", "84%", "62%"]
const widthImages_3 = ["80%", "100%", "100%", "88%", "80%", "77%"]

const SurgicalInstruments = () => {
  const [data, SetData] = useState(dataGenerator(i18n.language))

  i18n.on('languageChanged', function (lng) {
    SetData(dataGenerator(lng))
  })
  function renderFirstRow(row) {
    let render = [];
    switch (row) {
      case 1:
        for (let i = 0; i < 3; i++) {
          render.push(
            <Col xl={2}>
              <ProductCard
                image={cards_1_images[i]}
                title={"IHCsurgicalInstruments.cards_1.card_" + (i + 1)}
                subtitle={<RegisterMark>{"IHCsurgicalInstruments.cards_1.subtitle_" + (i + 1)}</RegisterMark>}
                cardDates={data.cards_1[i]}
                cardType={1}
                widthImage={widthImages_1[i]}
              ></ProductCard>
            </Col>
          )
        }
        render.push(
          <Col xl={4}>
            <ProductCard
              image={cards_1_images[3]}
              title={"IHCsurgicalInstruments.cards_1.card_4"}
              subtitle={<RegisterMark>{"IHCsurgicalInstruments.cards_1.subtitle_4"}</RegisterMark>}
              cardDates={data.cards_1[3]}
              cardType={2}
              widthImage={widthImages_1[3]}
            ></ProductCard>
          </Col>
        )
        break
      case 2:

        render.push(
          <Col xl={5}>
            <ProductCard
              cardDates={data.cards_1[5]}
              cardDates_2={data.cards_1[6]}
              image={cards_1_images[5]}
              image_2={cards_1_images[6]}
              cardType={3}
              widthImage={widthImages_1[4]}
              widthImage_2={widthImages_1[5]}
              title={<RegisterMark>{"IHCsurgicalInstruments.cards_1.card_6"}</RegisterMark>}
            ></ProductCard>
          </Col>
        )
        render.push(
          <Col xl={2}>
            <ProductCard
              image={cards_1_images[4]}
              title={"IHCsurgicalInstruments.cards_1.card_5"}
              subtitle={"IHCsurgicalInstruments.cards_1.subtitle_5"}
              cardDates={data.cards_1[4]}
              cardType={1}
              widthImage={widthImages_1[6]}
            ></ProductCard>
          </Col>

        )
        break;
      default: render.push(null)
    }
    return render;
  }
  function renderSecondRow(row) {
    let render = [];
    switch (row) {
      case 1:
        render.push(
          <Col xl={6}>
            <ProductCard
              cardDates={[data.cards_2[0], data.cards_2[1], data.cards_2[2]]}
              images={[cards_2_images[0], cards_2_images[1], cards_2_images[2]]}
              widthImages={[widthImages_2[0], widthImages_2[1], widthImages_2[2]]}
              subtitle={"IHCsurgicalInstruments.cards_2.card_1_subtitle"}
              subtitles={["IHCsurgicalInstruments.cards_2.subtitle_1_1", "IHCsurgicalInstruments.cards_2.subtitle_1_2", "IHCsurgicalInstruments.cards_2.subtitle_1_3"]}
              title={"IHCsurgicalInstruments.cards_2.card_1"}
              cardType={4}
            ></ProductCard>
          </Col>
        )
        render.push(
          <Col xl={4}>
            <ProductCard
              cardDates={[data.cards_2[3], data.cards_2[4]]}
              images={[cards_2_images[3], cards_2_images[4]]}
              widthImages={[widthImages_2[3], widthImages_2[4]]}
              subtitle={"IHCsurgicalInstruments.cards_2.card_2_subtitle"}
              subtitles={["IHCsurgicalInstruments.cards_2.subtitle_2_1", "IHCsurgicalInstruments.cards_2.subtitle_2_2"]}
              title={"IHCsurgicalInstruments.cards_2.card_2"}
              cardType={4}
            ></ProductCard>
          </Col>
        )
        break;
      case 2:
        for (let i = 5; i < 11; i = i + 2) {
          render.push(
            <Col xl={4}>
              <ProductCard
                cardDates={[data.cards_2[i], data.cards_2[i + 1]]}
                images={[cards_2_images[i], cards_2_images[i + 1]]}
                widthImages={[widthImages_2[i], widthImages_2[i + 1]]}
                subtitle={"IHCsurgicalInstruments.cards_2.card_" + (i - 2) + "_subtitle"}
                subtitles={["IHCsurgicalInstruments.cards_2.subtitle_" + (i - 2) + "_1", "IHCsurgicalInstruments.cards_2.subtitle_" + (i - 1) + "_2"]}
                title={"IHCsurgicalInstruments.cards_2.card_" + (i - 2)}
                cardType={4}
              ></ProductCard>
            </Col>
          )
        }
        break;
      default: render.push(null)
    }
    return render
  }
  function renderThreeRow(row) {
    let render = []
    switch (row) {
      case 1:
        let i;
        for (i = 0; i < 2; i++) {
          render.push(
            <Col xl={2}>
              <ProductCard
                image={cards_3_images[i]}
                widthImage={widthImages_3[i]}
                title={"IHCsurgicalInstruments.cards_3.card_" + (i + 1)}
                cardDates={data.cards_3[i]}
                cardType={1}
              ></ProductCard>
            </Col>
          )
        }
        render.push(
          <Col xl={4}>
            <ProductCard
              cardDates={[data.cards_3[2], data.cards_3[3]]}
              images={[cards_3_images[2], cards_3_images[3]]}
              widthImages={[widthImages_3[2], widthImages_3[3]]}
              subtitles={["IHCsurgicalInstruments.cards_3.subtitle_3_1", "IHCsurgicalInstruments.cards_3.subtitle_3_2"]}
              title={"IHCsurgicalInstruments.cards_3.card_3"}
              cardType={4}
            ></ProductCard>
          </Col>
        )
        for (i = 4; i < 6; i++) {
          render.push(
            <Col xl={2}>
              <ProductCard
                image={cards_3_images[i]}
                widthImage={widthImages_3[i]}
                title={"IHCsurgicalInstruments.cards_3.card_" + i}
                cardDates={data.cards_3[i]}
                cardType={1}
              ></ProductCard>
            </Col>
          )
        }
        break;

      default: render.push(null)
    }
    return render;
  }
  return (
    <Styles>
      <div className="content-wave">
        <Image src={VioletWaveImg} className="img-fluid first"></Image>
        <Image src={VioletWaveImg} className="img-fluid second"></Image>
      </div>
      <div className="container-page">
        <h1 class="title"><Trans>IHCsurgicalInstruments.self</Trans></h1>
        <Container fluid={true} className="row-content">
          <h1><Trans>IHCsurgicalInstruments.cards_1.title</Trans></h1>
          <Row className="content-cards">
            {renderFirstRow(1)}
          </Row>
          <Row className="content-cards">
            {renderFirstRow(2)}
          </Row>
        </Container>
      </div>
      <div className="container-page">
        <Container fluid={true} className="row-content">
          <h1><Trans>IHCsurgicalInstruments.cards_4.title</Trans></h1>
          <Row className="content-cards">
            <Col xl={2}>
              <ProductCard
                image={ImageCards4_1}
                title={"IHCsurgicalInstruments.cards_4.card_1"}
                subtitle={["IHCsurgicalInstruments.cards_4.subtitle_1"]}
                cardDates={data.cards_4[0]}
                cardType={1}
                widthImage={"97%"}
              ></ProductCard>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="container-page">
        <Container fluid={true} className="row-content">
          <h1><Trans>IHCsurgicalInstruments.cards_2.title</Trans></h1>
          <Row className="content-cards">
            {renderSecondRow(1)}
          </Row>
          <Row className="content-cards">
            {renderSecondRow(2)}
          </Row>
        </Container>
      </div>
      <div className="container-page">
        <Container fluid={true} className="row-content">
          <h1><Trans>IHCsurgicalInstruments.cards_3.title</Trans></h1>
          <Row className="content-cards">
            {renderThreeRow(1)}
          </Row>
        </Container>
      </div>
      <div className="container-page">
        <Container fluid={true} className="row-content">
          <h1><Trans>IHCsurgicalInstruments.cards_5.title</Trans></h1>
          <SurgicalKit
            data={data}
          />
        </Container>
        <div className="content-buttons">
          <Button onClick={() => navigate('/dental/internalHexagonConnection/smartImplants')}><Trans>dental.conexionHexagono_modal.card_1.title</Trans></Button>
          <Button onClick={() => navigate('/dental/internalHexagonConnection/prostheticComponents')}><Trans>dental.conexionHexagono_modal.card_2.title</Trans></Button>
        </div>
      </div>
    </Styles>
  )
}
const ProductCard = (props) => {
  function renderCard_1() {
    return (
      <div className="card-content">
        <div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
        <h2 className="card-title"><Trans>{props.title}</Trans></h2>
        <h3 className="card-subtitle"><Trans>{props.subtitle}</Trans></h3>
        <div className="">
          <Table className="table-content mr-5">
            <thead>
              <tr>
                <td></td>
                <td className="text-left"></td>
              </tr>
            </thead>
            <tbody>
              {generateCodesSecond(props.cardDates.products_cod, props.cardDates.products_type)}
              {/* {generateCodesFirst(props.cardDates)} */}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
  function renderCard_2() {
    return (
      <div className="card-content">
        <div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
        <h2 className="card-title"><Trans>{props.title}</Trans></h2>
        <h3 className="card-subtitle"><Trans>{props.subtitle}</Trans></h3>
        <div className="d-flex">
          <Table className="table-content mr-5 col-xl-6">
            <thead>
              <tr>
                <td></td>
                <td className="text-left">mm</td>
                <td className="text-left">mm</td>
              </tr>
            </thead>
            <tbody>
              {generateCodesSecond(props.cardDates.products_cod_1, props.cardDates.products_type_1, props.cardDates.products_detail_1)}
            </tbody>
          </Table>
          <Table className="table-content mr-4 col-xl-6">
            <thead>
              <tr>
                <td></td>
                <td className="text-left">mm</td>
                <td className="text-left">mm</td>
              </tr>
            </thead>
            <tbody>
              {generateCodesSecond(props.cardDates.products_cod_2, props.cardDates.products_type_2, props.cardDates.products_detail_2)}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
  function renderCard_3() {
    return (
      <div className="cards-content-doble col-xl-12">
        <div className=" d-flex justify-content-around">
          <div className="content-image" style={{ width: props.widthImage }} ><Image src={props.image} className="card-image img-fluid" ></Image></div>
          <div className="content-image" style={{ width: props.widthImage_2 }} ><Image src={props.image_2} className="card-image img-fluid" ></Image></div>
        </div>
        <h2 className="card-title"><Trans>{props.title}</Trans></h2>
        <div className="d-flex">
          <div className="col-xl-6 pl-0">
            <Table className="table-content mr-5">
              <thead>
                <tr>
                  <td></td>
                  <td className="text-left"></td>
                  <td className="text-left"></td>
                </tr>
              </thead>
              <tbody>
                {generateCodesSecond(props.cardDates.products_cod, props.cardDates.products_type, props.cardDates.products_detail)}
              </tbody>
            </Table>
          </div>
          <div className="col-xl-6">
            <Table className="table-content">
              <thead>
                <tr>
                  <td></td>
                  <td className="text-left"></td>
                  <td className="text-left"></td>
                </tr>
              </thead>
              <tbody>
                {generateCodesSecond(props.cardDates_2.products_cod, props.cardDates_2.products_type, props.cardDates_2.products_detail)}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
  function renderCard_4() {
    let renderTable = [];
    let renderImages = []
    let i;
    for (i = 0; i < props.images.length; i++) {
      renderImages.push(
        <div className="content-image-type-4" >
          <Image src={props.images[i]} style={{ width: props.widthImages[i] }} className="card-image img-fluid" ></Image>
        </div>
      )
    }
    for (i = 0; i < props.images.length; i++) {
      if (props.subtitle === "IHCsurgicalInstruments.cards_2.card_1_subtitle") {
      renderTable.push(
        <Table className="table-content col-xl-4 mr-3">
          <thead>
            <tr>
              <td className="td-header w-75"><Trans>{props.subtitles[i]}</Trans></td>
              <td>{props.cardDates[i].products_header}</td>
            </tr>
          </thead>
          <tbody>
            {generateCodesSecond(props.cardDates[i].products_cod, props.cardDates[i].products_type)}
          </tbody>
        </Table>
      )} else {
      renderTable.push(
          <Table className="table-content col-xl-6 mr-3">
          <thead>
            <tr>
              <td className="td-header w-75"><Trans>{props.subtitles[i]}</Trans></td>
              <td>{props.cardDates[i].products_header}</td>
            </tr>
          </thead>
          <tbody>
            {generateCodesSecond(props.cardDates[i].products_cod, props.cardDates[i].products_type)}
          </tbody>
        </Table>
      )}
    }
    return (
      <div className="cards-content-doble col-xl-12">
        <h2 className="card-title mb-0"><Trans>{props.title}</Trans></h2>
        <h2 className="card-title"><Trans>{props.subtitle}</Trans></h2>
        <div className="d-flex">
          {renderImages}
        </div>
        <div className="d-flex">
          {renderTable}
        </div>
      </div>
    )
  }
  function generateCodesSecond(products_cod, products_type, products_detail) {
    let table = [];
    for (let i = 0; i < products_cod.length; i++) {
      if (products_cod[i] !== "") {
        table.push(
          <tr>
            <td>{products_cod[i]}</td>
            <td className="text-left">{products_type[i]}</td>
            {products_detail ? <td className="text-left">{products_detail[i]}</td> : ""}
          </tr>
        )
      }
    }
    return table;
  }
  /* function generateCodesFirst(cardDates) {
    let table = [];
    for (let i = 0; i < cardDates.products_cod.length; i++) {
      if (cardDates.products_cod[i] !== "") {
        table.push(
          <div>
            <div className="card-text">
              <p>{cardDates.products_cod[i]}</p>
              <p  className="text-left">{cardDates.products_type[i]}</p>
            </div>
          </div>
        )
      } else {
        table.push(
          <div className="card-text-empty">
            <p>&nbsp;&nbsp;</p>
          </div>
        )
      }
    }
    return table;
  } */
  switch (props.cardType) {
    case 1:
      return (
        <ProductCardStyles>
          {renderCard_1()}
        </ProductCardStyles>
      )
    case 2:
      return (
        <ProductCardStyles>
          {renderCard_2()}
        </ProductCardStyles>
      )
    case 3:
      return (
        <ProductCardStyles>
          {renderCard_3()}
        </ProductCardStyles>
      )
    case 4:
      return (
        <ProductCardStyles>
          {renderCard_4()}
        </ProductCardStyles>
      )
    default: return (null)
  }
}
const SurgicalKit = (props) => {
  const renderTable = (productCode, productDescription) => {
    return (
      <div className="table-scroll">
        <Table striped bordered size="sm">
          <thead>
            <tr>
              <th><Trans>IHCsurgicalInstruments.cards_5.table_header.header_1</Trans></th>
              <th><Trans>IHCsurgicalInstruments.cards_5.table_header.header_2</Trans></th>
            </tr>
          </thead>
          <tbody>
            {
              productCode.map((code, i) => {
                return (
                  <tr>
                    <td><RegisterMark>{productDescription[i]}</RegisterMark></td>
                    <td>{code}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
  return (
    <SurgicalKitStyles>
      <div className="content-image">
        <Image src={SurgicalKitImage} className="img-fluid" id="img-kit"></Image>
      </div>
      <div className="content-tables pt-5 pb-5">
        {
          props.data.cards_5.map((dataItem, i) => {
            return (
              <Col className="content-table">
                <div className="titles">
                  <h3><RegisterMark>IHCsurgicalInstruments.cards_5.table_{`${i + 1}`}.title</RegisterMark></h3>
                  <h4 className="mt-2 mb-2"><Trans>IHCsurgicalInstruments.cards_5.table_{`${i + 1}`}.subtitle</Trans></h4>
                </div>
                {renderTable(dataItem.productCode, dataItem.productDescription, i)}
              </Col>
            )
          })
        }
      </div>
    </SurgicalKitStyles>
  )
}
export default SurgicalInstruments
