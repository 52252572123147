import React from 'react'
import { Trans } from "react-i18next";
import i18n from "i18next"
import { isMobile } from "react-device-detect";
import Styles from './Styles';

const LegalNotice = () => {
  const terms_of_use_texts = 19;
  const renderTermsOfUseTexts = () => {
    let render = []

    for (let i = 0; i < terms_of_use_texts; i++) {
      render.push(
        <div className="text-container">
          <h3><Trans>legalNotice.terms_of_use.texts.{`${i + 1}`}.title</Trans></h3>
          <p><Trans>legalNotice.terms_of_use.texts.{`${i + 1}`}.text</Trans></p>
          {i18n.exists("legalNotice.terms_of_use.texts." + (i + 1) + ".text_1") && <p>{i18n.t("legalNotice.terms_of_use.texts." + (i + 1) + ".text_1")}</p>}
          {i18n.exists("legalNotice.terms_of_use.texts." + (i + 1) + ".text_2") && <p>{i18n.t("legalNotice.terms_of_use.texts." + (i + 1) + ".text_2")}</p>}
        </div>
      )
    }
    return render;
  }
  return (
    <Styles isMobile={isMobile}>
      <div className="legal-notice">
        <h1><Trans>legalNotice.self</Trans></h1>
        <p><Trans>legalNotice.legal_notice_text</Trans></p>
        <p className="bold"><Trans>legalNotice.legal_notice_text_bold</Trans></p>
      </div>
      <div className="legal-notice">
        {renderTermsOfUseTexts()}
        <p className="nro"><Trans>legalNotice.footer</Trans></p>
      </div>
    </Styles>
  )
}
export default LegalNotice