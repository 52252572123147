import React from 'react';
import Carousel from 'react-multi-carousel';

const MultiCarousel = ({ children, showDots }) => {
	const responsiveConfig = {
		mobile: {
			breakpoint: {
				max: 464,
				min: 0
			},
			items: 1,
			partialVisibilityGutter: 50
		},

		tablet: {
			breakpoint: {
				max: 1024,
				min: 464
			},
			items: 2,
			partialVisibilityGutter: 20
		},

		desktop: {
			breakpoint: {
				max: 3000,
				min: 1024
			},
			items: 3,
			partialVisibilityGutter: 0
		}
	};
	return (
		<Carousel
			additionalTransfrom={0}
			arrows={false}
			//autoPlay
			// autoPlaySpeed={3000}
			centerMode={false}
			//infinite
			dotListClass=""
			draggable
			focusOnSelect={false}
			keyBoardControl
			minimumTouchDrag={0}
			renderButtonGroupOutside={false}
			renderDotsOutside={false}
			partialVisible={false}
			showDots={showDots}
			responsive={responsiveConfig}
			sliderClass=""
			slidesToSlide={1}
			swipeable
		>
			{children}
		</Carousel>
	);
};

export default MultiCarousel;
