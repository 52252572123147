import styled from "styled-components";

export default styled.main`
    background:black;
    position:relative;
    background:rgba(28,28,28,1);
    .container-page{
        margin:auto;
        padding-bottom:4%;
    }
    .first-row{
        position:relative;
        width:100%;
    }
    .onda-gris-reverse{
        position: absolute;
        z-index: 1;
        left: -6%;
        bottom: -16%;
        transform: scaleX(-1);
    }
    .onda-gris{
        position: absolute;
        z-index: 1;
        top: 7%;
        right: -10%;
    }
    .second-gradient{
        background: linear-gradient(to bottom,rgba(0,0,0,1) 5%,rgba(0,0,0,1) 10%,rgba(50,50,50,0.7) 25%,rgba(45,45,45,1) 80%,rgba(0,0,0,0.8) );
        width: 100%;
        height: 50%;
        z-index: 14;
        position: absolute;
        top: 50%;
    }
    .titles{
        top:39%;
        z-index:50;
        text-align:center;
        margin-top:10px;
        height:max-content;
        display:flex;
        flex-direction:column;
        align-items:center;
        h1{
            z-index:50;
            font-family: 'Montserrat', sans-serif;
            color:#EBEBEB;
            font-weight:700;
            font-size:45px;
            display:block;
        }
        h2{
            z-index:50;
            font-family: 'Montserrat', sans-serif;
            color:#F8F8F8;
            margin-top:20px;
            display:block;
            font-size:26px;
            font-weight:300;
            width: 50%;
        }
    }
    .second-row{
        position:relative;
        z-index:50;
        padding:10px;
        display:flex;
        align-content:center;
        justify-content:center;
    }
    .card{
        background: linear-gradient(45deg,rgba(0,0,0,0.69) ,rgba(74,73,73,0) 85%);
        border-radius: 10px;
        padding: 10px;
        width:80%;
        z-index:50;
        display:flex;
        flex-direction:row;
        .content-images{
            z-index:50;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        h3{
            z-index:50;
            color: white;
            font-family: 'Montserrat',sans-serif;
            font-weight: 700;
            text-align:center;
        }
        .head{
            background:transparent !important;
            border:0px;
            .tr-head{
                background:transparent !important;
                font-size:16px;
                border:0px;
                font-family: 'Montserrat', sans-serif;
                @media (max-width:1280px){
                    font-size:14px;
                }
            }
            th{
                padding: 10px;
                background:transparent !important;
                text-align: center;
                border:0px;
                vertical-align: middle;
                z-index:50;
                position: sticky; top: 0;
            }
        }
        td{
            text-align:center;
            padding:5px !important;
            vertical-align: middle;
            background:transparent !important;
            border:0px;
        }
        .table{
            background:transparent !important;
        }
    }
    .content-buttons{
			display:flex;
			width:100%;
			justify-content:center;
			padding-top:20px;
			z-index:50;
			button{
                background: transparent !important;
                width: 142px;
                height: 41px;
                z-index: 50;
                color: white;
                border: 2.5px solid white;
                font-size: 12px;
                font-weight: 700;
                border-radius: 25px;
                margin:0 10%;
            }
			button:hover {
					background:white !important;
					border:1px solid black !important;
					color:black !important;
					transform: scale(1.03); 
			}
			button:active{
					border: 1px solid white;
					box-shadow:none !important;
			}
			button:focus{
					border: 1px solid white;
					box-shadow:none !important;
			}
	}
`
