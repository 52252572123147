import styled from 'styled-components'
import BackgroundImageMobile from '../../assets/images/flyermechanicFaicoMobile.png'
import BackgroundImageCard from '../../assets/pdf/pdf8.png'


export const Styles = styled.div`

  
  min-height: 2028px;
  position:relative;
  .gradient{
    width:100%;
    height:100%;
    position:absolute;
    //background: transparent linear-gradient(to bottom, #6f3cea85 0%, #2A1759E6 26%, #080411 48%, #000000 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(to bottom, #6F3CEAB5 0%, #2A17592E 46%, #080411 52%, #000000 100%) 0% 0% no-repeat padding-box;
    z-index:10;
  }
  .wave{
    position: absolute;
    bottom: 11%;
    z-index: 10;
    transform: rotate(180deg);
  }
  .content-image-background{
    position:absolute;
    width:100%;
    img{
      width:100%;
      position:relative;
    }
  }
  .content-page{
    position:absolute;
    width: 100vw;
    padding: 2%;
  }
  .content-first-row{
    z-index:30;
    width:100%;
    height:100%;
    margin-top:256px;
    position:relative;
    padding:0px 140px;
    .faico-text{
      .title{
        display:flex;
        align-items: flex-end;
        h1{ 
          font-size:72px;
          font-weight:700;
          margin:0px;
          font-family: 'Montserrat', sans-serif;
          color:white;
        }
        .dot{
          height: 15px;
          width: 15px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 2px;
        }
      }
      p{
        color:white;
        width: 678px;
        height: 136px;
        font-family: 'Montserrat', sans-serif;
        font-size:26px;
        line-height:36px;
        margin-top:35px;
      }
    }
    .clinical-cases{
      margin-top:231px;
      padding:0;
      .title{
        margin:0;
        padding:0;
        h1{
          font-size:45px;
          font-weight:700;
          margin:0px;
          font-family: 'Montserrat', sans-serif;
          color:white;
        }
        p{
          color:white;
          width: 678px;
          height: 136px;
          font-family: 'Montserrat', sans-serif;
          font-size:23px;
          line-height:33px;
          margin-top:25px;
          margin-bottom:0px;
          height:min-content;
        }
      }
      .content-cards{  
        display:flex;
        margin-top:68px;
        .card:first-child{
         margin-right:30px;
        }
        .card:last-child{
         margin-left:30px;
        }
        @media  screen and (max-width: 1440px) {
          max-width: 75%;
		    }
      }
    }
  }

  .react-multi-carousel-track{
    min-height: 600px;
  }


  .content-first-row-desktop{
    z-index:30;
    width:100%;
    height:100%;
    margin-top:256px;
    position:relative;
    padding:0px 70px;
    .faico-text{
      margin-left: 40px;
      .title{
        display:flex;
        align-items: flex-end;
        h1{ 
          font-size:72px;
          font-weight:700;
          margin:0px;
          font-family: 'Montserrat', sans-serif;
          color:white;
        }
        .dot{
          height: 15px;
          width: 15px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 2px;s
        }
      }
      p{
        color:white;
        width: 678px;
        height: 136px;
        font-family: 'Montserrat', sans-serif;
        font-size:26px;
        line-height:36px;
        margin-top:35px;
      }
    }
    .clinical-cases{
      margin-top:231px;
      padding:0;
      margin-left: 40px;
      .title{
        margin:0;
        padding:0;
        h1{
          font-size:45px;
          font-weight:700;
          margin:0px;
          font-family: 'Montserrat', sans-serif;
          color:white;
        }
        p{
          color:white;
          width: 678px;
          height: 136px;
          font-family: 'Montserrat', sans-serif;
          font-size:23px;
          line-height:33px;
          margin-top:25px;
          margin-bottom:25px;
          height:min-content;
        }
      }
      .content-cards{
        display:flex;
        margin-top:68px;
        .card:first-child{
          margin-right:40px;
        }
        .card:last-child{
          margin-left:40px;
        }
        @media  screen and (max-width: 1440px) {
          max-width: 75%;
		    }
      }

    }
    
    @media  screen and (max-width: 1440px) {
        padding: 0px 107px !important ;
    }
  }

  .react-multiple-carousel__arrow--left{
    left: 0%;
    bottom: 68%;
  }
  
  .react-multiple-carousel__arrow--right{
    right: 0%;
    bottom: 68%;
  }

  .faico-extension{
    display:flex;
    position:relative;
    z-index:100;
    margin-top:50px;


    .default-button {
      font-family: 'Montserrat',sans-serif;
      font-size: 17px;
      font-weight: 700;
      color: white;
      display: flex;
      text-align: center;
      border-radius: 50px;
      border: 3px solid #FFFFFF;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 251px !important;
      height: 53px !important;
      margin-bottom: 37px;
      margin-top: 17px;
    }
    
    .card-extension{
      z-index:100;
      h1{
        font-size:45px;
        line-height:125px;
        font-weight:700;
        margin:0px;
        font-family: 'Montserrat', sans-serif;
        color:white;
        z-index:100;
      }
      p{
        color:white;
        font-family: 'Montserrat', sans-serif;
        font-size:23px;
        line-height:33px;
        margin-bottom:0px;
        margin-top:25px;
        height:min-content;
        text-align:left;
        width: 100%;
        z-index:100;
      }
      p:nth-child(1n){
        margin:0;
      }
    }
    #card-extension-1{
      margin-left:155px;
      @media  screen and (max-width: 1440px) {
        margin-left:105px;
		  }
      
    }
    #card-extension-2{
      margin-left:52px;
      @media  screen and (max-width: 1440px) {
        margin-left: 72px;
		  }
    }
    #card-extension-3{
      margin-left:71px;
      @media  screen and (max-width: 1440px) {
        margin-left: 154px;
		  }
      p{
        
        @media  screen and (max-width: 1440px) {
         
		    }
      }
    }
  }
  .full-width{
    width: 100%;
  }
`

export const MobileStyles = styled(Styles)`
  width:100%;
  
  min-height: 1280px;

  .react-multi-carousel-track{
    min-height: 400px;
  }

  .gradient{
    width:100%;
    height:100%;
    position:absolute;
    background: transparent linear-gradient(to bottom, #6f3cea85 0%, #2A1759E6 26%, #080411 46%, #000000 100%) 0% 0% no-repeat padding-box;
    z-index:0;
  }
  .background-content{
    width:100%;
    height:100%;
    background-image: url('${BackgroundImageMobile}');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center;
  }
  .content-image-background{
    position:absolute;
    width:100%;
    top: -1%;
    overflow: hidden;
    img{
      left: -10%;
      width:190%;
      position:relative;
      overflow: hidden;
    }
  }
  .content-page{
    width:100%;
    .content-first-row{
      margin-top:50px;
      padding:0px 30px;
      width:100%;

      

      .faico-text{
        h1{
          font-size:32px;
          line-height:54px;
        }
        .dot{
          width: 7px;
          height: 7px;
        }
        p{
          margin-top:15px;
          font-size:15px;
          line-height:19px;
          width:100%;
          font-weight:300;
        }
      }
      .clinical-cases{
        margin-top:40px;
        .title{
          h1{
            font-size:30px;
            line-height:42px;
          }
          p{
            font-size:15px;
            line-height:19px;
            width:100%;
            margin-top:10px;
            font-weight:300;
          }
        }
      }
    }
  }
  .react-multi-carousel-list{
    z-index:30;
    padding-bottom: 5%;
  }
  .content-card-mobile{
    justify-content: center;
    margin-top: 2.5em;
  }

  .react-multi-carousel-item{
    padding-right:5px;
  }

  .faico-extension-mobile{
    margin-top:60px;
    padding:0px 30px;
    width:100%;
    z-index:100;
    position:relative;
    color: white;
    .default-button {
      font-family: 'Montserrat',sans-serif;
      font-size: 17px;
      font-weight: 700;
      color: white;
      display: flex;
      text-align: center;
      border-radius: 50px;
      border: 3px solid #FFFFFF;
      align-items: center;
      justify-content: center;
      background: transparent;
      width: 251px !important;
      height: 53px !important;
      margin-bottom: 37px;
      margin-top: 17px;
    }
    h1{
      font-size:30px;
      line-height:42px;
      font-weight:700;
      font-family: 'Montserrat', sans-serif;
      color: white;
    }
    p{
      font-size:15px;
      color: white;
      font-family: 'Montserrat', sans-serif;
      line-height:19px;
      font-weight:300;
    }
    margin-bottom:35px;
    
  }
`
