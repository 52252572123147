/*----- Imports -----*/
import React from 'react';
import { useRoutes, usePath } from 'hookrouter';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import StylesGlobalMobile from './StylesGlobalMobile';
import ScrollToTop from '../../components/ScrollToTop';
import FooterMobile from '../../components/commons/footer/FooterMobile';
import Header from '../../components/commons/nav/mobile/Header';

import Home from '../home/mobile/index';
import About from "../about/mobile/index";
import Faico360 from '../faico360/mobile/index';
import Contact from '../contact/mobile/index'
import Dental from '../dental/mobile/index'
import Download from "../download/mobile/index";
import ConicalConnection from '../conicalConnection/mobile/index';
import InternalHexagonConnection from '../internalHexagonConnection/mobile/index';
import UniversalExpanders from '../universalExpanders/mobile/index';
import CCSmartImplantsCyroth from '../conicalConnection/mobile/smartImplants/cyroth/index';
import CCSmartImplantsIsFour from '../conicalConnection/mobile/smartImplants/isFour/index';
import IHCSmartImplantsCyroth from '../internalHexagonConnection/mobile/smartImplants/cyroth/index';
import IHCSmartImplantsCyrothShort from '../internalHexagonConnection/mobile/smartImplants/cyrothShort/index';
import IHCSmartImplantsIsFour from '../internalHexagonConnection/mobile/smartImplants/isFour/index';
import LegalNotice from '../legalNotice/index';
import NoticePrivacy from '../noticePrivacy/index';
import TermsConditions from '../termsConditions/index';
import Videos from "../videos/mobile";
import News from "../news/mobile";
import NoveltyContainer from '../news/mobile/NoveltyContainer'
import Maxilofacial from '../maxillofacial/mobile/index';
import MicroPlatingSystem from '../microPlatingSystem/mobile/index';
import MediumPlatingSystem from '../mediumplatingSystem/mobile';
import MiniplatingLowProfile from '../miniplatingLowProfile/mobile';
import MiniplatingStandardSystem from '../miniplatingStandardSystem/mobile';
import FracturePlatingSystem from '../fracturePlatingSystem/mobile';
import MandibularReconstruction from '../mandibularReconstruction/mobile';
import IMFSystem from '../imfSystem/mobile';
import GuideSurgery from '../guidedSurgery/mobile';
import ClinicalCaseDetail from '../clinicalCaseDetails/mobile/index';
import CCNewSmartImplantsMobile from '../conicalConnection/mobile/smartImplants/new/index'

function generateRoutes() {
	const routes = {
		'/': { breadCrumbText: i18n.t('breadcrumb./'), component: () => <Home /> },
		'/about': { breadCrumbText:  i18n.t('breadcrumb./about'), component: () => <About /> },
		'/faico360': { breadCrumbText: i18n.t('breadcrumb./faico360'), component: () => <Faico360 /> },
		'/legalNotice': { breadCrumbText: i18n.t('breadcrumb./legalNotice'), component: () => <LegalNotice /> },
		'/noticePrivacy': { breadCrumbText: i18n.t('breadcrumb./noticePrivacy'), component: () => <NoticePrivacy /> },
		'/termsConditions': { breadCrumbText: i18n.t('breadcrumb./termsConditions'), component: () => <TermsConditions /> },
		'/contact': { breadCrumbText: i18n.t('breadcrumb./contact'), component: () => <Contact /> },
		'/download': { breadCrumbText:  i18n.t('breadcrumb./download'), component: () => <Download /> },
		'/dental': { breadCrumbText: i18n.t('breadcrumb./'), component: () => <Dental /> },
		'/dental/conicalConnection': { breadCrumbText: i18n.t('breadcrumb./dental/conicalConnection'), component: () => <ConicalConnection /> },
		'/dental/conicalConnection/smartImplants/cyroth': { breadCrumbText: i18n.t('breadcrumb./dental/conicalConnection/smartImplants/cyroth'), component: () => <CCSmartImplantsCyroth /> },
		'/dental/conicalConnection/smartImplants/isFour': { breadCrumbText: i18n.t('breadcrumb./dental/conicalConnection/smartImplants/isFour'), component: () => <CCSmartImplantsIsFour /> },
		'/dental/internalHexagonConnection': { breadCrumbText: i18n.t('breadcrumb./dental/internalHexagonConnection'), component: () => <InternalHexagonConnection /> },
		'/dental/internalHexagonConnection/smartImplants/cyroth': { breadCrumbText: i18n.t('breadcrumb./dental/internalHexagonConnection/smartImplants/cyroth'), component: () => <IHCSmartImplantsCyroth /> },
		'/dental/internalHexagonConnection/smartImplants/cyrothShort': { breadCrumbText: i18n.t('breadcrumb./dental/internalHexagonConnection/smartImplants/cyrothShort'), component: () => <IHCSmartImplantsCyrothShort /> },
		'/dental/internalHexagonConnection/smartImplants/isFour': { breadCrumbText: i18n.t('breadcrumb./dental/internalHexagonConnection/smartImplants/isFour'), component: () => <IHCSmartImplantsIsFour /> },
		'/dental/universal_expanders': { breadCrumbText: i18n.t('breadcrumb./dental/universal_expanders'), component: () => <UniversalExpanders /> },
		'/dental/guided_surgery': { breadCrumbText: i18n.t('breadcrumb./dental/guideSurgery'), component: () => <GuideSurgery /> },
		'/videos': {breadCrumbText: i18n.t('breadcrumb./videos') , component: () => <Videos /> },
		'/news': { breadCrumbText:  i18n.t('breadcrumb./news'), component: () => <News /> },
		'/news/:postId': { breadCrumbText:  i18n.t('breadcrumb./news/:postId'), component: ({ postId }) => <NoveltyContainer id={postId} /> },
		'/maxillofacial': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial'), component: () => <Maxilofacial /> },
		'/maxillofacial/microplating_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/microplating_system'), component: () => <MicroPlatingSystem /> },
		'/maxillofacial/miniplating_low_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_low_system'), component: () => <MiniplatingLowProfile /> },
		'/maxillofacial/mediumplating_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mediumplating_system'), component: () => <MediumPlatingSystem /> },
		'/maxillofacial/miniplating_standard_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/miniplating_standard_system'), component: () => <MiniplatingStandardSystem /> },
		'/maxillofacial/fracture_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/fracture_system'), component: () => <FracturePlatingSystem /> },
		'/maxillofacial/mandibular_reconstruction': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/mandibular_reconstruction'), component: () => <MandibularReconstruction /> },
		'/maxillofacial/imf_system': { breadCrumbText:  i18n.t('breadcrumb./maxillofacial/imf_system'), component: () => <IMFSystem /> },
		'/faico360/:index': { breadCrumbText: i18n.t('breadcrumb./faico360/:index'), component: ({index}) => <ClinicalCaseDetail index={index}/> },
		'/dental/conicalConnection/newSmartImplants': {  breadCrumbText: i18n.t('breadcrumb./dental/conicalConnection/newsmartImplants'), component: () => <CCNewSmartImplantsMobile /> }
	}
	return routes
}

const ViewsContainer = () => {

	const routerForRouter = {};
	let routes = generateRoutes();
	Object.keys(routes).map((k) => { return (routerForRouter[k] = routes[k].component) });
	const routeResult = useRoutes(routerForRouter);
	const path = usePath();
	return (
		<StylesGlobalMobile>
			<Header />
			<ScrollToTop path={path}>
				{routeResult}
			</ScrollToTop>
			<FooterMobile />
		</StylesGlobalMobile>
	);
};

export default withTranslation()(ViewsContainer)