import React, { Component, Fragment } from 'react';
import styled from "styled-components";
import { A } from 'hookrouter';
/*----- Styles-----*/
const StyledParagraph = styled.main`
	background:#1e1e1e;
	padding-left: 0px;
	padding-top: 15px;
	padding-bottom:5px;
	z-index:900;
	position:relative;
	color:#9f6ace;
	p{
		margin-left:140px;
	}
	a{
		color: white;
		padding: 3px 3px 3px 0px;
		font-family: 'Montserrat', sans-serif;
		font-size:16px;
	}
	a:hover{
		text-decoration:none;
		color:#9f6ace;
		border-color:#9f6ace;
	}
`;

export default class Breadcrumb extends Component {
	render() {
		const hash = window.location.pathname
		const path = hash.split('/').filter(p => p !== "");
		if (hash === "/") path[1] = ("/");
		let breadcrumbVisible = !!(path.length !== 0);
		let indicePath = "";
		let breadcrumbPath = []
		path.forEach((part, i) => {
			indicePath = indicePath + "/" + part;
			breadcrumbPath[i] = indicePath;
			if (this.props.dictionary[indicePath] === "") {
				breadcrumbVisible = false;
				return;
			}
		})
		return breadcrumbVisible ?
			(
				<StyledParagraph>
					<p>
						{breadcrumbPath.map((part, index) => {
							// Append part to current path
							return <Fragment key={index}><A key={index} href={part} >{this.props.dictionary[part]}</A>{((index === path.length - 1) ? '' : ">")}</Fragment>
						})}
					</p>
				</StyledParagraph>
			)
			: null
	}
}
