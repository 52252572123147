import React from 'react'
import Styles from './Styles'
import { Image } from 'react-bootstrap'
import { navigate } from "hookrouter";

const LevelOneCard = ({image, title, url}) => {
    return (
        <Styles className="level-one-card" onClick={()=>navigate(url)}>
            <div className="container-card">    
                <div className="first-col">
                    <Image alt="" className="card-image" src={`${image}`}></Image>
                </div>
                <div className="second-col">
                    <div className="card-title">
                        <h1>{title}</h1>
                    </div>
                </div>
            </div>
        </Styles>
    )
}

export default LevelOneCard