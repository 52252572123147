import styled from "styled-components";
import ModalStyles from '../../../../components/modals/implants/ImplantsModalStyles'

export const Styles = styled(ModalStyles)`
	.container-image
	.modal-1-1{
    top: 10%;
    left: 30%;
	}
	.modal-1-2{
		left: 41%;
    	top: 19%;
	}
	.modal-1-3{
    left: 7%;
    top: 16%;
	}
	.modal-1-4{
		top: 46%;
		right: 17%;
	}
	.modal-1-5{
		bottom: 13%;
		right: 59%;
	}
	.modal-1-6{
		bottom: 1%;
		left: 26%;
	}
	.modal-1-7{
		bottom: 36%;
    right: 41%;
	}
	.modal-2-1{
    top: 18%;
    right: 47%;
	}
	.modal-2-2{
    top: 33%;
    left: 13%;
	}
	.modal-2-3{
    bottom: 2%;
    left: 28%;
	}
	.modal-2-4{
    right: 20%;
    top: 60%;
	}
	.modal-2-5{
    bottom: 13%;
    right: 40%;
	}
	}
	.modal-3-1{
		top:10%;
		left:44%;
	}
	.modal-3-2{
		left: 16%;
    	top: 18%;
	}
	.modal-3-3{
		top: 58%;
    	left: 9%;
	}
	.modal-3-4{
		right: 2%;
    	top: 46%;
	}
	.modal-3-5{
		top: 86%;
		left: 28%;
	}
	.modal-3-6{
		right: 28%;
    	bottom: 9%;

	}
	.modal-3-7{
		bottom: 48%;
    	left: 45%;
	}	
	.modal-1{
		max-width:78% !important;
	}
	.modal-2{
		max-width:80% !important;
	}
	.modal-3{
		max-width:98% !important;
	}
`