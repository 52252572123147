import React,{useState} from 'react'
import Sets from "../../maxillofacial/desktop/Sets"
import {dataGenerator} from "./data.js";
import i18n from "i18next";

const MiniplatingStandardSystemSets= (props) => {
	const [data, setData] = useState(dataGenerator(i18n.language))
	i18n.on('languageChanged', function(lng) {	
		setData(dataGenerator(lng))
	})
	return (
		<Sets
			title={"miniplatingStandardSystem.products.4.name"}
			navigate={"miniplating_standard_system"}
			subtitle={"miniplatingStandardSystem.products.4.description"}
			sectionTitles={["miniplatingStandardSystem.sets.titles.1"]}
			titleExtended={["miniplatingStandardSystem.sets.titles_extended.1"]}
			sectionData={[data.dataSection_1]}
		/>
	)
}

export default (MiniplatingStandardSystemSets)