// Core
import React from 'react'
import styled from 'styled-components'
import { Row, Col } from "react-bootstrap";
// Components
import NoveltyTile from '../../../components/news/NoveltyTile';

const StyleGriddNews = styled.main`
	margin:0;
	padding:0;
  .main-row{
		margin:0;
		padding:0;
		display:flex;
		justify-content:center;
  }
  .news-row{
	margin-bottom:80px;
  }
`

const NewsGrid = ({ news }) => {
	var main_new = news[0];
	var newsClone = news.slice(0);
	newsClone.shift()
	return (
		<StyleGriddNews>
			<Row className="main-row">
				<NoveltyTile
					image={main_new.attributes.main_image.url}
					title={main_new.attributes.title}
					subtitle={main_new.attributes.subtitle}
					tags={main_new.attributes.tags}
					urlPath={`/news/${news[0].id}`}
					main={true}
				/>
			</Row>
			<Row className="news-row">
				{
					newsClone.map((newsClone, i) => {
						let { id } = newsClone
						let {
							main_image,
							title,
							subtitle,
							tags
						} = newsClone.attributes
						return (
							<Col md={3} key={i}>
								<NoveltyTile
									image={main_image.url}
									title={title}
									subtitle={subtitle}
									tags={tags}
									urlPath={`/news/${id}`}
									main={false}
								/>
							</Col>
						)
					})
				}
			</Row>
		</StyleGriddNews>
	)
}
export default NewsGrid
