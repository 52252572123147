export const dataSection_1 = [
  ["MLPS-1.5-S-4.0-TI", "MLPS-1.5-S-5.0-TI", "MLPS-1.5-S-6.0-TI", "MLPS-1.5-S-7.0-TI", "MLPS-1.5-S-8.0-TI", "MLPS-1.5-S-9.0-TI", "MLPS-1.5-S-10.0-TI", "MLPS-1.5-S-11.0-TI", "MLPS-1.5-S-12.0-TI", "MLPS-1.5-S-13.0-TI", "MLPS-1.5-S-14.0-TI", "MLPS-1.5-S-15.0-TI"],
  ["Ø1.5 x 4 mm.", "Ø1.5 x 5 mm.", "Ø1.5 x 6 mm.", "Ø1.5 x 7 mm.", "Ø1.5 x 8 mm.", "Ø1.5 x 9 mm.", "Ø1.5 x 10 mm.", "Ø1.5 x 11 mm.", "Ø1.5 x 12 mm.", "Ø1.5 x 13 mm.", "Ø1.5 x 14 mm.", "Ø1.5 x 15 mm."]
]
export const dataSection_2 = [
  ["MLPS-1.5-C-4.0-TI-SC", "MLPS-1.5-C-5.0-TI-SC", "MLPS-1.5-C-6.0-TI-SC", "MLPS-1.5-C-7.0-TI-SC", "MLPS-1.5-C-8.0-TI-SC", "MLPS-1.5-C-9.0-TI-SC", "MLPS-1.5-C-10.0-TI-SC", "MLPS-1.5-C-11.0-TI-SC", "MLPS-1.5-C-12.0-TI-SC", "MLPS-1.5-C-13.0-TI-SC", "MLPS-1.5-C-14.0-TI-SC", "MLPS-1.5-C-15.0-TI-SC"],
  ["Ø1.5 x 4 mm.", "Ø1.5 x 5 mm.", "Ø1.5 x 6 mm.", "Ø1.5 x 7 mm.", "Ø1.5 x 8 mm.", "Ø1.5 x 9 mm.", "Ø1.5 x 10 mm.", "Ø1.5 x 11 mm.", "Ø1.5 x 12 mm.", "Ø1.5 x 13 mm.", "Ø1.5 x 14 mm.", "Ø1.5 x 15 mm."]
]
export const dataSection_3 = [
  ["MLPS-2.0-S-4.0-TI", "MLPS-2.0-S-5.0-TI", "MLPS-2.0-S-6.0-TI", "MLPS-2.0-S-7.0-TI", "MLPS-2.0-S-8.0-TI", "MLPS-2.0-S-9.0-TI", "MLPS-2.0-S-10.0-TI", "MLPS-2.0-S-11.0-TI", "MLPS-2.0-S-12.0-TI", "MLPS-2.0-S-13.0-TI", "MLPS-2.0-S-14.0-TI", "MLPS-2.0-S-15.0-TI", "MLPS-2.0-S-16.0-TI", "MLPS-2.0-S-17.0-TI", "MLPS-2.0-S-18.0-TI", "MLPS-2.0-S-19.0-TI", "MLPS-2.0-S-20.0-TI"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_4 = [
  ["MLPS-2.0-C-4.0-TI-SC", "MLPS-2.0-C-5.0-TI-SC", "MLPS-2.0-C-6.0-TI-SC", "MLPS-2.0-C-7.0-TI-SC", "MLPS-2.0-C-8.0-TI-SC", "MLPS-2.0-C-9.0-TI-SC", "MLPS-2.0-C-10.0-TI-SC", "MLPS-2.0-C-11.0-TI-SC", "MLPS-2.0-C-12.0-TI-SC", "MLPS-2.0-C-13.0-TI-SC", "MLPS-2.0-C-14.0-TI-SC", "MLPS-2.0-C-15.0-TI-SC", "MLPS-2.0-C-16.0-TI-SC", "MLPS-2.0-C-17.0-TI-SC", "MLPS-2.0-C-18.0-TI-SC", "MLPS-2.0-C-19.0-TI-SC", "MLPS-2.0-C-20.0-TI-SC"],
  ["Ø2.0 x 4 mm.", "Ø2.0 x 5 mm.", "Ø2.0 x 6 mm.", "Ø2.0 x 7 mm.", "Ø2.0 x 8 mm.", "Ø2.0 x 9 mm.", "Ø2.0 x 10 mm.", "Ø2.0 x 11 mm.", "Ø2.0 x 12 mm.", "Ø2.0 x 13 mm.", "Ø2.0 x 14 mm.", "Ø2.0 x 15 mm.", "Ø2.0 x 16 mm.", "Ø2.0 x 17 mm.", "Ø2.0 x 18 mm.", "Ø2.0 x 19 mm.", "Ø2.0 x 20 mm."]
]
export const dataSection_5 = [
  ["MLPS-2.3-EC-4.0-TI-SC", "MLPS-2.3-EC-5.0-TI-SC", "MLPS-2.3-EC-6.0-TI-SC", "MLPS-2.3-EC-7.0-TI-SC", "MLPS-2.3-EC-8.0-TI-SC", "MLPS-2.3-EC-9.0-TI-SC", "MLPS-2.3-EC-10.0-TI-SC", "MLPS-2.3-EC-11.0-TI-SC", "MLPS-2.3-EC-12.0-TI-SC", "MLPS-2.3-EC-13.0-TI-SC", "MLPS-2.3-EC-14.0-TI-SC", "MLPS-2.3-EC-15.0-TI-SC", "MLPS-2.3-EC-16.0-TI-SC", "MLPS-2.3-EC-17.0-TI-SC", "MLPS-2.3-EC-18.0-TI-SC", "MLPS-2.3-EC-19.0-TI-SC", "MLPS-2.3-EC-20.0-TI-SC"],

  ["Ø2.3 x 4 mm.", "Ø2.3 x 5 mm.", "Ø2.3 x 6 mm.", "Ø2.3 x 7 mm.", "Ø2.3 x 8 mm.", "Ø2.3 x 9 mm.", "Ø2.3 x 10 mm.", "Ø2.3 x 11 mm.", "Ø2.3 x 12 mm.", "Ø2.3 x 13 mm.", "Ø2.3 x 14 mm.", "Ø2.3 x 15 mm.", "Ø2.3 x 16 mm.", "Ø2.3 x 17 mm.", "Ø2.3 x 18 mm.", "Ø2.3 x 19 mm.", "Ø2.3 x 20 mm."]
]