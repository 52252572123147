import React, { useState } from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import { AccordionInvertStyles } from './Styles'
import { Container, Image } from "react-bootstrap";
import { Trans } from 'react-i18next';

import Arrow from "../../assets/icons/icon-components/Arrow.js"


const AccordionCard = ({ image, title, subtitle, content, inside, classNameCard, arrowColor }) => {

	const CustomToggle = ({ children, eventKey }) => {
		const [open, setOpen] = useState(false)

		const decoratedOnClick = useAccordionToggle(eventKey, () => setOpen(!open));

		return (
			<div onClick={decoratedOnClick}>
				<Arrow rotateDeg={open ? "90" : "270"} color={arrowColor} width="15px" heigth="15px" strokeWidth="40px" />
			</div>
		)
	}

	const renderContent = () =>
		(
			<Accordion.Collapse eventKey="0" className="accordion-collapse">
				<Card.Body>
					{content}
				</Card.Body>
			</Accordion.Collapse>
		)

	return (
		<AccordionInvertStyles>
			<Card className={classNameCard ? "card" + classNameCard : "card"}>
				<Card.Header>
					<Container fluid>
						<div className="system-card">
							<div className="title-container">
								<h1><Trans>{title}</Trans></h1>
								<h1><Trans>{subtitle}</Trans></h1>
							</div>
							<div className="image-container">
								<Image src={image}></Image>
							</div>
						</div>
						<div className='border-title' />
					</Container>
					{
						inside &&
						renderContent()
					}
					<div className="container-toggle">
						<CustomToggle eventKey="0"></CustomToggle>
					</div>
				</Card.Header>
			</Card>
			{
				!inside &&
				renderContent()
			}
		</AccordionInvertStyles>
	)
}

export default AccordionCard