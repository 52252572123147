import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
	body {
		margin: 0;
		padding: 0;
	}
	.scrollModal{
		overflow:hidden !important;
	}
	.pointer{
		cursor: pointer;
	}
	.zoom {
		z-index:999;
		transition: transform .2s; /* Animation */
	}
	.zoom:hover {
		transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
	}
`