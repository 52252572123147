import React from "react";
import { Image, Row, Col, Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import { navigate } from "hookrouter";
import RegisterMark from "../../../components/registerMark";

/*Styles*/
import { Styles } from "../Styles";
import PartnerCarousel from '../../../components/carousel/PartnerCarousel'

/*Assests*/
import VioletWaveImg from "../../../assets/images/onda-violeta-4.png";
import BackgroundImage from "../../../assets/images/img-fondo-casos-clinicos.png";

/*components*/
import GalleryCard from '../../../components/card/GalleryCard.js'
import DefaultButton from "../../../components/button/DefaultButton";

/*data*/
import clinicalCases from '../../../commons/data/faico360/ClinicalCases.js';
import { useEffect, useRef } from "react";

const Faico360 = (props) => {

  const fieldRef = useRef(null);

  useEffect(() => {
    const goToCards = localStorage.getItem('goToCards');
    if(goToCards){
      console.log(goToCards)
      if(fieldRef.current){
        console.log(fieldRef)
        setTimeout(() => {
          scroll()
        }, 1000)
      }
      localStorage.removeItem('goToCards')
    }
  }, [])

  function scroll(){
    console.log("scrollIntoView")
    fieldRef.current.scrollIntoView({behavior: "smooth"})
  }


  return (
    <Styles>
      <div className="gradient"></div>
      <Image src={VioletWaveImg} className="wave"></Image>
      <div className="content-image-background">
        <Image src={BackgroundImage}></Image>
      </div>
      <div className="content-page">
        <div className="content-first-row-desktop">
          <div className="faico-text">
            <div className="title">
              <h1><Trans>faico360.self2</Trans></h1>
              <span className="dot"></span>
            </div>
            <p><Trans>faico360.landscape_body</Trans></p>
          </div>
          <div id="goToCardsAnchor" ref={fieldRef}></div>
          <div className="clinical-cases">
            <div className="title mb-5">
              <h1><Trans>faico360.clinicalCases.self</Trans></h1>
              <p><Trans>faico360.clinicalCases.description</Trans></p>
            </div>
          </div>
          <div className="content-cards container-fluid">
            <PartnerCarousel>
              {
                clinicalCases.map((c, index) => {
                  if (c.skip){
                    return <div></div>
                  }
                  return (<GalleryCard
                    code={c.code}
                    title={<RegisterMark>faico360.clinicalCases.cases.{c.number}.title</RegisterMark>}
                    index={index}
                    CarrouselImg={c.CarouselImgDesktop}
                    key={"faico-360-"+index}
                    onClick={() => navigate("/faico360/"+index)}
                  ></GalleryCard>);
                })
              }

            </PartnerCarousel>
          </div>
        </div>

        <div className="faico-extension">
          <Row className="full-width">            
              <div className="card-extension full-width" id="card-extension-1">
                <h1><Trans>faico360.FaicoConnectTitle</Trans></h1>
              </div>
              <Row className="full-width">
                <Col xl={8} lg={8} mf={8} className="align-items-center">
                  <div className="card-extension" id="card-extension-1">
                    <p><Trans>faico360.FaicoConnectText</Trans></p>
                    <p><Trans>faico360.FaicoConnectText2</Trans></p>
                    <p><Trans>faico360.FaicoConnectText3</Trans></p>
                  </div>
                </Col>
                <Col xl={4} lg={4} mf={5}>
                  <div className="card-extension">
                    <Button className="default-button" onClick={() => navigate("/contact")}>
                      <Trans>faico360.ContactButton</Trans>
                    </Button>
                  </div>
                </Col>
              </Row>
            



          </Row>
          {/*  <div className="card-extension" id="card-extension-2">
            <h1><Trans>faico360.NewsletterTitle</Trans></h1>
            <p><Trans>faico360.NewsletterText</Trans></p>
          </div>
          <div className="card-extension" id="card-extension-3">
            <h1><Trans>faico360.WorkshopTitle</Trans></h1>
            <p><Trans>faico360.WorkshopText</Trans></p>
          </div> */}
        </div>
      </div>
    </Styles>
  )
}


export default (Faico360);