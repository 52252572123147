import { createSelector } from 'reselect'

const isLoading = state => state.team.isLoading
const teamList = state => state.team.teamList
const userPost = state => state.team.post
const postIsLoading = state => state.team.postIsLoading

export const isLoadingSelector = createSelector(
	postIsLoading,
	postIsLoading => postIsLoading
)

export const postIsLoadingSelector = createSelector(
	isLoading,
	isLoading => isLoading
)

export const teamListSelector = createSelector(
	teamList,
	teamList => teamList.slice(0,3)
)

export const userPostSelector = createSelector(
	userPost,
	userPost => userPost
)
