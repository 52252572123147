/*----- Core -----*/
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

/*----- Commons -----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";
import Device from "../../commons/Device";
import { Trans } from "react-i18next";

/*----- Components -----*/
import DefaultButton from "../../components/button/DefaultButton";
import Title from "../text/Title";

/*----- Styles -----*/
const StyledDescriptionCard = styled.article`
	position: relative;
	padding: 40px;
	.img-thumbnail {
		max-width: 100px;
		min-height: 100px;
	}
	.truncate-body {
		max-height: calc(2.6rem * 3);
		overflow: hidden;
		text-align:left;
	}
	.truncate-body::before {
		content: "...";
		position: absolute;
		bottom: 10px;
		right: 15px;
	}
	.truncate-body::after {
		content: "";
		position: absolute;
		right: 0; /* note: not using bottom */
		width: 1rem;
		height: 1rem;
		background: white;
	}
	.title {
		min-height: 20px;
	}
	p {
		${Montserrat(12, 500, "black")};
	}
	@media ${Device.sm} {
		padding: 20px;
	}
	@media ${Device.xs} {
		.img-thumbnail {
			position: relative;
			top: 15px;
			right: 30px;
		}
		.default-button{
		position: relative;
		right: 15px;
		}
	}
`;

/*----- Component -----*/
const DescriptionCard = ({ title, body, imageUrl, button, buttonText, onBtnClick }) => {
	return (
		<StyledDescriptionCard className="description-card col-9 col-md-10 col-lg-5 col-xl-4">
			<div className="col-12">
				<div className="row">
					<div className="col-5 col-md-3 col-lg-4 col-xl-4">
						<img src={`${imageUrl}`} alt="" className="img-thumbnail"></img>
					</div>
					<div className="col-7 col-md-8 col-xl-7">
						<div className="title">
							<Title content={title} size={4} />
						</div>
						<p className="truncate-body">{body}</p>
						{button === true && (
							<DefaultButton content={<Trans>{buttonText || 'see_more'}</Trans>} invert={true} onClick={() => { onBtnClick() }} />
						)}
					</div>
				</div>
			</div>
		</StyledDescriptionCard>
	);
};
export default DescriptionCard;

/*----- PropTypes -----*/
DescriptionCard.propTypes = {
	body: PropTypes.object,
	title: PropTypes.object,
	imageUrl: PropTypes.string,
	button: PropTypes.bool,
	onBtnClick: PropTypes.func,
};
DescriptionCard.defaultProps = {
	onBtnClick: () => { },
}
