// Core
import React from 'react'
import styled from 'styled-components'
// Commons

const StyledTag = styled.span`
  text-align: center;
  border-radius: 30px;
  display: inline-block;
  width: fit-content;
  border: 1px solid black;
  padding:5px 15px;
  font-family: 'Montserrat', sans-serif;
  font-size:8px;
  margin:0px;
	font-weight:700;
  color:black;
`

const Tag = ({ content, invert }) => {
  return(
    <StyledTag invert={invert}>
      { content }
    </StyledTag>
  )
}
export default Tag
