import React, { useState, useEffect } from "react";
import { Image, Table, Collapse } from "react-bootstrap";
import PropTypes from "prop-types"
import { Trans } from "react-i18next";

import { TableSecondaryStyles } from "./Styles"

import Arrow from "../../../../../assets/icons/icon-components/Arrow.js"

const TableSecondary = (props) => {
  const [openModal, setOpenModal] = useState([]);

  useEffect(() => {
    let openArray = []
    for (let i = 0; i < props.modalData[0].length; i++) {
      openArray.push(false)
    }
    setOpenModal(openArray);
  }, [props.modalData]);

  function changeStateOpen(e) {
    let openArray = [];
    for (let i = 0; i < openModal.length; i++) {
      openArray.push(openModal[i])
    }
    openArray[e.target.id] = !openArray[e.target.id]
    setOpenModal(openArray);
  }
  function renderField(i) {
    let table = [];
    let j;
    for (j = 0; j < props.modalData.length - 1; j++) {
      table.push(
        <td><p>{props.modalData[j][i]}</p></td>
      )
    }
    return table;
  }
  function infoTable() {
    let table = []
    let i;
    for (i = 0; i < props.modalData[0].length; i++) {
      table.push(
        <>
          <tr className={i % 2 === 0 ? "tr-info-par" : "tr-info-impar"}>
            {renderField(i)}
            {
              props.withoutImage ?
                null
                :
                props.modalData[props.modalData.length - 1][i] !== null ?
                  <td className="pointer" id={i} aria-controls={"collapse-table-secondary-" + i} aria-expanded={openModal[i]} onClick={changeStateOpen}>
                    {(!openModal[i]) ? <Arrow id={i} rotateDeg="270" color="white" width="20px" heigth="20px" /> : ""}
                  </td>
                  :
                  <td></td>
            }
          </tr>
          {
            props.modalData[props.modalData.length - 1][i] !== null ?
              <Collapse in={openModal[i]}>
                <tr className={i % 2 === 0 ? "tr-info-par" : "tr-info-impar"} aria-controls={"collapse-table-secondary-" + i} aria-expanded={openModal[i]}>
                  <td colSpan="2" className="td-collapse">
                    <div className="line-collapse"></div>
                    <div className="div-collapse">
                      <div className="content-info-expand">
                        <Image className="img-fluid" src={props.modalData[props.modalData.length - 1][i]} ></Image>
                      </div>
                    </div>
                  </td>
                  <td className="pointer" id={i} onClick={changeStateOpen}>
                    {openModal[i] ? <Arrow id={i} rotateDeg="90" color="white" width="20px" heigth="20px" /> : ""}
                  </td>
                </tr>
              </Collapse>
              :
              null
          }
        </>
      )
    }
    return table;
  }


  return (
    <TableSecondaryStyles height={props.height}>
      <div className="table-scroll">
        <Table className="mb-0" striped bordered variant="dark" size="sm">
          <thead className="head">
            <tr className="tr-head">
              {
                props.modalHeaders.map((header, i) => {
                  return (
                    <th scope="col"><Trans>{header}</Trans></th>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {infoTable()}
          </tbody>
        </Table>
      </div>
    </TableSecondaryStyles>
  )
}

/*----- PropTypes -----*/
TableSecondary.propTypes = {
  modalData: PropTypes.array.isRequired,
  modalHeaders: PropTypes.array.isRequired,
  height: PropTypes.string
}
export default (TableSecondary)