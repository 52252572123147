import styled from "styled-components";

export default styled.main`
    background:black;
    position:relative;
    background:rgba(28,28,28,1);
    .black-gradient{
        background: linear-gradient(to bottom,rgba(28,28,28,0) 0%,rgba(28,28,28,1) 32%);
        position: absolute;
        height: 186%;
        z-index: 12;
        width: 170%;
        top: 0%;
    }
	.card-content{
		/* background: linear-gradient(to bottom left,rgba(72,71,71,0) 15%,rgba(0,0,0,1) 100%); */
        background: linear-gradient(to bottom left,rgba(64, 62, 66, 0.24) 15%,rgba(0,0,0,1) 100%);
		margin:0px !important;
		border-radius:10px !important;
        margin:auto;
		transition: transform .2s; /* Animation */
        opacity:0.9;
        z-index:50;
			@media (min-width:1500px){
				margin-top:100px !important;
				height:100%
			}
			@media (min-width:1024px) and (max-width:1500px){
				margin-top:100px !important;
				height:85%
			}
	}
	.card-content:hover{
		transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

	}
    .content-buttons{
        display:flex;
        width:100%;
        justify-content:center;
        button{
            background: transparent !important;
			width: 186px;
			height: 41px;
			z-index:15;
			color: white;
			border: 2.5px solid white;
			font-size:12px;
			font-weight:700;
			border-radius: 25px !important;
			font-family: 'Montserrat',sans-serif;
			padding:10px;
			margin:0 10%;
        }
        button:hover {
            background:white !important;
            border:1px solid black !important;
            color:black !important;
            transform: scale(1.03);
        }
        button:active{
            border: 1px solid white;
            box-shadow:none !important;
        }
        button:focus{
            border: 1px solid white;
            box-shadow:none !important;
        }
    }
	.card-body{
		display: flex;
		flex-direction: column;
        margin-top:30%;
        margin-right:5%;
        margin-left:5%;
        z-index:50;
	}
	.card-button{
		background:transparent !important;
		width:50%;
		border:1.5px solid white;
		border-radius:25px !important;
		font-family: 'Montserrat', sans-serif;
		font-weight:600;
		padding:10px 10px;
		margin-right:auto;
		margin-left:auto;

        z-index:50;
			@media (min-width:1500px){
				font-size:16px;
				margin-bottom:15px;
                margin-top: auto;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:14px;
				margin-bottom:0px;
                margin-top: auto;
			}
	}
	.card-button:hover {
		background:white !important;
		border:1.5px solid white;
		color:black;
	}
    .card-images{
        z-index:50;
        position:relative;
        display:flex;
        justify-content:center;
        .card-image-first{
            position:absolute;
            bottom:-0;
            z-index:50;
        }
        .card-image-two{
            position:absolute;
            bottom:-10px;
            z-index:50;
        }
        .card-image-three{
            position:absolute;
            bottom:-30px;
            z-index:50;
        }
        img{
            max-width:80%;
        }
    }
	.card-text{
		color:white;
        z-index:50;
		font-family: 'Montserrat', sans-serif;
			@media (min-width:1500px){
				font-size:1vw;
				margin-top:2%;
			}
			@media (min-width:1280px) and (max-width:1500px){
				font-size:14px;
				margin-top:1%;
			}
            @media (max-width:1280px){
				font-size:13px;
				margin-top:1%;
			}
	}
	.card-title{
        z-index:50;
		margin-bottom:0px;
		color:white;
		font-weight:700;
		font-family: 'Montserrat', sans-serif;
			@media (min-width:1500px){
				font-size:32px;
				margin-top:10px;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:24px;
				margin-top:0px;
			}
	}
    .container-cards{
        justify-content:space-around;
        margin-bottom:10%;
        z-index:50;
    }
    .container-page{
        margin:auto;
    }
    .filter-blur{
		filter:blur(5px);
	}
    .first-row{
        position:relative;
        width:100%;
        height:100vh;
        .images-central{
            padding-top:70px;
            display:flex;
            justify-content:center;
            position:relative;
            width:50%;
            padding-bottom: 7%;
            .img-central{
                position:absolute;
                z-index:11;
                max-width:50%;
            }
        }
    }
    .onda-gris-reverse{
        position: absolute;
        z-index: 15;
        left: -6%;
        bottom: -16%;
        transform: scaleX(-1);
    }
    .onda-gris{
        position: absolute;
        z-index: 10;
        top: 0%;
        right: -10%;
    }
    .pop-up-style-content{
        padding:0px !important;
        width:100% !important;
        background: linear-gradient(63deg, #484848 , #2F2F2F) !important;
        opacity:88%;
        border:0px solid !important;
        position:relative;
        overflow:hidden;
        border-radius:25px;
        margin:60px 113px 60px 114px !important;
	}

    .filter-blur-footer{
        width: 100%;
        height: 5000px;
        position: relative;
        bottom: 0%;
    }
    .second-gradient{
        background: linear-gradient(to bottom,rgba(0,0,0,1) 5%,rgba(0,0,0,1) 10%,rgba(50,50,50,0.7) 25%,rgba(45,45,45,1) 80%,rgba(0,0,0,0.8) );
        width: 100%;
        height: 50%;
        z-index: 14;
        position: absolute;
        top: 50%;
    }
    .second-row{
        position:relative;
        z-index:50;
    }
    .titles{
        top:39%;
        z-index:50;
        text-align:center;
        margin-top:-5%;
        z-index:50;
        h1{
            font-family: 'Montserrat', sans-serif;
            color:#EBEBEB;
            font-weight: 700;
            display:block;
            @media (min-width:1500px) {
                padding-right:13%;
                padding-left:13%;
                font-size:45px;
            }
            @media (max-width:1500px) {
                padding-right:5%;
                padding-left:5%;
                font-size:35px;
            }
        }
        h2{
            font-family: 'Montserrat', sans-serif;
            color:#F8F8F8;
            margin-top:20px;
            display:block;
            @media (min-width:1500px) {
                font-size:26px;
                padding-right:300px;
                padding-left:300px;
            }
        }
    }
`
