import { Styles } from "./Styles";
import PropTypes from "prop-types"
import React from "react";
import { Button, Col, Card } from "react-bootstrap";
import { navigate } from "hookrouter";
import { Trans } from "react-i18next";
import RegisterMark from '../../../registerMark';

const CardModal = (props) => {
	return (
		<Styles>
			{
				props.card_titles.map((card, i) => {
					return (
						<Col md={4} key={i} className="col-card">
							<Card className="card-content">
								<Card.Body className="card-body">
									<Card.Title className="card-title"><Trans>{card}</Trans><span className="dot-span">.</span> </Card.Title>
									<Card.Text className="card-text">
										<RegisterMark>{props.card_contents[i]}</RegisterMark>
									</Card.Text>
									<Button
										variant="primary card-button"
										className="align-middle"
										onClick={() => navigate(props.card_navigates !== "" ? props.card_navigates[i] : "")}
									><Trans>general.ver-mas</Trans></Button>
								</Card.Body>
							</Card>
						</Col>
					)
				})
			}
		</Styles>
	)
}

/*----- PropTypes -----*/
CardModal.propTypes = {
	card_titles: PropTypes.array.isRequired,
	card_contents: PropTypes.array.isRequired,
	card_navigates: PropTypes.array.isRequired,
}
export default (CardModal)