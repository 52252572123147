import React from 'react'
import { Row, Col, Image, Button, Card } from "react-bootstrap";
import { navigate } from 'hookrouter';
import Styles from "./Styles.js";
import { Trans } from "react-i18next";

import ImageCentral from "../../../../assets/images/Dental/internalHexagonConnection/smartImplants/images-central/image-central-crop3.png"
import ImageCardOne from "../../../../assets/images/Dental/internalHexagonConnection/smartImplants/cards/card-image-one.png";
import ImageCardTwo from "../../../../assets/images/Dental/internalHexagonConnection/smartImplants/cards/card-image-two.png";
import ImageCardThree from "../../../../assets/images/Dental/internalHexagonConnection/smartImplants/cards/card-image-three.png";
import VioletWaveImg from "../../../../assets/images/onda-violeta-2.png";

import SmartImplantsModal from "../smartImplantsModal/index";
import { Modal } from "../../../../components/modals/Modal.js";
import RegisterMark from '../../../../components/registerMark/index.js';

const ModalContainer = Modal(SmartImplantsModal)

class SmartImplants extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isModal: false,
			actualModal: 0
		};
		this.openModal = this.openModal.bind(this);
	}
	scrollModal(modal) {
		if (!modal) {
			document.body.classList.add('scrollModal')
		} else {
			document.body.classList.remove('scrollModal')
		}
	}
	openModal(modal) {
		this.scrollModal(this.state.isModal)
		this.setState({
			isModal: !this.state.isModal,
			actualModal: modal
		});
	}
	render() {
		return (
			<Styles>
				<div className={(this.state.isModal ? "filter-blur" : "")}>
					<Image src={VioletWaveImg} className="onda-gris-reverse img-fluid"></Image>

					<div className="black-gradient"></div>
					<Image src={VioletWaveImg} className="onda-gris img-fluid"></Image>
					<Col md={10} className="container-page">
						<Row className="first-row justify-content-center">
							<div className="black-gradient"></div>
							<div className="images-central justify-content-center">
								<Image src={`${ImageCentral}`} className="img-central img-fluid"></Image>
							</div>
							<div className="titles">
								<h1><Trans>IHCsmartImplants.title</Trans></h1>
								<h2><RegisterMark>IHCsmartImplants.subtitle</RegisterMark></h2>
							</div>
						</Row>
						<Row className="second-row">
							<Row className="container-cards">
								<Col md={4}>
									<Card className="card-content">
										<Card.Body className="card-body">
											<div className="card-images">
												<Image src={`${ImageCardOne}`} className="card-image-first img-fluid"></Image>
											</div>
											<Card.Title className="card-title"><RegisterMark>IHCsmartImplants.card_1.title</RegisterMark></Card.Title>
											<Card.Text className="card-text">
												<RegisterMark>IHCsmartImplants.card_1.content_1</RegisterMark>
											</Card.Text>
											<Card.Text className="card-text">
												<Trans>IHCsmartImplants.card_1.content_2</Trans>
											</Card.Text>

											<Button
												variant="primary card-button"
												className="align-middle"
												onClick={() => {
													this.openModal(1)
												}}
											><Trans>general.ver-mas</Trans></Button>
										</Card.Body>
									</Card>
								</Col>
								<Col md={4}>
									<Card className="card-content">
										<Card.Body className="card-body">
											<div className="card-images">
												<Image src={`${ImageCardTwo}`} className="card-image-two  img-fluid"></Image>
											</div>
											<Card.Title className="card-title"><RegisterMark>IHCsmartImplants.card_2.title</RegisterMark></Card.Title>
											<Card.Text className="card-text">
												<Trans>IHCsmartImplants.card_2.content_1</Trans>
											</Card.Text>
											<Button
												variant="primary card-button"
												className="align-middle"
												onClick={() => {
													this.openModal(2)
												}}
											><Trans>general.ver-mas</Trans></Button>
										</Card.Body>
									</Card>
								</Col>
								<Col md={4}>
									<Card className="card-content">
										<Card.Body className="card-body">
											<div className="card-images">
												<Image src={`${ImageCardThree}`} className="card-image-three  img-fluid"></Image>
											</div>
											<Card.Title className="card-title"><RegisterMark>IHCsmartImplants.card_3.title</RegisterMark></Card.Title>
											<Card.Text className="card-text">
												<Trans>IHCsmartImplants.card_3.content_1</Trans>
											</Card.Text>
											<Card.Text className="card-text">
												<Trans>IHCsmartImplants.card_3.content_2</Trans>
											</Card.Text>

											<Button
												variant="primary card-button"
												className="align-middle"
												onClick={() => {
													this.openModal(3)
												}}
											><Trans>general.ver-mas</Trans></Button>
										</Card.Body>
									</Card>
								</Col>
							</Row>
							<div className="content-buttons mb-5">
								<Button onClick={() => navigate('/dental/internalHexagonConnection/prostheticComponents')}><Trans>dental.conexionHexagono_modal.card_2.title</Trans></Button>
								<Button onClick={() => navigate('/dental/internalHexagonConnection/surgicalInstruments')}><Trans>dental.conexionHexagono_modal.card_3.title</Trans></Button>
							</div>
						</Row>
					</Col>
				</div>
				<div className={(this.state.isModal ? "filter-blur-footer" : "")}></div>
				<ModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={this.openModal}
					modal={this.state.actualModal}
					open={this.state.isModal}
				/>
			</Styles>
		);
	}
}

export default (SmartImplants)