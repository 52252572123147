export const dataSection_1 = [
  ["MEDS-1.5-S-3.0-TI","MEDS-1.5-S-4.0-TI","MEDS-1.5-S-5.0-TI","MEDS-1.5-S-6.0-TI","MEDS-1.5-S-7.0-TI","MEDS-1.5-S-8.0-TI","MEDS-1.5-S-9.0-TI","MEDS-1.5-S-10.0-TI","MEDS-1.5-S-11.0-TI","MEDS-1.5-S-12.0-TI","MEDS-1.5-S-13.0-TI","MEDS-1.5-S-14.0-TI","MEDS-1.5-S-15.0-TI","MEDS-1.5-S-16.0-TI","MEDS-1.5-S-17.0-TI","MEDS-1.5-S-18.0-TI","MEDS-1.5-S-19.0-TI","MEDS-1.5-S-20.0-TI",],
  [" Ø1.5 x 3 mm."," Ø1.5 x 4 mm."," Ø1.5 x 5 mm."," Ø1.5 x 6 mm."," Ø1.5 x 7 mm."," Ø1.5 x 8 mm."," Ø1.5 x 9 mm."," Ø1.5 x 10 mm."," Ø1.5 x 11 mm."," Ø1.5 x 12 mm."," Ø1.5 x 13 mm."," Ø1.5 x 14 mm."," Ø1.5 x 15 mm."," Ø1.5 x 16 mm."," Ø1.5 x 17 mm."," Ø1.5 x 18 mm."," Ø1.5 x 19 mm."," Ø1.5 x 20 mm."]
]
export const dataSection_2 = [
  ["MEDS-1.5-C-3.0-TI-SC","MEDS-1.5-C-4.0-TI-SC","MEDS-1.5-C-5.0-TI-SC","MEDS-1.5-C-6.0-TI-SC","MEDS-1.5-C-7.0-TI-SC","MEDS-1.5-C-8.0-TI-SC","MEDS-1.5-C-9.0-TI-SC","MEDS-1.5-C-10.0-TI-SC","MEDS-1.5-C-11.0-TI-SC","MEDS-1.5-C-12.0-TI-SC","MEDS-1.5-C-13.0-TI-SC","MEDS-1.5-C-14.0-TI-SC","MEDS-1.5-C-15.0-TI-SC","MEDS-1.5-C-16.0-TI-SC","MEDS-1.5-C-17.0-TI-SC","MEDS-1.5-C-18.0-TI-SC","MEDS-1.5-C-19.0-TI-SC","MEDS-1.5-C-20.0-TI-SC"],
  [" Ø1.5 x 3 mm."," Ø1.5 x 4 mm."," Ø1.5 x 5 mm."," Ø1.5 x 6 mm."," Ø1.5 x 7 mm."," Ø1.5 x 8 mm."," Ø1.5 x 9 mm."," Ø1.5 x 10 mm."," Ø1.5 x 11 mm."," Ø1.5 x 12 mm."," Ø1.5 x 13 mm."," Ø1.5 x 14 mm."," Ø1.5 x 15 mm."," Ø1.5 x 16 mm."," Ø1.5 x 17 mm."," Ø1.5 x 18 mm."," Ø1.5 x 19 mm."," Ø1.5 x 20 mm."]
]
export const dataSection_3 = [
  ["MEDS-1.5-C-3.0-TI-SCD","MEDS-1.5-C-4.0-TI-SCD","MEDS-1.5-C-5.0-TI-SCD","MEDS-1.5-C-6.0-TI-SCD","MEDS-1.5-C-7.0-TI-SCD","MEDS-1.5-C-8.0-TI-SCD","MEDS-1.5-C-9.0-TI-SCD","MEDS-1.5-C-10.0-TI-SCD","MEDS-1.5-C-11.0-TI-SCD","MEDS-1.5-C-12.0-TI-SCD","MEDS-1.5-C-13.0-TI-SCD","MEDS-1.5-C-14.0-TI-SCD","MEDS-1.5-C-15.0-TI-SCD","MEDS-1.5-C-16.0-TI-SCD","MEDS-1.5-C-17.0-TI-SCD","MEDS-1.5-C-18.0-TI-SCD","MEDS-1.5-C-19.0-TI-SCD","MEDS-1.5-C-20.0-TI-SCD"],
  [" Ø1.5 x 3 mm."," Ø1.5 x 4 mm."," Ø1.5 x 5 mm."," Ø1.5 x 6 mm."," Ø1.5 x 7 mm."," Ø1.5 x 8 mm."," Ø1.5 x 9 mm."," Ø1.5 x 10 mm."," Ø1.5 x 11 mm."," Ø1.5 x 12 mm."," Ø1.5 x 13 mm."," Ø1.5 x 14 mm."," Ø1.5 x 15 mm."," Ø1.5 x 16 mm."," Ø1.5 x 17 mm."," Ø1.5 x 18 mm."," Ø1.5 x 19 mm."," Ø1.5 x 20 mm."]
]
export const dataSection_4 =[
  ["MEDS-1.5-Q-3.0-TI-SC", "MEDS-1.5-Q-4.0-TI-SC", "MEDS-1.5-Q-5.0-TI-SC", "MEDS-1.5-Q-6.0-TI-SC", "MEDS-1.5-Q-7.0-TI-SC", "MEDS-1.5-Q-8.0-TI-SC", "MEDS-1.5-Q-9.0-TI-SC", "MEDS-1.5-Q-10.0-TI-SC", "MEDS-1.5-Q-11.0-TI-SC", "MEDS-1.5-Q-12.0-TI-SC", "MEDS-1.5-Q-13.0-TI-SC", "MEDS-1.5-Q-14.0-TI-SC", "MEDS-1.5-Q-15.0-TI-SC", "MEDS-1.5-Q-16.0-TI-SC", "MEDS-1.5-Q-17.0-TI-SC", "MEDS-1.5-Q-18.0-TI-SC", "MEDS-1.5-Q-19.0-TI-SC", "MEDS-1.5-Q-20.0-TI-SC"],
  [" Ø1.5 x 3 mm."," Ø1.5 x 4 mm."," Ø1.5 x 5 mm."," Ø1.5 x 6 mm."," Ø1.5 x 7 mm."," Ø1.5 x 8 mm."," Ø1.5 x 9 mm."," Ø1.5 x 10 mm."," Ø1.5 x 11 mm."," Ø1.5 x 12 mm."," Ø1.5 x 13 mm."," Ø1.5 x 14 mm."," Ø1.5 x 15 mm."," Ø1.5 x 16 mm."," Ø1.5 x 17 mm."," Ø1.5 x 18 mm."," Ø1.5 x 19 mm."," Ø1.5 x 20 mm."]
]
export const dataSection_5 =[
  ["MEDS-1.7-ES-3.0-TI", "MEDS-1.7-ES-4.0-TI", "MEDS-1.7-ES-5.0-TI", "MEDS-1.7-ES-6.0-TI", "MEDS-1.7-ES-7.0-TI", "MEDS-1.7-ES-8.0-TI", "MEDS-1.7-ES-9.0-TI", "MEDS-1.7-ES-10.0-TI", "MEDS-1.7-ES-11.0-TI", "MEDS-1.7-ES-12.0-TI", "MEDS-1.7-ES-13.0-TI", "MEDS-1.7-ES-14.0-TI", "MEDS-1.7-ES-15.0-TI", "MEDS-1.7-ES-16.0-TI", "MEDS-1.7-ES-17.0-TI", "MEDS-1.7-ES-18.0-TI", "MEDS-1.7-ES-19.0-TI", "MEDS-1.7-ES-20.0-TI"],
  ["Ø1.7 x 3 mm.", "Ø1.7 x 4 mm.", "Ø1.7 x 5 mm.", "Ø1.7 x 6 mm.", "Ø1.7 x 7 mm.", "Ø1.7 x 8 mm.", "Ø1.7 x 9 mm.", "Ø1.7 x 10 mm.", "Ø1.7 x 11 mm.", "Ø1.7 x 12 mm.", "Ø1.7 x 13 mm.", "Ø1.7 x 14 mm.", "Ø1.7 x 15 mm.", "Ø1.7 x 16 mm.", "Ø1.7 x 17 mm.", "Ø1.7 x 18 mm.", "Ø1.7 x 19 mm.", "Ø1.7 x 20 mm."]
]
export const dataSection_6 = [
  ["MEDS-1.7-EC-3.0-TI-SC", "MEDS-1.7-EC-4.0-TI-SC", "MEDS-1.7-EC-5.0-TI-SC", "MEDS-1.7-EC-6.0-TI-SC", "MEDS-1.7-EC-7.0-TI-SC", "MEDS-1.7-EC-8.0-TI-SC", "MEDS-1.7-EC-9.0-TI-SC", "MEDS-1.7-EC-10.0-TI-SC", "MEDS-1.7-EC-11.0-TI-SC", "MEDS-1.7-EC-12.0-TI-SC", "MEDS-1.7-EC-13.0-TI-SC", "MEDS-1.7-EC-14.0-TI-SC", "MEDS-1.7-EC-15.0-TI-SC", "MEDS-1.7-EC-16.0-TI-SC", "MEDS-1.7-EC-17.0-TI-SC", "MEDS-1.7-EC-18.0-TI-SC", "MEDS-1.7-EC-19.0-TI-SC", "MEDS-1.7-EC-20.0-TI-SC"],
  ["Ø1.7 x 3 mm.", "Ø1.7 x 4 mm.", "Ø1.7 x 5 mm.", "Ø1.7 x 6 mm.", "Ø1.7 x 7 mm.", "Ø1.7 x 8 mm.", "Ø1.7 x 9 mm.", "Ø1.7 x 10 mm.", "Ø1.7 x 11 mm.", "Ø1.7 x 12 mm.", "Ø1.7 x 13 mm.", "Ø1.7 x 14 mm.", "Ø1.7 x 15 mm.", "Ø1.7 x 16 mm.", "Ø1.7 x 17 mm.", "Ø1.7 x 18 mm.", "Ø1.7 x 19 mm.", "Ø1.7 x 20 mm."]
]
export const dataSection_7 = [
  ["MEDS-1.7-C-3.0-TI-SCD", "MEDS-1.7-C-4.0-TI-SCD", "MEDS-1.7-C-5.0-TI-SCD", "MEDS-1.7-C-6.0-TI-SCD", "MEDS-1.7-C-7.0-TI-SCD", "MEDS-1.7-C-8.0-TI-SCD", "MEDS-1.7-C-9.0-TI-SCD", "MEDS-1.7-C-10.0-TI-SCD", "MEDS-1.7-C-11.0-TI-SCD", "MEDS-1.7-C-12.0-TI-SCD", "MEDS-1.7-C-13.0-TI-SCD", "MEDS-1.7-C-14.0-TI-SCD", "MEDS-1.7-C-15.0-TI-SCD", "MEDS-1.7-C-16.0-TI-SCD", "MEDS-1.7-C-17.0-TI-SCD", "MEDS-1.7-C-18.0-TI-SCD", "MEDS-1.7-C-19.0-TI-SCD", "MEDS-1.7-C-20.0-TI-SCD"],
  ["Ø1.7 x 3 mm.", "Ø1.7 x 4 mm.", "Ø1.7 x 5 mm.", "Ø1.7 x 6 mm.", "Ø1.7 x 7 mm.", "Ø1.7 x 8 mm.", "Ø1.7 x 9 mm.", "Ø1.7 x 10 mm.", "Ø1.7 x 11 mm.", "Ø1.7 x 12 mm.", "Ø1.7 x 13 mm.", "Ø1.7 x 14 mm.", "Ø1.7 x 15 mm.", "Ø1.7 x 16 mm.", "Ø1.7 x 17 mm.", "Ø1.7 x 18 mm.", "Ø1.7 x 19 mm.", "Ø1.7 x 20 mm."]
]
export const dataSection_8 = [
  ["MEDS-1.7-EQ-3.0-TI-SC", "MEDS-1.7-EQ-4.0-TI-SC", "MEDS-1.7-EQ-5.0-TI-SC", "MEDS-1.7-EQ-6.0-TI-SC", "MEDS-1.7-EQ-7.0-TI-SC", "MEDS-1.7-EQ-8.0-TI-SC", "MEDS-1.7-EQ-9.0-TI-SC", "MEDS-1.7-EQ-10.0-TI-SC", "MEDS-1.7-EQ-11.0-TI-SC", "MEDS-1.7-EQ-12.0-TI-SC", "MEDS-1.7-EQ-13.0-TI-SC", "MEDS-1.7-EQ-14.0-TI-SC", "MEDS-1.7-EQ-15.0-TI-SC", "MEDS-1.7-EQ-16.0-TI-SC", "MEDS-1.7-EQ-17.0-TI-SC", "MEDS-1.7-EQ-18.0-TI-SC", "MEDS-1.7-EQ-19.0-TI-SC", "MEDS-1.7-EQ-20.0-TI-SC"],
  ["Ø1.7 x 3 mm.", "Ø1.7 x 4 mm.", "Ø1.7 x 5 mm.", "Ø1.7 x 6 mm.", "Ø1.7 x 7 mm.", "Ø1.7 x 8 mm.", "Ø1.7 x 9 mm.", "Ø1.7 x 10 mm.", "Ø1.7 x 11 mm.", "Ø1.7 x 12 mm.", "Ø1.7 x 13 mm.", "Ø1.7 x 14 mm.", "Ø1.7 x 15 mm.", "Ø1.7 x 16 mm.", "Ø1.7 x 17 mm.", "Ø1.7 x 18 mm.", "Ø1.7 x 19 mm.", "Ø1.7 x 20 mm."]
]
export const dataSection_9 = [
  ["MEDS-1.9-ES-3.0-TI", "MEDS-1.9-ES-4.0-TI", "MEDS-1.9-ES-5.0-TI", "MEDS-1.9-ES-6.0-TI", "MEDS-1.9-ES-7.0-TI", "MEDS-1.9-ES-8.0-TI", "MEDS-1.9-ES-9.0-TI", "MEDS-1.9-ES-10.0-TI", "MEDS-1.9-ES-11.0-TI", "MEDS-1.9-ES-12.0-TI", "MEDS-1.9-ES-13.0-TI", "MEDS-1.9-ES-14.0-TI", "MEDS-1.9-ES-15.0-TI", "MEDS-1.9-ES-16.0-TI", "MEDS-1.9-ES-17.0-TI", "MEDS-1.9-ES-18.0-TI", "MEDS-1.9-ES-19.0-TI", "MEDS-1.9-ES-20.0-TI"],
  ["Ø1.9 x 3 mm", "Ø1.9 x 4 mm", "Ø1.9 x 5 mm", "Ø1.9 x 6 mm", "Ø1.9 x 7 mm", "Ø1.9 x 8 mm", "Ø1.9 x 9 mm", "Ø1.9 x 10 mm", "Ø1.9 x 11 mm", "Ø1.9 x 12 mm", "Ø1.9 x 13 mm", "Ø1.9 x 14 mm", "Ø1.9 x 15 mm", "Ø1.9 x 16 mm", "Ø1.9 x 17 mm", "Ø1.9 x 18 mm", "Ø1.9 x 19 mm", "Ø1.9 x 20 mm"]
]
export const dataSection_10 = [
  ["MEDS-1.9-EC-3.0-TI-SC", "MEDS-1.9-EC-4.0-TI-SC", "MEDS-1.9-EC-5.0-TI-SC", "MEDS-1.9-EC-6.0-TI-SC", "MEDS-1.9-EC-7.0-TI-SC", "MEDS-1.9-EC-8.0-TI-SC", "MEDS-1.9-EC-9.0-TI-SC", "MEDS-1.9-EC-10.0-TI-SC", "MEDS-1.9-EC-11.0-TI-SC", "MEDS-1.9-EC-12.0-TI-SC", "MEDS-1.9-EC-13.0-TI-SC", "MEDS-1.9-EC-14.0-TI-SC", "MEDS-1.9-EC-15.0-TI-SC", "MEDS-1.9-EC-16.0-TI-SC", "MEDS-1.9-EC-17.0-TI-SC", "MEDS-1.9-EC-18.0-TI-SC", "MEDS-1.9-EC-19.0-TI-SC", "MEDS-1.9-EC-20.0-TI-SC"],
  ["Ø1.9 x 3 mm", "Ø1.9 x 4 mm", "Ø1.9 x 5 mm", "Ø1.9 x 6 mm", "Ø1.9 x 7 mm", "Ø1.9 x 8 mm", "Ø1.9 x 9 mm", "Ø1.9 x 10 mm", "Ø1.9 x 11 mm", "Ø1.9 x 12 mm", "Ø1.9 x 13 mm", "Ø1.9 x 14 mm", "Ø1.9 x 15 mm", "Ø1.9 x 16 mm", "Ø1.9 x 17 mm", "Ø1.9 x 18 mm", "Ø1.9 x 19 mm", "Ø1.9 x 20 mm"]
]
export const dataSection_11 = [
  ["MEDS-1.9-EQ-3.0-TI-SC", "MEDS-1.9-EQ-4.0-TI-SC", "MEDS-1.9-EQ-5.0-TI-SC", "MEDS-1.9-EQ-6.0-TI-SC", "MEDS-1.9-EQ-7.0-TI-SC", "MEDS-1.9-EQ-8.0-TI-SC", "MEDS-1.9-EQ-9.0-TI-SC", "MEDS-1.9-EQ-10.0-TI-SC", "MEDS-1.9-EQ-11.0-TI-SC", "MEDS-1.9-EQ-12.0-TI-SC", "MEDS-1.9-EQ-13.0-TI-SC", "MEDS-1.9-EQ-14.0-TI-SC", "MEDS-1.9-EQ-15.0-TI-SC", "MEDS-1.9-EQ-16.0-TI-SC", "MEDS-1.9-EQ-17.0-TI-SC", "MEDS-1.9-EQ-18.0-TI-SC", "MEDS-1.9-EQ-19.0-TI-SC", "MEDS-1.9-EQ-20.0-TI-SC"],
  ["Ø1.9 x 3 mm", "Ø1.9 x 4 mm", "Ø1.9 x 5 mm", "Ø1.9 x 6 mm", "Ø1.9 x 7 mm", "Ø1.9 x 8 mm", "Ø1.9 x 9 mm", "Ø1.9 x 10 mm", "Ø1.9 x 11 mm", "Ø1.9 x 12 mm", "Ø1.9 x 13 mm", "Ø1.9 x 14 mm", "Ø1.9 x 15 mm", "Ø1.9 x 16 mm", "Ø1.9 x 17 mm", "Ø1.9 x 18 mm", "Ø1.9 x 19 mm", "Ø1.9 x 20 mm"]
]