import styled from "styled-components";

const TableStyles = styled.div`
	div::-webkit-scrollbar {
		width: 5px;
    	height:10px;
		background:#5F5F5F;
	}
	div::-webkit-scrollbar-thumb {
		background-color: #909090;
	}
	.table-scroll{
		position: relative;
		overflow: auto;
		display: block;
		height:500px !important;
		border-radius:5px;
		text-align:center;

		@media (min-width:1280px) and (max-width:1500px){
			height:480px !important;
		}
		@media (max-width:1280px){
			height:500px !important;
		}
	}
	ul{
		padding:0;
		width: max-content;
		margin:auto;
	}
	li{
		list-style: none;
		text-align:center;
		@media (min-width:1280px) and (max-width:1500px){
			font-size:14px;
		}
		@media (max-width:1280px){
			font-size:14px;
		}
	}
	.li-split-center{
		text-align:center;
	}
	.li-split-justify{
		text-align:justify;	
	}
	.head{
		background:#4A4949;
	}
	.list-value{

	}
	.tr-head{
		font-size:16px;
		font-family: 'Montserrat', sans-serif;
		@media (max-width:1280px){
			font-size:14px;
		}
		th{
			padding: 5px;
			background:#4A4949;
			text-align: center;
			border-right:1px solid #707070;
			border-bottom:0px solid transparent;
			border-left:0px solid transparent;
			border-top:0px solid transparent;
			vertical-align: middle;
			position: sticky; top: 0;
		}
		.first{
			width:15%;
		}
		.second{
			width:15%
		}
		.third{
			width:15%;
		}
		.fourth{
			width:15%;
		}
		.fifth{
			width:20%;
			border-right: 1px solid transparent;
		}
	}
	.tr-info-par{
		height:100px;
		td{
			vertical-align: middle;
			background:#2C2C2C;
			border:1px solid #2C2C2C;
		}
	}
	.tr-info-impar{
		height:100px;
		td{
			vertical-align: middle;
			background:#484747;
			border:1px solid #484747;
		}	
	}

`

export default TableStyles