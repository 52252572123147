import { put,takeLatest } from 'redux-saga/effects'
import { 
    FETCH_NEWS,
    SUCCESS_NEWS
} from '../NewConst'
// Api
import PostsApi  from '../../../../services/PostsApi';

function* fetchNews(action){
    const data = yield PostsApi(action.params)
    .then(posts => {
        return posts
    })
    .catch(e=>{
        return e.message
    })
    yield put({type: SUCCESS_NEWS, payload: data})
}


export default function* fetchListWatcher() {
	yield takeLatest(FETCH_NEWS, fetchNews)
}