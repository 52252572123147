import React from 'react';
import { Trans } from 'react-i18next';
import { Image, div } from 'react-bootstrap'

import AccordionDentalNavigation from '../../../../components/accordion/AccordionDentalNavigation';

import VioletWave from '../../../../assets/images/Dental/LevelOneMobile/onda-violeta.png';

import systems from '../systems';
import SmartImplantStyles from './Styles';

import BackButton from '../../../../components/button/BackButton';
import RegisterMark from '../../../../components/registerMark';

const References = ({reference, name}) => {
	let render = []
	const length = (reference === 2 && name === 'IHCsmartImplants') ? 6 : 8
	for (let i = 1; i < length; i++) {
		render.push(
			<div className="reference-content">
				<div className="reference-title">
					<span className="circles">{`${i}`}</span>
					<h4><Trans>{`${name}`}.modals.modal_{`${reference}`}.card_{`${i}`}.title_mobile</Trans></h4>
				</div>
				<h5><Trans>{`${name}`}.modals.modal_{`${reference}`}.card_{`${i}`}.text</Trans></h5>
			</div>
		)
	}
	return render;
}

const SmartImplant = ({image, reference, system, short,isFour}) => {
	let imageClass=''
	if (short){
		imageClass = 'image-short'
	} else {
		if (isFour){
		imageClass = 'image-isFour'
		} else {
			imageClass = 'image'
		}
	}
	const name = system === 'conicalConnection' ? 'CCsmartImplants' : 'IHCsmartImplants'
	return (
		<SmartImplantStyles id="smart-implant">
			<div className="navigation">
				<div className="title-content">
					<h1><Trans>dental.self</Trans></h1>
					<span className="dot"></span>
				</div>
				<div className="accordion-content">
					<AccordionDentalNavigation
						systems={systems}
						system={system}
					/>
				</div>
				<BackButton path={`/dental/${system}`}  />
			</div>
			<div className="title-info">
				<h1><Trans>{`${name}`}.title</Trans></h1>
			</div>
			
			<div className="image-content">
			
				<h2><RegisterMark>{`${name}`}.card_{`${reference}`}.title</RegisterMark></h2>
				<Image src={image} className={imageClass} />
				<Image src={VioletWave} className="wave" />
			</div>
			<div className="references">
				<References reference={reference} name={name}/>
			</div>
			<BackButton path={`/dental/${system}`} />
		</SmartImplantStyles >
	)
}

export default SmartImplant;