import styled from "styled-components";

export const DesktopStyles = styled.div`
  background: transparent linear-gradient(to bottom, #8958FF 0%, #25144D 17%, #0c041f 100%) 0% 0% no-repeat padding-box;
  position:relative;
  .content-image{
    width:100%;
    height:100%;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
     img{
       width:100%;
       position:relative;
       top:-19%;
     }
  }
  .content-text{
    height:100%;
    width:100%;
    z-index:100;
    position:relative;
    padding:182px 140px 182px 140px;
    .content-title{
      .title{
        text-align:left;
        display:flex;
        align-items:flex-end;
        h1{
          color:white;
          font-size:61px;
          font-family: 'Montserrat', sans-serif;
          font-weight:700;
          margin:0px;
          height:min-content;
        }
        .dot{
          height: 10px;
          width: 10px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 2px;
        }
      }
      p{
        color:white;
        text-align:left;
        font-family: 'Montserrat', sans-serif;
        font-weight:300;
        font-size:26px;
        line-height:36px;
        width:678px;
        margin-top:38px;
      }
    }
    iframe{
      z-index:100;
    }
    .content-videos{
      display:flex;
      flex-direction:column;
      .videos{
        margin-top:40px;
        @media screen and (max-width:1440px){
          display:flex;
        }
        iframe:first-child{
          margin-right:40px;
        }
        iframe:last-child{
          margin-left:40px;
        }
      }
      .videos:first-child{
        margin-top:120px;
      }
    }
    .content-button{
      display:flex;
      justify-content:center;
      margin-top:125px;
      button{
        background: transparent !important;
        z-index: 50;
        color: white;
        border: 2.5px solid white;
        font-size: 17px;
        font-weight: 700;
        font-family: 'Montserrat',sans-serif;
        border-radius: 25px;
        z-index:100;
        width: 250px;
        height: 53px;
      }
      button:hover{
        background: white !important;
        border: 1.5px solid white;
        color: black;
      }
    }
  }
`;

export const MobileStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: transparent linear-gradient(to bottom, #6F3CEA 0%, #25144D 18%, #000000 100%) 0% 0% no-repeat padding-box;
  position:relative;
  min-height:60vh;
  .content-image{
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
     img{
       width:100%;
       position:absolute;
       top: 10%;
     }
  }
  .content-text{
    padding: 62px 96px 0px 38px;
    margin-bottom: 40px;
    .content-title{
      .title{
        text-align:left;
        display:flex;
        align-items:flex-end;
        margin-bottom: 13px;
        h1{
          color:white;
          font-size:32px;
          font-weight:700;
          margin:0px;
        }
        .dot{
          height: 8px;
          width: 8px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 7px;
          margin-left: 3px;
        }
      }
      p{
        color:#F8F8F8;
        text-align:left;
        font-weight:300;
        font-size:15px;
        line-height:19px;
      }
    }
  }
  .content-videos{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 23px;
  }

  .content-button{
      display:flex;
      justify-content:center;
      margin-top:20px;
      margin-bottom: 90px;
      button{
        background: transparent !important;
        color: white;
        border: 2.5px solid white;
        font-size: 12px;
        font-weight: 700;
        border-radius: 25px;
        width: 222px;
        height: 37px;
      }
      button:hover{
        background: white !important;
        border: 1px solid white;
        color: black;
      }
    }
`;

export const ContentVideos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 120px;
`;

export const Video = styled.div`
  margin-bottom: 30px;
`;
