import styled from 'styled-components'

const SmartImplantsStyles = styled.div`
  padding-bottom: 60px;
  .system-text{
    margin:20px 0px 0px 0px;
    p{
      color:white;
      font-size:14px;
      line-height:16px;
      font-weight:400;
    }
  }
  .react-multi-carousel-list{
    z-index:30;
    padding-bottom: 5%;
  }
  .content-card-mobile{
    padding-left:25px;  
    margin-top: 40px;
  }
  .react-multi-carousel-item{
    padding-right:5px;
  }
  .react-multi-carousel-dot button{
    background:#6A5BC8;
    margin-left:10px;
    border:none;
  }
  .react-multi-carousel-dot--active button{
    background:#49D3EA;
  }
  .smart-implant-card{
    width:273px;
    background:black;
    border-radius:30px;
    margin-top:100px;
    padding:30% 23px 0px 23px;
    margin-left:25px;
    display:flex;
    flex-direction:column;
    align-items: center;
    position:relative;
    h1{
      font-size:20px;
      margin:0;
      color:white;
      font-weight:700;
      width: 100%;
      text-align: left;
      z-index: 100;
    }
    p{
      margin:10px 0px 0px 0px;
      color:white;
      font-size:13px;
      line-height:16px;
      font-weight:500;
      z-index: 100;
    }
    .content-button{
      width:100%;
      display:flex;
      justify-content:center;
      button{
        background: transparent !important;
        margin:20px 0px;
        width: 146px;
        height: 34px;
        border: 1px solid white;
        border-radius: 25px !important;
        font-family: 'Montserrat',sans-serif;
        font-size:12px;
        font-weight: 700;
        z-index: 50;
        color:white;
      }
    }
    img{
      position:absolute;
    }
    .isFourCC{
      top: -15%;
      width: 60%;
    }
    .cyrothCC{
      top: -19%;
      max-width:175px;
    }
    .cyrothIHC{
      top: -21%;
      max-width:180px;
    }
    .cyrothShort{
      top:-30px;
      width: 107px;
    }
    .isFourIHC{
      top: -16%;
      width: 166px;
    }
  }
`
export default SmartImplantsStyles;