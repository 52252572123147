import React from 'react'
import LevelOne from "../maxillofacial/desktop/LevelOne";
import Tornillos from "../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/level1/low-tornillos.png"
import Placas from "../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/level1/low-placa.png"
import Instrumental from "../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/level1/low-instrumental.png"
import Sets from "../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/level1/low-set(1).png"
const MiniplatingLowProfile = (props) => {
	return (
		<LevelOne
			title={"maxillofacial.miniplatingLowProfile.self"}
			subtitle={"maxillofacial.miniplatingLowProfile.subtitle"}
			productImages={[Tornillos,Placas,Instrumental,Sets]}
			productTitle={"maxillofacial.miniplatingLowProfile.products"}
			productDescription={"maxillofacial.miniplatingLowProfile.products"}
			openPage={["/maxillofacial/miniplating_low_system/screws",'/maxillofacial/miniplating_low_system/plates','/maxillofacial/miniplating_low_system/instruments','/maxillofacial/miniplating_low_system/sets']}
			currentPage = {3}
		/>
	)
}

export default (MiniplatingLowProfile)

