/*----- Imports -----*/
import React from "react";
import styled from "styled-components";
import { navigate } from 'hookrouter';
import { useTranslation } from 'react-i18next'
import appInfo from '../../../../package.json'

/*----- Assets -----*/
import logo from "../../../assets/images/logo.png";
import ImageFacebook from "../../../assets/icons/facebook-white-icon.png";
import InstagramImage from "../../../assets/icons/instagram-icon.svg";
import ImageEmail from "../../../assets/icons/mail-icon.svg";
import ImageTwitter from "../../../assets/icons/twitter-white-icon.png";
import ImagePointer from "../../../assets/icons/map-icon.svg";
import ImagePhone from "../../../assets/icons/phone-icon-footer.svg"
import ImageCustomer from "../../../assets/icons/customer-icon-footer.svg";

/*----- Components -----*/
import Subtitle from "../../text/Subtitle";

/*----- Commons -----*/
import { Trans } from "react-i18next";

/*----- Styles -----*/
const StyledFooter = styled.footer`
	position: relative;
	height: 238px;
	width: 100%;
	background: #171717;
	bottom: 0px;
	z-index: 900;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	padding: 71px 137px 30px 139px;
	.first-row{
		width: 100%;
		display: flex;
		flex-flow: row ;
		align-items: center;
		margin-bottom: 55px;
		.data-content{
			display: flex;
			flex-flow: row ;
			justify-content: flex-end;
			align-items: center;
			h2{
				font-size: 16px;
				font-weight: 300;
				margin: 0px;
			}
			.content{
				padding: 0px;
				display: flex;
				flex-flow: row ;
				justify-content: flex-end;
				align-items: center;
			}
			.email-content{
				display: flex;
				align-items: center;
				margin-right: 20px;
			}
			.phone-content{
				margin-left: 20px;
				display: flex;
    			align-items: center;
			}
			.customerService-content{
				margin-left: 20px;
				display: flex;
    			align-items: center;
			}
			.marginAddress{
				margin-right: 1%;
			}


		}
	}
	.white-line{
		border-top: 2px solid white;
		width: 100%;
	}
	.second-row{
		width: 100%;
		display: flex;
		flex-flow: row ;
		align-items: center;
		margin-top: 10px;
		h4{
			font-size: 16px;
			font-weight: 700;
			margin: 0px;
		}
		a{
			font-size: 16px;
			font-weight: 700;
			margin-right: 10px;
			justify-items: flex-end;
		}
		.legal{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			p{
				font-size:14px;
				color:white;
				font-weight:400;
				cursor:pointer;
			}
			/*p:first-child{
				margin-right:20px;
			}*/
			p{
				margin-right:20px;
			}
		}
		.copyright{
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}
	.img-footer{
		width: 150px;
		height: 43px;
	}

	.pointer-content{
		display: flex;
		align-items: center;
	}
	.icon-content{
		display: flex;
		align-items: center;
	}
	.footer-icon{
		width: 45px;
		min-height: 40px;
		height: 45px;
		margin-left: 6px;
	}
	.instagram{
		width: 42px;
	}
	.footer-email-icon{
		width: 30px;
		height: 23px;
		margin-right: 19px;
	}
	.footer-pointer-icon{
		width: 21px;
		height: 30px;
		margin-right: 16px;
	}
	.footer-phone-icon{
		width: 32px;
		height: 32px;
		margin-right: 19px;
	}
	.footer-customerService-icon{
		width: 32px;
		height: 32px;
		margin-right: 19px;
	}
	.link-email{
		color:#FFFFFF;
	}

	.version-tag{
		text-align: left;
		font: normal normal normal 12px/30px Montserrat;
		letter-spacing: 0px;
		color: #969696 !important;
		opacity: 1;
	}
`;

/*----- Component -----*/
const Footer = () => {
	const { t } = useTranslation()
	return (
		<StyledFooter>
			<div className="first-row">
				<div className="col-2 p-0">
					<img className="img-footer" src={logo} alt="logo" />
				</div>
				<div className="col-10 data-content pr-0">
					<div className="col-6 data-content pr-0">
						<div className="col-4 email-content">
							<img className="footer-email-icon" src={ImageEmail} alt="logo" />
							<a href={`mailto:${t('footer.email')}`} className="link-email">
								<Subtitle
									content={<Trans>footer.email</Trans>}
									size={2}
									color={"White"}
								/>
							</a>
						</div>
						<div className="col-4 phone-content">
							<img className="footer-phone-icon" src={ImagePhone} alt="logo" />
							<Subtitle
								content={<Trans>general.phone</Trans>}
								size={2}
								color={"White"}
							/>
						</div>
						<div className="col-4 customerService-content">
							<img className="footer-customerService-icon" src={ImageCustomer} alt="logo" />
							<Subtitle
								content={<Trans>general.customer_service</Trans>}
								size={2}
								color={"White"}
							/>
						</div>
					</div>
					<div className="col-4 pointer-content">
						<img className="footer-pointer-icon" src={ImagePointer} alt="logo" />
						<div className="marginAddress">
							<Subtitle
								content={<Trans>footer.address_1</Trans>}
								size={2}
								color={"White"}
							/>
							<Subtitle
								content={<Trans>footer.address_2</Trans>}
								size={2}
								color={"White"}
							/>
						</div>
					</div>
					<div className="col-2 icon-content">
						<a href={"https://www.facebook.com/FAICO-105830101160831/"} target="_blank"><img className="footer-icon" src={ImageFacebook} alt="logo" /></a>
						<a href={"https://www.instagram.com/faico.us/"} target="_blank"><img className="footer-icon instagram" src={InstagramImage} alt="logo" /></a>
						<a href={"https://twitter.com/FaicoMedical"} target="_blank"><img className="footer-icon" src={ImageTwitter} alt="logo" /></a>
					</div>
				</div>
			</div>
			<div className="white-line"></div>
			<div className="second-row">
				<div className="col-4 copyright p-0">
					<Subtitle
						content={<Trans>footer.faico_copyright</Trans>}
						size={4}
						color={"White"}
					/>
					
				</div>
				<div className="col-8 legal p-0">
					
					<p onClick={() => navigate('/termsConditions')}><Trans>footer.terminos_y_condiciones</Trans></p>
					<p onClick={() => navigate('/legalNotice')}><Trans>footer.legal_notice</Trans></p>
					<p onClick={() => navigate('/noticePrivacy')}><Trans>footer.privacy_police</Trans></p>
					<p className="version-tag">V-{appInfo.version}</p>
				</div>
			</div>
				
		</StyledFooter>
	);
};

export default Footer;
