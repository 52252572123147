import React, { useState }from 'react';

/*----- Assets -----*/
import ConnectionImplantsSpanish from "../../../assets/images/GuidedSurgery/connection-implants-spanish.png";
import ConnectionImplantsEnglish from "../../../assets/images/GuidedSurgery/connection-implants-english.png";

/*----- Styles -----*/
import {
	Main,
	StyledText,
	StyledImage,
	StyledTextItalic,
	StyledColorText,
	TitleAccordion,
	StyledTitle,
	StyledSubTitle,
	Dot,
	ItemContainer,
	ListContainer,
	StyledTextList,
	Section
} from './styles';

/*----- Commons -----*/
import { Trans } from "react-i18next";
import "../../../commons/i18n";
import i18n from '../../../commons/i18n';
import { Accordion } from 'react-bootstrap';
import CustomToggle from '../../../components/accordion/CustomToggle'

const SurgicalGuidedContent = () => {
	const [openComponents, setOpenComponents] = useState(false);

	const listCard_1 = [
		'dental.guidedSurgery.mobile.card_1.item_1',
		'dental.guidedSurgery.mobile.card_1.item_2',
		'dental.guidedSurgery.mobile.card_1.item_3',
		'dental.guidedSurgery.mobile.card_1.item_4',
		'dental.guidedSurgery.mobile.card_1.item_5',
		'dental.guidedSurgery.mobile.card_1.item_6'
	]

	const listCard_2 = [
		'dental.guidedSurgery.mobile.card_2.item_1',
		'dental.guidedSurgery.mobile.card_2.item_2',
		'dental.guidedSurgery.mobile.card_2.item_3',
		'dental.guidedSurgery.mobile.card_2.item_4',
		'dental.guidedSurgery.mobile.card_2.item_5',
		'dental.guidedSurgery.mobile.card_2.item_6',
		'dental.guidedSurgery.mobile.card_2.item_7',
		'dental.guidedSurgery.mobile.card_2.item_8'
	]

	const listCard_3 = [
		'dental.guidedSurgery.mobile.card_3.item_1',
		'dental.guidedSurgery.mobile.card_3.item_2',
		'dental.guidedSurgery.mobile.card_3.item_3',
	]

	const renderList = (title, list) => (
		<ListContainer>
			<StyledSubTitle><Trans>{title}</Trans></StyledSubTitle>
			{list.map((item,i) => (
				<ItemContainer key={i}>
					<Dot />
					<StyledTextList>
						<Trans>{item}</Trans>
					</StyledTextList>
				</ItemContainer>
			))}
		</ListContainer>
	)

	return (
		<Main>
			<Section>
				<StyledText><Trans>dental.guidedSurgery.card_1.text_1</Trans></StyledText>
				<StyledText><Trans>dental.guidedSurgery.card_1.text_2</Trans></StyledText>
				<StyledText><Trans>dental.guidedSurgery.card_1.text_3</Trans></StyledText>
			</Section>

			<Section>
				<StyledText><Trans>dental.guidedSurgery.card_2.text_2</Trans></StyledText>
				<StyledImage
					src={ i18n.language === 'es' ?
						ConnectionImplantsSpanish
						: ConnectionImplantsEnglish
					}
				/>
				<StyledTextItalic>
					<div>
						<StyledColorText><Trans>dental.guidedSurgery.card_2.text_1.title</Trans></StyledColorText>
						<Trans>dental.guidedSurgery.card_2.text_1.subtitle_1</Trans>
					</div>
					<div>
						<Trans>dental.guidedSurgery.card_2.text_1.subtitle_2</Trans>
					</div>
					<div>
						<Trans>dental.guidedSurgery.card_2.text_1.subtitle_3</Trans>
					</div>
				</StyledTextItalic>
				<StyledText><Trans>dental.guidedSurgery.card_2.text_3</Trans></StyledText>
			</Section>

			<Section>
				<StyledText><Trans>dental.guidedSurgery.card_3.text_1</Trans></StyledText>
				<StyledText><Trans>dental.guidedSurgery.card_3.text_2</Trans></StyledText>
				<StyledText><Trans>dental.guidedSurgery.card_3.text_3</Trans></StyledText>
			</Section>

			<Section>
				<Accordion >
					<TitleAccordion>
						<CustomToggle eventKey="1" setOpen={setOpenComponents} open={openComponents}>
							<StyledTitle><Trans>dental.guidedSurgery.mobile.title</Trans></StyledTitle>
						</CustomToggle>
					</TitleAccordion>
					<Accordion.Collapse eventKey="1">
						<div>
							{renderList('', listCard_1)}
							{renderList('dental.guidedSurgery.card_4.subtitle_3', listCard_2)}
							{renderList('dental.guidedSurgery.card_4.subtitle_4', listCard_3)}
						</div>
					</Accordion.Collapse>
				</Accordion>
			</Section>
		</Main>
	);
}

export default SurgicalGuidedContent;