import React from 'react';
import { Trans } from 'react-i18next';
import { isMobile } from "react-device-detect";
import Styles from './Styles';

const termsConditions = () => {
  return (
    <Styles isMobile={isMobile}>
      <h1><Trans>termsConditions.self</Trans></h1>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.1.title</Trans></h2>
        <p><Trans>termsConditions.texts.1.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.2.title</Trans></h2>
        <p><Trans>termsConditions.texts.2.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.3.title</Trans></h2>
        <p><Trans>termsConditions.texts.3.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.4.title</Trans></h2>
        <p><Trans>termsConditions.texts.4.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.5.title</Trans></h2>
        <p><Trans>termsConditions.texts.5.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.6.title</Trans></h2>
        <p><Trans>termsConditions.texts.6.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.7.title</Trans></h2>
        <p><Trans>termsConditions.texts.7.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.8.title</Trans></h2>
        <p><Trans>termsConditions.texts.8.text</Trans></p>
        <p><Trans>termsConditions.texts.8.text_1</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.9.title</Trans></h2>
        <p><Trans>termsConditions.texts.9.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.10.title</Trans></h2>
        <p><Trans>termsConditions.texts.10.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.11.title</Trans></h2>
        <p><Trans>termsConditions.texts.11.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.12.title</Trans></h2>
        <p><Trans>termsConditions.texts.12.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.13.title</Trans></h2>
        <p><Trans>termsConditions.texts.13.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.14.title</Trans></h2>
        <p><Trans>termsConditions.texts.14.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.15.title</Trans></h2>
        <p><Trans>termsConditions.texts.15.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>termsConditions.texts.16.title</Trans></h2>
        <p><Trans>termsConditions.texts.16.text</Trans></p>
      </div>
      <div className="paragrah">
        <p className="nro"><Trans>termsConditions.texts.17.text</Trans></p>
      </div>
    </Styles>
  )
}

export default termsConditions