import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const RegisterMark = ({children, split = false}) => {
	const {t} = useTranslation();

	let data = children;
	if(Array.isArray(children)){
		data = "";
		children.forEach(child => {
			data = data +child;
		})
	}
	
	let newText = t(data).split('®');
	const withRegister = newText.length > 1;

	
	function getContent(){
		if (!withRegister){
			let html= processTextToHtml(newText[0])
			return html;
		}
		
		return newText.map((el, index) => {
			if(index != 0){
				return getSuperIndex(newText[index])
			}

			let html = processTextToHtml(newText[index])
			return (<Trans>{html}</Trans>)
		})

	}
	function getSuperIndex(next){
		let html = processTextToHtml(next)
		return (
			<><sup>®</sup>{html}</>
		)
	}	

	function processTextToHtml(text){
		return(<span dangerouslySetInnerHTML={{__html: text}}></span>)
	}

	return(
		<span>
		{getContent()}
		</span>
	);
};

export default RegisterMark;