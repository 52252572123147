import styled from 'styled-components'

const Styles = styled.div`
  background: transparent linear-gradient(to bottom, #6F3CEA 0%, #25144D 46%, #000000 100%) 0% 0% no-repeat padding-box;
  .system-container{
    padding:0px 20px 0 20px;
    .title{
      color:white;
      font-weight:700;
      font-size:32px;
      margin:60px 20px 25px 20px;
    }
    .information-container{
      margin:60px 0px 0px 0px;
      p{
        color:white;
        font-size:14px;
        line-height:16px;
        font-weight:400;
        text-align:left;
      }
    }
    .systems{
      margin-top:30px;
      overflow: hidden;
      .title{
        display:flex;
        align-items:center;
        margin:0px 0px 0px 0px;
        z-index:100;
        h1{
          font-size:16px;
          color:white;
          font-weight:700;
          margin:0;
          z-index:100;
        }
      }
    }
    .catalog-button{
      width: 100%;
      /* margin: initial; */
      margin:55px 0px;
      height: max-content;
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:12px;
        font-weight:700;
        width: 70%;
        border: 1px solid white;
        border-radius: 30px;
        color:white;
        height: 40px;
      }
    }
  }
  .item{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin:5px 0px;
    .dot{
      height: 6px;
      width: 6px;
      background-color:#7161FF;
      border-radius: 50%;
      display: inline-block;
      z-index:100;
      margin-right: 10px;
    }
    .text{
      display:flex;
      flex-direction:column;
    }
    p{
      color:white;
      margin:0;
      font-size:13px;
      font-weight:400;
      z-index:100;
      line-height:16px;
      display:flex;
      flex:1;
      white-space: nowrap;
    }
  }
  .image-violet-wave{
    width: 100%;
    position: absolute;
    top: 37%;
    left:0;
  }
`
export default Styles;