import styled from 'styled-components'

export const Styles = styled.div`

    .my-popup-content{
        border-radius: 15px;
        border: none !important;
        background: black  !important;
        width: 30% !important;
    }

  	.my-popup-arrow {
		color: rgb(255, 255, 255);
  	}
	[role='tooltip'].my-popup-content {
		width: 200px;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  	}
  
  	.my-popup-overlay {
		background: rgba(0, 0, 0, 0.5);
  	}
  	[data-popup='tooltip'].my-popup-overlay {
		background: transparent;
  	}
`
