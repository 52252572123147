import React from 'react'
import Screws from "../../maxillofacial/desktop/Screws"
import * as data from "./data.js";

import Image_1_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/01_MLPS-1.5-S-12.0-0 1.png"
import Image_1_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/image_1_1.png"
import Image_2_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/03_MLPS-1.5-C-12.0-1_1.png"
import Image_2_0_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/03_MLPS-1.5-C-12.0-1.0-TI-SC_Plana.png"
import Image_3_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/04_MLPS-2.0-S-14.0-0 1.png"
import Image_3_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/image_3_0 1.png"
import Image_4_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/06_MLPS-2.0-C-14.0-1 1.png"
import Image_4_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/06_MLPS-2.0-C-14.0-TI-SC_Plana2.png"
import Image_5_0 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/06b_MLPS-2.3-EC-10.svg"
import Image_5_1 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Screws/06b_MLPS-2.3-EC-10_1.svg"

const MiniplatingLowProfileScrews = (props) => {
	return (
		<Screws
			self={"miniplating_low_system"}
			navigate={"miniplating_low_system"}
			title={"miniplatingLowProfile.products.1.name"}
			subtitle={"miniplatingLowProfile.products.1.description"}
			sectionTitles={["miniplatingLowProfile.screws.1", "miniplatingLowProfile.screws.2", "miniplatingLowProfile.screws.3", "miniplatingLowProfile.screws.4", "miniplatingLowProfile.screws.5"]}
			sectionImagesFirst={[Image_1_0, Image_2_0, Image_3_0, Image_4_0, Image_5_0]}
			sectionImagesSecond={[Image_1_1, Image_2_0_1, Image_3_1, Image_4_1,Image_5_1]}
			sectionData={[data.dataSection_1, data.dataSection_2, data.dataSection_3, data.dataSection_4, data.dataSection_5]}
		/>
	)
}

export default (MiniplatingLowProfileScrews)