/*----- Imports -----*/
import React from "react";
import {navigate} from 'hookrouter';
import SideBar from "./SideBar"

/*----- Assets -----*/
import logo from "../../../../assets/images/logo.png";

/*----- Styles -----*/
import { StyledHeader } from "./Styles";

/*----- Functions -----*/

/*----- Component -----*/

const Header = (props) => {
	return (
		<StyledHeader>
			<div className="logo-container" onClick={() => navigate("/")}>
				<img src={logo} alt="logo" className="img" />
			</div>
			<div>
				<SideBar/>
			</div>
		</StyledHeader>
	);
};

export default Header;
