import styled, {css} from 'styled-components'

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${ props => !props.isMobile && css`
    min-height: 100vh;
  `}
`
