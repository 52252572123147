import React from 'react'
import LevelOne from '../../maxillofacial/mobile/LevelOne';

const MiniplatingLowProfileSystem = () => {
	return (
		<LevelOne
			system={"miniplatingLowProfileSystem"}
		/>
	)
}

export default (MiniplatingLowProfileSystem)