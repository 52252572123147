import Image_2_1 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_1.png"
import Image_2_2 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_2.png"
import Image_2_3 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_3.png"
import Image_2_4 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_4.png"
import Image_2_5 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_5.png"
import Image_2_6 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_6.png"
import Image_2_7 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_7.png"
import Image_2_8 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_8.png"
import Image_2_9 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_9.png"
import Image_2_10 from "../../../assets/images/CranioMaxiloFacial/MiniplatingLowProfile/Instruments/image_8.png"
import Image_2_11 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_11.png"
import Image_2_12 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_12.png"
import Image_2_13 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_13.png"
import Image_2_14 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_14.png"
import Image_2_15 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_15.png"
import Image_2_16 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_16.png"
import Image_2_17 from "../../../assets/images/CranioMaxiloFacial/MicroPlating/Instruments/image_17.png"

export function dataGenerator(lng) {

	const instrumentsData_1_en = ["SMITH Wire cutting scissor with TC 16 cm. Straight.", " SMITH Wire cutting scissor with TC 16 cm. Curved.", " UNIVERSAL Wire cutting scissor with TC 12 cm.", " Plate and Wire cutting Plier with TC 16 cm."," Plate bending plier with pin 13.5 cm."]
	const instrumentsData_1_es = ["SMITH Tijera de corte de alambre con TC 16 cm. Recta", " SMITH Tijera de corte de alambre con TC 16 cm. Curva", "UNIVERSAL Tijera de corte de alambre con TC 12 cm.", " Alicate de corte de placa y alambre con TC 16 cm.", "Alicate para doblar placas con pin 13.5 cm."]

	const instrumentsData_2_en = [" ADERER Plate bending plier 12.5 cm.", " Flat plier 13.5 cm.", " Plate holding Clamps 14 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Straight.", " CUSHING Plate/Screw holding forcep 17.5 cm. Angled."]
	const instrumentsData_2_es = ["ADERER Alicate para doblar placas 12.5 cm.", "Alicate plano 13.5 cm.", "Clamps de sujeción de placa 14 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Recta.", " CUSHING Pinza de sujeción para Placas/Tornillos 17.5 cm. Angulada."]

	const instrumentsData_3_en = [ " Screwdriver detachable complete. Standard Head.", " Screwdriver detachable complete. Cross Head.", " Screwdriver detachable complete. Self Fitting. Cross Head."]
	const instrumentsData_3_es = [" Destornillador desmontable completo. Standard Head.", " Destornillador desmontable completo. Cross Head.", "Destornillador desmontable completo. Autoajustable. Cross Head."]

	const instrumentsData_4_en = [" Screwdriver fixed complete. Standard Head.", " Screwdriver fixed complete. Cross Head.", " Screwdriver fixed complete. Self Fitting. Cross Head."]
	const instrumentsData_4_es = [" Destornillador fijo completo. Standard Head.", " Destornillador fijo completo. Cross Head.", " Destornillador fijo completo. Autoajustable. Cross Head."]

	const instrumentsData_5_en = [" Depth Gauge 18 cm."," Spiral-Drill with Sk-connection, Ø 1.0 mm. 4 mm stop. 46 mm."," Spiral-Drill with Sk-connection, Ø 1.0 mm. 8 mm stop. 50 mm."," Spiral-Drill with Sk-connection, Ø 1.0 mm. 12 mm stop. 54 mm."]
	const instrumentsData_5_es = [" Medidor de profundidad 18 cm.", " Spiral-Drill con conexión-Sk, Ø 1.0 mm. 4 mm stop. 46 mm.", " Spiral-Drill con conexión-Sk, Ø 1.0 mm. 8 mm stop. 50 mm.", " Spiral-Drill con conexión-Sk, Ø 1.0 mm. 12 mm stop. 54 mm."]


	const instrumentsData_1 = [
		["F1.2-84416", "F1.2-84516", "F1.2-83512", "F1.2-70412", "F1.2-70213"],
		lng === "en" ? instrumentsData_1_en : instrumentsData_1_es,
		[Image_2_1, Image_2_2, Image_2_3, Image_2_4, Image_2_5]
	]
	const instrumentsData_2 = [
		["F1.2-70112", "F1.2-70313", "F1.2-50013", "F1.2-40018", "F1.2-45018"],
		lng === "en" ? instrumentsData_2_en : instrumentsData_2_es,
		[Image_2_6, Image_2_7, Image_2_8, Image_2_9, Image_2_10]
	]
	const instrumentsData_3 = [
		["F1.2-20117", "F1.2-20217", "F1.2-20217S"],
		lng === "en" ? instrumentsData_3_en : instrumentsData_3_es,
		[Image_2_11, Image_2_12, Image_2_13]
	]
	const instrumentsData_4 = [
		["F1.2-2F117", "F1.2-2F217", "F1.2-2F217S"],
		lng === "en" ? instrumentsData_4_en : instrumentsData_4_es,
		[Image_2_14, Image_2_15, Image_2_15]
	]
	const instrumentsData_5 = [
		["F1.2-20018", "F1.2-15906", "F1.2-15907", "F1.2-15908"],
		lng === "en" ? instrumentsData_5_en : instrumentsData_5_es,
		[Image_2_16, Image_2_17, Image_2_17, Image_2_17]
	]

	let data = {
		instrumentsData_1,
		instrumentsData_2,
		instrumentsData_3,
		instrumentsData_4,
		instrumentsData_5
	}
	return data;
}