/*----- Imports -----*/
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Row, Image } from 'react-bootstrap'
import { element } from 'prop-types';
import { Trans } from "react-i18next";
import { navigate } from "hookrouter";

/*----- Styles -----*/
const StyledGalleryCard = styled.main`
	.card{
		//background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;
		background: transparent;
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center;
		margin-bottom: 5%;
		border-radius:32px;
		width: 85% !important;
		margin-left: 7.5%;
		margin-right: 2.5%;
		opacity: 1;
	}

	.title{
		text-align: left;
		font: normal normal bold 25px/28px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
	}
	.code{
		text-align: left;
		font: normal normal medium 20px/38px Montserrat;
		letter-spacing: 0px;
		color: #FFFFFF;
		opacity: 1;
	}

  	.CarrouselImg{
		width: 100%;
	}

  	.image-row{
	 	background-size: cover; 
		padding: 0% !important;
		border-top-left-radius: 32px;
		border-top-right-radius: 32px;
		.img-content{
			widht: 100%;
			height:auto;
		}
	}

	.case-button:hover{
		background: #FFFFFF 0% 0% no-repeat padding-box;
		opacity: 1;
		font: normal normal bold 18px/32px Montserrat;
		letter-spacing: 0px;
		color: #333D98;
		opacity: 1;
	}

	.case-button{
		position:absolute;
		bottom: 10%;
		left 10%;
		font: normal normal bold 18px/32px Montserrat;
		background-color:transparent;
		border-radius:30px;
		border: 1px solid white;
		color:white;
		width: 25%;
		height: 44px;
	}

	.title-row{
		margin: 2% 0 0 0;
	}

	.code-row{
		margin: 2% 0 0 0;
	}
  `;

/*----- Component -----*/

const GalleryCard = ({ code, title, CarrouselImg, index }) => {

	useEffect(() => {

	}, []);

	function setClinicalCasesAnchor(){
		localStorage.setItem("goToCards", "true");
	}

	function getButton() {
		return (
			<div className="px-5">
			<a className="">
				<button className="case-button" 
				onClick={() => {
					navigate("/faico360/" + index)
					setClinicalCasesAnchor()
				}}
				>
				<Trans>faico360.ClinicalCaseCardButton</Trans></button>
			</a>
			</div>
		);
	}

	return (
		<StyledGalleryCard>
		
			<Row className="card" id={"card-" + code}>
				<Col>
					<Row id={"img-" + code} className="image-row">
						<Image src={CarrouselImg} className="CarrouselImg"></Image>
					</Row>
					<Row className="title-row">
						<h3 className="title mt-3">{title}</h3>
					</Row>
					<Row className="code-row">
						<span className="code">#{code}</span>
					</Row>
				</Col>
			</Row>
			
			{getButton()}
		
		</StyledGalleryCard>
	);
};
export default GalleryCard;

/*----- PropTypes -----*/
/*PersonCard.propTypes = {
	name: PropTypes.string.isRequired,
	job: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	imageURL: PropTypes.string.isRequired,
}*/
