import React from 'react';
import { Video } from './Styles';

export const videos = [
	'https://www.youtube.com/embed/f2J8La-zf24',
	'https://www.youtube.com/embed/ntYHFryDAEg',
	'https://www.youtube.com/embed/zhcGcBDv4dg',
	'https://www.youtube.com/embed/g_FRwYLjeno',
	'https://www.youtube.com/embed/KgxHuQfDUI4',
	'https://www.youtube.com/embed/YgX6uRbfb8s',
	'https://www.youtube.com/embed/LVsNVkl3NG0',
	'https://www.youtube.com/embed/VGhuJOcqq2w',
	'https://www.youtube.com/embed/E1apcTWaLFA',
	'https://www.youtube.com/embed/9flSodreS8Q',
	'https://www.youtube.com/embed/9L1y2Uw5YjY',
	''
]

export const renderVideos = (width, height) =>
	videos.map((video, id) =>
		<Video>
			<iframe title={`video_${id}`} width={width} height={height} src={video}
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen>
			</iframe>
		</Video>
	)