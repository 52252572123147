import styled from "styled-components";
import ModalStyles from '../../../../components/modals/implants/ImplantsModalStyles'

export const Styles = styled(ModalStyles)`
	.modal-1-1{
		top:11%;
		left:37%;
	}
	.modal-1-2{
		left: 43%;
    top: 20%;
	}
	.modal-1-3{
		left: 17%;
    top: 16%;
	}
	.modal-1-4{
		top: 46%;
		right: 15%;
	}
	.modal-1-5{
		bottom: 13%;
		right: 63%;
	}
	.modal-1-6{
		bottom: 0%;
		left: 30%;
	}
	.modal-1-7{
		bottom: 18%;
		right: 40%;
	}
	.modal-2-1{
    right: 40%;
	}
	.modal-2-2{
    top: 20%;
    left: 16%;
	}
	.modal-2-3{
    bottom: 14%;
    left: 43%;
	}
	.modal-2-4{
		right: 3%;
    top: 42%;
	}
	.modal-2-5{
    bottom: 25%;
    right: 30%;
	}
	.modal-3-1{
		top:10%;
		left:35%;
	}
	.modal-3-2{
		left: 16%;
    top: 18%;
	}
	.modal-3-3{
		top: 68%;
    left: 5%;
	}
	.modal-3-4{
		right: 17%;
    top: 46%;
	}
	.modal-3-5{
		top: 90%;
		left: 21%;
	}
	.modal-3-6{
		right: 38%;
    bottom: 9%;
	}
	.modal-3-7{
		bottom: 48%;
    left: 35%;
	}
`