import React from "react";
import { useEffect, useRef } from "react";
import { Trans } from "react-i18next";
import { navigate } from "hookrouter";
import { Image, Row, Col, Button } from "react-bootstrap";
/* Components */
import { MobileStyles } from "../Styles";
import MultiCarousel from '../../../components/carousel/MuiltiCarousel/index'

import GalleryCardMobile from '../../../components/card/GalleryCardMobile.js'
import clinicalCases from '../../../commons/data/faico360/ClinicalCases';

import BackgroundImage from "../../../assets/images/img-fondo-casos-clinicos-mobile.png";
import RegisterMark from "../../../components/registerMark";




const Faico360 = () => {
	const fieldRef = useRef(null);

  useEffect(() => {
    const goToCards = localStorage.getItem('goToCards');
    if(goToCards){
      console.log(goToCards)
      if(fieldRef.current){
        console.log(fieldRef)
        setTimeout(() => {
          scroll()
        }, 1000)
      }
      localStorage.removeItem('goToCards')
    }
  }, [])

  function scroll(){
    console.log("scrollIntoView")
    fieldRef.current.scrollIntoView({behavior: "smooth"})
  }
	return (
		<MobileStyles id="faico360">
			<div className="content-image-background">
				{
					<Image className="img-background" src={BackgroundImage}></Image>
				}
			</div>
			<div className="gradient"></div>
			<div className="background-content">
				<div className="content-page" style={{'padding': '0 !important'}}>
					<div className="content-first-row">
						<div className="faico-text">
							<div className="title">
								<h1><Trans>faico360.self2</Trans></h1>
								<span className="dot"></span>
							</div>
							<p><Trans>faico360.landscape_body</Trans></p>
						</div>
						<div id="goToCardsAnchor" ref={fieldRef}></div>
						<div className="clinical-cases">
							<div className="title">
								<h1><Trans>faico360.clinicalCases.self</Trans></h1>
								<p><Trans>faico360.clinicalCases.description</Trans></p>
							</div>
						</div>
					</div>
					<div className="content-card-mobile">
						<MultiCarousel>
							{
								clinicalCases.map((c, index) => {
									if (c.skip){return null}
									return (<GalleryCardMobile
										code={c.code}
										title={<RegisterMark>faico360.clinicalCases.cases.{c.number}.title</RegisterMark>}
										index={index}
										CarrouselImg={c.CarouselImgDesktop}
										key={"faico-360-"+index}
										onClick={() => navigate("/faico360/"+index)}
									></GalleryCardMobile>);
								})
							}
						</MultiCarousel>
					</div>
					<div className="content-card-mobile">
						<div className="faico-extension-mobile">
							
								<div className="card-extension full-width" id="card-extension-1">
									<h1><Trans>faico360.FaicoConnectTitle</Trans></h1>
								</div>
								<Row>
									<Col md={12} sm={12} xs={12} className="align-items-center">
										<div className="card-extension full-width" id="card-extension-1">
											<p><Trans>faico360.FaicoConnectText</Trans></p>
											<span><Trans>faico360.FaicoConnectText2</Trans>&nbsp;</span>
											<span><Trans>faico360.FaicoConnectText3</Trans></span>
										</div>
									</Col>
									<Col md={12} sm={12} xs={12}>
										<div className="card-extension">
											<Button className="default-button" onClick={() => navigate("/contact")}>
												<Trans>faico360.ContactButton</Trans>
											</Button>
										</div>
									</Col>
								</Row>
							
						</div>
					</div>
				</div>
			</div>
		</MobileStyles>
	)
}

export default Faico360