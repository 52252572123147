import styled from 'styled-components'
import Color from "../../commons/Color"
import Image from '../../assets/images/onda-violeta-modal.png'

export const ModalDentalLevelOneStyles = styled.div`
	position:relative;
	width:100%;
	height:100%;
	background-image: url('${Image}');
	background-repeat: no-repeat;
	background-size: contain;
	background-position:center;

	.close-button{
		background-color:transparent !important;
		border-width:0px !important;
		font-size:24px;
		position: absolute;
		top: 5%;
		right: 4%;

	}
	.first-col{
		margin-top:0px;
		margin-right:2%;
		margin-left:3%;
		padding:0;
		display: flex;
		flex-direction: column;
		justify-content:center;
		text-align:right;
		img{
			max-height:450px;
		}
	}
	.short-first-col{
		padding-bottom: 230px;
	}
	h1{

		color:white;
		font-weight:700;
		font-family: 'Montserrat', sans-serif;
			@media (min-width:1500px){
				font-size:29px;
				margin-top:2%;
			}
			@media (min-width:1024px) and (max-width:1500px){
				font-size:23px;
				margin-top:10px;
			}
	}
	h3{
		font-family: 'Montserrat', sans-serif;
		color:white;
		text-align:left;
		font-weight:700;
		/* margin-bottom:0px; */
		margin-top: 10px;
		margin-bottom: 14px;

		@media (min-width:1500px){
			font-size:18px;
		}
		@media (min-width:1024px) and (max-width:1500px){
			font-size:16px;
		}
	}
	.header{
		display: flex;
		h1{
			margin-top: 1%;
			font-size:35px;
			margin-bottom:20px;
			padding-left:15px;
		}
	}
	.row-content{
		/* align-items: center; */
		position:absolute;
		height:100%;
		width: 100%;
		display:flex;
		overflow-y:scroll;
		padding:10px 0px;
		justify-content:space-around;
		padding:61px 60px 56px;
	}
	.row-content::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
	}
	.row-content::-webkit-scrollbar-thumb {
    background: #FF0000;
	}
	.second-col{
		margin-right: 2%;
		display: flex;
		padding-left: 0px;
		flex-direction: column;
		justify-content: center;
		height:min-content;
	}
	.content-description{
		padding-bottom:30px;
	}

	.text-columns{
		column-count:2;
		column-gap:90px;
		padding-left:15px;
		padding-right:0px;
	}

	.text-column-one{
		width: 50%;
	}

	.text{
		color:white;
		margin:0px;
		padding:0px;
		text-align:left;
		line-height: 22px;

		@media (min-width:1500px){
			font-size:18px;
		}
		@media (min-width:1024px) and (max-width:1500px){
			font-size:15px;
		}
	}

	.text-one{
		min-height:170px;
		width:510px;
		@media  screen and (max-width: 1440px) {
			min-height:auto;
			width:auto;
		}
		p{
			margin: 0;
    		padding: 0;
			margin-bottom: 15px;
			font-size: 18px;
			line-height:21px;
			font-family: 'Montserrat', sans-serif;
			font-weight:500;
		}
	}

	.short-text{
		min-height:50px;
		padding-left:1px;
	}
	.text-two{
		font-size: 18px;
		line-height:21px;
		font-family: 'Montserrat', sans-serif;
		font-weight:500;
	}

	.short-col{
		.header h1{
			padding-left:1px;
		}
	}

	.dot-span{
		color: #49D3EA;
	}
`

export const PopUpStyles = styled.div`

	.pop-up-style-conexion-hexagono-content{
			z-index:999;
			padding:0px !important;
			background: ${Color.gradientModal} !important;
			border:0px solid !important;
			position:relative;
			overflow:hidden;
			border-radius:5px;
			height:95vh;
			width:95vw !important;
	}
`
