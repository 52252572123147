import React, { useState, useEffect } from "react";
import { Image, Table, Collapse } from "react-bootstrap";
import PropTypes from "prop-types"
import { Trans } from "react-i18next";

import { TableStyles, CircleStyles } from "./Styles"

import Arrow from "../../../../../assets/icons/icon-components/Arrow.js"

const TablePrimaryModal = (props) => {
	const [openModal, setOpenModal] = useState([]);

	useEffect(() => {
		let openArray = []
		for (let i = 0; i < props.modalData[0].length; i++) {
			openArray.push(false)
		}
		setOpenModal(openArray);
	}, [props.modalData]);

	function changeStateOpen(e) {
		let openArray = [];
		for (let i = 0; i < openModal.length; i++) {
			openArray.push(openModal[i])
		}
		openArray[e.target.id] = !openArray[e.target.id]
		setOpenModal(openArray);
	}
	function infoTable() {
		const modalData = props.modalData;
		const modalColors = props.modalColors
		let table = []
		let i;
		for (i = 0; i < modalData[0].length; i++) {
			table.push(
				<tbody key={i}>
					<tr className={i % 2 === 0 ? "tr-info-par" : "tr-info-impar"}>
						<td>
							<div className="td-first">
								<CircleStyles
									color_1={modalColors[i][0]}
									color_2={modalColors[i][1]}
								/>
								<p>{modalData[0][i]}</p>
							</div>
						</td>
						<td><p>{modalData[1][i]}</p></td>
						<td><p>{modalData[2][i]}</p></td>
						<td className="td-fourth"><p>{modalData[3][i]}</p></td>
						<td className="pointer" id={i} aria-controls={"collapse-table-" + i} aria-expanded={openModal[i]} onClick={changeStateOpen}>
							{!openModal[i] ? <Arrow id={i} rotateDeg="270" color="white" width="20px" heigth="20px" /> : ""}
						</td>
					</tr>
					<Collapse in={openModal[i]}>
						<tr className={i % 2 === 0 ? "tr-info-par" : "tr-info-impar"} aria-controls={"collapse-table-" + i} aria-expanded={openModal[i]}>
							<td colSpan="4" className="td-collapse">
								<div className="line-collapse"></div>
								<div className="div-collapse">
									{infoExpand(i)}
								</div>
							</td>
							<td className="pointer" id={i} onClick={changeStateOpen}>
								{openModal[i] ? <Arrow id={i} rotateDeg="90" color="white" width="20px" heigth="20px" /> : ""}
							</td>
						</tr>
					</Collapse>
				</tbody>
			)
		}
		return table;
	}
	function infoExpand(i) {
		let table = []
		let j = 0;
		const modalDataExpand = props.modalDataExpand;

		while (j < modalDataExpand[i].length) {
			table.push(
				<div key={j} className="content-info-expand">
					<Image className="img-fluid" src={modalDataExpand[i][j]} ></Image>
					<div className="content-text">
						<p className="type">{modalDataExpand[i][j + 1]}</p>
						<p className="code-product">{modalDataExpand[i][j + 2]}</p>
					</div>
				</div>
			)
			j = j + 3;
		}
		return table
	}
	return (
		<TableStyles>
			<div className="table-scroll">
				<Table className="mb-0" striped bordered variant="dark" size="sm">
					<thead className="head">
						<tr className="tr-head">
							{
								props.modalHeaders.map((header, i) => {
									return (
										<th scope="col" className=""><Trans>{header}</Trans></th>
									)
								})
							}
							<th scope="col" className="fifth" ></th>
						</tr>
					</thead>
					{infoTable()}
				</Table>
			</div>
		</TableStyles>
	)

}
/*----- PropTypes -----*/
TablePrimaryModal.propTypes = {
	modalHeaders: PropTypes.array.isRequired,
	modalDataExpand: PropTypes.array.isRequired,
	modalData: PropTypes.array.isRequired,
	modalColors: PropTypes.array.isRequired,
}
export default (TablePrimaryModal)