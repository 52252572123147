/* First Table */
import Image1_1 from "../../../assets/images/Dental/universalExpanders/modal-images/image-1.png";
import Image1_2 from "../../../assets/images/Dental/universalExpanders/modal-images/image-2.png";
import Image1_3 from "../../../assets/images/Dental/universalExpanders/modal-images/image-3.png";
import Image1_4 from "../../../assets/images/Dental/universalExpanders/modal-images/image-4.png";
import Image1_5 from "../../../assets/images/Dental/universalExpanders/modal-images/image-5.png";
import Image1_6 from "../../../assets/images/Dental/universalExpanders/modal-images/image-6.png";
import Image1_7 from "../../../assets/images/Dental/universalExpanders/modal-images/image-7.png";
import Image1_8 from "../../../assets/images/Dental/universalExpanders/modal-images/image-8.png";
import Image1_9 from "../../../assets/images/Dental/universalExpanders/modal-images/image-9.png";
import Image1_10 from "../../../assets/images/Dental/universalExpanders/modal-images/image-10.png";
import Image1_11 from "../../../assets/images/Dental/universalExpanders/modal-images/image-11.png";
import Image1_12 from "../../../assets/images/Dental/universalExpanders/modal-images/image-12.png";
import Image1_13 from "../../../assets/images/Dental/universalExpanders/modal-images/image-13.png";
import Image1_14 from "../../../assets/images/Dental/universalExpanders/modal-images/image-14.png";
import Image1_15 from "../../../assets/images/Dental/universalExpanders/modal-images/image-15.png";

/* Second Table */
import Image2_1 from "../../../assets/images/Dental/universalExpanders/table-2-images/image-1.png";
import Image2_2 from "../../../assets/images/Dental/universalExpanders/table-2-images/image-2.png";
import Image2_3 from "../../../assets/images/Dental/universalExpanders/table-2-images/image-3.png";
import Image2_4 from "../../../assets/images/Dental/universalExpanders/table-2-images/image-4.png";
import Image2_5 from "../../../assets/images/Dental/universalExpanders/table-2-images/image-5.png";


export function universalDataGenerate(lng) {

    const universalModalDataExpand_En = [
        [Image1_1, "Expander Pilot 1 - Sharp.", "AEX17000PI00"],
        [Image1_2, "Expander Pilot 2 - Sharp.", "AEX205000PI00"],
        [Image1_3, "Expander Pilot 3 - Sharp.", "AEX23000PI00"],
        [Image1_4, "Horizontal Expander - Convex.", "AEX330000OZ00"],
        [Image1_5, "Horizontal Expander - Convex.", "AEX375000OZ00", Image1_6, "Vertical Expander - Concave.", "AEX375000VT00"],
        [Image1_7, "Horizontal Expander - Convex.", "AEX400000OZ00", Image1_8, "Vertical Expander - Concave.", "AEX400000VT00"],
        [Image1_9, "Horizontal Expander - Convex.", "AEX450000OZ00", Image1_10, "Vertical Expander - Concave.", "AEX450000VT00"],
        [Image1_11, "Horizontal Expander - Convex.", "AEX500000OZ00", Image1_12, "Vertical Expander - Concave.", "AEX500000VT00"],
        [Image1_13, "Horizontal Expander - Convex.", "AEX550000OZ00", Image1_14, "Vertical Expander - Concave.", "AEX550000VT00"],
        [Image1_15, "Horizontal Expander - Convex.", "AEX650000OZ00"],
    ]
    const universalModalDataExpand_Es = [
        [Image1_1, "Expansor Piloto 1 - Agudo.", "AEX17000PI00"],
        [Image1_2, "Expansor Piloto 2 - Agudo.", "AEX205000PI00"],
        [Image1_3, "Expansor Piloto 3 - Agudo.", "AEX23000PI00"],
        [Image1_4, "Expansor Horizontal-Convexo.", "AEX330000OZ00"],
        [Image1_5, "Expansor Horizontal-Convexo.", "AEX375000OZ00", Image1_6, "Expansor Vertical-Cóncavo.", "AEX375000VT00"],
        [Image1_7, "Expansor Horizontal-Convexo.", "AEX400000OZ00", Image1_8, "Expansor Vertical-Cóncavo.", "AEX400000VT00"],
        [Image1_9, "Expansor Horizontal-Convexo.", "AEX450000OZ00", Image1_10, "Expansor Vertical-Cóncavo.", "AEX450000VT00"],
        [Image1_11, "Expansor Horizontal-Convexo.", "AEX500000OZ00", Image1_12, "Expansor Vertical-Cóncavo.", "AEX500000VT00"],
        [Image1_13, "Expansor Horizontal-Convexo.", "AEX550000OZ00", Image1_14, "Expansor Vertical-Cóncavo.", "AEX550000VT00"],
        [Image1_15, "Expansor Horizontal-Convexo.", "AEX650000OZ00"],
    ]

    const universalModalData = [
        ["1", "2", "3", "ø 3.30", "ø 3.75", "ø 4.00", "ø 4.50", "ø 5.00", "ø 5.50", "ø 6.50"],
        ["-", "-", "-", "3.3 mm", "3.75 mm", "4 mm", "4.5 mm", "5 mm", "5.5 mm", "6.5 mm", "-", "-", "-", "-", "-"],
        ["1.7 mm", "2.05 mm", "2.3 mm", "3 mm", " 3.4 mm", "3.65 mm", "4.15 mm", "4.7 mm", "5.15 mm", "6.2 mm"],
        ["1.3 mm", "1.55 mm", "1.8 mm", "1.7 mm", "2 mm", "2.3 mm", "2.75 mm", "3.3 mm", "3.75 mm", "4.8 mm"]

    ]
    const universalModalSecondaryData = [
        ["ATY000000EX00", "AST000000CCCR", "AST00000CTMA0", "AST200000CCIP", "AST170000CCIP"],
        lng === "es" ? 
            ["Bandeja Quirúrgica.", "Carraca reversible.", "Destornillador", "Pin de Paralelismo.", "Pin de Paralelismo."] :
            ["Surgical Tray.", "Reversible Ratchet.", "Screwdriver Short.", "Parallelism Pin.", "Parallelism Pin."], 
        [Image2_1, Image2_2, Image2_3, Image2_4, Image2_5]
    ]
    const universalModalDataCirclesColors =
        [
            ["", ""],
            ["", ""],
            ["", ""],
            ["#EA81C9", "#E55287"],
            ["#EAE2A2", "#DBC72C"],
            ["#B5DFEB", "#1DC6E5"],
            ["#4775B2", "#2360B2"],
            ["#BFAF91", "#BC8117"],
            ["#82C193", "#18A23E"],
            ["#DDDFE0", "#ACACAC"],
        ]

    const data = {
        universalModalData,
        universalModalSecondaryData,
        universalModalDataExpand: lng  === "en" ? universalModalDataExpand_En : universalModalDataExpand_Es,
        universalModalDataCirclesColors
    }

    return data;
}