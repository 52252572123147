import React from "react";
import { Trans } from "react-i18next";
import { Button, Col, Image } from "react-bootstrap";
import { Styles } from "./DarkModalStyles"
import EmptyImage from "../../assets/images/empty_resources.png"
import TableSecondaryModal from "./components/Tables/TableSecondary/TableSecondary"
import RegisterMark from "../registerMark";

const DarkModal = (props) => {
	const modalText = ["microPlatingSystem", "mediumplatingSystem", "miniplatingLowProfile", "miniplatingStandardSystem", "fracturePlatingSystem", "mandibularReconstruction"]
	const height = ((props.currentPage === 4) && (props.currentModal === 2)) ? "400px !important" : "600px !important";
	return (
		<Styles>
			<div className="row-content">
				<Col className="first-col" md={2} >
					<Image src={EmptyImage} className={"img-fluid"}></Image>
				</Col>
				<Col className="second-col" md={9}>
					<Button className="close-button float-right"
						onClick={() => props.onButtonClose(props.currentModal)}
					>X</Button>
					<h1><RegisterMark>{modalText[props.currentPage - 1]}.products.{`${props.currentModal}`}.name</RegisterMark></h1>
					<div className="border-h1"></div>
					<div className="row-info justify-content-between">
						<h2><Trans>{modalText[props.currentPage - 1]}.products.{`${props.currentModal}`}.description</Trans></h2>
						{(((props.currentPage === 4) && (props.currentModal === 2)) || ((props.currentPage === 6) && (props.currentModal === 4)) )?
							<div className="mt-4">
								<h3>{props.titles[0]}</h3>
								<TableSecondaryModal
									modalData={props.modalData}
									modalHeaders={props.modalHeaders}
									height={height}
									withoutImage={props.withoutImage}
								/>
								<h3>{props.titles[1]}</h3>
								<TableSecondaryModal
									modalData={props.modalData_2}
									modalHeaders={props.modalHeaders}
									height={height}
									withoutImage={props.withoutImage}
								/>
							</div>
							:
							<TableSecondaryModal
								modalData={props.modalData}
								modalHeaders={props.modalHeaders}
								height={height}
								withoutImage={props.withoutImage}
							/>
						}
					</div>
				</Col>
			</div>
		</Styles>
	)
}
export default DarkModal;