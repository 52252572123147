import React from 'react'
import LevelOne from '../../maxillofacial/mobile/LevelOne';

const MiniplatingSystem = () => {
	return (
		<LevelOne
			system={"miniplatingSystem"}
		/>
	)
}

export default (MiniplatingSystem)