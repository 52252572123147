import React,{useState} from 'react'
import { Row, Image, Col, Container, Table, Button } from "react-bootstrap"
import { navigate } from 'hookrouter'
import { Trans } from "react-i18next"
import i18n from "i18next";

import { Styles, ProductCardStyles, SurgicalKitStyles } from "./Styles";
import RegisterMark from "../../../../components/registerMark";
import dataGenerator from "./data.js";

/* Assets */
/* FRESAS-MUCOTOMOS-STOP-PREPARADORES DE HOMBRO */
import ImageCards1_1 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_1/image-card-1.png";
import ImageCards1_2 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_1/image-card-2.png";
import ImageCards1_3 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_1/image-card-3.png";
import ImageCards1_4 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_1/image-card-4.png";
import ImageCards1_5 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_1/image-card-5.png";
import ImageCards1_6 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_1/image-card-6.png";

import ImageCards2_1 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_2/image-card-1.png";

import ImageCards3_1 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-1.png";
import ImageCards3_2 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-2.png";
import ImageCards3_3 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-3.png";
import ImageCards3_4 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-4.png";
import ImageCards3_5 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-5.png";
import ImageCards3_6 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-8.png";
import ImageCards3_7 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-6.png";
import ImageCards3_8 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-7.png";
import ImageCards3_9 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-9.png";
import ImageCards3_10 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_3/image-card-10.png";

import ImageCards4_1 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-1.png";
import ImageCards4_2 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-2.png";
import ImageCards4_3 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-3.png";
import ImageCards4_4 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-4.png";
import ImageCards4_5 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-5.png";
import ImageCards4_6 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-6.png";
import ImageCards4_7 from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/cards_4/image-card-7.png";


import VioletWaveImg from "../../../../assets/images/onda-violeta-2.png";
import SurgicalKitImage from "../../../../assets/images/Dental/conicalConnection/surgicalInstruments/kitImage.png";

const cards_1_images = [ImageCards1_1, ImageCards1_2, ImageCards1_3, ImageCards1_4, ImageCards1_5, ImageCards1_6]
const cards_3_images = [ImageCards3_1, ImageCards3_2, ImageCards3_3, ImageCards3_4, ImageCards3_5, ImageCards3_6, ImageCards3_7, ImageCards3_8, ImageCards3_9, ImageCards3_10]
const cards_4_images = [ImageCards4_1, ImageCards4_2, ImageCards4_3, ImageCards4_4, ImageCards4_5, ImageCards4_6, ImageCards4_7]

const widthImages_1 = ["106%", "103%", "52%", "46%", "30%", "32%"]
const widthImages_3 = ["100%", "90%", "98%", "95%", "92%", "100%", "93%", "89%", "93%", "69%"]
const widthImages_4 = ["57%", "80%", "100%", "82%", "83%", "55%", "65%"]
const SurgicalInstruments = () => {
  const [data, setData] = useState(dataGenerator(i18n.language))
  i18n.on('languageChanged', function (lng) {
    setData(dataGenerator(lng))
  })
  function renderFresasStop() {
    let render = [];
    for (let i = 0; i < 2; i++) {
      render.push(
        <Col className="zIndex100" md={2}>
          <ProductCard
            title={"CCsurgicalInstruments.cards_1.card_" + (i + 1) + ".title"}
            subtitle={"CCsurgicalInstruments.cards_1.card_" + (i + 1) + ".subtitle"}
            carddata={data.cards_1[i]}
            image={cards_1_images[i]}
            cardType={1}
            widthImage={widthImages_1[i]}
          ></ProductCard>
        </Col>
      )
    }
    render.push(
      <Col className="zIndex100" md={4}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_1.card_3.title"}
          subtitle={<RegisterMark>{"CCsurgicalInstruments.cards_1.card_3.subtitle"}</RegisterMark>}
          carddata={data.cards_1[2]}
          image={cards_1_images[2]}
          widthImage={widthImages_1[2]}
          cardType={2}
          cantElements={2}
        ></ProductCard>
      </Col>
    )
    render.push(
      <Col className="zIndex100" md={4}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_1.card_4.title"}
          subtitle={"CCsurgicalInstruments.cards_1.card_4.subtitle"}
          subtitles={["CCsurgicalInstruments.cards_1.card_4.subtitle_1", "CCsurgicalInstruments.cards_1.card_4.subtitle_2"]}
          carddata={data.cards_1[3]}
          image={cards_1_images[3]}
          widthImage={widthImages_1[3]}
          cardType={2}
          cantElements={2}
        ></ProductCard>
      </Col>
    )
    render.push(
      <Col className="zIndex100" md={5}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_1.card_5.title"}
          subtitles={["", "", ""]}
          carddata={[data.cards_1[4], data.cards_1[5]]}
          images={[cards_1_images[4], cards_1_images[5],]}
          widthImages={[widthImages_1[4], widthImages_1[5]]}
          cardType={4}
        ></ProductCard>
      </Col>
    )
    return render;
  }
  function renderLlaves() {
    let render = [];

    render.push(
      <Col className="zIndex100" md={4}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_3.card_1.title"}
          subtitle={"CCsurgicalInstruments.cards_3.card_1.subtitle"}
          subtitles={["CCsurgicalInstruments.cards_3.card_1.subtitle_1", "CCsurgicalInstruments.cards_3.card_1.subtitle_2"]}
          carddata={[data.cards_3[0], data.cards_3[1]]}
          images={[cards_3_images[0], cards_3_images[1]]}
          widthImages={[widthImages_3[0], widthImages_3[1]]}
          cardType={4}
        ></ProductCard>
      </Col>
    )
    render.push(
      <Col className="zIndex100" md={2}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_3.card_2.title"}
          subtitle={"CCsurgicalInstruments.cards_3.card_2.subtitle"}
          subtitles={["CCsurgicalInstruments.cards_3.card_2.subtitle_1"]}
          carddata={[data.cards_3[2]]}
          images={[cards_3_images[2]]}
          widthImages={[widthImages_3[2]]}
          cardType={4}
          cantElements={1}
        ></ProductCard>
      </Col>
    )
    render.push(
      <Col className="zIndex100" md={4}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_3.card_3.title"}
          subtitle={"CCsurgicalInstruments.cards_3.card_3.subtitle"}
          subtitles={["CCsurgicalInstruments.cards_3.card_3.subtitle_1", "CCsurgicalInstruments.cards_3.card_3.subtitle_2"]}
          carddata={[data.cards_3[3], data.cards_3[4]]}
          images={[cards_3_images[3], cards_3_images[4]]}
          widthImages={[widthImages_3[3], widthImages_3[4]]}
          cardType={4}
        ></ProductCard>
      </Col>
    )
    render.push(
      <Col className="zIndex100" md={6}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_3.card_4.title"}
          subtitle={"CCsurgicalInstruments.cards_3.card_4.subtitle"}
          subtitles={["CCsurgicalInstruments.cards_3.card_4.subtitle_1", "CCsurgicalInstruments.cards_3.card_4.subtitle_2", "CCsurgicalInstruments.cards_3.card_4.subtitle_3"]}
          carddata={[data.cards_3[5], data.cards_3[6], data.cards_3[7]]}
          images={[cards_3_images[5], cards_3_images[6], cards_3_images[7]]}
          widthImages={[widthImages_3[5], widthImages_3[6], widthImages_3[7]]}
          cardType={4}
        ></ProductCard>
      </Col>
    )
    render.push(
      <Col className="zIndex100" md={4}>
        <ProductCard
          title={"CCsurgicalInstruments.cards_3.card_5.title"}
          subtitle={"CCsurgicalInstruments.cards_3.card_5.subtitle"}
          subtitles={["CCsurgicalInstruments.cards_3.card_5.subtitle_1", "CCsurgicalInstruments.cards_3.card_5.subtitle_2"]}
          carddata={[data.cards_3[8], data.cards_3[9]]}
          images={[cards_3_images[8], cards_3_images[9]]}
          widthImages={[widthImages_3[8], widthImages_3[9]]}
          cardType={4}
        ></ProductCard>
      </Col>
    )
    return render;
  }
  function renderInstrumentos() {
    let render = [];

    for (let i = 0; i < 7; i++) {
      render.push(
        <Col className="zIndex100" md={3}>
          <ProductCard
            title={"CCsurgicalInstruments.cards_4.card_" + (i + 1) + ".title"}
            carddata={data.cards_4[i]}
            image={cards_4_images[i]}
            cardType={1}
            widthImage={widthImages_4[i]}
          ></ProductCard>
        </Col>
      )
    }

    return render;
  }
  return (
    <Styles>
      <div className="content-images-wave">
        <Image src={VioletWaveImg} className="img-fluid image-wave"></Image>
        <Image src={VioletWaveImg} className="img-fluid image-wave-reverse"></Image>
      </div>
      <div className="container-page">
        <h1 class="title"><Trans>CCsurgicalInstruments.self</Trans></h1>
        <Container fluid={true} className="row-content">
          <h1><Trans>CCsurgicalInstruments.title_1</Trans></h1>
          <Row className="content-cards">
            {renderFresasStop()}
          </Row>
        </Container>
        <Container fluid={true} className="row-content">
          <h1><Trans>CCsurgicalInstruments.title_2</Trans></h1>
          <Row className="content-cards">
            <Col className="zIndex100" md={2}>
              <ProductCard
                title={"CCsurgicalInstruments.cards_2.card_1.title"}
                subtitle={"CCsurgicalInstruments.cards_2.card_1.subtitle"}
                carddata={data.cards_2[0]}
                image={ImageCards2_1}
                cardType={1}
                widthImage={"100%"}
              ></ProductCard>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="row-content">
          <h1><Trans>CCsurgicalInstruments.title_3</Trans></h1>
          <Row className="content-cards">
            {renderLlaves()}
          </Row>
        </Container>
        <Container fluid={true} className="row-content">
          <h1><Trans>CCsurgicalInstruments.title_4</Trans></h1>
          <Row className="content-cards">
            {renderInstrumentos()}
          </Row>
        </Container>
        <Container fluid={true} className="row-content">
          <h1><Trans>CCsurgicalInstruments.cards_5.title</Trans></h1>
          <SurgicalKit 
            data={data}
          />
        </Container>
        <div className="content-buttons mb-5">
          <Button onClick={() => navigate('/dental/conicalConnection/prostheticComponents')}><Trans>dental.conicalConnection_modal.card_2.title</Trans></Button>
          <Button onClick={() => navigate('/dental/conicalConnection/newSmartImplants')}><Trans>dental.conicalConnection_modal.card_1.title</Trans></Button>
        </div>
      </div>
    </Styles>
  )
}
const ProductCard = (props) => {
  function renderCard_1() {
    return (
      <div className="card-content">
        <div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
        <h2 className="card-title"><Trans>{props.title}</Trans></h2>
        <h3 className="card-subtitle"><Trans>{props.subtitle}</Trans></h3>
        {generateCodesFirst(props.carddata)}
        {props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
      </div>
    )
  }
  function renderTable() {
    let render = []
    for (let i = 0; i < props.cantElements; i++) {
      render.push(
        <>
          <Table className="table-content mr-3">
            <thead>
              <h3 className="card-subtitle"><Trans>{props.subtitles ? props.subtitles[i] : null}</Trans></h3>
              <tr>
                <td></td>
                <td></td>
                {props.carddata.products_type[0][0] !== "" ? <td></td> : null}
              </tr>
            </thead>
            <tbody>
              {generateCodesSecond(props.carddata.products_cod[i], props.carddata.products_type[i], props.carddata.products_detail[i])}
            </tbody>
          </Table>
        </>
      )
    }
    return render
  }
  function renderCard_2() {
    return (
      <div className="card-content">
        <h2 className="card-title"><Trans>{props.title}</Trans></h2>
        <h3 className="card-subtitle"><Trans>{props.subtitle}</Trans></h3>
        <div className="content-image" style={{ width: props.widthImage }}><Image src={props.image} className="card-image img-fluid" ></Image></div>
        <div className="d-flex">
          {renderTable()}
        </div>
        {props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
      </div>
    )
  }
  function renderCard_3() {
    return (
      <div className="cards-content-doble">
        <div className=" d-flex justify-content-around">
          <div className="content-image" style={{ width: props.widthImage }} ><Image src={props.image} className="card-image img-fluid" ></Image></div>
          <div className="content-image" style={{ width: props.widthImage_2 }} ><Image src={props.image_2} className="card-image img-fluid" ></Image></div>
        </div>
        <h2 className="card-title"><Trans>{props.title}</Trans></h2>
        <div className="card-content-info">
          <div className="card-content-doble">
            {generateCodesFirst(props.carddata)}
          </div>
          <div className="card-content-doble">
            {generateCodesFirst(props.carddata_2)}
          </div>
        </div>
        {props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
      </div>
    )
  }
  function renderCard_4() {
    let renderTable = [];
    let renderImages = []
    let i;
    for (i = 0; i < props.images.length; i++) {
      renderImages.push(
        <div className="content-image-type-4" >
          <Image src={props.images[i]} style={{ width: props.widthImages[i] }} className="card-image img-fluid" ></Image>
        </div>
      )
    }
    for (i = 0; i < props.images.length; i++) {
      renderTable.push(
        <Table className="table-content mr-3">
          <thead>
            <tr>
              <td className="td-header" style={{ width: "60%" }}><Trans>{props.subtitles[i]}</Trans></td>
              <td>{props.carddata[i].products_detail !== "" ? props.carddata[i].products_detail : ""}</td>
              <td></td>
            </tr>
          </thead>
          <tbody className="table-type-4">
            {generateCodesSecond(props.carddata[i].products_cod, props.carddata[i].products_type)}
          </tbody>
        </Table>
      )
    }
    return (
      <div className="card-content">
        <h2 className="card-title mb-0"><Trans>{props.title}</Trans></h2>
        <h2 className="card-title"><Trans>{props.subtitle}</Trans></h2>
        <div className="d-flex">
          {renderImages}
        </div>
        <div className="d-flex">
          {renderTable}
        </div>
        {props.cardInformation !== "" ? <p className="information-text"><Trans>{props.cardInformation}</Trans></p> : null}
      </div>
    )
  }
  function generateCodesSecond(products_cod, products_type, products_detail) {
    let table = [];
    for (let i = 0; i < products_cod.length; i++) {
      if (products_cod[i] !== "") {
        table.push(
          <>
            <tr>
              <td>{products_cod[i]}</td>
              {products_detail ? <td className="text-left">{products_detail[i]}</td> : ""}
              {products_type !== "" ? <td className="text-left">{products_type[i]}</td> : null}
            </tr>
            {props.productDetail ? <p className="product-detail"><Trans>{props.productDetail[i]}</Trans></p> : null}
          </>
        )
      }
    }
    return table;
  }
  function generateCodesFirst(carddata) {
    let table = [];
    for (let i = 0; i < carddata.products_cod.length; i++) {
      if (carddata.products_cod[i] !== "") {
        table.push(
          <div>
            <div className="card-text">
              <p>{carddata.products_cod[i]}</p>
              <p>{carddata.products_type[i]}</p>
            </div>
            {props.productDetail ? <p className="product-detail"><Trans>{props.productDetail[i]}</Trans></p> : null}
          </div>
        )
      } else {
        table.push(
          <div className="card-text-empty">
            <p>&nbsp;&nbsp;</p>
          </div>
        )
      }
    }
    return table;
  }
  switch (props.cardType) {
    case 1:
      return (
        <ProductCardStyles>
          {renderCard_1()}
        </ProductCardStyles>
      )
    case 2:
      return (
        <ProductCardStyles>
          {renderCard_2()}
        </ProductCardStyles>
      )
    case 3:
      return (
        <ProductCardStyles>
          {renderCard_3()}
        </ProductCardStyles>
      )
    case 4:
      return (
        <ProductCardStyles>
          {renderCard_4()}
        </ProductCardStyles>
      )
    default: return (null)
  }
}
const SurgicalKit = (props) => {
  const renderTable = (productCode, productDescription) => {
    return (
      <div className="table-scroll">
        <Table striped bordered size="sm">
          <thead>
            <tr>
              <th><Trans>IHCsurgicalInstruments.cards_5.table_header.header_1</Trans></th>
              <th><Trans>IHCsurgicalInstruments.cards_5.table_header.header_2</Trans></th>
            </tr>
          </thead>
          <tbody>
            {
              productCode.map((code, i) => {
                return (
                  <tr>
                    <td><RegisterMark>{productDescription[i]}</RegisterMark></td>
                    <td>{code}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </div>
    )
  }
  return (
    <SurgicalKitStyles>
      <div className="content-image">
        <Image src={SurgicalKitImage} className="img-fluid" id="img-kit"></Image>
      </div>
      <div className="content-tables pt-5 pb-5 d-flex justify-content-center">
        {
          props.data.cards_5.map((data, i) => {
            return (
              <Col md={6} className="content-table">
                <div className="titles">
                  <h3><RegisterMark>CCsurgicalInstruments.cards_5.table_{`${i + 1}`}.title</RegisterMark></h3>
                  <h4 className="mt-2 mb-2"><Trans>CCsurgicalInstruments.cards_5.table_{`${i + 1}`}.subtitle</Trans></h4>
                </div>
                {renderTable(data.productCode, data.productDescription, i)}
              </Col>
            )
          })
        }
      </div>
    </SurgicalKitStyles>
  )
}
export default SurgicalInstruments
