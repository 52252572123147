/*----- Core -----*/
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
/*----- Commons -----*/
import Montserrat from "../../commons/mixins/fonts/Montserrat";
import ArrowIcon from "../../assets/icons/right-arrow.svg";
import RegisterMark from '../../components/registerMark/index.js';

/*----- Styles -----*/
const StyledArrowList = styled.ul`
	${Montserrat(20, 600, "White")};
	list-style-image: url(${ArrowIcon});
	list-style-position: outside;
	padding-left: 19px;
	`;

/*----- Styles -----*/
const StyledArrowListText = styled.li`
	padding-left: 10px;
`;


/*----- Components -----*/
function ArrowList({ items }) {
	return (
		<StyledArrowList>
			{items.map(function(each, i){
				return <StyledArrowListText key={i}>{each}</StyledArrowListText>;
			})}
		</StyledArrowList>
	);
}
export default ArrowList;

/*----- PropTypes -----*/
ArrowList.propTypes = {
	items: PropTypes.array.isRequired
};
