import React, { useState } from "react";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Trans } from "react-i18next";
import i18next from "i18next";

import { navigate } from 'hookrouter';
/*Styles*/
import { Styles, FaicoSystemDentalStyles } from "./Styles";

/*Assests*/
import MicroscopeImg from "../../../assets/images/Dental/speed.png";
import DentalIconOne from "../../../assets/images/dental_icon_one.svg";
import DentalIconTwo from "../../../assets/images/dental_icon_two.svg";
import DentalIconThree from "../../../assets/images/Implant-Assembly.svg";
import DentalProductOne from "../../../assets/images/dental_product_two.png";
import DentalProductTwo from "../../../assets/images/dental_product_one.png";
import DentalProductThree from "../../../assets/images/expansores.png";
import DentalProductFour from "../../../assets/images/dental_product_four.png";
import UniversalSoldier from "../../../assets/images/kit_expansores.png";
import ConicConection from "../../../assets/images/10_Conexion_Conica.png";
import ImageConexionHexagono from "../../../assets/images/Dental/conexion_hexagono.png";
import ImageScrew from '../../../assets/images/Dental/implantes.png'
import OndaVioletaSection3 from '../../../assets/images/Dental/onda-violeta2.png'

/*Components*/
import ImplantologiaModal from "./ImplantologiaModal";
import ModalDentalLevelOne from "../../../components/modals/ModalDentalLevelOne";
import { Modal } from "../../../components/modals/Modal";
import { universalDataGenerate } from "./modalData";
import RegisterMark from "../../../components/registerMark";

const FaicoSystemDentalImages = [DentalProductOne, DentalProductTwo, DentalProductThree, DentalProductFour];

const Dental = (props) => {
	const [openModalImplantologia, setOpenModalImplantologia] = useState(false);
	const [openModalConexionHexagono, setOpenModalConexionHexagono] = useState(!!props.hexagonModalState);
	const [openModalConexionConica, setOpenModalConexionConica] = useState(!!props.conicalModalState);
	const [openModalUniversalExpanders, setOpenModalUniversalExpanders] = useState(false);
	const [universalData, setUniversalData] = useState(universalDataGenerate(i18next.language))

	const ModalSystemFaico = Modal(ModalDentalLevelOne);
	const ModalSection = Modal(ImplantologiaModal)

	i18next.on('languageChanged', function(lng) {
		setUniversalData(universalDataGenerate(lng))
	})

	function scrollModal(modal){
		if (!modal){
			document.body.classList.add('scrollModal')
		}else{
			document.body.classList.remove('scrollModal')
		}
	}
	function changeopenModalImplantologia() {
		setOpenModalImplantologia(!openModalImplantologia)
		scrollModal(openModalImplantologia);
	}
	function changeopenModalConexionHexagono() {
		if (openModalConexionHexagono) {
			navigate('/dental')
		}
		setOpenModalConexionHexagono(!openModalConexionHexagono);
		scrollModal(openModalConexionHexagono);

	}
	function changeOpenModalConexionConica() {
		setOpenModalConexionConica(!openModalConexionConica)
		scrollModal(openModalConexionConica);
	}
	function changeOpenModalUniversalExpanders() {
		setOpenModalUniversalExpanders(!openModalUniversalExpanders)
		scrollModal(openModalUniversalExpanders);
	}
	function redirect(i) {
		switch (i) {
		case 1:
			navigate('/dental/hexagonal_connections');
			break;
		case 2:
			navigate('/dental/conical_connections');
			break;
		case 3:
			navigate('/dental/universal_expanders');
			break;
		case 4:
			navigate('/dental/guided_surgery');
			break;
		default:
			break;
		}

	}
	return (
		<Styles>
			<div id="container">
				<div className="gradient-violet-one"></div>
				<div id="section1" className="section section1">

					<Row className="justify-content-center">
						<Col md={5} className="dental-data">
							<div className="container-title">
								<h1>Dental<span className="blue-dot">.</span></h1>
								<h3><RegisterMark>dental.landscape_body</RegisterMark></h3>
							</div>
							<div className="card">
								<div className="content-information">
									<div className="content-image">
										<Image alt="" className="" src={`${DentalIconOne}`}></Image>
									</div>
									<div className="content-text">
										<div className="title">
											<h1><Trans>dental.title_card_two_part_one</Trans><span className="blue-dot">.</span></h1>
											<h2><Trans>dental.title_card_two_part_two</Trans></h2>
										</div>
										<p><RegisterMark>dental.body_card_two</RegisterMark></p>
									</div>
								</div>
							</div>

						</Col>
						<Col md={5}  className="container-dental-image">
							<div style={{backgroundImage: `url(${ImageScrew})`}}/>
						</Col>
					</Row>
				</div>
				<div id="section2" className="section section2">

					<Row className="row-content-card justify-content-center">
						<Col md={5} className="card">
							<div className="content-information">
								<div className="content-image">
									<Image alt="" className="" src={`${DentalIconThree}`}></Image>
								</div>
								<div className="content-text">
									<div className="title">
										<h1><Trans>dental.title_card_three_part_one</Trans><span className="blue-dot">.</span></h1>
										<h2><Trans>dental.title_card_three_part_two</Trans></h2>
									</div>
									<p><RegisterMark>dental.body_card_three</RegisterMark></p>
								</div>
							</div>
						</Col>
						<Col md={5} className="card">
							<div className="content-information">
								<div className="content-image">
									<Image alt="" className="" src={`${DentalIconTwo}`}></Image>
								</div>
								<div className="content-text">
									<div className="title">
										<h1><Trans>dental.title_card_four_part_one</Trans><span className="blue-dot">.</span></h1>
									</div>
									<p><RegisterMark>dental.body_card_four</RegisterMark></p>
									<p><Trans>dental.body_card_four_four</Trans></p>
								</div>
							</div>
							<div className="content-button">
								<Button
									className="button zoomCard"
									onClick={() => { changeopenModalImplantologia() }}
								><Trans>see_more</Trans></Button>
							</div>
						</Col>
					</Row>

				</div>
				<div id="section3" className="section section3">
					<Row className="row-content-card justify-content-center">
						<Image alt="" className="ocv3" src={`${OndaVioletaSection3}`}></Image>
						<Col md={5} className="content-image">
							<Image alt="" className="microscope-img img-fluid" src={`${MicroscopeImg}`} ></Image>
						</Col>
						<Col md={5} className="card-section-3" >
							<div className="dental-card">
								<h1><Trans>dental.title_card_one_1</Trans><br></br><Trans>dental.title_card_one_2</Trans><span className="blue-dot">.</span></h1>
								<div className="card-text">
									<p><Trans>dental.body_card_one</Trans></p>
									<p><RegisterMark>dental.body_card_one_one</RegisterMark></p>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div id="section4" className="section section4">
					<FaicoSystemDental
						openModal={[changeOpenModalConexionConica, changeopenModalConexionHexagono,  changeOpenModalUniversalExpanders, () => redirect(4)]}
					></FaicoSystemDental>
				</div>
			</div>
			{/* PopUp */}
			<ModalSection
				classNamePopUp={"pop-up-style-implantologia"}
				onButtonClose={changeopenModalImplantologia}
				open={openModalImplantologia}
			/>
			<ModalSystemFaico
				classNamePopUp={"pop-up-style-conexion-hexagono"}
				open={openModalConexionHexagono}
				title={"dental.conexionHexagono_modal.title"}
				textTitleCant={4}
				textTitle={"dental.conexionHexagono_modal.texts"}
				subtitle={"dental.conexionHexagono_modal.subtitle"}
				textSubtitle={"dental.conexionHexagono_modal.text_three"}
				card_titles={["dental.conexionHexagono_modal.card_1.title", "dental.conexionHexagono_modal.card_2.title", "dental.conexionHexagono_modal.card_3.title"]}
				card_contents={["dental.conexionHexagono_modal.card_1.content","dental.conexionHexagono_modal.card_2.content","dental.conexionHexagono_modal.card_3.content"]}
				card_navigates={["/dental/internalHexagonConnection/smartImplants","/dental/internalHexagonConnection/prostheticComponents","/dental/internalHexagonConnection/surgicalInstruments"]}
				onButtonClose={changeopenModalConexionHexagono}
				image={ImageConexionHexagono}
				isModalTable={false}
				isModalSecondaryTable={false}
			/>
			<ModalSystemFaico
				classNamePopUp={"pop-up-style-conexion-hexagono"}
				open={openModalConexionConica}
				title={<RegisterMark>{"dental.conicalConnection_modal.title"}</RegisterMark>}
				textTitleCant={3}
				textTitle={"dental.conicalConnection_modal.texts"}
				subtitle={"dental.conicalConnection_modal.subtitle"}
				textSubtitle={"dental.conicalConnection_modal.text_two"}
				card_titles={["dental.conicalConnection_modal.card_1.title", "dental.conicalConnection_modal.card_2.title", "dental.conicalConnection_modal.card_3.title"]}
				card_contents={["dental.conicalConnection_modal.card_1.content","dental.conicalConnection_modal.card_2.content","dental.conicalConnection_modal.card_3.content"]}
				//card_navigates={["/dental/conicalConnection/smartImplants","/dental/conicalConnection/prostheticComponents","/dental/conicalConnection/surgicalInstruments"]}
				card_navigates={["/dental/conicalConnection/newSmartImplants","/dental/conicalConnection/prostheticComponents","/dental/conicalConnection/surgicalInstruments"]}
				onButtonClose={changeOpenModalConexionConica}
				image={ConicConection}
				isModalPrimaryTable={false}
				isModalSecondaryTable={false}
			/>
			<ModalSystemFaico
				classNamePopUp={"pop-up-style-conexion-hexagono"}
				open={openModalUniversalExpanders}
				title={"universal.modal.title"}
				textTitleCant={1}
				textTitle={"universal.modal.texts"}
				onButtonClose={changeOpenModalUniversalExpanders}
				image={UniversalSoldier}
				isModalPrimaryTable={true} Secondary
				isModalSecondaryTable={true}
				modalHeaders={["universal.modal.header.first","universal.modal.header.second","universal.modal.header.third","universal.modal.header.fourth"]}
				modalData={universalData.universalModalData}
				modalDataExpand={universalData.universalModalDataExpand}
				modalColors={universalData.universalModalDataCirclesColors}
				modalSecondary={"universal.modal.headerSecondary"}
				modalSecondaryHeaders={["universal.modal.headerSecondary.first","universal.modal.headerSecondary.second","universal.modal.headerSecondary.third"]}
				modalSecondaryData={universalData.universalModalSecondaryData}
				imageSize={3}
			/>
		</Styles>
	)

}
function FaicoSystemDental(props) {
	const cantCards = 5;
	function renderCards(openModal) {
		let render = []

		for (let i = 1; i < cantCards; i++) {
			render.push(
				<Col key={i} className="content-card zoomCard"
					onClick={openModal[i - 1]}
				>
					<div className="card">
						<div className={(i === 1 || i === 2) ? "content-image image-30"  : "content-image image-10"} >
							<Image src={`${FaicoSystemDentalImages[i - 1]}`} className="image" ></Image>
						</div>
						<div className="titles">
							<h1><RegisterMark>dental.system_faico_dental.cards.{`${i}`}.title</RegisterMark><span className="blue-dot">.</span></h1>
							<h2><Trans>dental.system_faico_dental.cards.{`${i}`}.subtitle</Trans></h2>
						</div>
					</div>
				</Col>
			)
		}
		return render;
	}
	return (
		<FaicoSystemDentalStyles>
			<div className="content">
				<Row className="titles">
					<Col md={7}>
						<h1 className="h1-flex"><RegisterMark>dental.system_faico_dental.title</RegisterMark><span className="blue-dot">.</span></h1>
						<h2><RegisterMark>dental.system_faico_dental.subtitle</RegisterMark></h2>
					</Col>
				</Row>
				<Row className="content-cards">
					{renderCards(props.openModal)}
				</Row>
			</div>
		</FaicoSystemDentalStyles>
	)
}

export default (Dental);