/*----- Core -----*/
import React from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap';
import { Trans } from 'react-i18next';
/*----- Assets -----*/
import Image from '../../../assets/images/flyermechanicFaico.png';
/*----- Actions -----*/

/*----- Selectors -----*/

/*----- Components -----*/

/*----- Component -----*/
import LandscapeCard from '../../../components/card/LandscapeCard';
import Subtitle from '../../../components/text/Subtitle';
/*----- Commons -----*/
import Styles from '../../../commons/styles/ProductsMainPage.js';
import { UniversalExpandersStyles } from './Styles'
import '../../../commons/i18n';

const UniversalExpanders = () => {
	return (
		<Styles>
			<UniversalExpandersStyles>
				<React.Fragment>
					<LandscapeCard
						title={<Trans>dental.universalExpanders.title</Trans>}
						body={<Trans>dental.universalExpanders.content</Trans>}
						imageUrl={Image}
					/>
					<Row className="body">
						<Col md={6}>
							<Subtitle content={<Trans>dental.guidedSurgery.content</Trans>} size={3}></Subtitle>
						</Col>
					</Row>
				</React.Fragment>
			</UniversalExpandersStyles>
		</Styles>
	);

}

export default connect()(UniversalExpanders)
