/*----- Core -----*/
import { navigate } from "hookrouter";
import React, {useEffect} from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import RegisterMark from "../../../components/registerMark";

/*----- Commons -----*/
import { Trans } from "react-i18next";
import ClinicalCases from '../../../commons/data/faico360/ClinicalCases';

//import img from "../../../assets/images/Implants/Logo_KiSS.png"
/*------ style -------*/
import { Style } from './style';

const ClinicalCaseDetail = (props) => {
    useEffect(() => {
        console.log(ClinicalCases.length);
        console.log(parseInt(props.index)+1);
        if(typeof ClinicalCases[props.index] === 'undefined'){
            navigate("/faico360");
        }
        
    }, []);
    //esto es por si ponen un index fuera del array
    function getCaseData(){
        let defaultCase= {
            number: "0",
            code: "",
            title: "",
            description: "",
            pdfUrl: "",
            imageUrl: "",
            detail: {
                mainImage: "",
                images: [ ],
                imagesZoom:[],
                learnMore: "",
                next: "",
                previous: "",
                isFirst: false,
                isLast: false
            }
        }
        if(typeof ClinicalCases[props.index] === 'undefined'){
            return defaultCase;
        }else{
            return ClinicalCases[props.index];
        }
    }

    //para levantar la data del caso usar la expresion
    //ClinicalCases[props.index]
    function getPrevious(){
        if(!getCaseData().detail.isFirst){
            return <div onClick={() => {navigate(getCaseData().detail.previous)}} className="nav-footer-button"><i class="arrow left"></i></div>
        }
    }

    function getReturn() {
        return (
            <div onClick={() => {navigate("/faico360")}} className="nav-footer-button">
                <a><Trans>breadcrumb./faico360</Trans></a>
            </div>
        )
    }

    function getNext(){
        if(!getCaseData().detail.isLast){
            return <div onClick={() => {navigate(getCaseData().detail.next)}} className="nav-footer-button"><i class="arrow right"></i></div>
        }
    }

    function getHeader(){
        if(getCaseData().detail.mainImageMobile == null){
            return;
        }

        return (
            <Col>
                <Row className="justify-content-center align-content-center">
                    <Image className="case-main-image" src={getCaseData().detail.mainImageMobile} style={getCaseData().detail.mainImageMobileStyle}></Image>
                </Row>
            </Col>
        )
    }

    function getTitle(){
        return <div className={"clinical-case-title clinical-case-title-container"}><RegisterMark>faico360.clinicalCases.cases.{getCaseData().number}.detail.title</RegisterMark></div>
    }

    function getSubTitle(){
        return <div className={"clinical-case-subtitle clinical-case-subtitle-container"}><Trans>faico360.clinicalCases.cases.{getCaseData().number}.detail.subtitle</Trans></div>
    }

    function getCaseNumber(){
        return <div className={"clinical-case-code clinical-case-code-container"}>#{getCaseData().code}</div>
    }

    function getCaseDescription(){
        return <div className={"clinical-case-description clinical-case-description-container"}><RegisterMark>faico360.clinicalCases.cases.{getCaseData().number}.detail.description</RegisterMark></div>
    }

    function getLearnMore(){
        if (getCaseData().number > 20){
            return (         
                <div>
                    <Button 
                        onClick={() => navigate('/dental/conicalConnection')} 
                        className="learn-more-button default-button">
                        <RegisterMark>faico360.clinicalCases.cases.{getCaseData().number}.detail.learnMore</RegisterMark>
                    </Button>
                </div>
            )
        }
        return (         
            <Row className="clear">              
            </Row>
        )
    }

    function getImageArray(){
        return getCaseData().detail.images;
    }
    function getImageArrayZoom() {
        return getCaseData().detail.imagesZoom;
    }
    function renderPage(){
        let data = getCaseData();
        
        if(!data.number){
            navigate("/faico360");
        }

        return(
            <>
                <Row className="d-flex justify-content-center align-content-center">
                    { getHeader() }
                </Row>
                <Row className="d-flex justify-content-center align-content-center">
                    {getTitle()}
                </Row>
                <Row className="d-flex justify-content-center align-content-center">
                    {getSubTitle()}
                </Row>
                <Row className="d-flex justify-content-center align-content-center">
                    {getCaseNumber()}
                </Row>
                <Row className="d-flex justify-content-center align-content-center">
                    {getCaseDescription()}
                </Row>
                <Row className="d-flex justify-content-center align-content-center gallery-container">
                    <Col>
                        <Row className="d-flex justify-content-center align-content-center gallery-display">
                            {getImageArray().map((img, index) => {
                                  const imgZoom=getImageArrayZoom()[index]
                                return (
                                    <Col key={"img-detail-"+index} lg={4}>
                                        <a className="centered" data-fancybox={"detail-gallery-mobile-"+props.index} href={imgZoom} target="_blank">
                                            <Image src={img} className="gallery-img"></Image>
                                        </a>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Row className="d-flex justify-content-center align-content-center">
                            { getLearnMore() }
                        </Row>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center align-content-center footer">
                    <Col sm={2} xs={3}>
                        <Row className="justify-content-center align-content-center">
                            { getPrevious() }
                        </Row>
                    </Col>
                    <Col>
                        <Row className="justify-content-center align-content-center">
                            { getReturn() }
                        </Row>
                    </Col>
                    <Col sm={2} xs={3}>
                        <Row className="justify-content-center align-content-center">
                            { getNext() }
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }


	return (
        <Style>
            {renderPage()}
        </Style>
	);
};

export default (ClinicalCaseDetail);