import React from "react";
import Popup from "reactjs-popup";
import { Styles } from "./ModalDarkMobileStyles"

export function ModalDarkMobile(WrappedComponent) {
  return class extends React.Component {

    render() {
      return (
        <Styles>
          <Popup
            open={this.props.open}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            className={"my-popup"}
            lockScroll={true}
          >
            <WrappedComponent
              {...this.props}
            ></WrappedComponent>
          </Popup>
        </Styles>
      )
    }
  }
}