import React from 'react';
import { Trans } from 'react-i18next';
import { isMobile } from "react-device-detect";
import Styles from './Styles';

const noticePrivacy = () => {
  return (
    <Styles isMobile={isMobile}>
      <h1><Trans>noticePrivacy.self</Trans></h1>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.1.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.1.text_1</Trans></p>
        <p><Trans>noticePrivacy.texts.1.text_2</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.text_1</Trans></p>
        <p><Trans>noticePrivacy.texts.2.text_2</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.section.a.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.section.a.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.section.b.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.section.b.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.section.c.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.section.c.text</Trans></p>
        <p><Trans>noticePrivacy.texts.2.section.c.text_1</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.section.d.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.section.d.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.section.e.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.section.e.text</Trans></p>
      </div>
      <div className="paragrah">
        <h2><Trans>noticePrivacy.texts.2.section.f.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.2.section.f.text</Trans></p>
      </div>
      <div className="paragrah m-0 p-0">
        <h2><Trans>noticePrivacy.texts.3.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.3.text_1</Trans></p>
      </div>
      <div className="paragrah m-0 p-0">
        <h2><Trans>noticePrivacy.texts.4.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.4.text_1</Trans></p>
      </div>
      <div className="paragrah m-0 p-0">
        <h2><Trans>noticePrivacy.texts.5.title</Trans></h2>
        <p><Trans>noticePrivacy.texts.5.text_1</Trans></p>
      </div>
      <div className="paragrah m-0 p-0">
        <h2><Trans>noticePrivacy.texts.6.title</Trans></h2>
        <p className="nro"><Trans>noticePrivacy.texts.6.text_1</Trans></p>
      </div>
    </Styles>
  )
}

export default noticePrivacy