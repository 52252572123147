import styled from 'styled-components'

export const Style = styled.div`
    .class-example{
        color: red;
    }


  
    .case-main-image{ 
        padding-top: 5.875rem; 
        left: 840px;
        max-width: 240px;
    }

    .clinical-case-title-container{
        margin: 8% 0 0 0;
    }

    .clinical-case-title{
        margin-top: 5.875rem;
        text-align: center;
        font: normal normal bold 45px/48px Montserrat;
        letter-spacing: 0px;
        color: #171717;
        opacity: 1;
    }

    .clinical-case-subtitle-container{
        margin: 3% 0 0 0;
    }

    .clinical-case-subtitle{
        margin-top: 2.313rem;
        text-align: center;
        font: normal normal bold 26px/36px Montserrat;
        letter-spacing: 0px;
        color: #171717;
        opacity: 1;
    }

    .clinical-case-code-container{
        margin: 0.5% 0 0 0;
    }

    .clinical-case-code{
        margin-top: 0.875rem;
        text-align: center;
        line-heigt: 36px;
        font: normal normal 22px/36px Montserrat;
        font-weight: 500;
        letter-spacing: 0px;
        color: #6C6C6C;
        opacity: 1;
    }

    .clinical-case-description-container{
        margin-top: 4.75rem;
    }

    .clinical-case-description{
        text-align: left;
        font: normal normal normal 24px/28px Montserrat;
        letter-spacing: 0px;
        color: #171717;
        opacity: 1;
    }

    .gallery-container{
        background: #1E1E1E 0% 0% no-repeat padding-box;
        opacity: 1;
        margin: 6% 0 0 0;
    }

    .gallery-display{
        margin: 6% 0 0 0;
    }

    .gallery-img{
        width: 100%;
        margin: 4% 0 0 0;
        border-radius: 22px;
    }

    .gallery-img:hover{
        //z-index: 100000;
        //width: 105% !important;
        //left: -2.5%;
        //position: absolute;
    }

    .learn-more-button{
        background: #7160FF 0% 0% no-repeat padding-box;
        opacity: 1;
        font: normal normal bold 16px/25px Montserrat;
        letter-spacing: 0px;
        color: #FFFFFF;
        border-radius: 45px;
        margin: 40% 0 40% 0;
        min-width: 403px;
    }
    .default-button {
        font-family: 'Montserrat',sans-serif;
        font-size: 15px;
        font-weight: 600;
        display: flex;
        text-align: center;
        border-radius: 29px;
        align-items: center;
        justify-content: center;
        align-content: center;
        border: none;
        background: #7160ff;
        width:144px;
        height:43px;
        margin-top:100px;
    }
    .clear{
        margin-top: 100px;
    }
   
    .footer{
        margin: 5% 0 3% 0;
    }

    .nav-footer-button{
        background: transparent;
        text-align: left;
        font: normal normal medium 14px/36px Montserrat;
        letter-spacing: 0px;
        color: #1E1E1E;
        opacity: 1;
    }

    .nav-footer-button:hover{
        text-decoration: underline;
    }

    .arrow {
        border: 1px solid #1E1E1E;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        width: 6px;
        font: normal normal medium 14px/36px Montserrat;
        letter-spacing: 0px;
        color: #1E1E1E;
        opacity: 1;
      }
      
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
      
      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
      
`;