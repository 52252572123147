import styled, { css } from 'styled-components'

export const DesktopStyles = styled.main`
  .about-us-container{
    height: 1228px;
    width:100%;
    color:white;
    font-family: 'Montserrat', 'light';
    position:relative;
    .content-image{
      position:absolute;
      height:100%;
      width:100%;
      top:0;
      img{
        height:100%;
        width:100%;
        z-index:10;
      }
      .gradient{
        position:absolute;
        height:100%;
        width:100%;
        top:0;
        opacity: 0.72;
        background: transparent linear-gradient(to bottom, #6F3CEA 0%, #25144D 46%, #000000 100%) 0% 0% no-repeat padding-box;
      }
    }
    .first-row{
      width:100%;
      height: 50%;
      .content-col{
        margin-left: 140px;
      }
      .title{
        display:flex;
        align-items:flex-end;
        margin-top: 256px;
        h1{
          font-weight:700;
          font-family: 'Montserrat', 'light';
          color:white;
          font-size:72px;
          z-index: 100;
          margin:0;
        }
        .dot {
          height: 15px;
          width: 15px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom:15px;
          margin-left:2px;
        }
      }
      p{
        font-size:26px;
        letter-spacing: 0px;
        line-height:36px;
        margin-top:35px;
        font-weight:300;
        font-family: 'Montserrat', 'light';
      }
      p{
        font-size:26px;
        letter-spacing: 0px;
      }
      .history-content{
        z-index: 100;
        width: 577px;
        height: 302px;
      }
      .content-col{
        z-index:100;
      }
    }
    .second-row{
      width:100%;
      height: 50%;
      margin-top:95px;
      .content-col{
        display:flex;
        margin-left:140px;
        margin-bottom:105px;
        @media  screen and (max-width: 1440px) {
          margin-left:105px;
        }
        .information-col{
          height:min-content;
          z-index: 100;
          letter-spacing: 0px;
          width: 680px;
          height: 347px;
          p{
            font-size:25px;
            line-height:31px;
            font-weight:400;
          }
        }
        .information-col:first-child{
          margin-right:160px;
          @media  screen and (max-width: 1440px) {
            margin-right:80px;
          }
        }
      }
    }
  }
  .about-us-data{
    background: #0F0F0F 0% 0% no-repeat padding-box;
    display:flex;
    justify-content:center;
    align-items:center;
		.product-card {
			background: transparent
				linear-gradient(219deg, #00000021 0%, #000000 100%) 0% 0% no-repeat
				padding-box;
			box-shadow: 0px 3px 6px #00000033;
			border-radius: 32px;
      padding:47px 48px 41px 64px;
			width: 520px;
      height: 392px;
      margin:0;
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      margin-top:163px;
      margin-bottom:163px;
      margin-left:40px;
      @media  screen and (max-width: 1440px) {
        padding:10px;
      }
      .title{
        position:absolute;
        display: flex;
        align-items: flex-end;
        h1{
          text-align:center;
          color:white;
          font-size:50px;
          font-weight:700;
          font-family: 'Montserrat', 'light';
        }
        .dot {
          height: 10px;
          width: 10px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom:20px;
          margin-left:2px;
        }
      }
			p {
				display: flex;
				width: 100%;
				justify-content: space-evenly;
        position:relative;
				opacity: 0;
				transition: 0.5s ease;
        margin-top: 10px;
    		font-size: 17px;
				color:white;
        font-family: 'Montserrat', 'light';
				margin-bottom:0px;
        letter-spacing: 0px;
        line-height:20px;
			}
			&:hover {
				&::before {
					opacity: 1;
				}
				.title {
					opacity: 1;
				}
				p {
					opacity: 1;
				}
				.title {
					opacity: 0;
					transition: 0s ease;

				}
			}
    }
    .product-card:first-child{
      margin-left:140px;
    }
    .product-card:last-child{
      margin-right:140px;
    }
    .product-card:hover{
        justify-content:flex-start;
        background: transparent linear-gradient(to bottom, #6F3CEA 0%, #4B299E 0%, #070707 100%) 0% 0% no-repeat padding-box;
    }
  }
`;

export const MobileStyles = styled.main`
  width: 100%;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  .info-content{
    width: 100%;
    overflow: hidden;
    position: relative;
    .title-content{
      position: relative;
      z-index: 10;
      margin: 60px 28px 60px 38px;
      opacity: 1;
      .title{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 12px;
        h1 {
          color: white;
          font-size: 32px;
          font-weight: 700;
        }
        .dot {
          height: 8px;
          width: 8px;
          background-color: #49D3EA;
          border-radius: 50%;
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 5px;
        }
      }
      .history-content{
        p{
          color: white;
          font-size: 15px;
          font-weight: 300;
          line-height: 19px;
        }
      }
    }
    .text-content{
      z-index: 10;
      position: relative;
      margin: 0px 34px 55px 34px;
      p{
        color: white;
        font-size: 15px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 55px;
      }
    }
    .container-image{
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      img{
        z-index: 1;
        position: absolute;
        mix-blend-mode: soft-light;
        left: -600px;
      }
      .gradient{
        z-index: 2;
        position: absolute;
        background: transparent linear-gradient(to bottom, #6F3CEA 0%, #25144D 46%, #000000 100%) 0% 0% no-repeat padding-box;
        opacity: 0.72;
        width: 100%;
        height: 100%;
      }
    }
  }
  .cards-content{
    background: #0F0F0F;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
  }
`;

export const AccordionStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
 .accordion{
    width: 90%;
    margin-bottom: 30px;
    background: #000000;
    border-radius: 20px;
    .card{
      border-radius: 20px !important;
      background: #000000;
      padding: 30px 20px 20px 20px;
      .card-header{
        padding: 0px;
        .title{
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          h1{
            color: white;
            font-size: 22px;
            font-weight: 700;
            line-height: 19px;
          }
          .dot {
            height: 4px;
            width: 4px;
            background-color: #49D3EA;
            border-radius: 50%;
            display: inline-block;
            margin-bottom: 8px;
            margin-left: 3px;
          }
        }
        .line{
          width: 100%;
          border-bottom: 1px solid rgb(73, 211, 234);
          margin: 5px 0px;
        }
        .container-toggle{
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
    .accordion-collapse{
      .card-body{
        padding: 10px 0px 0px 0px;
        p{
          color: white;
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
        }
      }
    }
  }
  ${props =>
		props.open && css`
			.accordion{
				border-bottom-left-radius:  0px;
				border-bottom-right-radius: 0px;
			}
	`}
`;
