import React from 'react';
import { Trans } from 'react-i18next';
/* import { Image } from 'react-bootstrap'; */
import ProstheticComponentsStyles from './styles';
import RegisterMark from '../../../../../components/registerMark';

const ProstheticComponents = ({ content }) => {
  const renderList = () => {
    let render = [];
    content.items.forEach(element => {
      render.push(
        <div className="element">
          <span className="dot"></span>
          <p><Trans>{element}</Trans></p>
        </div>
      )
    });
    return render;
  }
  return (
    <ProstheticComponentsStyles id="prosthetic-components">
      <div className="system-text">
        <p><Trans><RegisterMark>{content.text}</RegisterMark></Trans></p>
        <div className="system-list">
          {renderList()}
        </div>
      </div>
    </ProstheticComponentsStyles >
  )
}

export default ProstheticComponents;