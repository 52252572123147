import styled from 'styled-components'

export const Styles = styled.div`
  background:#1c1c1c;
  display:flex;
  flex-direction: row;
  width: 100%;

  .section{
    display:flex;
    width: 100%;
    position:relative;
  }
  .section1{
    display: flex;
    align-items: center;
    .first-gradient{
      position:absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #8958FF 0%, #25144D 51%, #000000 150%) 0% 0% no-repeat padding-box transparent;
      opacity: 0.6;
    }
    .violet-wave{
      position:absolute;
      opacity: 0.6;
      width: 100%;
      padding-top: 100px;
    }
    .content-info{
      display: flex;
      flex-direction: row;
      height:max-content;
      padding: 171px 0px 131px 141px;
      .content-text{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        p {
          color: white;
          font-size: 26px;
          font-family: 'Montserrat',sans-serif;
          margin-top: 35px;
          font-weight: 300;
          padding-right: 180px;
          line-height: 36px;
        }
        h1{
          letter-spacing: 0px;
          color: white;
          font-size: 72px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
        }
      }
      .content-cards{
        margin-top: 103px;
        .info{
          .info-text{
            padding-right: 150px;
          }
        }
      }
    }
    .img-kit{
      position:absolute;
      width: 986px;
      height: 816px;
    }
  }

  .last-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .second-gradient{
      position:absolute;
      width: 100%;
      height: 100%;
      background: transparent linear-gradient(180deg, #25144D 30%, #000000 100%) 0% 0% no-repeat padding-box;
      opacity: 0.6;
    }
    .section2{
      height:max-content;
      flex-direction:row;
      width:100%;
      padding: 160px 284px 143px 140px;
      .card{
        display: flex;
        justify-content: space-around;
        .info{
          margin-left: 20px;
          .info-text{
            padding-right: 15px;
          }
        }
      }
      .card:first-child{
        margin-right: 30px;
      }
    }
    .section3{
      height:max-content;
      padding: 0px 140px 200px 140px;
      @media  screen and (max-width: 1440px) {
        padding:0 60px;
      }
    }
  }

  .gradient-violet-one {
    position: absolute;
    left: 0px;
    background: linear-gradient(197deg, rgba(113, 97, 255, 0.655) 0%, rgba(147, 84, 221, 0.455) 51%, rgba(116, 49, 128, 0) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    z-index: 101;
    width: 100%;
    height:138vh;
  }
  .gradient-two{
    height: 110vh;
    width: 100%;
    position: absolute;
    top: 20vh;
    z-index: 102;
    background: linear-gradient(rgba(28, 28, 28, 0) 0%, rgb(28, 28, 28) 100%) 0% 0% no-repeat padding-box padding-box transparent;
  }
  .gradient-three{
    background: rgb(28, 28, 28);
    width: 100%;
    height:100vh;
    top:99vh;
    z-index: 102;
    position:absolute;
  }
  .gradient-four{
    position: absolute;
    background: linear-gradient(16deg,rgb(28,28,28) 39%,rgba(255,255,255,0) 65%);
    width: 100%;
    height: 125vh;
    z-index: 104;
    top: 140vh;
  }

  .card{
    display: flex;
    flex-direction: row;
    background: transparent;
    border: none;
    padding: 0px;
    .content-image{
      position: relative;
      .circle{
        width: 97px;
        height: 97px;
        position: absolute;
        background: black;
        border-radius: 50%;
        display: inline-block;
        padding: 0px;
        opacity: 0.4;
      }
      .img-card{
        position: absolute;
        width: 97px;
        height: 97px;
        opacity: 1;
      }
    }
    .info{
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      margin-top: 15px !important;
      .info-title{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        h1{
          letter-spacing: 0px;
          color: white;
          font-size: 33px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
        }
        h2{
          letter-spacing: 0px;
          color: white;
          font-size: 25px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 300;
        }
      }
      .info-text{
        margin-top: 40px;
        h3{
          letter-spacing: 0px;
          color: white;
          font-size: 22px;
          font-family: 'Montserrat', sans-serif;
          font-weight: 700;
          line-height: 30px
        }
      }
    }
  }
`
export const FaicoSystemCranioStyles = styled.div`
	.content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
	}
	.titles{
    display:flex;
    flex-direction:column;
    margin-bottom: 93px;

		h1{
		font-family: 'Montserrat', sans-serif;
		color:white;
		font-size: 61px;
		font-weight: 700;
		margin-bottom: 27px;
    }
		h2{
			font-family: 'Montserrat', sans-serif;
			color:white;
			font-size: 23px;
		  font-weight: 300;
      margin-right: 700px;
      line-height: 36px;
      @media  screen and (max-width: 1440px) {
        margin-right: 0px;
      }
		}
    .h1-flex {
      display: flex;
    }
	}
  .content-cards{
    display: flex;
    justify-content:center;
    flex-flow: wrap;
    margin:0px;
    width: 100%;

  }
	.content-card{
    margin-bottom: 46px;
    padding: 0px;
    width: 100%;
    @media  screen and (max-width: 1440px) {
      margin:40px;
    }
		.card{
      width: 381px;
      height: 294px;
      background: transparent linear-gradient(90deg, #333D98 0%, #4345A6 10%, #695AC7 37%, #8469DF 62%, #9572ED 83%, #9B76F3 100%) 0% 0% no-repeat padding-box;			box-shadow: 0px 4px 4px #3d339836;
			border-radius: 10px;
			display:flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			cursor: pointer !important;
      margin-right: 20px;
        @media  screen and (max-width: 1440px) {
          width: 100%;
          height: 100%;
          margin:0px;
        }
			}
			.card-titles{
				margin:0;
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
        position:relative;
        height:280px;
        .title-container{
          display:flex;
			  	width: 100%;
          flex-direction:row;
          margin-bottom: 24px;
          min-height: 120px;
          h1{
            font-family: 'Montserrat', sans-serif;
            color:white;
            font-size: 21px;
            font-weight: 700;
            margin: 45px 0px 0px 30px;
          }
          .content-img{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0px;
            overflow: hidden;
            margin-top: 19px;
            img{
              position: absolute;
              width: 85px;
              height: 116px;
            }
            .img-square{
              position: absolute;
              width: 83px;
              height: 57px;
              margin-left: 30px;
              background: transparent linear-gradient(90deg, #333D98 0%, #3B419F 16%, #534EB4 43%, #7A64D6 76%, #9B76F3 100%) 0% 0% no-repeat padding-box;
            }
          }
        }
				h2{
					font-family: 'Montserrat', sans-serif;
					color:white;
					font-size:18px;
          font-weight:300;
          line-height: 21px;
          margin: 0px 30px;
				}
			}
		}
	.zoomCard {
		z-index:900;
		transition: transform .2s; /* Animation */
	}
	.zoomCard:hover {
		transform: scale(1.03); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
	}
`

export const DotStyles = styled.span`
  color: #49D3EA
`