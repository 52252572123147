import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Styles } from "./NavigationConfirmModalStyles"
import { navigate } from "hookrouter";

const NavigationConfirmModal = (props) => {
	return (
		<Styles>
			<Container className="modal-container" fluid>
				<Row className="close-row justify-content-end">
					<Button variant="danger" className="close" onClick={() => props.onButtonClose(props.currentModal)}
						>&times;</Button>
				</Row>
				<Row className="title-row justify-content-center align-items-center">
					<span className="title">{props.title}</span>
				</Row>
				<Row>
					<span className="description">{props.description}</span>
				</Row>
				<Row>
					
				</Row>
				<Row className="buttons-row justify-content-center">
						<button className="confirm-button modal-button" onClick={() => {
							props.onButtonClose(props.currentModal);
							navigate('/' + props.route);
						}}>
							{props.confirmBtnMsg}
						</button>
				</Row>
				<Row className="buttons-row justify-content-center">
						<button className="cancel-button  modal-button" onClick={() => props.onButtonClose(props.currentModal)}
						>{props.cancelBtnMsg}</button>
				</Row>
				
			</Container>
		</Styles>
	)
}
export default NavigationConfirmModal;