/*----- Core -----*/
import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Image, Col, Button } from "react-bootstrap";
import { Parallax } from 'react-parallax';
/*----- Assets -----*/
import FirstSlider from "../../../assets/images/home_carousel_1.png";
import SecondSlider from "../../../assets/images/home_carousel_2E.png";
import SecondSliderSpanish from "../../../assets/images/home_carousel_2S.png";
import SloganSlider from "../../../assets/images/sloga-slider-desktop.png";
import DentalImage from "../../../assets/images/dental_placeholder.png";
import CranioImage from "../../../assets/images/cranio_placeholder_1.png";
import FaicoConnect from "../../../assets/images/Home/faico-connect.png"
import RefreshIcon from "../../../assets/images/Home/faico-360-flecha.png";
import AboutUsHome from "../../../assets/images/abous-us-image.jpg";
/*----- Components -----*/
import { navigate } from "hookrouter";
import Carousel from "../../../components/carousel";
import { Styles } from "./Styles";
import DefaultButton from "../../../components/button/DefaultButton";
import Title from "../../../components/text/Title";
import Subtitle from "../../../components/text/Subtitle";
import ArrowList from "../../../components/list/ArrowList";
import { withTranslation } from 'react-i18next';

/*----- Sections -----*/
import "../../../commons/i18n";
import i18n from "../../../commons/i18n";
import { Trans } from "react-i18next";
import { postsSelector, } from '../../news/redux/NewSelectors';

/*----- Actions -----*/
import { fetchNews, resetNews } from '../../news/redux/NewsActions';
import RegisterMark from '../../../components/registerMark';
import { ModalSmall } from '../../../components/modals/ModalSmall';
import NavigationConfirmModal from '../../../components/modals/NavigationConfirmModal';
import { ModalDarkDesktop } from '../../../components/modals/templates/modalDarkDesktop/ModalDarkDesktop'
import FVconModal from '../../../components/modals/FVconModal/FVconModal'
// import NoveltyTile from "../../../components/news/NoveltyTile";

/*----- Export -----*/
const Home = props => { 
	const { t } = props;

	const [openFaico360NavModal, setOpenFaico360NavModal] = useState(false);
	const Faico360NavModalContainer = ModalSmall(NavigationConfirmModal);

	function openFaico360NavModalCmd(){
		setOpenFaico360NavModal(true);
	}

	function closeFaico360NavModal(){
		setOpenFaico360NavModal(false);
	}

	const [openFVconModal, setopenFVconModal] = useState(true);
	const FVconModalContainer = ModalDarkDesktop(FVconModal);

	function closeFVconModal(){
		setopenFVconModal(false);
	}

	const GetImageSecondSlider = () => {
		if(i18n.language == 'en'){
			return (<Image src={SecondSlider} />)
		}

		return (<Image src={SecondSliderSpanish} />)
	}

	// const [postData, setPostData] = useState([]);
	// const {
	// 	fetchNews,
	// 	postProps,
	// 	resetNews
	// } = props;

	// useEffect(() => {
	// 	resetNews()
	// }, [resetNews])

	// useEffect(() => {
	// 	const payload = {
	// 		"per_page": 3,
	// 		"page": 1
	// 	}
	// 	fetchNews(payload);
	// }, [fetchNews]);

	// useEffect(() => {
	// 	if (postProps.length !== 0) {
	// 		setPostData(postProps);
	// 	}
	// }, [postProps])

	return (
		<Styles>
			<Carousel className="carousel" autoPlayInterval={7000} indicator={true} switcher={true}>
				<div className="carousel-image">
					<div className="content-image">
						<Image src={SloganSlider}></Image>
					</div>
					<div className="carousel-text">
						<div className="content-text mb-5 pb-5">
							<h1><Trans>home.slogan.1</Trans></h1>
							<h1><Trans>home.slogan.2</Trans></h1>
							<h1><Trans>home.slogan.3</Trans></h1>
						</div>
					</div>
				</div>
				<div className="carousel-image">
					<div className="content-image">
						{
							GetImageSecondSlider()
							//<Image src={SecondSlider}></Image>
						}
					</div>
					<div className="carousel-text">
						<div className="content-text">
							<p className="first-text"><Trans>home.carousel.1.text_1</Trans></p>
							<p className="first-text"><Trans>home.carousel.1.text_2</Trans></p>
							<h1><Trans>home.carousel.1.title_desktop</Trans></h1>
							<p className="second-text"><Trans>home.carousel.1.text_2_desktop</Trans></p>
							<a target="_blank" href={"https://www.aonimplants.it/"}>															
							<DefaultButton
								content={<Trans>general.goto</Trans>}
								colorText={"white"}
							/>
							</a>
						</div>
					</div>
				</div>
				<div className="carousel-image" id="my-carousel">
					<div className="content-image">
						<Image src={FirstSlider}></Image>
					</div>
					<div className="carousel-text">
						<div className="content-text">
							<p className="first-text"><Trans>home.carousel.2.text_1</Trans></p>
							<h1><Trans>home.carousel.2.title_desktop</Trans></h1>
							<p className="second-text"><Trans>home.carousel.2.text_2_desktop</Trans></p>
							<DefaultButton
								content={<Trans>general.ver-mas</Trans>}
								colorText={"white"}
								onClick={() => navigate('/maxillofacial')}
							/>
						</div>
					</div>
				</div>
			</Carousel>

			<div className="who-we-are-container">
				<Parallax
					className="aboutUsImg"
					bgImage={AboutUsHome}
					bgImageAlt="faico"
					strength={200}
				>
					<div className="parallax" />
				</Parallax>
				<div className="content-image">
					<div className="linear-effect"></div>
				</div>
				<div className="main-row">
					<div className="column-information" >
						<div className="content-information">
							<h1><Trans>home.who_we_are</Trans></h1>
							<h2><Trans>home.who_we_are_text</Trans></h2>
							<div className="pl-0">
								<Button
									onClick={() => navigate('/about')}
									className="default-button zoomButton">
									<Trans>general.ver-mas</Trans>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="dental-container d-flex justify-content-center">
				<div className="content-image ">
					<img alt="" className="img-fluid" src={`${DentalImage}`} />
				</div>
				<div className="content-information flex-column">
					<Title
						content={<RegisterMark>home.dental_subtitle</RegisterMark>}
						size={"home-faico"}
					/>
					<Title
						content={<Trans>home.dental_title</Trans>} size={"home"} />
					<Subtitle
						content={<RegisterMark>home.dental_description</RegisterMark>}
						size={'home'}
					/>
					<ArrowList
						items= {[
							<RegisterMark>home.dental_list.1</RegisterMark>,
							t('home.dental_list.2'),
							t('home.dental_list.3'),
							t('home.dental_list.4'),
							t('home.dental_list.5'),
						]}
					/>
					<Col md={2} className="p-0">
						<DefaultButton
							content={<Trans>general.ver-mas</Trans>}
							onClick={() => navigate('/dental')}
							colorText={"white"}
						/>
					</Col>
				</div>
			</div>

			<div className="cranio-container">
				<div className="row main-row">
					<div className="col-5 cranioCont">
						<Title
							content={<RegisterMark>home.dental_subtitle</RegisterMark>} size={"home-faico"} />
						<Title
							content={<Trans>home.cranio_title</Trans>} size={"home"} />
						<Subtitle
							content={<RegisterMark>home.cranio_description</RegisterMark>}
							size={'home'}
						/>
						<ArrowList
							items={[
								"Microplating System",
								"Mediumplating System",
								"Miniplating - Low-Profile System",
								"Miniplating - Standard System",
								"Fracture Plating System",
								"Mandibular Reconstruction",
								"IMF System"
							]}
						/>
						<DefaultButton
							content={<Trans>general.ver-mas</Trans>}
							onClick={() => navigate('/maxillofacial')}
							colorText={"white"}
						/>
					</div>
					<div className=" col-5 content-image">
						<img alt="" className="cranio-img" src={`${CranioImage}`} />
					</div>
				</div>
			</div>

			<div className="faicoConnect-container">
				<Parallax
					bgImage={FaicoConnect}
					bgImageAlt="faicoConnect"
					strength={200}
				>
					<div className="parallax" />
				</Parallax>
				<div className="content-image">
					<div className="linear-effect"></div>
				</div>
				<div className="faicoConnect-content">
					<div className="column-information" >
						<div className="content-information">
							<Title
								content={<Trans>home.faico360_card_connect.item_3</Trans>}
								size={"home-faico"} />
							<h1><Trans>home.faico360_consulting_room</Trans></h1>
							<h3><Trans>home.faico360_visit</Trans></h3>
							<Button className="default-button" onClick={() => navigate("/contact")}>
								<Trans>home.faico360_write_us</Trans>
							</Button>
						</div>
					</div>
				</div>
			</div>

			<div className="faico360-container">
				<div className="row card-faico360-connect">
					<div className="col-4 p-0 refresh-content">
						<Image src={`${RefreshIcon}`} className="refresh-image"></Image>
						<h1>
							<Trans>home.faico360_card_connect.title_1</Trans>
							<Trans>home.faico360_card_connect.title_2</Trans>
						</h1>
					</div>
					<div className="col-8 p-0 content">
						<div className="arrow-list">
							<ArrowList
								items={[
									<Trans>home.faico360_card_connect.item_1</Trans>,
									<Trans>home.faico360_card_connect.item_2</Trans>,
									<Trans>home.faico360_card_connect.item_3</Trans>,
									<Trans>home.faico360_card_connect.item_4</Trans>
								]}
							/>
						</div>
						<Subtitle
							content={<Trans>home.faico360_body</Trans>}
							size={'home'}
							color={"White"}
						/>
						<Button className="button-connect" onClick={() =>  openFaico360NavModalCmd()/*navigate('/faico360')*/}>
							<Trans>home.faico360_service_details</Trans>
						</Button> 
					</div>
				</div>
			</div>
			{/*
			<div className="row-news">
				<h1><Trans>home.news.title</Trans></h1>
				<div className="content-cards">
					{
						postData.length !== 0 ? (
							postData.map((postData, i) => {
								let { id } = postData
								let {
									main_image,
									title,
									subtitle,
									tags
								} = postData.attributes
								return (
									<Col key={i} className="col-news" md={4}>
										<div className="card-info">
											<NoveltyTile
												key={i}
												id={id}
												image={main_image.url}
												title={title}
												subtitle={subtitle}
												tags={tags}
												urlPath={`/news/${id}`}
											>
											</NoveltyTile>
										</div>
									</Col>
								)
							})
						) : null
					}
				</div>
				<Button className="default-button" onClick={() => navigate('/news')}>
					<Trans>general.ver-mas</Trans>
				</Button>
			</div> */}
			<Faico360NavModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeFaico360NavModal}
					open={openFaico360NavModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="faico360"
				/>

				<FVconModalContainer
					classNamePopUp={"pop-up-style"}
					onButtonClose={closeFVconModal}
					open={openFVconModal}				
					title={<Trans>home.faico360NavModal.title</Trans>}
					description={<Trans>home.faico360NavModal.description</Trans>}
					confirmBtnMsg={<Trans>home.faico360NavModal.confirmBtnMsg</Trans>}
					cancelBtnMsg={<Trans>home.faico360NavModal.cancelBtnMsg</Trans>}
					route="dental/conicalConnection/newSmartImplants"
				/>
		</Styles>
	);
};
function mapStateToProps(state) {
	return {
		postProps: postsSelector(state),
	}
}

function mapDispatchToProps(dispatch) {
	return {
		fetchNews: (payload) => fetchNews(dispatch, payload),
		resetNews: (payload) => resetNews(dispatch, payload),
	}
}

export default withTranslation(['es']['contact'])(connect(
	mapStateToProps,
	mapDispatchToProps
)(Home))
