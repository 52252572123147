
//logos
import logoHexa from "../../../assets/images/faico360/clinicalCases/Logos/desktop/EI_Caratula3.png"
import logoFVcon from "../../../assets/images/faico360/clinicalCases/Logos/desktop/FVCON-marca-registrada-azul-negro.png"
import logoOneToOne from "../../../assets/images/faico360/clinicalCases/Logos/desktop/Logo_One-to-One.png"
import logoKiss from "../../../assets/images/faico360/clinicalCases/Logos/desktop/Logo_KiSS.png"
import logoHexaMobile from "../../../assets/images/faico360/clinicalCases/Logos/EDIT2-EI_Caratula3.png"
import logoFVconMobile from "../../../assets/images/faico360/clinicalCases/Logos/EDIT2-FVCON-marca-registrada-azul-negro.png"
import logoOneToOneMobile from "../../../assets/images/faico360/clinicalCases/Logos/EDIT2-Logo_One-to-One.png"
import logoKissMobile from "../../../assets/images/faico360/clinicalCases/Logos/EDIT2-Logo_KiSS.png"

//clinical case 4
import CC4CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/CC20-AP040-12.png"

import CC4learnMore from "../../../assets/pdf/faico360/CC20-AP040-12-FAICO.pdf";

import clinicalCase4DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/detail/01.png"
import clinicalCase4DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/detail/02.png"
import clinicalCase4DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/detail/03.png"
import clinicalCase4DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/detail/04.png"
import clinicalCase4DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/detail/05.png"
import clinicalCase4DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP040-12/detail/06.png"

//clinical case 7
import CC7CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/CC20-AP070-12.png"

import CC7learnMore from "../../../assets/pdf/faico360/CC20-AP070-12-FAICO.pdf";

import clinicalCase7DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/detail/01.png"
import clinicalCase7DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/detail/02.png"
import clinicalCase7DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/detail/03.png"
import clinicalCase7DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/detail/04.png"
import clinicalCase7DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/detail/05.png"
import clinicalCase7DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP070-12/detail/06.png"

//clinical case 8
import CC8CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/CC20-AP080-12.png"

import CC8learnMore from "../../../assets/pdf/faico360/CC20-AP080-12-FAICO.pdf";

import clinicalCase8DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/detail/01.png"
import clinicalCase8DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/detail/02.png"
import clinicalCase8DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/detail/03.png"
import clinicalCase8DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/detail/04.png"
import clinicalCase8DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/detail/05.png"
import clinicalCase8DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP080-12/detail/06.png"

//clinical case 9
import CC9CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/CC20-AP090-12.png"

import CC9learnMore from "../../../assets/pdf/faico360/CC20-AP090-12-FAICO.pdf";

import clinicalCase9DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/detail/01.png"
import clinicalCase9DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/detail/02.png"
import clinicalCase9DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/detail/03.png"
import clinicalCase9DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/detail/04.png"
import clinicalCase9DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/detail/05.png"
import clinicalCase9DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP090-12/detail/06.png"

//clinical case 10
import CC10CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/CC20-AP100-12.png"

import CC10learnMore from "../../../assets/pdf/faico360/CC20-AP100-12-FAICO.pdf";

import clinicalCase10DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/detail/01.png"
import clinicalCase10DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/detail/02.png"
import clinicalCase10DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/detail/03.png"
import clinicalCase10DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/detail/04.png"
import clinicalCase10DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/detail/05.png"
import clinicalCase10DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP100-12/detail/06.png"

//clinical case 13
import CC13CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/CC20-AP130-12.png"

import CC13learnMore from "../../../assets/pdf/faico360/CC20-AP130-12-FAICO.pdf";

import clinicalCase13DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/detail/01.png"
import clinicalCase13DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/detail/02.png"
import clinicalCase13DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/detail/03.png"
import clinicalCase13DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/detail/04.png"
import clinicalCase13DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/detail/05.png"
import clinicalCase13DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP130-12/detail/06.png"

//clinical case 14
import CC14CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/CC20-AP140-12.png"

import CC14learnMore from "../../../assets/pdf/faico360/CC20-AP140-12-FAICO.pdf";

import clinicalCase14DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/detail/01.png"
import clinicalCase14DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/detail/02.png"
import clinicalCase14DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/detail/03.png"
import clinicalCase14DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/detail/04.png"
import clinicalCase14DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/detail/05.png"
import clinicalCase14DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP140-12/detail/06.png"

//clinical case 16
import CC16CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/CC20-AP160-12.png"

import CC16learnMore from "../../../assets/pdf/faico360/CC20-AP160-12-FAICO.pdf";

import clinicalCase16DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/detail/01.png"
import clinicalCase16DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/detail/02.png"
import clinicalCase16DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/detail/03.png"
import clinicalCase16DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/detail/04.png"
import clinicalCase16DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/detail/05.png"
import clinicalCase16DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP160-12/detail/06.png"

//clinical case 20
import CC20CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/CC20-AP200-12.png"

import CC20learnMore from "../../../assets/pdf/faico360/CC20-AP200-12-FAICO.pdf";

import clinicalCase20DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/detail/01.png"
import clinicalCase20DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/detail/02.png"
import clinicalCase20DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/detail/03.png"
import clinicalCase20DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/detail/04.png"
import clinicalCase20DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/detail/05.png"
import clinicalCase20DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-AP200-12/detail/06.png"

//clinical case 21
import CC21CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/CC20-MB210-12.png"

import clinicalCase21DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/small/01.png"
import clinicalCase21DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/small/02.png"
import clinicalCase21DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/small/03.png"
import clinicalCase21DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/small/04.png"
import clinicalCase21DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/small/05.png"
import clinicalCase21DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/small/06.png"

//clinical case 22
import CC22CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/CC20-MB215-12.png"

import clinicalCase22DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/small/01.png"
import clinicalCase22DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/small/02.png"
import clinicalCase22DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/small/03.png"
import clinicalCase22DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/small/04.png"
import clinicalCase22DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/small/05.png"
import clinicalCase22DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/small/06.png"

//clinical case 23
import CC23CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/CC20-LC230-12.png"

import clinicalCase23DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/small/01.png"
import clinicalCase23DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/small/02.png"
import clinicalCase23DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/small/03.png"
import clinicalCase23DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/small/04.png"
import clinicalCase23DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/small/05.png"
import clinicalCase23DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/small/06.png"

//clinical case 24
import CC24CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/CC20-LC240-12.png"

import clinicalCase24DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/01.png"
import clinicalCase24DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/02.png"
import clinicalCase24DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/03.png"
import clinicalCase24DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/04.png"
import clinicalCase24DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/05.png"
import clinicalCase24DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/06.png"
import clinicalCase24DetailImg7 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/07.png"
import clinicalCase24DetailImg8 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/08.png"
import clinicalCase24DetailImg9 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/09.png"
import clinicalCase24DetailImg10 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/10.png"
import clinicalCase24DetailImg11 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/11.png"
import clinicalCase24DetailImg12 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/12.png"
import clinicalCase24DetailImg13 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/13.png"
import clinicalCase24DetailImg14 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/14.png"
import clinicalCase24DetailImg15 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/15.png"
import clinicalCase24DetailImg16 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/16.png"
import clinicalCase24DetailImg17 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/17.png"
import clinicalCase24DetailImg18 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/18.png"
import clinicalCase24DetailImg19 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/19.png"
import clinicalCase24DetailImg20 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/20.png"
import clinicalCase24DetailImg21 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/21.png"
import clinicalCase24DetailImg22 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/small/22.png"


//clinical case 25
import CC25CarrouseImgDesktop from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/CC20-LC250-12.png"

import clinicalCase25DetailImg1 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/01.png"
import clinicalCase25DetailImg2 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/02.png"
import clinicalCase25DetailImg3 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/03.png"
import clinicalCase25DetailImg4 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/04.png"
import clinicalCase25DetailImg5 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/05.png"
import clinicalCase25DetailImg6 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/06.png"
import clinicalCase25DetailImg7 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/07.png"
import clinicalCase25DetailImg8 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/08.png"
import clinicalCase25DetailImg9 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/09.png"
import clinicalCase25DetailImg10 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/10.png"
import clinicalCase25DetailImg11 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/11.png"
import clinicalCase25DetailImg12 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/12.png"
import clinicalCase25DetailImg13 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/13.png"
import clinicalCase25DetailImg14 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/14.png"
import clinicalCase25DetailImg15 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/15.png"
import clinicalCase25DetailImg16 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/small/16.png"

//clinical case 21 ampliable

import clinicalCase21DetailImgzoom1 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/detail/01.jpg"
import clinicalCase21DetailImgzoom2 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/detail/02.jpg"
import clinicalCase21DetailImgzoom3 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/detail/03.jpg"
import clinicalCase21DetailImgzoom4 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/detail/04.jpg"
import clinicalCase21DetailImgzoom5 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/detail/05.jpg"
import clinicalCase21DetailImgzoom6 from "../../../assets/images/faico360/clinicalCases/CC20-MB210-12/detail/06.jpg"

//clinical case 22 ampliable

import clinicalCase22DetailImgzoom1 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/detail/01.jpg"
import clinicalCase22DetailImgzoom2 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/detail/02.jpg"
import clinicalCase22DetailImgzoom3 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/detail/03.jpg"
import clinicalCase22DetailImgzoom4 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/detail/04.jpg"
import clinicalCase22DetailImgzoom5 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/detail/05.jpg"
import clinicalCase22DetailImgzoom6 from "../../../assets/images/faico360/clinicalCases/CC20-MB215-12/detail/06.jpg"

//clinical case 23 ampliable

import clinicalCase23DetailImgzoom1 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/detail/01.jpg"
import clinicalCase23DetailImgzoom2 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/detail/02.jpg"
import clinicalCase23DetailImgzoom3 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/detail/03.jpg"
import clinicalCase23DetailImgzoom4 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/detail/04.jpg"
import clinicalCase23DetailImgzoom5 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/detail/05.jpg"
import clinicalCase23DetailImgzoom6 from "../../../assets/images/faico360/clinicalCases/CC20-LC230-12/detail/06.jpg"

//clinical case 24 ampliable

import clinicalCase24DetailImgzoom1 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/01.jpg"
import clinicalCase24DetailImgzoom2 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/02.jpg"
import clinicalCase24DetailImgzoom3 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/03.jpg"
import clinicalCase24DetailImgzoom4 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/04.jpg"
import clinicalCase24DetailImgzoom5 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/05.jpg"
import clinicalCase24DetailImgzoom6 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/06.jpg"
import clinicalCase24DetailImgzoom7 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/07.jpg"
import clinicalCase24DetailImgzoom8 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/08.jpg"
import clinicalCase24DetailImgzoom9 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/09.jpg"
import clinicalCase24DetailImgzoom10 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/10.jpg"
import clinicalCase24DetailImgzoom11 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/11.jpg"
import clinicalCase24DetailImgzoom12 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/12.jpg"
import clinicalCase24DetailImgzoom13 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/13.jpg"
import clinicalCase24DetailImgzoom14 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/14.jpg"
import clinicalCase24DetailImgzoom15 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/15.jpg"
import clinicalCase24DetailImgzoom16 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/16.jpg"
import clinicalCase24DetailImgzoom17 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/17.jpg"
import clinicalCase24DetailImgzoom18 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/18.jpg"
import clinicalCase24DetailImgzoom19 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/19.jpg"
import clinicalCase24DetailImgzoom20 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/20.jpg"
import clinicalCase24DetailImgzoom21 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/21.jpg"
import clinicalCase24DetailImgzoom22 from "../../../assets/images/faico360/clinicalCases/CC20-LC240-12/detail/22.jpg"


//clinical case 25 ampliable

import clinicalCase25DetailImgzoom1 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/01.jpg"
import clinicalCase25DetailImgzoom2 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/02.jpg"
import clinicalCase25DetailImgzoom3 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/03.jpg"
import clinicalCase25DetailImgzoom4 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/04.jpg"
import clinicalCase25DetailImgzoom5 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/05.jpg"
import clinicalCase25DetailImgzoom6 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/06.jpg"
import clinicalCase25DetailImgzoom7 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/07.jpg"
import clinicalCase25DetailImgzoom8 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/08.jpg"
import clinicalCase25DetailImgzoom9 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/09.jpg"
import clinicalCase25DetailImgzoom10 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/10.jpg"
import clinicalCase25DetailImgzoom11 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/11.jpg"
import clinicalCase25DetailImgzoom12 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/12.jpg"
import clinicalCase25DetailImgzoom13 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/13.jpg"
import clinicalCase25DetailImgzoom14 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/14.jpg"
import clinicalCase25DetailImgzoom15 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/15.jpg"
import clinicalCase25DetailImgzoom16 from "../../../assets/images/faico360/clinicalCases/CC20-LC250-12/detail/16.jpg"

const MainImgMobileStyleFvcon = {"max-height": "161px"};
const MainImgMobileStyleKiss = {"max-height": "99px"};
const MainImgMobileStyleonetoone = {"max-height": "151px"};
const MainImgMobileStyleHexa = {"max-height": "250px"};


const clinicalCases = [
	{
		number: "21",
		code: "CC20-MB210-12",
		title: "faico360.clinicalCases.cases.21.title",
		CarouselImgDesktop: CC21CarrouseImgDesktop,
		detail: {
			mainImage: logoKiss,
			mainImageMobile: logoKiss,
			mainImageMobileStyle: MainImgMobileStyleKiss,
			images: [
				clinicalCase21DetailImg1,
				clinicalCase21DetailImg2,
				clinicalCase21DetailImg3,
				clinicalCase21DetailImg4,
				clinicalCase21DetailImg5,
				clinicalCase21DetailImg6
			],
			imagesZoom:[
				clinicalCase21DetailImgzoom1,
				clinicalCase21DetailImgzoom2,
				clinicalCase21DetailImgzoom3,
				clinicalCase21DetailImgzoom4,
				clinicalCase21DetailImgzoom5,
				clinicalCase21DetailImgzoom6
			],
			learnMore: null,
			next: "/faico360/1",
			previous: "",
			isFirst: true,
			isLast: false
		}
	},
	{
		number: "22",
		code: "CC20-MB215-12",
		title: "faico360.clinicalCases.cases.22.title",
		CarouselImgDesktop: CC22CarrouseImgDesktop,
		detail: {
			mainImage: logoOneToOne,
			mainImageMobile: logoOneToOne,
			mainImageMobileStyle: MainImgMobileStyleonetoone,
			images: [
				clinicalCase22DetailImg1,
				clinicalCase22DetailImg2,
				clinicalCase22DetailImg3,
				clinicalCase22DetailImg4,
				clinicalCase22DetailImg5,
				clinicalCase22DetailImg6
			],
			imagesZoom:[			
				clinicalCase22DetailImgzoom1,
				clinicalCase22DetailImgzoom2,
				clinicalCase22DetailImgzoom3,
				clinicalCase22DetailImgzoom4,
				clinicalCase22DetailImgzoom5,
				clinicalCase22DetailImgzoom6
			],
			learnMore: null,
			next: "/faico360/2",
			previous: "/faico360/0",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "23",
		code: "CC20-LC230-12",
		title: "faico360.clinicalCases.cases.23.title",
		CarouselImgDesktop: CC23CarrouseImgDesktop,
		detail: {
			mainImage: logoKiss,
			mainImageMobile: logoKiss,
			mainImageMobileStyle: MainImgMobileStyleKiss,
			images: [
				clinicalCase23DetailImg1,
				clinicalCase23DetailImg2,
				clinicalCase23DetailImg3,
				clinicalCase23DetailImg4,
				clinicalCase23DetailImg5,
				clinicalCase23DetailImg6
			],
			imagesZoom:[			
				clinicalCase23DetailImgzoom1,
				clinicalCase23DetailImgzoom2,
				clinicalCase23DetailImgzoom3,
				clinicalCase23DetailImgzoom4,
				clinicalCase23DetailImgzoom5,
				clinicalCase23DetailImgzoom6
			],
			learnMore: null,
			next: "/faico360/3",
			previous: "/faico360/1",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "24",
		code: "CC20-LC240-12",
		title: "faico360.clinicalCases.cases.24.title",
		CarouselImgDesktop: CC24CarrouseImgDesktop,
		detail: {
			mainImage: logoFVcon,
			mainImageMobile: logoFVcon,
			mainImageMobileStyle: MainImgMobileStyleFvcon,
			images: [
				clinicalCase24DetailImg1,
				clinicalCase24DetailImg2,
				clinicalCase24DetailImg3,
				clinicalCase24DetailImg4,
				clinicalCase24DetailImg5,
				clinicalCase24DetailImg6,
				clinicalCase24DetailImg7,
				clinicalCase24DetailImg8,
				clinicalCase24DetailImg9,
				clinicalCase24DetailImg10,
				clinicalCase24DetailImg11,
				clinicalCase24DetailImg12,
				clinicalCase24DetailImg13,
				clinicalCase24DetailImg14,
				clinicalCase24DetailImg15,
				clinicalCase24DetailImg16,
				clinicalCase24DetailImg17,
				clinicalCase24DetailImg18,
				clinicalCase24DetailImg19,
				clinicalCase24DetailImg20,
				clinicalCase24DetailImg21,
				clinicalCase24DetailImg22
			],
			imagesZoom:[			
				clinicalCase24DetailImgzoom1,
				clinicalCase24DetailImgzoom2,
				clinicalCase24DetailImgzoom3,
				clinicalCase24DetailImgzoom4,
				clinicalCase24DetailImgzoom5,
				clinicalCase24DetailImgzoom6,
				clinicalCase24DetailImgzoom7,
				clinicalCase24DetailImgzoom8,
				clinicalCase24DetailImgzoom9,
				clinicalCase24DetailImgzoom10,
				clinicalCase24DetailImgzoom11,
				clinicalCase24DetailImgzoom12,
				clinicalCase24DetailImgzoom13,
				clinicalCase24DetailImgzoom14,
				clinicalCase24DetailImgzoom15,
				clinicalCase24DetailImgzoom16,
				clinicalCase24DetailImgzoom17,
				clinicalCase24DetailImgzoom18,
				clinicalCase24DetailImgzoom19,
				clinicalCase24DetailImgzoom20,
				clinicalCase24DetailImgzoom21,
				clinicalCase24DetailImgzoom22
			],
			learnMore: null,
			next: "/faico360/4",
			previous: "/faico360/2",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "25",
		code: "CC20-LC250-12",
		title: "faico360.clinicalCases.cases.25.title",
		CarouselImgDesktop: CC25CarrouseImgDesktop,
		detail: {
			mainImage: logoFVcon,
			mainImageMobile: logoFVcon,
			mainImageMobileStyle: MainImgMobileStyleFvcon,
			images: [
				clinicalCase25DetailImg1,
				clinicalCase25DetailImg2,
				clinicalCase25DetailImg3,
				clinicalCase25DetailImg4,
				clinicalCase25DetailImg5,
				clinicalCase25DetailImg6,
				clinicalCase25DetailImg7,
				clinicalCase25DetailImg8,
				clinicalCase25DetailImg9,
				clinicalCase25DetailImg10,
				clinicalCase25DetailImg11,
				clinicalCase25DetailImg12,
				clinicalCase25DetailImg13,
				clinicalCase25DetailImg14,
				clinicalCase25DetailImg15,
				clinicalCase25DetailImg16
			],
			imagesZoom:[			
				clinicalCase25DetailImgzoom1,
				clinicalCase25DetailImgzoom2,
				clinicalCase25DetailImgzoom3,
				clinicalCase25DetailImgzoom4,
				clinicalCase25DetailImgzoom5,
				clinicalCase25DetailImgzoom6,
				clinicalCase25DetailImgzoom7,
				clinicalCase25DetailImgzoom8,
				clinicalCase25DetailImgzoom9,
				clinicalCase25DetailImgzoom10,
				clinicalCase25DetailImgzoom11,
				clinicalCase25DetailImgzoom12,
				clinicalCase25DetailImgzoom13,
				clinicalCase25DetailImgzoom14,
				clinicalCase25DetailImgzoom15,
				clinicalCase25DetailImgzoom16
			],
			learnMore: null,
			next: "/faico360/5",
			previous: "/faico360/3",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "4",
		code: "CC20-AP040-12",
		title: "faico360.clinicalCases.cases.4.title",
		CarouselImgDesktop: CC4CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase4DetailImg1,
				clinicalCase4DetailImg2,
				clinicalCase4DetailImg3,
				clinicalCase4DetailImg4,
				clinicalCase4DetailImg5,
				clinicalCase4DetailImg6
			],
			imagesZoom:[	
				clinicalCase4DetailImg1,
				clinicalCase4DetailImg2,
				clinicalCase4DetailImg3,
				clinicalCase4DetailImg4,
				clinicalCase4DetailImg5,
				clinicalCase4DetailImg6
			],
			learnMore: CC4learnMore,
			next: "/faico360/6",
			previous: "/faico360/4",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "7",
		code: "CC20-AP070-12",
		title: "faico360.clinicalCases.cases.7.title",
		CarouselImgDesktop: CC7CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase7DetailImg1,
				clinicalCase7DetailImg2,
				clinicalCase7DetailImg3,
				clinicalCase7DetailImg4,
				clinicalCase7DetailImg5,
				clinicalCase7DetailImg6
			],
			imagesZoom:[
				clinicalCase7DetailImg1,
				clinicalCase7DetailImg2,
				clinicalCase7DetailImg3,
				clinicalCase7DetailImg4,
				clinicalCase7DetailImg5,
				clinicalCase7DetailImg6
			],
			learnMore: CC7learnMore,
			next: "/faico360/7",
			previous: "/faico360/5",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "8",
		code: "CC20-AP080-12",
		title: "faico360.clinicalCases.cases.8.title",
		CarouselImgDesktop: CC8CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase8DetailImg1,
				clinicalCase8DetailImg2,
				clinicalCase8DetailImg3,
				clinicalCase8DetailImg4,
				clinicalCase8DetailImg5,
				clinicalCase8DetailImg6
			],
			imagesZoom:[			
				clinicalCase8DetailImg1,
				clinicalCase8DetailImg2,
				clinicalCase8DetailImg3,
				clinicalCase8DetailImg4,
				clinicalCase8DetailImg5,
				clinicalCase8DetailImg6
			],
			learnMore: CC8learnMore,
			next: "/faico360/8",
			previous: "/faico360/6",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "9",
		code: "CC20-AP090-12",
		title: "faico360.clinicalCases.cases.9.title",
		CarouselImgDesktop: CC9CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase9DetailImg1,
				clinicalCase9DetailImg2,
				clinicalCase9DetailImg3,
				clinicalCase9DetailImg4,
				clinicalCase9DetailImg5,
				clinicalCase9DetailImg6
			],
			imagesZoom:[			
				clinicalCase9DetailImg1,
				clinicalCase9DetailImg2,
				clinicalCase9DetailImg3,
				clinicalCase9DetailImg4,
				clinicalCase9DetailImg5,
				clinicalCase9DetailImg6
			],
			learnMore: CC9learnMore,
			next: "/faico360/9",
			previous: "/faico360/7",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "10",
		code: "CC20-AP100-12",
		title: "faico360.clinicalCases.cases.10.title",
		CarouselImgDesktop: CC10CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase10DetailImg1,
				clinicalCase10DetailImg2,
				clinicalCase10DetailImg3,
				clinicalCase10DetailImg4,
				clinicalCase10DetailImg5,
				clinicalCase10DetailImg6
			],
			imagesZoom:[
				clinicalCase10DetailImg1,
				clinicalCase10DetailImg2,
				clinicalCase10DetailImg3,
				clinicalCase10DetailImg4,
				clinicalCase10DetailImg5,
				clinicalCase10DetailImg6
			],
			learnMore: CC10learnMore,
			next: "/faico360/10",
			previous: "/faico360/8",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "13",
		code: "CC20-AP130-12",
		title: "faico360.clinicalCases.cases.13.title",
		CarouselImgDesktop: CC13CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase13DetailImg1,
				clinicalCase13DetailImg2,
				clinicalCase13DetailImg3,
				clinicalCase13DetailImg4,
				clinicalCase13DetailImg5,
				clinicalCase13DetailImg6
			],
			imagesZoom:[			
				clinicalCase13DetailImg1,
				clinicalCase13DetailImg2,
				clinicalCase13DetailImg3,
				clinicalCase13DetailImg4,
				clinicalCase13DetailImg5,
				clinicalCase13DetailImg6
			],
			learnMore: CC13learnMore,
			next: "/faico360/11",
			previous: "/faico360/9",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "14",
		code: "CC20-AP140-12",
		title: "faico360.clinicalCases.cases.14.title",
		CarouselImgDesktop: CC14CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase14DetailImg1,
				clinicalCase14DetailImg2,
				clinicalCase14DetailImg3,
				clinicalCase14DetailImg4,
				clinicalCase14DetailImg5,
				clinicalCase14DetailImg6
			],
			imagesZoom:[			
				clinicalCase14DetailImg1,
				clinicalCase14DetailImg2,
				clinicalCase14DetailImg3,
				clinicalCase14DetailImg4,
				clinicalCase14DetailImg5,
				clinicalCase14DetailImg6
			],
			learnMore: CC14learnMore,
			next: "/faico360/12",
			previous: "/faico360/10",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "16",
		code: "CC20-AP160-12",
		title: "faico360.clinicalCases.cases.16.title",
		CarouselImgDesktop: CC16CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase16DetailImg1,
				clinicalCase16DetailImg2,
				clinicalCase16DetailImg3,
				clinicalCase16DetailImg4,
				clinicalCase16DetailImg5,
				clinicalCase16DetailImg6
			],
			imagesZoom:[			
				clinicalCase16DetailImg1,
				clinicalCase16DetailImg2,
				clinicalCase16DetailImg3,
				clinicalCase16DetailImg4,
				clinicalCase16DetailImg5,
				clinicalCase16DetailImg6
			],
			learnMore: CC16learnMore,
			next: "/faico360/13",
			previous: "/faico360/11",
			isFirst: false,
			isLast: false
		}
	},
	{
		number: "20",
		code: "CC20-AP200-12",
		title: "faico360.clinicalCases.cases.20.title",
		CarouselImgDesktop: CC20CarrouseImgDesktop,
		detail: {
			mainImage: logoHexa,
			mainImageMobile: logoHexa,
			mainImageMobileStyle: MainImgMobileStyleHexa,
			images: [
				clinicalCase20DetailImg1,
				clinicalCase20DetailImg2,
				clinicalCase20DetailImg3,
				clinicalCase20DetailImg4,
				clinicalCase20DetailImg5,
				clinicalCase20DetailImg6
			],
			imagesZoom:[			
				clinicalCase20DetailImg1,
				clinicalCase20DetailImg2,
				clinicalCase20DetailImg3,
				clinicalCase20DetailImg4,
				clinicalCase20DetailImg5,
				clinicalCase20DetailImg6
			],
			learnMore: CC20learnMore,
			next: "",
			previous: "/faico360/12",
			isFirst: false,
			isLast: true
		}
	},
	{
		skip: true,
		number: null,
		code: null,
		title: null,
		CarouselImgDesktop: null,
		detail: {
			mainImage: null,
			images: [		
			],
			imagesZoom:[
			],
			learnMore: null,
			next: null,
			previous: null,
			isFirst: null,
			isLast: null
		}
	}
];

export default clinicalCases;