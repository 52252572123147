import React from 'react'
import LevelOne from '../../maxillofacial/mobile/LevelOne';

const MandibularSystem = () => {
	return (
		<LevelOne
			system={"mandibularSystem"}
		/>
	)
}

export default (MandibularSystem)